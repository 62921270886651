import {
  types,
  fetchProjectSectionsAction,
  delegateTaskAction,
  createTaskAction,
} from "./action_types";
import { takeLatest, put, call } from "redux-saga/effects";
import { store } from "../../App";
import { changeTaskList, fetchTasks, setTaskAddMode } from "../Tasks/actions";
import { fetchData } from "../../utils/fetchData";
import { CreateNotif } from "../../utils/createNotification";
import {
  setIsSendingTask,
  clearTask,
  setProjectSections,
  setTaskWorkLoad,
  setTaskLoad,
  lastChanged,
  successful_create_new_task,
} from "./actions";
import { updateTask } from "../../common/actions";
import { selectTask, setShownTaskInfoPlate } from "../TaskInfoPlate/actions";

function* createTask({ executor_id }: createTaskAction) {
  yield put(setIsSendingTask(true));
  const { newTask, tasksPage } = store.getState();

  let body = {
      name: newTask.name,
      description: newTask.description,
      begin: newTask.begin.replace(/\//g, "-"),
      end: newTask.end.replace(/\//g, "-"),
      task_load: newTask.task_load,
      work_load: newTask.work_load,
      provide_to: newTask.provide_to,
      project_id: newTask.project_id,
      executor_id: executor_id ? executor_id : newTask.executor_id,
      spectators: newTask.spectators ? newTask.spectators : newTask.spectators,
      priority_id: newTask.priority_id,
      workflow_id: newTask.workflow_id,
      status_id: newTask.executor_id === newTask.author_id ? 10 : 3,
      project_section_id: newTask.project_section_id,
      parent_id: newTask.parent_id,
      next_id: newTask.next_id,
      prev_id: newTask.prev_id,
      id_bp: newTask.id_bp,

    },
    taskId = newTask.id,
    task;

  if (taskId) {
    delete body["status_id"]; // при редактировании задачи нельзя отправлять статус, иначе хана
    yield put(updateTask(taskId, body));
    yield put(setTaskAddMode(false));
  } else {
    task = yield call(fetchData.post, "/api/v1/tasks", JSON.stringify(body));
    if (task) {
      yield put(
        successful_create_new_task([
          ...store.getState().newTask.successfulCreatedTask,
          task.id,
        ])
      );
      yield put(changeTaskList([...tasksPage.tasks, task]));
      // CreateNotif('Задача успешно сохранена', 'success')
      yield put(setTaskAddMode(false));
      yield put(clearTask());

      yield put(changeTaskList([]));
      yield put(fetchTasks(1));

      yield put(selectTask(task));
      yield put(setShownTaskInfoPlate(true));
    }
  }
  yield put(setIsSendingTask(false));
}

function* fetchProjectSections({ projectId }: fetchProjectSectionsAction) {
  yield put(setProjectSections([]));
  const sections = yield call(
    fetchData.get,
    `/api/v1/projects/${projectId}/sections`
  );
  if (sections) {
    yield put(setProjectSections(sections));
  }
}

function* delegateTask({ executor_id, taskId }: delegateTaskAction) {
  const data = yield call(
    fetchData.post,
    `/api/v1/tasks/${taskId}/delegate`,
    JSON.stringify({ user_id: executor_id })
  );
  if (data.success) {
    yield put(setTaskAddMode(false));
    yield put(clearTask());
    CreateNotif("Задача успешно делегирована", "success");
  }
}

function* recalculateLoad({ arg }: any) {
  const { executor_id, begin, end, task_load, work_load, lastChangedValue } =
    store.getState().newTask;
  if (executor_id && begin && end && task_load) {
    let sections;

    if (arg) {
      // исполнение пришло с изменения с клавиатуры
      let request_url = "/api/v1/task-load?";
      const actionType = Object.keys(arg)[0];

      if (actionType === "userId") {
        request_url +=
          actionType +
          "=" +
          Object.values(arg)[0] +
          `&begin=${begin}&end=${end}&task_load=${task_load}`;
      }

      if (["task_load", "work_load"].indexOf(actionType) + 1) {
        // сравниваю одно значение с несколькими
        request_url +=
          `userId=${executor_id}&begin=${begin}&end=${end}&` +
          actionType +
          "=" +
          Object.values(arg)[0];
        if (actionType === "task_load") {
          yield put(lastChanged("task_load"));
        } else if (actionType === "work_load") {
          yield put(lastChanged("work_load"));
        }
      }

      sections = yield call(fetchData.get, request_url);
    } else {
      sections = yield call(
        fetchData.get,
        `/api/v1/task-load?userId=${executor_id}&begin=${begin}&end=${end}&` +
          (lastChangedValue === "task_load"
            ? "task_load=" + task_load
            : "work_load=" + work_load)
      );
    }

    if (!sections) {
      CreateNotif(sections);
    } else {
      if (Object.keys(sections).length === 1) {
        if (!parseFloat(Object.keys(sections)[0])) {
          // тут считываются вещественные данные для задач
          if (sections[0] === "Incorrect working hours!") {
            CreateNotif("Нерабочие часы исполнителя !");
          }
        }

        switch (Object.keys(sections)[0]) {
          case "task_load":
            yield put(setTaskLoad(sections[Object.keys(sections)[0]]));
            break;
          case "work_load":
            yield put(setTaskWorkLoad(sections[Object.keys(sections)[0]]));
            break;
        }
      } else CreateNotif("с сервера вернулось больше 2х параметров");
    }
  }
}

export function* watchSetRecalculateLoad() {
  yield takeLatest(types.SET_RECALCULATE, recalculateLoad);
}

export function* watchCreateTask() {
  yield takeLatest(types.CREATE_TASK, createTask);
}

export function* watchFetchSections() {
  yield takeLatest(types.FETCH_PROJECT_SECTIONS, fetchProjectSections);
}

export function* watchDelegateTask() {
  yield takeLatest(types.DELEGATE_TASK, delegateTask);
}
