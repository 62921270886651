import React, { useContext } from "react";
import { StatusContext } from "../../context/status";
import TaskItem from "../task-item/TaskItem";

const TasksList = ({ tasks, projectName, projectSectionName }) => {
  const {
    openMenuTasks,
    openMenuBp,
    createTaskStatus,
    createBpSampleStatus,
    createBpStatus,
  } = useContext(StatusContext);

  if (tasks.length > 0) {
    return (
      <div
        className="dependencies"
        style={
          openMenuTasks ||
          openMenuBp ||
          createTaskStatus ||
          createBpSampleStatus ||
          createBpStatus
            ? { paddingLeft: 65 + "px" }
            : {}
        }
      >
        <div className="dependencies__content">
          <div className="dependencies__content-list">
            {tasks.map((el) => {
              if (el.status !== 6) {
                return (
                  <TaskItem
                    el={el}
                    style={"dropdown"}
                    key={el.id}
                    projectName={projectName}
                    projectSectionName={projectSectionName}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default TasksList;
