import React, {useCallback, useState, useRef, useEffect, useMemo} from 'react';
import AddButton from '../../../common/components/Buttons/AddButton';
import UserProjectSelect from '../../../common/components/Selects/UserProjectSelect';
import {Project, SelectData, Section, Task, User} from '../../../common/types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getProjects} from '../../../common/selectors';
import {State} from '../../../rootReducer';

import DotsButton from '../../../common/components/Buttons/DotsButton';
import {openExistingProject} from '../../NewProject/actions';
import {useHistory, useParams} from 'react-router-dom';
import {fetchData} from '../../../utils/fetchData';
import TableSearch from '../../../common/components/TableSearch/TableSearch';

import FilterButton from '../../../common/components/Buttons/filterButton';
import ViewWeekRoundedIcon from '@material-ui/icons/ViewWeekRounded';
import ProjectsFilters from '../../Projects/components/ProjectFilters';
import ColumnsWindow from '../../../common/ColumnsCustomWindow';
import Button from '../../../common/components/newComponent/Buttons/Button';
import Toggler from '../../../common/components/Togglers/Toggler';
import {
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    ShowFiltersWindow,
} from '../../../common/actions';
import FiltersWindow from '../../../common/FiltersWindow';
import Icon from '../../../common/components/Icon';
import {cookieMaster} from '../../../utils/CookieMaster';
import {urlApp} from '../../../App';
import {createDeleteResolutionItem, patchResolutionItem, setNewParametersToReducer} from "../actions";
import styled from "styled-components";
import DateMaster from "../../../utils/DateMaster";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import MultySelect from "../../../common/components/Selects/MultySelect";
import CheckboxItem from "../../../common/components/CheckboxItem/CheckboxItem";
import TaskDateTime from "../../../common/components/DatePickers/DateTimePiker";
import {TextareaAutosize} from "@material-ui/core";

type Props = {
    taskItem: any,
    commonUsers: User[],
    checkList: any[],
    createDeleteResolutionItem: (context: string, delete_index?: number) => void    // если послать без delete_index, то создаст. Если с ним - удалит
    patchResolutionItem: (context: string, id: number, body: object) => void
};


const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 36px;
  margin-right: 8px;
`;


const RuleItem: React.FC<Props> = ({
                                       taskItem,
                                       commonUsers,
                                       checkList,
                                       createDeleteResolutionItem,
                                       patchResolutionItem
                                   }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [timer, setTimer] =
        useState<ReturnType<typeof setTimeout> | null>(null);
    // создал изолированную копию объекта, чтобы не перезаписать пропс
    // const newRolesArr = currentUserRoles?.map((item) => {
    //     return item;
    // });
    // newRolesArr?.sort();

    const [showMenu, setShow] = useState<boolean>(false);
    const {id} = useParams();
    const menu = useRef<HTMLDivElement>(null);
    const tasksOrder = useSelector(
        (state: State) => state.projectsPage.tasksOrder
    );
    const outsideClick = useCallback((e: Event) => {
        if (!menu.current?.contains(e.target as HTMLElement)) setShow(false);
    }, []);

    // useEffect(() => {
    //   document.addEventListener('click', outsideClick);
    //   return () => document.removeEventListener('click', outsideClick);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    let company_id = localStorage.getItem('company_id');


    const begin = useMemo(() => {
        if (taskItem.begin) return new DateMaster(taskItem.begin);
        else return null
    }, [taskItem]);


    const end = useMemo(() => {
        if (taskItem.end) return new DateMaster(taskItem.end);
        else return null
    }, [taskItem]);

    const [agendaDeleted, setAgendaDeleted] = useState<boolean>(false);
    const [bufferTextEdit, setBufferTextEdit] = useState<string>('');
    const [editName, setEditName] = useState<boolean>(false);
    const [editDescription, setEditDescription] = useState<boolean>(false);
    // const [editBegin, setEditBegin] = useState<boolean>(false);
    // const [editEnd, setEditEnd] = useState<boolean>(false);

    // console.log(taskItem)
    return (
        <div className={'taskItem'}>

            <FlexDiv style={{fontSize: '14px', fontWeight: 500}}>
                <FlexDiv>
                    <div style={{paddingTop: '8px'}}>
                        <CheckboxItem text={''} checked={taskItem.done} value={50}
                                      addHandler={() => {
                                          patchResolutionItem('task', taskItem.id, {done: 1})
                                      }}
                                      removeHandler={() => {
                                          patchResolutionItem('task', taskItem.id, {done: 0})
                                      }}/>
                    </div>
                    {!editName &&
                    <div style={{minWidth: '50px'}} onClick={() => setEditName(true)}>{taskItem.name}</div>}
                    {editName &&
                    <TextareaAutosize style={{width: '450px'}}
                                      value={bufferTextEdit.length ? bufferTextEdit : taskItem.name}
                                      onChange={event => {
                                          setBufferTextEdit(event.target.value)
                                      }}
                                      autoFocus={true}
                                      onFocus={(event) => setBufferTextEdit(event.target.value)}
                                      onBlur={() => {
                                          patchResolutionItem('task', taskItem.id, {name: bufferTextEdit})
                                          setBufferTextEdit('')
                                          setEditName(false)
                                      }}
                    />}
                </FlexDiv>
                <div>
                    <Icon name={'cross'} onClick={() => {
                        createDeleteResolutionItem('task', taskItem.id)
                    }}/>
                </div>
            </FlexDiv>

            <FlexDiv>
                {!editDescription &&
                <div style={{minWidth: '50px'}} onClick={() => setEditDescription(true)}>{taskItem.description}</div>}
                {editDescription &&
                <TextareaAutosize style={{width: '100%'}}
                                  value={bufferTextEdit.length ? bufferTextEdit : taskItem.description}
                                  onChange={event => {
                                      setBufferTextEdit(event.target.value)
                                  }}
                                  autoFocus={true}
                                  onFocus={(event) => setBufferTextEdit(event.target.value)}
                                  onBlur={() => {
                                      patchResolutionItem('task', taskItem.id, {description: bufferTextEdit})
                                      setBufferTextEdit('')
                                      setEditDescription(false)
                                  }}
                />}
            </FlexDiv>

            <NameWithInput title="Начало" iconName="calendar-black">
                <TaskDateTime
                    acceptHandler={(val) => {
                        patchResolutionItem('task', taskItem.id, {begin: val})
                    }}
                    date={
                        taskItem.begin ? taskItem.begin : ''
                    }
                />
            </NameWithInput>

            <NameWithInput title="Конец" iconName="calendar-black">
                <TaskDateTime
                    acceptHandler={(val) => {
                        patchResolutionItem('task', taskItem.id, {end: val})
                    }}
                    date={
                        taskItem.end ? taskItem.end : ''
                    }
                />
            </NameWithInput>


            <FlexDiv>
                <NameWithInput title="Ответственный" iconName="people">
                    <MultySelect itemsList={commonUsers.map(user => {
                        return {
                            text: user.name + ' ' + user.surname,
                            value: user.id
                        }
                    })}
                                 radio={true}
                                 selectedItems={
                                     taskItem.responsible_id ? [commonUsers.find(user => taskItem.responsible_id === user.id)].map((item, number) => {
                                         return {text: item?.name + ' ' + item?.surname, value: number}
                                     }) : []
                                 }
                                 addHandler={(value) => {
                                     patchResolutionItem('task', taskItem.id, {responsible_id: value})
                                 }}
                                 removeHandler={() => {
                                     patchResolutionItem('task', taskItem.id, {responsible_id: null})
                                 }}
                    />
                </NameWithInput>
            </FlexDiv>

            <FlexDiv>
                <NameWithInput title="На кого распространяется" iconName="people">
                    <MultySelect itemsList={commonUsers.map(user => {
                        return {
                            text: user.name + ' ' + user.surname,
                            value: user.id
                        }
                    })}
                                 selectedItems={
                                     [...commonUsers.filter(user => taskItem.executors.includes(user.id))].map(item => {
                                         return {text: item?.name + ' ' + item?.surname, value: item.id}
                                     })
                                 }
                                 addHandler={(value) => {
                                     patchResolutionItem('task', taskItem.id, {executors: [...taskItem.executors, value]})
                                 }}
                                 removeHandler={(value) => {
                                     patchResolutionItem('task', taskItem.id, {remove_executors: [value]})
                                 }}
                    />
                </NameWithInput>
            </FlexDiv>

            <NameWithInput title="Пункт повестки" iconName="people">
                <MultySelect itemsList={checkList.map(item => {
                    return {
                        text: item.name,
                        value: item.id
                    }
                })}
                             radio={true}
                             selectedItems={
                                 agendaDeleted ? [] :
                                     taskItem.agenda_item_id ? [checkList.find(item => item.id === taskItem.agenda_item_id)].map(item => ({
                                                 text: item.name,
                                                 value: item.id
                                             }
                                         ))
                                         : []
                             }
                             addHandler={(value) => {
                                 patchResolutionItem('task', taskItem.id, {agenda_item_id: value})
                             }}
                             removeHandler={() => {
                                 setAgendaDeleted(true)
                             }}
                />
            </NameWithInput>
        </div>
    );
};

const mapStateToProps = (state: State) => {
    return {
        appliedFiltersExist: Boolean(
            Object.keys(state.projectsPage.filters.appliedFilters).length
        ),
        reducerRules: state.meetingsPage.meetingRules,
        projects: getProjects(state),
        // selectData: getProjectSelectData(state),
        selectedProject: state.projectsPage.selectedProject,
        sections: state.commonInfo.sections,
        // showFiltersWindow: state.projectsPage.filtersWindowShow,
        showFiltersWindow: state.commonInfo.filters.showFiltersWindow,
        currentUserRoles: state.commonInfo.currentUserInfo?.roles,
        tableFilter: state.projectsPage.tableFilter,
        projectId: state.projectsPage.selectedProject?.id || null,
        showColumnsWindow: state.projectsPage.showColumnsWindow,
        tableOrTreeToggler: state.projectsPage.tableOrTreeToggler,
        current_user: state.commonInfo.current_user,
        filters: state.commonInfo.filters,
        commonUsers: state.commonInfo.users,
        checkList: state.meetingsPage.checkList,
    };
};

const mapDispatchToProps = {
    // fetchTasks,
    openExistingProject,
    ShowFiltersWindow,
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    setNewParametersToReducer,
    createDeleteResolutionItem,
    patchResolutionItem
};

export default connect(mapStateToProps, mapDispatchToProps)(RuleItem);
