import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Control, Controller } from "react-hook-form";

import UserProjectSelect from '../../../common/components/Selects/UserProjectSelect';
import CustomCheckbox from "../../../common/components/CheckboxItem/CustomCheckbox";
import { Item, User } from '../../../common/types';
import { State } from '../../../rootReducer';

const WrapperTasksActions = styled.div`
	display: flex;
	gap: 50px;
  margin: 8px 40px 16px;
`;

const WrapperCheckbox = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

type Props = {
	users: User[];
	selectedUserId: number;
	positions: any;
	current_user: any;
	control: Control;
};

const CalendarActions: FC<Props> = (
	{
		users,
		selectedUserId,
		current_user,
		positions,
		control
	}) => {
	const dataForUserSelect = useMemo(() => {
		let selected = null as Item | null;

		let found_my_user = users.find((user) => {
			return user.id === current_user;
		});

		let attached_items: Item[] = [];
		if (found_my_user) {
			attached_items.push({
				text: `${found_my_user.surname} ${found_my_user.name}`,
				name_cap_letters:
					found_my_user.surname.substr(0, 1).toUpperCase() +
					found_my_user.name.substr(0, 1).toUpperCase(),
				value: found_my_user.id,
				subtitle: found_my_user.email,
				position: positions.find(
					(pos) => pos.id === found_my_user?.positions[0]
				)?.name,
				urlImage: found_my_user?.image?.url,
			});
		}

		let list = users
			.map((user) => {
				let item = {
					text: `${user.surname} ${user.name}`,
					name_cap_letters:
						user.surname.substr(0, 1).toUpperCase() +
						user.name.substr(0, 1).toUpperCase(),
					value: user.id,
					subtitle: user.email,
					position: positions.find((pos) => pos.id === user.positions[0])?.name,
					urlImage: user?.image?.url,
				};
				if (user.id === selectedUserId) selected = item;
				return item;
			})
			.filter((list_item) => list_item.value !== current_user);

		return { selected, list, attached_items };
	}, [selectedUserId, users]);

	return (
		<WrapperTasksActions>
			<UserProjectSelect
				type="calendar"
				itemsList={dataForUserSelect.list}
				attached_items={dataForUserSelect.attached_items}
			/>
			<WrapperCheckbox>
				<Controller
					as={<CustomCheckbox label="Совещания" />}
					name="connection"
					control={control}
				/>
				<Controller
					as={<CustomCheckbox label="Встречи" />}
					name="meeting"
					control={control}
				/>
				<Controller
					as={<CustomCheckbox label="Задачи" />}
					name="tasks"
					control={control}
				/>
			</WrapperCheckbox>
		</WrapperTasksActions>
	);
};

const mapStateToProps = (state: State) => {
	return {
		positions: state.commonInfo.positions,
		current_user: state.commonInfo.current_user,
		users: state.commonInfo.users,
		selectedUserId: state.tasksPage.selectedUserId,
	};
};

export default connect(mapStateToProps)(CalendarActions);
