import {Action} from 'redux';
import {
    Task,
    User,
    Comment,
    File as FileType,
    Schedule,
    Section,
    Item,
    Dependences,
} from '../../common/types';

export enum types {
    UPDATE_TASK_INFO_PLATE = '@@TASK_INFO_PLATE/UPDATE_TASK_INFO_PLATE',
    UPDATE_CHECKLIST = '@@TASK_INFO_PLATE/UPDATE_CHECKLIST',
    SET_DEPENDENCIS = '@@TASK_INFO_PLATE/SET_DEPENDENCIS',
    SETTING_DEPENDENCIES = '@@TASK_INFO_PLATE/SETTING_DEPENDENCIES',
    UPDATE_COMMENTS_LIST = '@@TASK_INFO_PLATE/UPDATE_COMMENTS_LIST',
    SET_TASK_NAME = '@@TASK_INFO_PLATE/SET_PROJECT_NAME',
    SET_TASK_DESCRIPTION = '@@TASK_INFO_PLATE/SET_TASK_DESCRIPTION',
    SET_TASK_PROJECT = '@@TASK_INFO_PLATE/SET_TASK_PROJECT',
    SET_TASK_SECTION = '@@TASK_INFO_PLATE/SET_TASK_SECTION',
    SET_TASK_PRIORITY = '@@TASK_INFO_PLATE/SET_TASK_PRIORITY',
    SET_PROVIDE_TO = '@@TASK_INFO_PLATE/SET_PROVIDE_TO',
    FETCH_PROJECT_SECTIONS = '@@TASK_INFO_PLATE/FETCH_PROJECT_SECTIONS',
    SET_TASK_INFO_ACTIVE_TAB = '@@TASK_INFO_PLATE/SET_TASK_INFO_ACTIVE_TAB',
    SET_SHOWN_TASK_INFO_PLATE = '@@TASK_INFO_PLATE/SET_SHOWN_TASK_INFO_PLATE',
    SELECT_TASK = '@@TASK_INFO_PLATE/SELECT_TASK',
    SET_PROJECT_USERS = '@@TASK_INFO_PLATE/SET_PROJECT_USERS',
    ADD_COMMENTS_LIST = '@@TASK_INFO_PLATE/ADD_COMMENTS_LIST',
    SET_COMMENTS_LIST = '@@TASK_INFO_PLATE/SET_COMMENTS_LIST',
    GET_COMMENTS_LIST = '@@TASK_INFO_PLATE/GET_COMMENTS_LIST',
    DELETE_COMMENT = '@@TASK_INFO_PLATE/DELETE_COMMENT',
    UPLOAD_FILE = '@@TASK_INFO_PLATE/UPLOAD_FILE',
    DELETE_FILE = '@@TASK_INFO_PLATE/DELETE_FILE',
    SET_COMMENT_FILES = '@@TASK_INFO_PLATE/SET_COMMENT_FILES',
    SET_COMMENT_TEXT = '@@TASK_INFO_PLATE/SET_COMMENT_TEXT',
    SEND_COMMENT = '@@TASK_INFO_PLATE/SEND_COMMENT',
    SET_IS_SENDING_COMMENT = '@@TASK_INFO_PLATE/SET_IS_SENDING_COMMENT',
    CLEAR_COMMENT_FILED = '@@TASK_INFO_PLATE/CLEAR_COMMENT_FIELD',
    SET_PARENT_COMMENT = '@@TASK_INFO_PLATE/SET_PARENT_COMMENT',
    SET_EXECUTOR_SCHEDULE = '@@TASK_INFO_PLATE/SET_EXECUTOR_SCHEDULE',
    SET_SPECTATOR_SCHEDULE = '@@TASK_INFO_PLATE/SET_SPECTATOR_SCHEDULE',
    SET_PROJECT_SECTIONS = '@@TASK_INFO_PLATE/SET_PROJECT_SECTIONS',
    SET_DELEGETE_TO = '@@TASK_INFO_PLATE/SET_DELEGETE_TO',
    DISPATCH_CHECKBOX_ITEM = '@@TASK_INFO_PLATE/DISPATCH_CHECKBOX_ITEM',
    SET_RECALCULATE = '@@TASK_INFO_PLATE/SET_RECALCULATE',
    SET_TASKWORKLOAD_TOGGLER = '@@TASK_INFO_PLATE/SET_TASKWORKLOAD_TOGGLER',
    REWRITE_WHOLE_TASKLIST = '@@TASK_INFO_PLATE/REWRITE_WHOLE_TASKLIST',
    DELETE_CHECKBOX_ITEM = '@@TASK_INFO_PLATE/DELETE_CHECKBOX_ITEM',
    UPDATE_REDUCER_CHECKBOX_ITEM = '@@TASK_INFO_PLATE/UPDATE_REDUCER_CHECKBOX_ITEM',
    DELEGATE_CONFIRM_ACTION = '@@TASK_INFO_PLATE/DELEGATE_CONFIRM_ACTION',
    SUCCESSFUL_DELEGATED_TO = '@@TASK_INFO_PLATE/SUCCESSFUL_DELEGATED_TO',
    SET_IS_SENDING_DELEGATE = '@@TASK_INFO_PLATE/SET_IS_SENDING_DELEGATE',
    SET_TASK_WORKLOAD = '@@TASK_INFO_PLATE/SET_TASK_WORKLOAD',
    SET_TASKLOAD = '@@TASK_INFO_PLATE/SET_TASKLOAD',
    SET_EXECUTOR_ID = '@@TASK_INFO_PLATE/SET_EXECUTOR_ID',
    SET_SPECTATORS = '@@TASK_INFO_PLATE/SET_SPECTATORS',
    CREATE_TASK = '@@TASK_INFO_PLATE/CREATE_TASK',
    SET_TASK_BEGIN = '@@TASK_INFO_PLATE/SET_TASK_BEGIN',
    SET_TASK_SPECTATORS = '@@TASK_INFO_PLATE/SET_TASK_SPECTATORS',
    SET_TASK_END = '@@TASK_INFO_PLATE/SET_TASK_END',
    SET_DEFAULT_VALUES = '@@TASK_INFO_PLATE/SET_DEFAULT_VALUES',
    SET_PREV_ID = '@@TASK_INFO_PLATE/SET_PREV_ID',
    SET_NEXT_ID = '@@TASK_INFO_PLATE/SET_NEXT_ID',
    SUCCESSFUL_CREATED_NEW_TASKS = '@@TASK_INFO_PLATE/SUCCESSFUL_CREATED_NEW_TASKS',
    SET_PARENT_ID = '@@TASK_INFO_PLATE/SET_PARENT_ID',
    SET_START_CYCLICK = '@@TASK_INFO_PLATE/SET_START_CYCLICK',
    SET_FINISH_CYCLICK = '@@TASK_INFO_PLATESET_FINISH_CYCLICK',
    SET_CHILD_TASKS = '@@TASK_INFO_PLATE/SET_CHILD_TASKS',
    SET_IS_SENDING_CREATE_NEW_TASK = '@@TASK_INFO_PLATE/SET_IS_SENDING_CREATE_NEW_TASK',
    SET_CYCLIC_TASK_CHANGE = '@@TASK_INFO_PLATE/SET_CYCLIC_TASK_CHANGE',
    SET_FLAG_REPEAT_TASK_CHANGE = '@@TASK_INFO_PLATE/SET_FLAG_REPEAT_TASK_CHANGE',
    SEND_REQ_CHANGE_TEMPL_REP_TASK = '@@TASK_INFO_PLATE/SEND_REQ_CHANGE_TEMPL_REP_TASK',
    SET_PARAMETER_TRIGGER = '@@TASK_INFO_PLATE/SET_PARAMETER_TRIGGER',
    DUPLICATE_TASK = '@@TASK_INFO_PLATE/DUPLICATE_TASK',
    MAKE_SUBTASK = '@@TASK_INFO_PLATE/MAKE_SUBTASK',
    MAKE_AS_SUBTASK = '@@TASK_INFO_PLATE/MAKE_AS_SUBTASK',
    CLEAR_CYCLIC_TASK_CHANGE = '@@TASK_INFO_PLATE/CLEAR_CYCLIC_TASK_CHANGE',
    SET_LABELS_DATA = '@@TASK_INFO_PLATE/SET_LABELS_DATA',
}

export interface setTaskNameAction extends Action<types.SET_TASK_NAME> {
    name: string;
}

export interface setTaskDescriptionAction
    extends Action<types.SET_TASK_DESCRIPTION> {
    description: string;
}

export interface setTaskProjectAction extends Action<types.SET_TASK_PROJECT> {
    projectId: number;
}

export interface setTaskSectionAction extends Action<types.SET_TASK_SECTION> {
    id: number;
}

export interface fetchProjectSectionsAction
    extends Action<types.FETCH_PROJECT_SECTIONS> {
    projectId: number;
}

export interface setTaskInfoActiveTabAction
    extends Action<types.SET_TASK_INFO_ACTIVE_TAB> {
    activeTab: string;
}

export interface setShownTaskInfoPlateAction
    extends Action<types.SET_SHOWN_TASK_INFO_PLATE> {
    isShownTaskInfoPlate: boolean;
}

export interface selectTaskAction extends Action<types.SELECT_TASK> {
    task: Task | null;
}

export interface updateTaskInfoPlateAction
    extends Action<types.UPDATE_TASK_INFO_PLATE> {
    task: any;
}

export interface updateChecklistAction extends Action<types.UPDATE_CHECKLIST> {
    checkItem: object;
}

// export interfaceupdateCommentsAction extends Action<types>

export interface setProjectUsersAction extends Action<types.SET_PROJECT_USERS> {
    users: User[];
}

export interface setComentListAction extends Action<types.SET_COMMENTS_LIST> {
    commentList: Comment[];
}

export interface addCommentsListAction extends Action<types.ADD_COMMENTS_LIST> {
    commentList: Comment[];
}

export interface updateCommentsListAction
    extends Action<types.UPDATE_COMMENTS_LIST> {
    commentList: Comment[];
}

export interface getCommentsListAction extends Action<types.GET_COMMENTS_LIST> {
    pageNum: number;
}

export interface deleteCommentAction extends Action<types.DELETE_COMMENT> {
    taskId: number;
    commentId: number;
}

export interface uploadCommentFileAction extends Action<types.UPLOAD_FILE> {
    file: File;
}

export interface deleteCommentFileAction extends Action<types.DELETE_FILE> {
    id: number;
}

export interface setCommentFilesAction extends Action<types.SET_COMMENT_FILES> {
    files: FileType[];
}

export interface setCommentTextAction extends Action<types.SET_COMMENT_TEXT> {
    text: string;
}

export interface sendCommentAction extends Action<types.SEND_COMMENT> {
    task_Id?: number;
    commentText?: string;
}

export interface setIsSendongCommentAction
    extends Action<types.SET_IS_SENDING_COMMENT> {
    isSending: boolean;
}

export interface setParentCommentAction
    extends Action<types.SET_PARENT_COMMENT> {
    parent: Comment | null;
}

export interface clearCommentFieldAction
    extends Action<types.CLEAR_COMMENT_FILED> {
}

export interface setExecutorScheduleAction
    extends Action<types.SET_EXECUTOR_SCHEDULE> {
    schedule: Schedule;
}

export interface setSpectatorScheduleAction
    extends Action<types.SET_SPECTATOR_SCHEDULE> {
    schedule: Schedule;
}

export interface setProjectSectionsAction
    extends Action<types.SET_PROJECT_SECTIONS> {
    sections: Section[];
}

export interface setDelegeteToAction extends Action<types.SET_DELEGETE_TO> {
    delegeteTo: number;
}

export interface setChangeCheckboxItem
    extends Action<types.DISPATCH_CHECKBOX_ITEM> {
    orderNumber: number | null;
    text: string;
    checkboxState: boolean;
    actionType: string;
}

export interface setRecalculateLoadAction
    extends Action<types.SET_RECALCULATE> {
    arg?: object;
}

export interface setTaskWorkLoadToggle
    extends Action<types.SET_TASKWORKLOAD_TOGGLER> {
}

export interface setRewriteWholeTaskList
    extends Action<types.REWRITE_WHOLE_TASKLIST> {
    newArray: any | null;
}

export interface setDeleteCheckboxItem
    extends Action<types.DELETE_CHECKBOX_ITEM> {
    orderNumber: number;
}

export interface setChangeReducerCheckboxItem
    extends Action<types.UPDATE_REDUCER_CHECKBOX_ITEM> {
    orderNumber: number | null;
    text: string;
    checkboxState: boolean;
    actionType?: string;
    id?: number;
}

export interface delegateConfirmAction
    extends Action<types.DELEGATE_CONFIRM_ACTION> {
    users_ids: number[];
}

export interface successfulDelegatedToAction
    extends Action<types.SUCCESSFUL_DELEGATED_TO> {
    value: number[];
}

export interface sendingDelegateAction
    extends Action<types.SET_IS_SENDING_DELEGATE> {
    isSending: boolean;
}

export interface setTaskPriorityAction extends Action<types.SET_TASK_PRIORITY> {
    priority: number;
}

export interface setSetProvideTo extends Action<types.SET_PROVIDE_TO> {
    val: number;
}

export interface setTaskWorkLoadAction extends Action<types.SET_TASK_WORKLOAD> {
    workLoad: number | null;
}

export interface setTaskLoadAction extends Action<types.SET_TASKLOAD> {
    taskLoad: any;
}

export interface setExecutorIdAction extends Action<types.SET_EXECUTOR_ID> {
    value: any;
}

export interface setSpectatorIdAction extends Action<types.SET_SPECTATORS> {
    value: number[];
}

export interface createTaskAction extends Action<types.CREATE_TASK> {
    executor_id?: number;
    isUpdateScheduler?: boolean;
}

export interface setTaskBeginAction extends Action<types.SET_TASK_BEGIN> {
    begin: string;
}

export interface setTaskSpectatorsAction extends Action<types.SET_TASK_SPECTATORS> {
    value: number[] ;
}

export interface setTaskEndAction extends Action<types.SET_TASK_END> {
    end: string;
}

export interface setPrevIdAction extends Action<types.SET_PREV_ID> {
    value: number | null;
}

export interface setNextIdAction extends Action<types.SET_NEXT_ID> {
    value: number | null;
}

export interface setParentIdAction extends Action<types.SET_PARENT_ID> {
    value: number | null;
}

export interface setChildTasksAction extends Action<types.SET_CHILD_TASKS> {
    value: any[];
}

export interface duplicateTaskAction extends Action<types.DUPLICATE_TASK> {
}

export interface makeSubtaskAction extends Action<types.MAKE_SUBTASK> {
}

export interface makeAsSubtaskAction extends Action<types.MAKE_AS_SUBTASK> {
}

export interface setDefaultValuesAction
    extends Action<types.SET_DEFAULT_VALUES> {
}

export interface successfulCreatedNewTasksAction
    extends Action<types.SUCCESSFUL_CREATED_NEW_TASKS> {
    // value: {}
    value: { executorsIds: []; createdTasks: any[]; checkItems: [] };
}

export interface successfulCreatedNewTasksAction
    extends Action<types.SUCCESSFUL_CREATED_NEW_TASKS> {
    // value: {}
    value: { executorsIds: []; createdTasks: any[]; checkItems: [] };
}

export interface sendingCreateNewTaskAction
    extends Action<types.SET_IS_SENDING_CREATE_NEW_TASK> {
    isSending: boolean;
}

export interface setStartCiclickAction extends Action<types.SET_START_CYCLICK> {
    period?: string;
    params?: Array<number>;
    interval?: number;
    show_modal_window?: boolean;
    settings_string?: string;
}

export interface setFinishCiclickAction
    extends Action<types.SET_FINISH_CYCLICK> {
    ending_condition?: string;
    end_count?: number | null;
    end_date?: string | null;
}

export interface setCyclicTaskToChangeAction
    extends Action<types.SET_CYCLIC_TASK_CHANGE> {
    obj: {};
}

export interface clearCyclicTaskToChangeAction
    extends Action<types.CLEAR_CYCLIC_TASK_CHANGE> {
}

export interface setFlagRepeatTaskToChangeAction
    extends Action<types.SET_FLAG_REPEAT_TASK_CHANGE> {
    val: string;
}

export interface sendReqToChangeTemplRepTaskAction
    extends Action<types.SEND_REQ_CHANGE_TEMPL_REP_TASK> {
    obj: {};
}

export interface setParameterTriggerAction
    extends Action<types.SET_PARAMETER_TRIGGER> {
}

export interface setDependenciesAction extends Action<types.SET_DEPENDENCIS> {
    dependencies: Dependences;
}

export interface settingDependenciesAction
    extends Action<types.SETTING_DEPENDENCIES> {
    isDependencies: boolean;
}


export interface setLabelsDataAction extends Action<types.SET_LABELS_DATA> {
    comments_count?: number;
    files_count?: number
}


export type TaskInfoPlateAction =
    | setTaskInfoActiveTabAction
    | settingDependenciesAction
    | setShownTaskInfoPlateAction
    | selectTaskAction
    | setProjectUsersAction
    | setComentListAction
    | deleteCommentAction
    | uploadCommentFileAction
    | deleteCommentFileAction
    | setCommentFilesAction
    | setCommentTextAction
    | sendCommentAction
    | setIsSendongCommentAction
    | clearCommentFieldAction
    | setParentCommentAction
    | setExecutorScheduleAction
    | setSpectatorScheduleAction
    | setProjectSectionsAction
    | fetchProjectSectionsAction
    | setDelegeteToAction
    | setDependenciesAction
    | addCommentsListAction
    | getCommentsListAction
    | setChangeCheckboxItem
    | setRecalculateLoadAction
    | setTaskWorkLoadToggle
    | setRewriteWholeTaskList
    | setDeleteCheckboxItem
    | setChangeReducerCheckboxItem
    | delegateConfirmAction
    | successfulDelegatedToAction
    | sendingDelegateAction
    | setTaskNameAction
    | setTaskDescriptionAction
    | setTaskProjectAction
    | setTaskSectionAction
    | setTaskPriorityAction
    | setSetProvideTo
    | setTaskWorkLoadAction
    | setTaskLoadAction
    | setExecutorIdAction 
    | setSpectatorIdAction
    | createTaskAction
    | setTaskEndAction
    | setTaskBeginAction
    | setTaskSpectatorsAction
    | setDefaultValuesAction
    | setPrevIdAction
    | setNextIdAction
    | successfulCreatedNewTasksAction
    | setParentIdAction
    | setChildTasksAction
    | setStartCiclickAction
    | setFinishCiclickAction
    | sendingCreateNewTaskAction
    | setCyclicTaskToChangeAction
    | setFlagRepeatTaskToChangeAction
    | sendReqToChangeTemplRepTaskAction
    | setParameterTriggerAction
    | updateTaskInfoPlateAction
    | updateChecklistAction
    | duplicateTaskAction
    | makeSubtaskAction
    | makeAsSubtaskAction
    | clearCyclicTaskToChangeAction
    | setLabelsDataAction
    | updateCommentsListAction;
