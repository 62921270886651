import axios from "axios";
import { nanoid } from "nanoid";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import "../BpResultForm.scss";
import AddFileButton from "../templates/AddFileButton";
import CheckboxElement from "../templates/CheckboxElement";
import NameOfStep from "../templates/NameOfStep";
import RadioButtons from "../templates/RadioButtons";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
//

let fileTranslate;

const CheckOfJurist = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,

    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [allFilesJurist1, setAllFilesJurist1] = useState([]);
  const [allFilesJurist2, setAllFilesJurist2] = useState([]);
  const [allFilesJurist3, setAllFilesJurist3] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("taskCurrentGlobanl", taskCurrentGlobal);

  //Добавляет файлы
  const onSendFileJurist = (e, numberFilesJurist) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesJurist === "filesJurist1") {
        if (file && file.name) {
          setAllFilesJurist1([
            ...allFilesJurist1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesJurist1",
            },
          ]);
        }
      } else if (numberFilesJurist === "filesJurist2") {
        if (file && file.name) {
          setAllFilesJurist2([
            ...allFilesJurist2,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesJurist2",
            },
          ]);
        }
      } else if (numberFilesJurist === "filesJurist3") {
        if (file && file.name) {
          setAllFilesJurist3([
            ...allFilesJurist3,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesJurist3",
            },
          ]);
        }
      }
    }, [500]);
  };
  //Удаляет файл
  const onDeletedFileJurist = (keyId, numberFilesJurist) => {
    if (numberFilesJurist === "filesJurist1") {
      setAllFilesJurist1(allFilesJurist1.filter((file) => file.id !== keyId));
    } else if (numberFilesJurist === "filesJurist2") {
      setAllFilesJurist2(allFilesJurist2.filter((file) => file.id !== keyId));
    } else if (numberFilesJurist === "filesJurist3") {
      setAllFilesJurist3(allFilesJurist3.filter((file) => file.id !== keyId));
    }
  };

  //Смотрит на какую кнопку какого раздела с файлами было нажато
  const onClickFetchFilesJurist = (e, numberFilesJurist) => {
    e.preventDefault();

    let data;
    if (numberFilesJurist === "filesJurist1") {
      data = JSON.stringify({
        files: allFilesJurist1,
      });
      console.log("Нажал на кнопку для отправки файлов filesJurist1");
    }

    if (numberFilesJurist === "filesJurist2") {
      data = JSON.stringify({
        files: allFilesJurist2,
      });
      console.log("Нажал на кнопку для отправки файлов filesJurist2");
    }

    if (numberFilesJurist === "filesJurist3") {
      data = JSON.stringify({
        files: allFilesJurist3,
      });
      console.log("Нажал на кнопку для отправки файлов filesJurist3");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })

      .catch((e) => {
        console.log(e);
      });

    if (numberFilesJurist === "filesJurist1") {
      setAllFilesJurist1([]);
    }

    if (numberFilesJurist === "filesJurist2") {
      setAllFilesJurist2([]);
    }

    if (numberFilesJurist === "filesJurist3") {
      setAllFilesJurist3([]);
    }
  };

  const [data, setData] = useState(
    settingsOfFields?.checkOfJurist?.extra
      ? settingsOfFields?.checkOfJurist.extra
      : {}
  );
  const arrForCheckbox = [
    "Сопоставимость выручки + убытки",
    "Кол-во сотрудников",
    "Блокировки р/с",
    "Суды",
    "Красный или зеленый в спарке",
    "Риски генерального директора и учредителей",
    "Не закрытые исполнительные  производства",
    "Участие в торгах",
    "ОКВЭДЫ соответствуют (когда мы покупатели либо заказчики)",
  ];

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  useEffect(() => {
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields?.checkOfJurist, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId, taskCurrentGlobal]);

  // console.log("     ", );

  return (
    <form id="checkOfJurist" className="form">
      <div>
        <NameOfStep text={"Прикрепить выгрузку из СПАРК"} />
        <div className="file-uploaded__block">
          <ul>
            {taskCurrentGlobal &&
              taskCurrentGlobal["files"] &&
              taskCurrentGlobal["files"]["list"]
                .filter((item) => item.tag == "filesJurist1")
                .map((item2) => (
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                      target="_blank"
                    >
                      {item2.name}
                    </a>
                  </li>
                ))}
          </ul>
        </div>
        <AddFileButton
          filesList={taskCurrentGlobal}
          data={data}
          setData={setData}
          id={"c3"}
          handlerOnSendFile={onSendFileJurist}
          handlerOnDeletedFile={onDeletedFileJurist}
          handlerOnClickFetchFiles={onClickFetchFilesJurist}
          allFiles={allFilesJurist1}
          numberFiles={"filesJurist1"}
          otherTerm={data.c31_1 === "Не применимо"}
        />

        <CheckboxElement
          name={"c31_1"}
          text={"Не применимо"}
          data={data}
          setData={setData}
        />
        {data.c31_1 && (
          <TextAreaOrInputElement
            name={"c31_2"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
          />
        )}
      </div>
      <div>
        <div>
          <NameOfStep text={"Группа полей"} />
          <CheckboxElement
            name={"c3_1_1"}
            arr={arrForCheckbox}
            isNeedTextFieldsAfterEach={true}
            data={data}
            setData={setData}
          />
          {/* <TextAreaOrInputElement
            name={"c3_1_2"}
            isInput={true}  
            type={"text"}
            data={data}
            setData={setData}
          /> */}
        </div>
        <div>
          <NameOfStep
            text={
              "Проведена проверка полномочий лица, подписывающего договор со стороны контрагента"
            }
          />
          <RadioButtons
            data={data}
            setData={setData}
            name={"c3_2"}
            positiveText={"Да"}
            negativeText={"Нет"}
          />
          {data.c3_2 === "Да" && (
            <>
              <div className="file-uploaded__block">
                <ul>
                  {taskCurrentGlobal &&
                    taskCurrentGlobal["files"] &&
                    taskCurrentGlobal["files"]["list"]
                      .filter((item) => item.tag == "filesJurist2")
                      .map((item2) => (
                        <li>
                          <a
                            href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                            target="_blank"
                          >
                            {item2.name}
                          </a>
                        </li>
                      ))}
                </ul>
              </div>
              <AddFileButton
                filesList={taskCurrentGlobal}
                data={data}
                setData={setData}
                id={"c3_2_1"}
                handlerOnSendFile={onSendFileJurist}
                handlerOnDeletedFile={onDeletedFileJurist}
                handlerOnClickFetchFiles={onClickFetchFilesJurist}
                allFiles={allFilesJurist2}
                numberFiles={"filesJurist2"}
              />
            </>
          )}
        </div>
        <div>
          <NameOfStep text={"Проведен комплексный правовой анализ договора"} />
          <RadioButtons
            data={data}
            setData={setData}
            name={"c3_3"}
            positiveText={"Да"}
            negativeText={"Нет"}
          />
          {data.c3_3 === "Да" && (
            <>
              <TextAreaOrInputElement
                name={"c3_3_1"}
                isInput={true}
                type={"text"}
                data={data}
                setData={setData}
              />
              <div className="file-uploaded__block">
                <ul>
                  {taskCurrentGlobal &&
                    taskCurrentGlobal["files"] &&
                    taskCurrentGlobal["files"]["list"]
                      .filter((item) => item.tag == "filesJurist3")
                      .map((item2) => (
                        <li>
                          <a
                            href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                            target="_blank"
                          >
                            {item2.name}
                          </a>
                        </li>
                      ))}
                </ul>
              </div>
              <AddFileButton
                filesList={taskCurrentGlobal}
                data={data}
                setData={setData}
                id={"c3_3_2"}
                handlerOnSendFile={onSendFileJurist}
                handlerOnDeletedFile={onDeletedFileJurist}
                handlerOnClickFetchFiles={onClickFetchFilesJurist}
                allFiles={allFilesJurist3}
                numberFiles={"filesJurist3"}
              />
            </>
          )}
        </div>
        <div>
          <NameOfStep text={"Дополнительные комментарии к рискам договора"} />
          <RadioButtons
            data={data}
            setData={setData}
            name={"c3_4"}
            positiveText={"Имеются"}
            negativeText={"Отсутствуют"}
          />
          {data.c3_4 === "Имеются" && (
            <TextAreaOrInputElement
              name={"c3_4_1"}
              isInput={true}
              type={"text"}
              data={data}
              setData={setData}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default CheckOfJurist;
