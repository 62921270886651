import React, { useState, useEffect, useCallback, useRef } from "react";
import { Task } from "../../../common/types";
import { connect } from "react-redux";
import ProjectTaskTr from "./ProjectTaskTr";
import { State } from "../../../rootReducer";
import { useParams } from "react-router-dom";
import { fetchTasks } from "../actions";

import styled from "styled-components";
import ProjectTableHead from "./ProjectTableHead";

type Props = {
  tasks: Task[];
  tableFilter: string;
  fetchTasks: (projectId: number, pageNum: number) => void;
  pageNum: number;
  setPageNum: (pageNum: number) => void;
};

const WrapperTasksTable = styled.div`
  height: calc(100vh - 214px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f4f4f4;
`;

const WrapperTasks = styled.div`
  /* max-width: 1280px; */
  width: 100%;
  /* padding: 0px 40px; */
`;

const ProjectTableContent: React.FC<Props> = ({
  tasks,
  tableFilter,
  fetchTasks,
  pageNum,
  setPageNum,
}) => {
  const scrollHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      if (
        scrollHeight <= scrollTop + clientHeight &&
        tasks.length === 20 * pageNum
      )
        setPageNum(pageNum + 1);
    },
    [pageNum, tasks.length]
  );

  const { id } = useParams();

  const [isScroll, setScroll] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (pageNum !== 1) fetchTasks(id, pageNum);
  }, [fetchTasks, id, pageNum]);

  useEffect(() => {
    setPageNum(1);
  }, [id]);

  useEffect(() => {
    let widthScrol = ref?.current?.offsetWidth || 0;
    let widthTable = ref?.current?.scrollWidth || 0;

    if (widthScrol > widthTable) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [ref]);

  return (
    <>
      <ProjectTableHead setPageNum={setPageNum} isScroll={isScroll} />
      <WrapperTasksTable onScroll={scrollHandler} ref={ref}>
        <WrapperTasks>
          {tasks.map((task: Task) => (
            <ProjectTaskTr key={task.id} task={task} />
          ))}
        </WrapperTasks>
      </WrapperTasksTable>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    tasks: state.projectsPage.tasks,
    tableFilter: state.projectsPage.tableFilter.toLocaleLowerCase(),
  };
};

const mapDispatchToProps = {
  fetchTasks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectTableContent);
