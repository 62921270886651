import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { StatusContext } from "../../../context/status";

const ChildTaskButton = ({ etTaskId }) => {
  const { etTask, setEtTask, bearer, company_id, userId } =
    useContext(StatusContext);

  const history = useHistory();

  useEffect(() => {
    if (etTaskId) {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_PUBLIC_API}/tasks/${etTaskId}`,
        headers: {
          Authorization: bearer,
          company_id: company_id,
        },
      };

      axios(config)
        .then((response) => {
          setEtTask(response.data.data);
        })
        .catch((e) => console.log(e));
    } else {
      setEtTask();
    }
  }, [etTaskId]);
  // console.log(etTaskId);
  // console.log(etTask);
  // console.log(etTask?.child_tasks[0]);

  return etTask && etTask.child_tasks[0] ? (
    <div className="child-task-container">
      <div
        className={
          "chatMenu-footer__nav-item chatMenu-footer__nav-item-active child-task-button "
        }
        onClick={() => {
          history.push(`/tasks/${userId}#${etTask.child_tasks[0]}`);
        }}
      >
        Задача на согласовании
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ChildTaskButton;
