import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { StatusContext } from "../../../context/status";
import AddFileButton from "../templates/AddFileButton";
import NameOfStep from "../templates/NameOfStep";
import RadioButtons from "../templates/RadioButtons";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import "../BpResultForm.scss";

let fileTranslate;

const CheckFinalOfJurist = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.checkFinalOfJurist?.extra
      ? settingsOfFields?.checkFinalOfJurist.extra
      : {}
  );

  const [allFilesFinalJurist1, setAllFilesFinalJurist1] = useState([]);
  const [allFilesFinalJurist2, setAllFilesFinalJurist2] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  //Добавляет файлы
  const onSendFileFinalJurist = (e, numberFilesFinalJurist) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesFinalJurist === "filesFinalJurist1") {
        if (file && file.name) {
          setAllFilesFinalJurist1([
            ...allFilesFinalJurist1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesFinalJurist1",
            },
          ]);
        }
      } else if (numberFilesFinalJurist === "filesFinalJurist2") {
        if (file && file.name) {
          setAllFilesFinalJurist2([
            ...allFilesFinalJurist2,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesFinalJurist2",
            },
          ]);
        }
      }
    }, [500]);
  };
  //Удаляет файлы
  const onDeletedFileFinalJurist = (keyId, numberFilesFinalJurist) => {
    if (numberFilesFinalJurist === "filesFinalJurist1") {
      setAllFilesFinalJurist1(
        allFilesFinalJurist1.filter((file) => file.id !== keyId)
      );
    } else if (numberFilesFinalJurist === "filesFinalJurist2") {
      setAllFilesFinalJurist2(
        allFilesFinalJurist2.filter((file) => file.id !== keyId)
      );
    }
  };

  //Смотрит на какую кнопку какого раздела с файлами было нажато
  const onClickFetchFilesFinalJurist = (e, numberFilesFinalJurist) => {
    e.preventDefault();
    console.log(numberFilesFinalJurist);

    let data;

    if (numberFilesFinalJurist === "filesFinalJurist1") {
      data = JSON.stringify({
        files: allFilesFinalJurist1,
      });
      console.log("Нажал на кнопку для отправки файлов filesFinalJurist1");
    }

    if (numberFilesFinalJurist === "filesFinalJurist2") {
      data = JSON.stringify({
        files: allFilesFinalJurist2,
      });
      console.log("Нажал на кнопку для отправки файлов filesFinalJurist2");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesFinalJurist === "filesFinalJurist1") {
      setAllFilesFinalJurist1([]);
    }

    if (numberFilesFinalJurist === "filesFinalJurist2") {
      setAllFilesFinalJurist2([]);
    }
  };

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  useEffect(() => {
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields?.checkFinalOfJurist, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  // console.log("     ", );

  return (
    <form id="checkFinalOfJurist">
      <div>
        <NameOfStep
          text={
            "Протокол разногласий по комментариям всех подразделений составлен и согласован с контрагентом"
          }
        />
        <RadioButtons
          data={data}
          setData={setData}
          name={"g7_1_1"}
          positiveText={"Да"}
          negativeText={"Нет"}
          otherText={"Не применимо"}
        />
        {data.g7_1_1 === "Да" && (
          <>
            <TextAreaOrInputElement //если да
              name={"g7_1_2"}
              isInput={true}
              type={"text"}
              data={data}
              setData={setData}
            />
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal &&
                  taskCurrentGlobal["files"] &&
                  taskCurrentGlobal["files"]["list"]
                    .filter((item) => item.tag == "filesFinalJurist1")
                    .map((item2) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                          target="_blank"
                        >
                          {item2.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
            <AddFileButton
              filesList={taskCurrentGlobal}
              data={data}
              setData={setData}
              id={"g7_1_3"}
              handlerOnSendFile={onSendFileFinalJurist}
              handlerOnDeletedFile={onDeletedFileFinalJurist}
              handlerOnClickFetchFiles={onClickFetchFilesFinalJurist}
              allFiles={allFilesFinalJurist1}
              numberFiles={"filesFinalJurist1"}
            />
          </>
        )}
      </div>
      <div>
        <NameOfStep text={"Номер договора присвоен"} />
        <RadioButtons
          data={data}
          setData={setData}
          name={"g7_2_1"}
          positiveText={"Да"}
          negativeText={"Нет"}
          otherText={"Не применимо"}
        />
        {data.g7_2_1 === "Да" && (
          <>
            <TextAreaOrInputElement //если да
              name={"g7_2_2"}
              isInput={true}
              type={"text"}
              data={data}
              setData={setData}
            />
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal &&
                  taskCurrentGlobal["files"] &&
                  taskCurrentGlobal["files"]["list"]
                    .filter((item) => item.tag == "filesFinalJurist2")
                    .map((item2) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                          target="_blank"
                        >
                          {item2.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
            <AddFileButton
              filesList={taskCurrentGlobal}
              data={data}
              setData={setData} //если да
              id={"g7_2_3"}
              handlerOnSendFile={onSendFileFinalJurist}
              handlerOnDeletedFile={onDeletedFileFinalJurist}
              handlerOnClickFetchFiles={onClickFetchFilesFinalJurist}
              allFiles={allFilesFinalJurist2}
              numberFiles={"filesFinalJurist2"}
            />
          </>
        )}
      </div>
    </form>
  );
};

export default CheckFinalOfJurist;
