import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import {getSectionSelectList} from "../../TaskInfoPlate/selectors";
import {useSelector} from "react-redux";
import {State} from "../../../rootReducer";

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 15px;
`;

type Props = {
	eventType: 'task' | 'meeting' | 'conference',
	deleteForm: () => void,
	closeForm: () => void,
	saveForm: () => void,
	isCreatedTask: boolean,
}

const ButtonActions: FC<Props> = ({ deleteForm, saveForm, closeForm, isCreatedTask, eventType }) => {
	const state = useSelector((state: State) => state);
	const	sectionSelect = getSectionSelectList(state);

	return (
		<ButtonsContainer>
		<Button color='secondary' onClick={deleteForm}>Удалить</Button>
		<div>
			<Button onClick={closeForm}>Закрыть</Button>
			<Button
				disabled={eventType === 'task' ? sectionSelect.selected ? false : true : false}
				variant='contained'
				color='primary'
				onClick={saveForm}
			>
				Сохранить
			</Button>
		</div>
	</ButtonsContainer>
	);
};

export default ButtonActions;
