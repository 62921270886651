import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { StatusContext } from "../../../context/status";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import AddFileButton from "../templates/AddFileButton";
import NameOfStep from "../templates/NameOfStep";
import "../BpResultForm.scss";

let fileTranslate;

const FinalSettlementWithEmployeeDismissal = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.finalSettlementWithEmployee?.extra
      ? settingsOfFields?.finalSettlementWithEmployee.extra
      : {}
  );
  const [allFilesFinalSettlement1, setAllFilesFinalSettlement1] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  console.log(apiBp);
  //Добавляет файлы
  const onSendFileFinalSettlement = (e, numberFilesTreaty) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesTreaty === "filesFinalSettlement1") {
        if (file && file.name) {
          setAllFilesFinalSettlement1([
            ...allFilesFinalSettlement1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesFinalSettlement1",
            },
          ]);
        }
      }
    }, [500]);
  };

  //Удаляет файлы
  const onDeletedFileFinalSettlement = (keyId, numberFilesAccountant) => {
    if (numberFilesAccountant === "filesFinalSettlement1") {
      setAllFilesFinalSettlement1(
        allFilesFinalSettlement1.filter((file) => file.id !== keyId)
      );
    }
  };

  //Смотрит на какую кнопку какого рездела с файлами было нажато
  const onClickFetchFilesFinalSettlement = (e, numberFilesAccountant) => {
    e.preventDefault();
    let data;

    if (numberFilesAccountant === "filesFinalSettlement1") {
      data = JSON.stringify({
        files: allFilesFinalSettlement1,
      });
      console.log("Нажал на кнопку для отправки файлов filesFinalSettlement1");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesAccountant === "filesFinalSettlement1") {
      setAllFilesFinalSettlement1([]);
    }
  };

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  // console.log(taskId);
  useEffect(() => {
    if (taskId) {
      axios
        .patch(
          url,
          {
            ...settingsOfFields?.finalSettlementWithEmployee,
            extra: data,
          },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);
  return (
    <form id="checkOfFinanceDirector">
      <div>
        <NameOfStep text={"Расчет с сотрудником выполнен"} />
        <div>
          <TextAreaOrInputElement
            name={"g7"}
            isInput={true}
            type={"number"}
            data={data}
            setData={setData}
          />
        </div>
      </div>
      <div>
        <NameOfStep text={"Прикрепить документы (расписка)"} />
        <>
          <div className="file-uploaded__block">
            <ul>
              {taskCurrentGlobal &&
                taskCurrentGlobal["files"] &&
                taskCurrentGlobal["files"]["list"]
                  .filter((item) => item.tag == "filesFinalSettlement1")
                  .map((item2) => (
                    <li>
                      <a
                        href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                        target="_blank"
                      >
                        {item2.name}
                      </a>
                    </li>
                  ))}
            </ul>
          </div>
          <AddFileButton
            filesList={taskCurrentGlobal}
            data={data}
            setData={setData}
            id={"g7_1"}
            handlerOnSendFile={onSendFileFinalSettlement}
            handlerOnDeletedFile={onDeletedFileFinalSettlement}
            handlerOnClickFetchFiles={onClickFetchFilesFinalSettlement}
            allFiles={allFilesFinalSettlement1}
            numberFiles={"filesFinalSettlement1"}
          />
        </>
      </div>
    </form>
  );
};

export default FinalSettlementWithEmployeeDismissal;
