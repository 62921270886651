import axios from "axios";
import { nanoid } from "nanoid";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import "../BpResultForm.scss";
import AddFileButton from "../templates/AddFileButton";
import NameOfStep from "../templates/NameOfStep";
//
let fileTranslate;

const ProcessTerminating = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [allFilesProcessTerminating1, setAllFilesProcessTerminating1] =
    useState([]);
  const [allFilesProcessTerminating2, setAllFilesProcessTerminating2] =
    useState([]);
  const [allFilesProcessTerminating3, setAllFilesProcessTerminating3] =
    useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  //Добавляет файлы
  const onSendFileProcessTerminating = (e, numberFilesProcessTerminating) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesProcessTerminating === "filesProcessTerminating1") {
        if (file && file.name) {
          setAllFilesProcessTerminating1([
            ...allFilesProcessTerminating1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesProcessTerminating1",
            },
          ]);
        }
      } else if (numberFilesProcessTerminating === "filesProcessTerminating2") {
        if (file && file.name) {
          setAllFilesProcessTerminating2([
            ...allFilesProcessTerminating2,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesProcessTerminating2",
            },
          ]);
        }
      } else if (numberFilesProcessTerminating === "filesProcessTerminating3") {
        if (file && file.name) {
          setAllFilesProcessTerminating3([
            ...allFilesProcessTerminating3,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesProcessTerminating3",
            },
          ]);
        }
      }
    }, [500]);
  };
  //Удаляет файл
  const onDeletedFileProcessTerminating = (
    keyId,
    numberFilesProcessTerminating
  ) => {
    if (numberFilesProcessTerminating === "filesProcessTerminating1") {
      setAllFilesProcessTerminating1(
        allFilesProcessTerminating1.filter((file) => file.id !== keyId)
      );
    } else if (numberFilesProcessTerminating === "filesProcessTerminating2") {
      setAllFilesProcessTerminating2(
        allFilesProcessTerminating2.filter((file) => file.id !== keyId)
      );
    } else if (numberFilesProcessTerminating === "filesProcessTerminating3") {
      setAllFilesProcessTerminating3(
        allFilesProcessTerminating3.filter((file) => file.id !== keyId)
      );
    }
  };

  //Смотрит на какую кнопку какого раздела с файлами было нажато
  const onClickFetchFilesProcessTerminating = (
    e,
    numberFilesProcessTerminating
  ) => {
    e.preventDefault();
    console.log(numberFilesProcessTerminating);

    let data;
    if (numberFilesProcessTerminating === "filesProcessTerminating1") {
      data = JSON.stringify({
        files: allFilesProcessTerminating1,
      });
      console.log(
        "Нажал на кнопку для отправки файлов filesProcessTerminating1"
      );
    }

    if (numberFilesProcessTerminating === "filesProcessTerminating2") {
      data = JSON.stringify({
        files: allFilesProcessTerminating2,
      });
      console.log(
        "Нажал на кнопку для отправки файлов filesProcessTerminating2"
      );
    }

    if (numberFilesProcessTerminating === "filesProcessTerminating3") {
      data = JSON.stringify({
        files: allFilesProcessTerminating3,
      });
      console.log(
        "Нажал на кнопку для отправки файлов filesProcessTerminating3"
      );
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesProcessTerminating === "filesProcessTerminating1") {
      setAllFilesProcessTerminating1([]);
    }

    if (numberFilesProcessTerminating === "filesProcessTerminating2") {
      setAllFilesProcessTerminating2([]);
    }

    if (numberFilesProcessTerminating === "filesProcessTerminating3") {
      setAllFilesProcessTerminating3([]);
    }
  };

  const [data, setData] = useState(
    settingsOfFields?.processTerminating?.extra
      ? settingsOfFields?.processTerminating.extra
      : {}
  );
  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  useEffect(() => {
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields?.processTerminating, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="processTerminating" className="form">
      <div>
        <NameOfStep text={"Прикрепление документов"} />
        <div className="file-uploaded__block">
          <ul>
            {taskCurrentGlobal &&
              taskCurrentGlobal["files"] &&
              taskCurrentGlobal["files"]["list"]
                .filter((item) => item?.tag == "filesProcessTerminating1")
                .map((item2) => (
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                      target="_blank"
                    >
                      {item2.name}
                    </a>
                  </li>
                ))}
          </ul>
        </div>
        <AddFileButton
          filesList={taskCurrentGlobal}
          data={data}
          setData={setData}
          id={"k11"}
          handlerOnSendFile={onSendFileProcessTerminating}
          handlerOnDeletedFile={onDeletedFileProcessTerminating}
          handlerOnClickFetchFiles={onClickFetchFilesProcessTerminating}
          allFiles={allFilesProcessTerminating1}
          numberFiles={"filesProcessTerminating1"}
        />
      </div>
    </form>
  );
};

export default ProcessTerminating;
