import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../context/status";
import axios from "axios";
import CreateTaskForm from "../create-task-form/CreateTaskForm";
import { CreateNotif } from "../../../../../utils/createNotification";
import { cookieMaster } from "../../../../../utils/CookieMaster";
//
const CreateTask = () => {
  const {
    setBpListChange,
    createTaskForm,
    setCreateTaskForm,
    createBpForm,
    setCreateTaskStatus,
    apiBp,
    setCreateBpForm,
    tasks,
    setTasks,
    setCreateTaskSampleFormStatus,
    createTaskSampleFormStatus,
    setTasksArr,
    setNowTask,
    taskSample,
    setAddTaskSample,
    setCreateBpSampleForm,
    setStatusCreateTask,
    addTasksMenu,
    setAddTasksMenu,
    bearer,
    thisBp,
    idBp,
    company_id,
  } = useContext(StatusContext);
  const [addTask, setAddTask] = useState();
  const [createFirstTask, setCreateFirstTask] = useState(true);
  const [idOfBp, setIdofBp] = useState("");

  // console.log("createTaskForm", createTaskForm);
  // console.log("tasks111", tasks);

  useEffect(() => {
    setCreateFirstTask(true);
  }, []);

  useEffect(() => {
    if (addTasksMenu) {
      // console.log(idBp);
      setCreateTaskForm({
        ...createTaskForm,
        data: {
          ...createTaskForm.data,
          project_section_id: thisBp.project_section_id,
          project_id: thisBp.project_id,
        },
      });
      setCreateBpForm({
        ...createBpForm,
        name: thisBp.name,
        project_section_id: thisBp.project_section_id,
        project_id: thisBp.project_id,
      });
    }
  }, []);

  useEffect(() => {
    if (!!idBp) {
      setIdofBp(idBp);
    }
  }, []);
  // console.log(createTaskForm.data.project_id);
  // console.log(createTaskForm.data.project_section_id);

  const saveTask = (e) => {
    if (createTaskForm.name && createTaskForm.begin && createTaskForm.end) {
      if (addTasksMenu) {
        // console.log("***tasks***", tasks);
        let num = createTaskForm.length - 1;

        // et_task_id: createTaskForm.id,
        let data = JSON.stringify({
          name: createTaskForm.name,
          description: createTaskForm.description,
          begin: createTaskForm.begin,
          end: createTaskForm.end,
          executor_id: createTaskForm.executor_id,
          business_process_id: 4,
          data: {
            author_id: createTaskForm.data.author_id,
            // checklist: createTaskForm.data.checklist,
            task_load: createTaskForm.data.task_load,
            work_load: createTaskForm.data.work_load,
            project_id: createTaskForm.data.project_id,
            priority_id: createTaskForm.data.priority_id,
            project_section_id: createTaskForm.data.project_section_id,
          },
          // files: JSON.stringify(createTaskForm.files),
        });

        let config = {
          method: "post",
          url: `${apiBp}/bp/${thisBp.id}/task`,
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": `${localStorage.getItem("access_token_jwt")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then((res) => {
            setBpListChange((prev) => !prev);
            CreateNotif(`Задача "${createTaskForm.name}" создана`, "success");

            //  console.log("******", res);
          })
          .then((res) => {
            console.log(res);
          })

          .catch((error) => {
            console.log("error", error);
            CreateNotif(`Создание задачи в данном случае невозможно!`, "error");
          });

        setCreateBpForm({
          name: null,
          initiator_id: parseInt(
            document.cookie.replace(
              /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            )
          ),
          project_id: createBpForm.project_id,
          deadlineDate: null,
          deadlineTime: "00:00:00",
          tasks: null,
          file_id: null,
          deadline: null,
        });

        setCreateTaskStatus(false);
        setCreateTaskSampleFormStatus(false);
        setStatusCreateTask(false);
        setCreateTaskForm({
          ...createTaskForm,
          createTaskForm: createTaskForm.data.project_section_id,
        });
        setTasks([]);
        setTasksArr([]);
        setNowTask("");
        setCreateBpSampleForm({
          type: 0,
          businessProcess: {
            name: null,
            deadline: null,
            project_id: null,
            tasks: 1,
            initiator_id: cookieMaster.getCookie("user_id"),
          },
          options: [],
        });
      }
    } else {
      CreateNotif(`Не все необходимые поля заполнены!`, "error");
    }
  };

  const saveBp = () => {
    setCreateFirstTask(false);

    if (tasks.length > 0 && !addTasksMenu) {
      let tasksStr = "";
      for (let i in tasks) {
        tasksStr = tasksStr.concat(tasks[i]);
        if (i < tasks.length - 1) {
          tasksStr = tasksStr.concat("|");
        }
      }

      if (createBpForm.deadlineDate !== null) {
        if (!createBpForm.deadlineTime) {
          setCreateBpForm({ ...createBpForm, deadlineTime: "00:00:00" });
        }
      }
      if (createBpForm.file_id === null || createBpForm.deadlineDate === null) {
        if (
          createBpForm.file_id === null &&
          createBpForm.deadlineDate === null
        ) {
          fetch(
            `${apiBp}/bp?name=${createBpForm.name}&initiator_id=${createBpForm.initiator_id}&project_id=${createBpForm.project_id}&project_section_id=${createBpForm.project_section_id}`,
            {
              method: "POST",
              headers: {
                Authorization: bearer,
                "company-id": company_id,
                "secret-token": document.cookie.replace(
                  /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                  "$1"
                ),
              },
            }
          ).then((res) => {
            res.json();
            setBpListChange((prev) => !prev);
          });
        }
        if (
          createBpForm.file_id === null &&
          createBpForm.deadlineDate !== null
        ) {
          fetch(
            `${apiBp}/bp?name=${createBpForm.name}&initiator_id=${
              createBpForm.initiator_id
            }&project_id=${createBpForm.project_id}&project_section_id=${
              createBpForm.project_section_id
            }&deadline=${
              createBpForm.deadlineDate + " " + createBpForm.deadlineTime
            }`,
            {
              method: "POST",
              headers: {
                Authorization: bearer,
                "company-id": company_id,
                "secret-token": document.cookie.replace(
                  /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                  "$1"
                ),
              },
            }
          )
            .then((res) => {
              res.json();
              setBpListChange((prev) => !prev);
            })
            .catch((e) => {
              console.log(e);
            });
        }
        if (
          createBpForm.deadlineDate === null &&
          createBpForm.file_id !== null
        ) {
          fetch(
            `${apiBp}/bp?name=${createBpForm.name}&initiator_id=${createBpForm.initiator_id}&project_id=${createBpForm.project_id}&project_section_id=${createBpForm.project_section_id}&file_id=${createBpForm.file_id}`,
            {
              method: "POST",
              headers: {
                Authorization: bearer,
                "company-id": company_id,
                "secret-token": document.cookie.replace(
                  /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                  "$1"
                ),
              },
            }
          )
            .then((res) => {
              res.json();
              setBpListChange((prev) => !prev);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
      if (createBpForm.file_id !== null && createBpForm.deadlineDate !== null) {
        fetch(
          `${apiBp}/bp?name=${createBpForm.name}&initiator_id=${
            createBpForm.initiator_id
          }&project_id=${createBpForm.project_id}&project_section_id=${
            createBpForm.project_section_id
          }&deadline=${
            createBpForm.deadlineDate + " " + createBpForm.deadlineTime
          }&file_id=${createBpForm.file_id}`,
          {
            method: "POST",
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": document.cookie.replace(
                /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
              ),
            },
          }
        )
          .then((res) => {
            res.json();
            setBpListChange((prev) => !prev);
          })
          .catch((e) => {
            console.log(e);
          });
      }
      setCreateBpForm({
        name: null,
        initiator_id: parseInt(
          document.cookie.replace(
            /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
          )
        ),
        project_id: createBpForm.project_id,
        deadlineDate: null,
        deadlineTime: "00:00:00",
        tasks: null,
        file_id: null,
        deadline: null,
      });

      setCreateTaskStatus(false);
      setCreateTaskSampleFormStatus(false);
      setStatusCreateTask(false);
      setCreateTaskForm({
        ...createTaskForm,
        data: {
          ...createTaskForm.data,
          project_section_id: createTaskForm.data.project_section_id,
        },
      });
      setTasks([]);
      setTasksArr([]);
      setNowTask("");
      setCreateBpSampleForm({
        type: 0,
        businessProcess: {
          name: null,
          deadline: null,
          project_id: null,
          tasks: 1,
          initiator_id: parseInt(
            document.cookie.replace(
              /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            )
          ),
        },
        options: [],
      });
    }
  };

  useEffect(() => {
    if (createTaskForm.name && createTaskForm.begin && createTaskForm.end) {
      setAddTask(true);
    } else {
      setAddTask(false);
    }
    if (
      createTaskSampleFormStatus &&
      !!taskSample.name &&
      !!taskSample.executor_id
    ) {
      setAddTaskSample(true);
    } else {
      setAddTaskSample(false);
    }
  }, [createTaskForm]);

  return (
    <div className="business__drop-content">
      <div className="businessTask businessClass">
        <div id="create-task-container">
          <p className="busines__drop-content__title p__drop-content">
            * - обязательные для заполнения поля
          </p>
          <CreateTaskForm />
        </div>
        <div>
          {/*     <button
            className={
              addTask || addTaskSample
                ? "blue-btn"
                : "blue-btn blue-btn__disabled"
            }
            id="add-task"
            style={
              !!createTaskSampleFormStatus
                ? { paddingLeft: 64 + "px", paddingRight: 64 + "px" }
                : {}
            }
            onClick={() => saveTask()}
          >
         {!createTaskSampleFormStatus ? "Добавить еще" : "Далее"}  
          </button> */}
          {!createTaskSampleFormStatus ? (
            <button
              className={
                addTask === true
                  ? "blue-btn white-btn"
                  : "blue-btn white-btn white-btn__disabled"
              }
              id="save-task"
              onClick={(e) => saveTask(e)}
            >
              Сохранить задачу
            </button>
          ) : (
            <></>
          )}

          <button
            className="defualt__btn"
            id="close-btn"
            onClick={() => {
              setCreateBpForm({
                name: null,
                initiator_id: parseInt(
                  document.cookie.replace(
                    /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                    "$1"
                  )
                ),
                project_id: createBpForm.project_id,
                deadlineDate: null,
                deadlineTime: "00:00:00",
                tasks: null,
                file_id: null,
                deadline: null,
              });

              setCreateTaskStatus(false);
              setCreateTaskSampleFormStatus(false);
              setStatusCreateTask(false);

              setCreateTaskForm({
                ...createTaskForm,
              });
              setTasks([]);
              setTasksArr([]);
              setNowTask("");

              setCreateBpSampleForm({
                type: 0,
                businessProcess: {
                  name: null,
                  deadline: null,
                  project_id: null,
                  tasks: 1,
                  initiator_id: parseInt(
                    document.cookie.replace(
                      /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    )
                  ),
                },
                options: [],
              });
              setAddTasksMenu(false);
            }}
          >
            Отмена
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateTask;
