// Тут разрабатывается компонент "Календарь"
import React, { FC, useState, useEffect } from 'react';
import { SchedulerStatic } from 'dhtmlx-scheduler';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CalendarActions from './components/CalendarActions';
import Scheduler from './components/Scheduler';
import Lightbox from './components/Lightbox/Lightbox';

import {
	fetchTasks,
	changeTaskList,
	setSelectedUserId,
} from '../Tasks/actions';

import { selectTask } from "../TaskInfoPlate/actions";

import { State } from '../../rootReducer';
import { Task } from '../../common/types';

import './style.sass'

declare global {
	interface Window {
		scheduler: SchedulerStatic;
	}
}

const data1 = [
	{ start_date:'2022-03-10 6:00', end_date:'2022-03-10 8:00', text:'Event 1', id: 1, holder: 'John' },
	{ start_date:'2022-03-13 10:00', end_date:'2022-03-13 18:00', text:'Event 2', id: 2 },
];


const data2 = [
	{ start_date:'2022-03-10 6:00', end_date:'2022-03-10 8:00', text:'Event 1', id: 1, holder: 'John' },
	{ start_date:'2022-03-13 10:00', end_date:'2022-03-13 18:00', text:'Event 2', id: 2 },
	{ start_date:'2022-03-11 6:00', end_date:'2022-03-11 8:00', text:'Event 1', id: 3, holder: 'John' },
	{ start_date:'2022-03-12 10:00', end_date:'2022-03-12 18:00', text:'Event 2', id: 4 }
];

export interface IEvent {
	start_date: string,
	end_date: string,
	text: string,
	id: number,
	holder?: string,
}

interface ICalendar {
	events: Array<IEvent | Task>,
}

const scheduler = window.scheduler;

const Calendar: FC<ICalendar> = () => {
	const [pageNum, setPageNum] = useState<number>(4);
	const { visualizationData, tasks } = useSelector((state: State) => state.tasksPage);
	const dispatch = useDispatch();
	const params = useParams<{id: string}>();
	const [data, setData] = useState<Array<IEvent | Task>>([]);
	const { control, watch } = useForm();
	const watchFields = watch(['tasks', 'meeting', 'connection'])

	useEffect(() => {
		dispatch(changeTaskList([]));
	}, [params.id, changeTaskList]);

	useEffect(() => {
		const parseDate = scheduler.date.str_to_date("%Y-%m-%d");
		dispatch(setSelectedUserId(+params.id));
		dispatch(fetchTasks(pageNum));
		setPageNum((prev) => prev + 1);
		//
		const filtred = tasks.map((el) => ({ ...el, start_date: parseDate(el.begin), end_date: parseDate(el.end), text: el.name, eventType: 'task' }));
		setData(filtred);
		dispatch(selectTask(null));
	}, [params.id]);

	useEffect(() => {
		// console.log('==========>values', watchFields);
	}, [watch]);

	return (
		<>
			<CalendarActions control={control} />
			<div className='scheduler-container'>
				<Scheduler events={data} />
			</div>
			<Lightbox />
		</>
	);
};

export default Calendar;
