/**********   Главный модуль   ***************** */
import React, { useState, useEffect } from "react";
import CommonInfoPreloader from "./CommonInfoPreloader/CommonInfoPreloader";
import Header from "./Header/Header"; // Меню слева
import ProjectAddPanel from "../../pages/NewProject/index";
import TaskAddPanel from "../../pages/NewTask/index";
import UserAddPanel from "../../pages/NewUser/index";
import UserChangePasswordWindow from "../../pages/NewUser/components/UserChangePasswordWindow";
import { connect } from "react-redux";
import TaskInfoPlate from "../../pages/TaskInfoPlate/index";
import { State } from "../../rootReducer";
import {
  Route,
  useHistory,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import Tasks from "../../pages/Tasks";
import Projects from "../../pages/Projects";
import Payment from "../../pages/Payment";
import Users from "../../pages/Users";
import Calendar from "../../pages/Calendar";
import Bp from "../../pages/Bp/src/App";

import Meetings from "../../pages/Meetings";

import { cookieMaster } from "../../utils/CookieMaster";
import { fetchCommonInfo } from "../actions";
import { Project } from "../types";
import AddDepPlate from "../../pages/Users/components/AddDepPlate";
import {
  setAddUserToGroupDep,
  setDepGroupType,
} from "../../pages/Users/actions";
import AddUsersPlate from "../../pages/Users/components/AddUsersPlate/AddUsersPlate";
import RepeatCustomWindow from "../../pages/TaskInfoPlate/components/RepeatCustomWindow";
import ConfirmEditRepeatTask from "../../pages/TaskInfoPlate/components/ConfirmEditRepeatTask";
import TaskDone from "../../pages/Tasks/components/TaskDone";
import ClosingDay from "../../pages/Tasks/components/ClosingDay";
import { fetchData } from "../../utils/fetchData";
import CompanyBlock from "../../pages/Company";
import Faq from "./Faq";
import BusinessProcessesModal from "../../pages/Projects/components/BusinessProcessesModal";
import DependenciesWindow from "../../pages/TaskInfoPlate/components/DependenciesWindow";
import MeetingRightPlate from "../../pages/Meetings/components/RightPlate";
import styled from "styled-components"; //******************************************************* */

import HeaderAvatar from "../components/Header/HeaderAvatar";
import NotificationButton from "../components/Header/NotificationButton";
import { Search } from "@material-ui/icons";

/*  ***************************
Цвета:
Синий: #99e6ff
Светло-синий: #ccf2ff
Цвет из макета: #01B0E9
*/
//******************************************** */

const SearchDiv = styled.div`
  width: 288px;
  height: 34px;
  top: calc(50% - 34px / 2 - 509px);
  background: #4bb5eb;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  width: 233px;
  height: 17px;
  top: 18px;
  color: #cae8ff;
  background: #4bb5eb;
  margin-left: 10px;
`;

// Горизонтальное верхнее меню
const HeaderHorizontal = styled.header`
  /* margin: 0 auto; */
  /* max-width: 1280px; */
  display: flex;
  align-items: center;
  justify-content: flex-end; //**************************************************************
  height: 63px;
  border-bottom: 1px solid #e1e1e7;
  padding: 0 40px;
  background: #01b0e9; /****/
`;
//******************************************* */

type Props = {
  projectAddMode: boolean;
  taskAddMode: boolean;
  userAddMode: boolean;
  isShownTaskInfoPlate: boolean;
  isShownDayClose: boolean;
  currentId: number;
  projects: Project[];
  fetchCommonInfo: () => void;
  changePassWindowShow: boolean;
  // show_notification_window: boolean,
  setDepGroupType: (value: string | null) => void;
  showAddUserToGroupDep: boolean;
  showWindowCreateDepGroup: boolean;
  plateType: string | null;
  selectedDepGroupId: null | number;
  repeat_custom_window: boolean;
  showEditCyclicTaskWindow: {};
  showTaskDoneConfirmationWindow: boolean;
  closingDayWindowShow: boolean;
  businessProcessesModalShow: boolean;
  isDependencies: boolean;
  selectedProject: Project | null;
  show_company_window: boolean;
  showMeetingRightPlate: boolean;
};

const Main: React.FC<Props> = ({
  setDepGroupType,
  repeat_custom_window,
  selectedDepGroupId,
  isDependencies,
  plateType,
  showWindowCreateDepGroup,
  showAddUserToGroupDep,
  //    show_notification_window,
  taskAddMode,
  projectAddMode,
  userAddMode,
  isShownTaskInfoPlate,
  currentId,
  projects,
  isShownDayClose,
  fetchCommonInfo,
  changePassWindowShow,
  showEditCyclicTaskWindow,
  showTaskDoneConfirmationWindow,
  closingDayWindowShow,
  businessProcessesModalShow,
  selectedProject,
  show_company_window,
  showMeetingRightPlate,
}) => {
  const [value, setValue] = useState("");

  function handleChange(event) {
    setValue(event.target.value);
  }

  let history = useHistory();
  let jiva = document.getElementById("jvlabelWrap");

  const location = useLocation();

  const [isProjectsPage, setIsProjectsPage] = useState(true);
  //   console.log(location.pathname.includes("/projects"));

  useEffect(() => {
    if (location.pathname.includes("/projects")) {
      setIsProjectsPage(true);
    } else {
      setIsProjectsPage(false);
    }
  }, [location.pathname]);

  if (jiva) jiva.style.display = "none";
  useEffect(() => {
    let token = cookieMaster.getCookie("access_token");
    let company_id = localStorage.getItem("company_id") || "";
    let company_id_old = company_id;
    if (token) {
      fetchData
        .get(`/api/v1/users/${currentId}/companies`)
        .then((data) => {
          if (data !== undefined) {
            if (data.length === 0) {
              let x = [0];
              return x;
            } else {
              return data;
            }
          } else {
            return [0];
          }
        })
        .then((company) => {
          if (+company_id === 0 || company_id === "") {
            if (company.length > 0 && company[0] !== 0) {
              company_id =
                company.find((id) => id === +company_id) || company[0];
              localStorage.setItem("company_id", company_id);
              window.location.replace("/");
            } else if (company[0] === 0) {
              localStorage.setItem("company_id", "0");
              window.location.replace("/register");
            }
          }
          if (+company_id > 0) {
            // console.log('заходит', company[0]);
            company_id =
              company.find((id) => id === +company_id) || company[0] || "";
            localStorage.setItem("company_id", company_id);
            fetchCommonInfo();
            if (company_id != company_id_old) {
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    } else {
      history.push("/login");
    }
    // if (token) {
    //   fetchData
    //     .get(`/api/v1/users/${currentId}/companies`)
    //     .then((data) => {
    //       if (data === undefined) {
    //         cookieMaster.deleteCookie('access_token');
    //         localStorage.clear();
    //         window.location.replace('/login');
    //       }

    //       if (+company_id === 0) {
    //         if (data) {
    //           console.log(data);
    //           company_id =
    //             data.find((id) => id === +company_id) || data[0] || '';
    //           localStorage.setItem('company_id', company_id);
    //           window.location.replace('/');
    //         } else {
    //           window.location.replace('/register');
    //         }
    //       }
    //       if (+company_id > 0) {
    //         company_id = data.find((id) => id === +company_id) || data[0] || '';
    //       }
    //     })
    //     .then(() => {
    //       localStorage.setItem('company_id', company_id);
    //       window.location.replace('/');
    //     })
    //     .then(() => {
    //       if (company_id === '') {
    //         fetchData
    //           .get(`/api/v1/users/${currentId}/companies`)
    //           .then((data) => {
    //             company_id = data?.sort((a, b) => a - b)[0] || '0';
    //             console.log(company_id);
    //           })
    //           .then(() => {
    //             console.log(company_id);
    //             localStorage.setItem('company_id', company_id);
    //           })
    //           .then(() => window.location.replace('/'))
    //           .catch((error) => {
    //             cookieMaster.deleteCookie('access_token');
    //             localStorage.clear();

    //             window.location.replace('/');
    //           });
    //       } else if (+company_id === 0) {
    //         history.push('/register');
    //       } else if (+company_id > 0) {
    //         fetchCommonInfo();
    //       }
    //     })
    //     .catch((error) => {
    //       cookieMaster.getCookie('access_token');
    //       throw new Error(error);
    //     });
    // } else {
    //   history.push('/login');
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const blur =
    projectAddMode ||
    taskAddMode ||
    // userAddMode ||
    // isShownDayClose ||
    showWindowCreateDepGroup ||
    showAddUserToGroupDep ||
    repeat_custom_window ||
    showTaskDoneConfirmationWindow ||
    businessProcessesModalShow ||
    Boolean(Object.keys(showEditCyclicTaskWindow).length);

  //******************************************************* */
  // широкое левое меню или поменьше
  // Если isLeftBlockBig = true - ширина левого меню 286 px
  // Если isLeftBlockBig = false - ширина левого меню 146 px
  // В случае узкого меню надписи "Задачи", "проекты", "Сотрудники" скрываются
  // надпись над ними вместо "Easy Task" остается "E"
  const [isLeftBlockBig, setIsLeftBlockBig] = useState<boolean>(true);
  //******************************************************* */

  return (
    <>
      <main className={`${blur ? "blur" : ""}`}>
        <CommonInfoPreloader />
        {/* ***************************************** */}
        <div style={{ display: "flex", justifyContent: "left" }}>
          {/* ***************************************** */}
          {/* Вертикальное меню слева. Передаем туда выбранный размер меню */}
          <Header
            isLeftBlockBig={isLeftBlockBig}
            setIsLeftBlockBig={setIsLeftBlockBig}
            isProjectsPage={isProjectsPage}
          />
          <div
            style={{
              display: "block",
              minHeight: "100vh",
              width: isLeftBlockBig
                ? `calc(100vw - 286px)`
                : `calc(100vw - 146px)`,
            }}
          >
            {/* Верхнее (Горизонтальное) меню */}
            <HeaderHorizontal>
              {/*
                <SearchDiv>
               
                <Search style={{color: "#FFFFFF", marginLeft: "10px"}}/>
                <SearchInput placeholder="Поиск" color="#fff" placeholderTextColor={'red'} value={value} onChange={handleChange} />
                </SearchDiv>
                */}

              {/* "Колокольчик" в верхнем меню- кнопочка вывода сообщений */}
              <NotificationButton />

              {/* Профиль пользователя */}
              <HeaderAvatar />
            </HeaderHorizontal>
            {/* ****************************************** */}

            <Switch>
              <Route path="/tasks/:id" component={Tasks} />
              {/*<Route path="/tasks/:id/:task_id" component={Tasks}/>*/}
              <Route
                exact
                path="/tasks"
                render={() => <Redirect to={`/tasks/${currentId}`} />}
              />

              <Route
                exact
                path="/projects"
                render={() => {
                  if (projects.length) {
                    return (
                      <Redirect
                        to={`/projects/${
                          selectedProject
                            ? selectedProject?.id
                            : projects[0]?.id || 0
                        }`}
                      />
                    );
                  } else {
                    return <Projects />;
                  }
                }}
              />

              <Route
                path={["/meetings/:id", "/meetings"]}
                component={Meetings}
              />

              <Route path={"/projects/:id"} component={Projects} />

              <Route path={"/payment"} component={Payment} />

              <Route path={["/users/:id", "/users"]} component={Users} />

              <Route path="/calendar/:id" component={Calendar} />
              <Route
                exact
                path="/calendar"
                render={() => <Redirect to={`/calendar/${currentId}`} />}
              />

              <Route path="/bp/" component={Bp} />
            </Switch>
          </div>{" "}
          {/* ***************************************** */}
        </div>{" "}
        {/* ***************************************** */}
        {show_company_window && <CompanyBlock />}
        <TaskInfoPlate />
        {showMeetingRightPlate && <MeetingRightPlate />}
        {/*<ClosingDay />*/}
        {/* {isShownTaskInfoPlate && <TaskInfoPlate />} */}
        {closingDayWindowShow && <ClosingDay />}
      </main>

      {projectAddMode && <ProjectAddPanel />}
      {taskAddMode && <TaskAddPanel />}
      {userAddMode && <UserAddPanel />}

      {showWindowCreateDepGroup && (
        <AddDepPlate
          setShow={() => {
            // если ни группа ни отдел сейчас не активны - то можно плашку обнулить. В противном случае - нельзя.
            if (selectedDepGroupId === null) setDepGroupType(null);
          }}
        />
      )}
      {showAddUserToGroupDep && (
        <AddUsersPlate
          setShow={() => {
            setAddUserToGroupDep(false);
          }}
        />
      )}

      {showTaskDoneConfirmationWindow && <TaskDone />}

      {Boolean(repeat_custom_window) && <RepeatCustomWindow />}

      {Object.keys(showEditCyclicTaskWindow).length > 0 && (
        <ConfirmEditRepeatTask />
      )}

      {businessProcessesModalShow && <BusinessProcessesModal />}

      {changePassWindowShow && <UserChangePasswordWindow />}

      {/*{isShownDayClose && <ClosingDay />}*/}
      {/* <Faq /> */}
      {isDependencies && <DependenciesWindow />}
      {/* {show_notification_window && <NotificationWindow/>} */}
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedDepGroupId: state.usersPage.selectedDepGroupId,
    showWindowCreateDepGroup: state.usersPage.showWindowCreateDepGroup,
    showAddUserToGroupDep: state.usersPage.showAddUserToGroupDep,
    plateType: state.usersPage.depGroupPlateType,
    // show_notification_window: state.commonInfo.show_notification_window,

    selectedProject: state.projectsPage.selectedProject,

    showMeetingRightPlate: state.meetingsPage.showRightPlate,

    projectAddMode: state.projectsPage.projectAddMode,
    taskAddMode: state.tasksPage.taskAddMode,
    userAddMode: state.usersPage.userAddMode,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    isShownDayClose: state.tasksPage.isShownDayClose,
    currentId: state.commonInfo.current_user,
    projects: state.commonInfo.projects,
    show_company_window: state.commonInfo.show_company_window,
    closingDayWindowShow: state.tasksPage.closingDayWindowShow,
    changePassWindowShow: state.newUser.changePassWindowShow,
    repeat_custom_window: state.taskInfoPlate.repeat.show_modal_window,
    showEditCyclicTaskWindow:
      state.taskInfoPlate.rememberObjectCyclicTaskToChange,
    showTaskDoneConfirmationWindow:
      state.tasksPage.taskDoneConfirmation.showTaskDoneConfirmationWindow,
    businessProcessesModalShow: state.projectsPage.businessProcessesModalShow,
    isDependencies: state.taskInfoPlate.isDependencies,
  };
};

const mapDispatchToProps = {
  fetchCommonInfo,
  setDepGroupType,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
