import React, { useEffect, useMemo, useState } from "react";
import AddPositionSelect from "./AddPositionSelect";

import SelectBtn from "./SelectBtn";
import { Item } from "../../types";

type Props = {
  list: Item[];
  selected: Item | null;
  selectHandler: (value: string | number, target: any) => void;
  disabled?: boolean;
  className?: string;
  headOptions?: object;
  placeholder?: string;
  fieldName?: string;
  setPage?: (page: number) => void;
  page?: number;
  searchData?: (value: string) => void;
};

const SelectWithSecondaryBtn: React.FC<Props> = ({
  headOptions,
  fieldName,
  list,
  selected,
  selectHandler,
  className,
  disabled = false,
  setPage,
  page,
  searchData,
  placeholder,
}) => {
  let filteredValues: Item[] = [];
  const [searchString, setSearchString] = useState<string>("");

  if (!searchData) {
    if (searchString !== "") {
      list.forEach((listItem) => {
        if (listItem.text.toLowerCase().includes(searchString.toLowerCase())) {
          filteredValues.push(listItem);
        }
      });
    }
  }

  useEffect(() => {
    if (searchData) searchData(searchString);
  }, [searchString]);

  useMemo(() => {
    setSearchString("");
  }, [selected]);

  return (
    <AddPositionSelect
      setPage={setPage}
      page={page}
      headOptions={headOptions}
      itemsList={searchString === "" || searchData ? list : filteredValues}
      selectedItem={selected}
      selectHandler={selectHandler}
      className={className ? className : "" + fieldName}
      disabled={disabled}
      closeWindow={(obj) => setSearchString(obj)}
      searchString={searchString}
    >
      <SelectBtn
        searchString={searchString}
        placeholder={placeholder}
        setSearchString={(obj) => {
          setSearchString(obj);
        }}
      />
    </AddPositionSelect>
  );
};

export default SelectWithSecondaryBtn;
