import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import SelectWithSecondaryBtnProject from '../../../../common/components/Selects/SelectWithSecondaryBtnProject';

import {
	fetchProjectSections,
	setCyclicTaskToChange,
	setTaskProject,
} from '../../../TaskInfoPlate/actions';
import { updateTask } from '../../../../common/actions';
import {
	getProjectsSelectList,
	getSelectedTask,
} from '../../../TaskInfoPlate/selectors';

import {State} from '../../../../rootReducer';



const Wrapper = styled.div`
	width: 100%;
`;

const ProjectAutocomplete: FC = () => {
	const dispatch = useDispatch();
	const state = useSelector((state: State) => state);
	const { currentUserInfo, sections } = useSelector((state: State) => state.commonInfo);
	const selectedTask = getSelectedTask(state);
	const projectsSelect = getProjectsSelectList(state);


	const disabledComponent =
		currentUserInfo?.id === selectedTask?.author_id ||
		currentUserInfo?.id === selectedTask?.executor_id ||
		!selectedTask
			? false
			: currentUserInfo?.roles[0] !== 1;

	return (
		<Wrapper>
			<SelectWithSecondaryBtnProject    // Для проектов- свой модуль. Данные о количестве проектов передаются в -> SelectWithSecondaryBtnProject -> SelectBtnProject, а там выводится предупреждение если нет проектов
				projectSelect = {projectsSelect.list.length} // передаем - сколько проектов выбранно. Если 0- выведем предупреждение.
				disabled={disabledComponent}
				list={projectsSelect.list.sort((a, b) =>
					a.text > b.text ? 1 : -1
				)}
				selected={projectsSelect.selected}
				selectHandler={(value, target) => {
					let params = { project_id: value };
					let section = sections.find((sec) => sec.project_id === value);
					if (section) params['project_section_id'] = section.id;
					if (selectedTask) {
						if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
							// сравнение одного с несколькими
							// эта задача вообще циклическая или сама по себе ?
							// значит циклическая

							// больше не нужно запоминать выбор в текущей сессии работы с задачей

							// if (flagForActionCyclycTask === '') {
							dispatch(setCyclicTaskToChange(params)); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
							// } else if (flagForActionCyclycTask === 'task') {
							//     updateTask(selectedTask.id, params);
							// } else {
							//     updateTask(selectedTask.id, params);
							//     sendReqToChangeTemplRepTask(params);
							// }
						} else {
							updateTask(selectedTask.id, params);
						}
					} else {
						dispatch(setTaskProject(value as number));
						dispatch(fetchProjectSections(value as number));
					}
				}}
			/>
		</Wrapper>
	);
};

export default ProjectAutocomplete;
