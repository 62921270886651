import React from 'react';
import Avatar from '../Avatar/Avatar';
import styled from 'styled-components';

type Props = {
  url: string | null;
  executorName: string;
  minWidth?: string;
};
const WrapperExecutorCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ minWidth }) => minWidth};
  overflow: hidden;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
`;
const ExecutorCell: React.FC<Props> = ({ url, executorName, minWidth }) => {
  return (
    <WrapperExecutorCell minWidth={minWidth}>
      <Avatar
        url={url}
        name={executorName}
        style={{ width: '32px', height: '32px' }}
      />
    </WrapperExecutorCell>
  );
};

export default ExecutorCell;
