import React from "react";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { rootReducer } from "./rootReducer";
import { rootSaga } from "./rootSaga";
import LoginPlate from "./pages/Login/index";
import Notifier from "./common/components/Notifier/Notifier";
import Main from "./common/components/Main";
import "./sass/main.sass";
import { useStateContext } from "./useStateContext";
import { useState } from "react";

const composeEnhancers =
  (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) || compose;

const sagaMiddleware = createSagaMiddleware();

export const urlApp: string = process.env.REACT_APP_ENV
  ? `${process.env.REACT_APP_ENV}`
  : "https://way.easy-task.ru"; // глобальная переменная урла, чтобы использовать везе и менять в одном месте
export const timeUpdate = !!process.env.REACT_APP_ENV_TIME
  ? +process.env.REACT_APP_ENV_TIME
  : 60000;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

function App() {
  // Тут вносятся данные, общие для всей программы

  // Показывать или нет Профиль пользователя / компании
  const [show1, setshow1] = useState<boolean>(false);

 // индикатор - если меняется - (не важно - true или false) - заходим на страницу tasks
 const [openPageTasks, setOpenPageTasks] = useState(1);

  // Тут надо повторить данные, общие для всей программы
  let context = {
    show1,
    setshow1, // Показывать или нет Профиль пользователя / компании
    openPageTasks,
    setOpenPageTasks  // индикатор - если меняется - (не важно - true или false) - заходим на страницу tasks
  };

  return (
    <Provider store={store}>
      <useStateContext.Provider value={context}>
        <SnackbarProvider>
          <BrowserRouter>
            <Notifier />
            <Switch>
              <Route
                path={[
                  "/login",
                  "/register",
                  "/company",
                  "/company-request",
                  "/forgot-password",
                  "/password/recovery",
                  "/password-recovery-from-user",
                  "/password-recovery-from-user2",
                  // '/email',
                  "/verify/",
                  "/unique_link/",
                ]}
                component={LoginPlate}
              />
              <Route exact path="/" render={() => <Redirect to="/tasks" />} />
              <Route path="/" component={Main} />
            </Switch>
          </BrowserRouter>
        </SnackbarProvider>
      </useStateContext.Provider>
    </Provider>
  );
}

export default App;
