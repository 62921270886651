import React, { useState } from 'react';
import AddUsersPlate from './AddUsersPlate/AddUsersPlate';
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';
import { setAddUserToGroupDep } from '../actions';
import styled from 'styled-components';

type Props = {
  name: string;
  counter: number;
  setAddUserToGroupDep: (val: boolean) => void;
};

const SelectedDepStyle = styled.div`
  margin-bottom: 24px;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    color: rgba(0, 0, 0, 0.87);
    font-size: 32px;
    margin-bottom: 8px;
  }
`;

const SelectedDep: React.FC<Props> = ({
  name,
  setAddUserToGroupDep,
  counter,
}) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <SelectedDepStyle>
      <span>{name}</span>
      {/*<div onClick={() => {*/}
      {/*    setShow(!show)*/}
      {/*    setAddUserToGroupDep(true)*/}
      {/*}} className="add_to_dep"/>*/}
      {/* <div className="groups_counter">{counter} группа(ы)</div> */}
      {/*{show && <AddUsersPlate setShow={setShow}/>}*/}
    </SelectedDepStyle>
  );
};

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  setAddUserToGroupDep,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedDep);
