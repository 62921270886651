// Выбор отдела для добавления пользователя в компанию

import React, {useEffect} from 'react';  //***************************** */
import withTitle from '../../../utils/hoc/withTitle';
import { connect,useSelector } from 'react-redux';
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import { setUserDepartment } from '../actions';
import { State } from '../../../rootReducer';
import { getDepSelectData } from '../selectors';
import { SelectData, User } from '../../../common/types';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

type Props = {
  depSelectData: SelectData;
  userId: number;
  currentUserInfo: User | null;
  setUserDepartment: (position: number[]) => void;
  setChangeDataUser: any;
  rolesUser: number[];
  title?: string;
  isAddUserInCompany?: boolean;
};

//отдел можно менять для новых пользователей и если ты админ или директор

const DepartmentSelect: React.FC<Props> = ({
  depSelectData,
  userId,
  currentUserInfo,
  setUserDepartment,
  setChangeDataUser,
  rolesUser,
  title,
  isAddUserInCompany,
}) => {

  // ***********************  Получение информации из state
  // const { departments} = useSelector(
  //  (state: State) => state.commonInfo
  //);
// console.log("departments***",departments)
//****************************************************** */

// console.log("isAddUserInCompany",isAddUserInCompany) 
//   useEffect(()=>{console.log(setUserDepartment); 
// if (isAddUserInCompany===true) {setUserDepartment([departments[0].id]);} 
//},[isAddUserInCompany])  //******************************************** */

  return (
    <NameWithInput title={title} iconName="bag">
      <SelectWithSecondaryBtn
        disabled={
          !(
            userId === 0 ||
            (userId === currentUserInfo?.id &&
              currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]) ||
            currentUserInfo?.roles.includes(1) ||
            currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]
          )
        }
        list={depSelectData.list}
        selected={depSelectData.selected}
        selectHandler={(value) => {
          setUserDepartment([value as number]);


        //  console.log("depSelectData.list",depSelectData.list); //********************************* */ 
        //  console.log(" depSelectData.selected", depSelectData.selected); //********************************* */ 
        //  console.log("value position",value); //********************************* */ 
         
          
          (userId !== 0 || isAddUserInCompany) &&
            setChangeDataUser((prev) => ({
              ...prev,
              data: { ...prev.data, departments: [value] },
              schedule: { ...prev.schedule },
            }));
        }}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    depSelectData: getDepSelectData(state),
    userId: state.newUser.id,
    rolesUser: state.newUser.roles,
    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setUserDepartment: setUserDepartment,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentSelect);
