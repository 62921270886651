import React from 'react';
import { connect } from 'react-redux';
import { setUserBirthDay } from '../actions';
import CustomDatePicker from '../../../common/components/DatePickers/DatePicker';
import { State } from '../../../rootReducer';
import { User } from '../../../common/types';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';
import styled from 'styled-components';

type Props = {
  birthday: string;
  userId: number;
  currentUserInfo: User | null;
  setUserBirthDay: (birthday: string) => void;
  setChangeDataUser: any;
  rolesUser: number[];
};
const WrapperBirthDay = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? 'auto' : 'none')};

  ${({ disabled }) =>
    !disabled &&
    `
    .date_time{border-color: transparent;}
      .date {
    background: rgba(0, 0, 0, 0.02);
    color: #2021249f;
    border: 1px solid #e0e0e0;
      }
    `};
`;
//Дату рождения можно менять для новых пользователей, для себя и если у пользователя есть роль  админа

const UserBirthDay: React.FC<Props> = ({
  birthday,
  userId,
  currentUserInfo,
  setUserBirthDay,
  setChangeDataUser,
  rolesUser,
}) => {
  return (
    <NameWithInput title="Дата рождения" iconName="calendar-dark">
      <WrapperBirthDay
        disabled={
          userId === 0 ||
          userId === currentUserInfo?.id ||
          currentUserInfo?.roles.includes(1) ||
          currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]
        }
      >
        <CustomDatePicker
          date={new Date(birthday)}
          acceptHandler={(birthday) => {
            // if (
            //   userId === 0 ||
            //   userId === currentUserInfo?.id ||
            //   currentUserInfo?.roles.includes(1) ||
            //   currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]
            // ) {
            setUserBirthDay(birthday);
            userId !== 0 &&
              setChangeDataUser((prev) => ({
                ...prev,
                data: { ...prev.data, birthday },
                schedule: { ...prev.schedule },
              }));
            // }
          }}
        />
      </WrapperBirthDay>
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    birthday: state.newUser.birthday,
    userId: state.newUser.id,
    currentUserInfo: state.commonInfo.currentUserInfo,
    rolesUser: state.newUser.roles,
  };
};

const mapDispatchToProps = {
  setUserBirthDay: setUserBirthDay,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBirthDay);
