import React, { useMemo, useCallback } from "react";
import { Task, User } from "../../../common/types";
import DateMater from "../../../utils/DateMaster";
import TaskNameCell from "../../../common/components/TableCells/TaskNameCell";
import DeadLineCell from "../../../common/components/TableCells/DeadLineCell";
import ExecutorCell from "../../../common/components/TableCells/ExecutorCell";
import TaskWorkLoadingCell from "../../../common/components/TableCells/TaskWorkLoadingCell";
import PriorityCell from "../../../common/components/TableCells/PriorityCell";
import { selectTask, setShownTaskInfoPlate } from "../../TaskInfoPlate/actions";
import { connect } from "react-redux";
import TaskStatus from "../../../common/components/TaskStatus/TaskStatus";
import { State } from "../../../rootReducer";
import { getSelectedTask } from "../../TaskInfoPlate/selectors";
import OpenBtn from "../../../common/components/TableCells/OpenBtn";
import { urlApp } from "../../../App";
import IdCell from "../../../common/components/TableCells/IdCell";
import ProjectCell from "../../../common/components/TableCells/ProjectSection";
import PartitionCell from "../../../common/components/TableCells/PartitionCell";
import styled from "styled-components";
import { columnsOfTables } from "../../../common/shared_data";

type Props = {
  task: Task;
  selectedTask: Task | null;
  selectTask: (task: Task) => void;
  setShownTaskInfoPlate: (show: boolean) => void;
  isParent?: boolean;
  counter?: number;
  isOpen?: boolean;
  setOpen?: (open: boolean) => void;
  users: User[];
  projects: object;
  partitions: object;
  activeColumns: string[];
};

const TaskStyle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  margin: 0 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  /* border-bottom: 1px solid; */
  border-image: linear-gradient(to right, #ffffff, #e0e0e0, #e0e0e0, #ffffff)
    45% 0%;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  &:hover {
    background: linear-gradient(
      to right,
      white 0%,
      #f2f2f2 5%,
      #f2f2f2 90%,
      white 100%
    );
  }

  ${({ active }) =>
    active &&
    `
    background: linear-gradient(to right, white 0%,#F3FAFE 5%, #F3FAFE 90%, white 100%)!important;
    border-image: linear-gradient(to right,#ffffff,#99D7F5,#99D7F5,#ffffff ) 0% 5% 75% 7%;
    `}
`;

const BlockLeftFlex = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const BlockRightFlex = styled.div`
  position: relative;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TaskTr: React.FC<Props> = ({
  activeColumns,
  projects,
  partitions,
  task,
  users,
  selectedTask,
  selectTask,
  setShownTaskInfoPlate,
  isParent = false,
  counter = 0,
  isOpen = false,
  setOpen = () => {},
}) => {
  const dateMaster = useMemo(() => {
    return new DateMater(task.end);
  }, [task]);

  const trClickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (!(e.target as HTMLElement).closest(".task_status_wrapper"))
        setShownTaskInfoPlate(true);
      if (selectedTask?.id !== task.id) selectTask(task);
    },
    [setShownTaskInfoPlate, selectedTask, task, selectTask]
  );

  const executor = users.find((user) => user.id === task.executor_id);
  const author = users.find((user) => user.id === task.author_id);

  let project_of_task: string = "";
  Object.keys(projects).forEach((id) => {
    if (projects[id].id === task.project_id) {
      project_of_task = projects[id].name;
    }
  });

  let section_of_task: string = "";
  Object.keys(partitions).forEach((id) => {
    if (partitions[id].id === task.project_section_id) {
      section_of_task = partitions[id].name;
    }
  });

  return (
    <TaskStyle active={task.id === selectedTask?.id} onClick={trClickHandler}>
      <BlockLeftFlex>
        {activeColumns.includes("id") && <IdCell id={task.id} />}
        {activeColumns.includes("author_id") && (
          <ExecutorCell
            minWidth={columnsOfTables[1].style?.minWidth}
            url={
              author?.image
                ? urlApp + `/files/${author.image.url}/download`
                : null
            }
            executorName={
              author ? `${author.surname} ${author.name}` : "Неизвестен"
            }
          />
        )}
        {activeColumns.includes("name") && (
          <TaskNameCell id={task.id} name={task.name} desc={task.description} />
        )}
      </BlockLeftFlex>
      <BlockRightFlex>
        {activeColumns.includes("status_id") && (
          <TaskStatus
            id={
              // если статус "в работе" и дедлайн уже прошел, то надо ставить статус "просрочена" - id 14
              task.status_id === 10 &&
              new Date(
                dateMaster.year,
                dateMaster.month,
                dateMaster.day,
                dateMaster.hours,
                dateMaster.minutes
              ).getTime() <= new Date(Date.now()).getTime()
                ? 14
                : task.status_id
            }
            taskId={task.id}
          />
        )}
        {activeColumns.includes("end") && (
          <DeadLineCell deadline={dateMaster.deadlineMini} />
        )}

        {activeColumns.includes("executor_id") && (
          <ExecutorCell
            minWidth={columnsOfTables[5].style?.minWidth}
            url={
              executor?.image
                ? urlApp + `/files/${executor.image.url}/download`
                : null
            }
            executorName={
              executor ? `${executor.surname} ${executor.name}` : "Неизвестен"
            }
          />
        )}
        {activeColumns.includes("task_load") && (
          <TaskWorkLoadingCell
            taskLoad={task.task_load}
            workLoad={task.work_load}
          />
        )}

        {activeColumns.includes("project_name") && (
          <ProjectCell
            data_project={project_of_task}
            data_section={section_of_task}
          />
        )}
        {/* {activeColumns.includes('section_name') && (
          <PartitionCell data={section_of_task} />
        )} */}
        {activeColumns.includes("priority_id") && (
          <PriorityCell
            priorityId={task.priority_id}
            style={{ backgroundColor: "transparent" }}
          />
        )}
      </BlockRightFlex>
    </TaskStyle>
  );
};

const mapStateToProps = (state: State) => {
  return {
    users: state.commonInfo.users,
    projects: state.commonInfo.projects,
    partitions: state.commonInfo.sections,
    activeColumns: state.projectsPage.activeColumns,
    selectedTask: getSelectedTask(state),
  };
};

const mapDispatchToProps = {
  selectTask,
  setShownTaskInfoPlate,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTr);
