import React from 'react';
import { connect } from 'react-redux';
import { setProjectLeader } from '../actions';
import { getLeaderSelecData, getProjectLeader } from '../selectors';
import { State } from '../../../rootReducer';
import MultySelect from '../../../common/components/Selects/MultySelect';

type Props = {
  placeholder?: string;
  leaderSelectData: any;
  setProjectLeader: (head_of_departments: number[]) => void;
  leader_ids: any;
};

const ChiefSelect: React.FC<Props> = ({
  placeholder,
  leaderSelectData,
  leader_ids,
  setProjectLeader,
}) => {
  return (
    <MultySelect
      disabled={false}
      placeholder={placeholder}
      itemsList={leaderSelectData.list}
      selectedItems={leaderSelectData.selected}
      isValid={leaderSelectData.selected.length > 0}
      addHandler={(value: number) => {
        setProjectLeader([...leader_ids, value as number]);
      }}
      removeHandler={(value: number) => {
        setProjectLeader(leader_ids.filter((id) => id !== value));
      }}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    leader_ids: getProjectLeader(state),
    leaderSelectData: getLeaderSelecData(state),
  };
};

const mapDispatchToProps = {
  setProjectLeader,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChiefSelect);
