import { Action } from 'redux';
import {
  File as FileType,
  Project,
  SectionInComponent,
} from '../../common/types';

export enum types {
  SET_PROJECT_NAME = '@@NEW_PROJECT/SET_PROJECT_NAME',
  SET_PROJECT_DESCRIPTION = '@@NEW_PROJECT/SET_PROJECT_DESCRIPTION',
  SET_PROJECT_SECTIONS = '@@NEW_PROJECT/SET_PROJECT_SECTIONS',
  SET_PROJECT_FILES = '@@NEW_PROJECT/SET_PROJECT_FILES',
  SET_PROJECT_CURATORS = '@@NEW_PROJECT/SET_PROJECT_CURATORS',
  SET_PROJECT_PRIORITY = '@@NEW_PROJECT/SET_PROJECT_PRIORITY',
  SET_PROJECT_CPE_LIST = '@@NEW_PROJECT/SET_PROJECT_CPE_LIST',
  SET_PROJECT_CHIEF = '@@NEW_PROJECT/SET_PROJECT_CHIEF',
  SET_PROJECT_LEADER = '@@NEW_PROJECT/SET_PROJECT_LEADER',
  CLEAR_PROJECT = '@@NEW_PROJECT/CLEAR_PROJECT',
  CREATE_PROJECT = '@@NEW_PROJECT/CREATE_PROJECT',
  UPLOAD_FILE = '@@NEW_PROJECT/UPLOAD_FILE',
  DELETE_FILE = '@@NEW_PROJECT/DELETE_FILE',
  SET_IS_SENDING = '@@NEW_PROJECT/SET_IS_SENDING',
  OPEN_EXISTING_PROJECT = '@@NEW_PROJECT/OPEN_EXISTING_PROJECT',
  REMOVE_PROJECT_SECTION = '@@NEW_PROJECT/REMOVE_PROJECT_SECTION',
  CHANGE_SECTION_NAME = '@@NEW_PROJECT/CHANGE_SECTION_NAME',
  UPDATE_PROJECT = '@@NEW_PROJECT/UPDATE_PROJECT',
  ADD_SECTION = '@@NEW_PROJECT/ADD_SECTION',
}

export interface setProjectNameAction extends Action<types.SET_PROJECT_NAME> {
  name: string;
}

export interface setProjectDescriptionAction
  extends Action<types.SET_PROJECT_DESCRIPTION> {
  description: string;
}

export interface setProjectSectionsAction
  extends Action<types.SET_PROJECT_SECTIONS> {
  sections: SectionInComponent[];
}

export interface setProjectFilesAction extends Action<types.SET_PROJECT_FILES> {
  files: FileType[];
}

export interface clearProjectAction extends Action<types.CLEAR_PROJECT> {}

export interface setProjectCuratorsAction
  extends Action<types.SET_PROJECT_CURATORS> {
  curators: number[];
}

export interface setProjectCpeListAction
  extends Action<types.SET_PROJECT_CPE_LIST> {
  cpeList: number[];
}

export interface setProjectChiefAction extends Action<types.SET_PROJECT_CHIEF> {
  head_of_departments: number[];
}

export interface setProjectLeaderAction
  extends Action<types.SET_PROJECT_LEADER> {
  head_of_work_groups: number[];
}

export interface setProjectPriorityAction
  extends Action<types.SET_PROJECT_PRIORITY> {
  priority: number;
}

export interface createProjectAction extends Action<types.CREATE_PROJECT> {}

export interface uploadFileAction extends Action<types.UPLOAD_FILE> {
  file: File;
}

export interface deleteFileAction extends Action<types.DELETE_FILE> {
  id: number;
}

export interface setIsSendingAction extends Action<types.SET_IS_SENDING> {
  isSending: boolean;
}

export interface openExistingProjectAction
  extends Action<types.OPEN_EXISTING_PROJECT> {
  project: Project;
}

export interface removeProjectSectionAction
  extends Action<types.REMOVE_PROJECT_SECTION> {
  sectionId: number;
}

export interface changeSectionNameAction
  extends Action<types.CHANGE_SECTION_NAME> {
  sectionId: number;
  name: string;
}

export interface updateProjectAction extends Action<types.UPDATE_PROJECT> {}

export interface addSectionAction extends Action<types.ADD_SECTION> {
  projectId: number;
  name: string;
}

export type NewProjectAction =
  | setProjectNameAction
  | setProjectDescriptionAction
  | setProjectSectionsAction
  | setProjectFilesAction
  | clearProjectAction
  | setProjectCuratorsAction
  | setProjectCpeListAction
  | setProjectChiefAction
  | setProjectLeaderAction
  | setProjectPriorityAction
  | createProjectAction
  | uploadFileAction
  | deleteFileAction
  | setIsSendingAction
  | openExistingProjectAction
  | removeProjectSectionAction
  | changeSectionNameAction
  | updateProjectAction
  | addSectionAction;
