import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { State } from "../../../rootReducer";
import { Comment, User, Role, Position } from "../../../common/types";
import FileElem from "../../../common/components/FileElem/FileElem";
import DateMaster from "../../../utils/DateMaster";
import { deleteFile, setParentComment } from "../actions";
import ParentComment from "./ParentComment";
import Avatar from "../../../common/components/Avatar/Avatar";
import { urlApp } from "../../../App";

type Props = {
  comment: Comment;
  users: User[];
  roles: Role[];
  positions: Position[];
  currentId: number;
  commentList: Comment[];
  deleteHandler: () => void;
  setParentComment: (parent: Comment) => void;
};

const CommentElem: React.FC<Props> = ({
  comment,
  users,
  roles,
  positions,
  currentId,
  commentList,
  deleteHandler,
  setParentComment,
}) => {
  const authorInfo = useMemo(() => {
    let author: any = users.find((user) => user.id === comment.author_id);
    if (!author) return { authorName: null, authorRole: null };
    let authorRole = roles.find((role) => role.id === author?.roles[0]);
    let authorPosition = positions.find(
      (position) => position.id === author?.positions[0]
    );

    return {
      authorName: `${author.surname} ${author.name}`,
      authorRole: authorRole?.name,
      authorPosition: authorPosition?.name,
      first_l_name: author.name.substr(0, 1),
      first_l_surname: author.surname.substr(0, 1),
      author: author,
    };
  }, [comment.author_id, roles, users]);
  const parentComment = useMemo(() => {
    let parentComment: Comment | null = null;
    if (comment.parent_id !== 0) {
      parentComment =
        commentList.find((com) => com.id === comment.parent_id) || null;
    }
    return parentComment;
  }, [comment.parent_id, commentList]);

  const commentDate = useMemo(() => {
    const dateMaster = new DateMaster(comment.created_at);
    return dateMaster.commentDate;
  }, [comment.created_at]);

  let name: string = `${authorInfo.first_l_surname}
  ${authorInfo.first_l_name}`;

  return (
    <div
      className={`comment${
        comment.author_id === currentId ? " my_comment" : ""
      }`}
    >
      {!comment?.is_system ? (
        <div className="comment_head">
          <Avatar
            style={{ width: "40px", height: "40px" }}
            url={
              authorInfo?.author?.image
                ? urlApp + `/files/${authorInfo?.author?.image.url}/download`
                : null
            }
            name={name && name}
          />

          {/* <div className="comment_avatar">
          <div className={'round_icon_name_letters'}>
            <div>
              {authorInfo.first_l_surname}
              {authorInfo.first_l_name}
            </div>
          </div>
        </div> */}
          <div className="comment_author">
            <div className="comment_author_name">{authorInfo.authorName}</div>
            <div className="comment_author_role">
              {authorInfo.authorPosition}
            </div>
          </div>

          <div
            onClick={() => setParentComment(comment)}
            className="answer_comment"
          >
            Ответить
          </div>
          <div className="comment_date_delete_block">
            <div className="comment_date">{commentDate}</div>
            {comment.author_id === currentId && (
              <div onClick={deleteHandler} className="comment_delete">
                Удалить
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="date_comment">{commentDate}</div>
      )}
      <div className="comment_body">
        {parentComment && <ParentComment parentComment={parentComment} />}
        <div className="comment_text">{`${comment.text}`}</div>
        <div className={"attachments"}>
          {comment.files &&
            comment.files.map((file) => (
              <FileElem
                url={file.url}
                key={file.id}
                id={file.id}
                name={file.name}
                canBeDeleted={false}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  users: state.commonInfo.users,
  roles: state.commonInfo.roles,
  positions: state.commonInfo.positions,
  currentId: state.commonInfo.current_user,
  commentList: state.taskInfoPlate.commentList,
});

const mapDispatchToProps = {
  setParentComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentElem);
