import React, { useState, useCallback, useEffect, useRef } from 'react';
import UserTr from './UserTr';
import { User } from '../../../common/types';
import { State } from '../../../rootReducer';
import { connect } from 'react-redux';
import { fetchUsers } from '../actions';
import { throttle } from '../../../utils/throttle';
import styled from 'styled-components';

type Props = {
  userList: User[];
};
const WrapperUsers = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 1280px; */
  width: 100%;
`;
const UsersTableContent: React.FC<Props> = ({ userList }) => {
  return (
    <
      //   className={`table_content users_table_content${
      //     selectedDepGroupId ? ' is_dep_group' : ''
      //   }`}
    >
      <WrapperUsers>
        {userList.map((user) => (
          <UserTr key={user.id} user={user} />
        ))}
      </WrapperUsers>
    </>
  );
};

const mapStateToProps = (state: State) => ({
  userList: state.usersPage.depGroupUsers,
});

export default connect(mapStateToProps)(UsersTableContent);
