import React from 'react';
import Avatar from '../Avatar/Avatar';
import styled from 'styled-components';
import {nanoid} from "nanoid";
import {urlApp} from "../../../App";

type Props = {
    data: { name: string, url: string }[];
    minWidth?: string;
};
const WrapperExecutorCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({minWidth}) => minWidth};
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
`;

const AdditionalIcon = styled.div`
    border-radius: 50%;
    border: 1px solid #1BAAF0;
    width: 32px;
    height: 32px;
    color: #1BAAF0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    position: absolute;
    background-color: white;
    left: ${({number}) => 16 * number + 'px'};
`;

const Participants: React.FC<Props> = ({data, minWidth}) => {

    return (
        <WrapperExecutorCell minWidth={minWidth}>
            {data.map((item, number) => {
                if (number < 3) {
                    return <Avatar
                        key={nanoid()}
                        url={item.url && typeof item.url === 'object' ? urlApp + `/files/${item.url['url']}/download` : null}
                        name={item.name}
                        style={{
                            width: '32px',
                            height: '32px',
                            position: 'absolute',
                            left: `${16 * number}px`
                        }}
                    />
                } else {
                    if (number === 3) {
                        return <AdditionalIcon number={number} key={nanoid()}>
                            {"+" + (data.length - 3)}
                        </AdditionalIcon>
                    } else return null
                }
            })}

        </WrapperExecutorCell>
    );
};

export default Participants;
