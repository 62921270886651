import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Button from '../../../common/components/newComponent/Buttons/Button';
import Icon from '../../../common/components/Icon';
import { State } from '../../../rootReducer';
import { changeStep, setFree } from '../actions';
import { useHistory } from 'react-router-dom';

type Props = {
  iconName: string;
  color: string;
  active: boolean;
  tariffName: string;
  describe1: string;
  price: string;
  describe2: string;
  companyId?: number;
  //   buttonTitle: string;
  buttonDesign: string;
  changeStep: (step: number) => void;
  id: number;
  selectedTariffId: (num: number) => void;
};

const Tile1stStep = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 280px;
  padding: 16px;
  border: 1px solid ${({ color }) => (color ? '#' + color : 'black')};
  border-radius: 12px;
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
`;

const NewRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  /* &:last-child {
    margin-bottom: 0;
  } */
  ${({ styles }) => styles && { ...styles }}
`;

const SendMail = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  background: #27cba6;
  color: #ffffff;
  font-weight: 400;
  &:hover {
    border-color: #fff;
  }
  a {
    padding: 0.5rem 4rem;
    color: white;
    text-decoration: none;
  }
`;

const Tile: React.FC<Props> = ({
  iconName,
  color,
  active,
  tariffName,
  describe1,
  price,
  describe2,
  //   buttonTitle,
  buttonDesign,
  changeStep,
  companyId,
  id,
  selectedTariffId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const titleName = () => {
    let buttonTitle = '';
    if (id === 1) {
      if (active) {
        buttonTitle = 'Продолжить работу';
      } else {
        buttonTitle = 'Перейти';
      }
    } else if (id === 2) {
      if (active) {
        buttonTitle = 'Пополнить';
      } else {
        buttonTitle = 'Оформить подписку';
      }
    } else if (id === 3) {
      buttonTitle = 'Напишите нам';
    }
    return buttonTitle;
  };

  useEffect(() => {
    titleName();
  }, []);
  return (
    <Tile1stStep color={color}>
      <div>
        <NewRow>
          <Icon name={iconName} width={'96'} height={'96'} />
        </NewRow>
        <NewRow
          styles={{ lineHeight: '32px', justifyContent: 'space-between' }}
        >
          <div
            style={{ fontSize: '22px', color: '#' + color, fontWeight: 500 }}
          >
            {tariffName}
          </div>
          {active && (
            <div
              style={{
                fontSize: '13px',
                width: '85px',
                border: '1px solid #E0E0E0',
                borderRadius: '20px',
                textAlign: 'center',
              }}
            >
              Текущий
            </div>
          )}
        </NewRow>
        <NewRow styles={{ color: '#707070', lineHeight: '16px' }}>
          {describe1}
        </NewRow>
        <NewRow
          styles={{ fontSize: '36px', fontWeight: '300px', color: '#02405E' }}
        >
          {price}
        </NewRow>
        <NewRow
          style={{
            color: '#707070',
            flexDirection: 'column',
            lineHeight: '16px',
          }}
        >
          <div>{describe2}</div>
          {id === 2 && <div></div>}
        </NewRow>
      </div>
      <NewRow>
        {id < 3 ? (
          <Button
            onClick={() => {
              if (id === 1 && active) {
                history.push('/');
              } else if (id === 1 && !active) {
                dispatch(setFree(companyId));
              }
              if (id === 2) {
                changeStep(2);
                selectedTariffId(id);
              }
              if (id === 3) {
              }
            }}
            design={buttonDesign}
            title={titleName()}
            style={{ width: '100%' }}
          />
        ) : (
          <SendMail>
            <a
              href="javascript:void(0)"
              onClick={() => {
                // @ts-ignore
                jivo_api.open();
                return false;
              }}
            >
              Оставить заявку
            </a>
          </SendMail>
        )}
      </NewRow>
    </Tile1stStep>
  );
};

const mapStateToProps = (state: State) => ({
  currentTariff: state.payment.currentTariff,
  step: state.payment.step,
  months: state.payment.months,
  members: state.payment.members,
});

const mapDispatchToProps = {
  // changeStep
};

export default connect(mapStateToProps, mapDispatchToProps)(Tile);
