import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SelectContext } from './context';
import { Item } from '../../types';
import { Link } from 'react-router-dom';

type Props = {
  selectedItem: Item | null;
  itemsList: Item[];
  selectHandler: (value: string | number, target: any) => void;
  className?: string | undefined;
  disabled?: boolean;
  closeWindow: any;
  headOptions?: object;
  setPage?: any;
  page?: number | undefined;
};

const CustomSelect: React.FC<Props> = ({
  headOptions,
  selectedItem,
  itemsList,
  selectHandler,
  children,
  className,
  disabled = false,
  closeWindow,
  setPage,
  page = 1,
}) => {
  const [isOpen, setOpen] = useState(false);
  const select = useRef<HTMLDivElement>(null);
  const firstUpdate = useRef(true);
  let element;
  let dependencies = document.getElementById('dependencies');
  useEffect(() => {
    // отрабатывает только при первом рендере компоненты
    if (firstUpdate.current) {
      if (headOptions && headOptions['openDropdownByDefault']) {
        processing(select.current);
        setOpen(!isOpen);
        firstUpdate.current = false;
        return;
      }
    }
    // Do something
  });

  const clickHandler = useCallback(
    (e) => {
      if (!disabled) {
        processing(e);
        setOpen(!isOpen);
      }
    },
    [disabled, isOpen]
  );

  function processing(e) {
    let custom_select_node;

    if (e.target) {
      custom_select_node = e.target;
      // пройти до 5 родителей вверх, чтобы найти нод custom_select
      for (let i = 0; i < 5; i++) {
        let found = false;
        // прохожу по каждому имени класса в каждом тэге
        custom_select_node.className
          .trim()
          .split(' ')
          .forEach((item) => {
            if (item === 'custom_select') found = true;
          });

        if (found) break;
        else custom_select_node = custom_select_node.parentElement;
      }
    } else custom_select_node = e;

    // в taskInfoPlate используется этот же компонент для смены приоритета. Там такого нода нет.
    if (custom_select_node.getElementsByClassName('current_item_text')[0]) {
      if (
        custom_select_node.className.trim().split(' ').includes('custom_select')
      ) {
        if (isOpen) {
          // activate
          custom_select_node.getElementsByClassName(
            'current_item_text'
          )[0].style.display = 'block';
          custom_select_node.getElementsByClassName(
            'input_search_box'
          )[0].style.display = 'none';
          closeWindow('');
        } else {
          // deactivate
          custom_select_node.getElementsByClassName(
            'current_item_text'
          )[0].style.display = 'none';
          custom_select_node.getElementsByClassName(
            'input_search_box'
          )[0].style.display = 'flex';
          custom_select_node.getElementsByTagName('input')[0].focus();
        }
      }
    }
  }

  const outsideClickHandler = useCallback(
    (e: MouseEvent): void => {
      if (isOpen && !select.current?.contains(e.target as Node)) {
        setOpen(false);
        processing(select.current);
      }
    },
    [isOpen, select]
  );

  // const cleanSearchHook = (inputField) => {
  //     // inputField.value = ''
  //     closeWindow('')
  //     // inputField.style.display = 'none'
  // }

  useEffect(() => {
    document.addEventListener('click', outsideClickHandler);
    return () => document.removeEventListener('click', outsideClickHandler);
  }, [outsideClickHandler]);

  useEffect(() => {
    if (page !== undefined) {
      element = dependencies?.getElementsByClassName('select_items')[0];
      element?.addEventListener('scroll', () => {
        if (
          element?.scrollTop + element?.clientHeight >=
          element?.scrollHeight
        ) {
          setPage((prev) => prev + 1);
        }
      });
    }
  }, [isOpen, setOpen, element, element?.scrollTop]);

  return (
    <SelectContext.Provider value={{ isOpen, selectedItem, disabled }}>
      <div
        ref={select}
        onClick={clickHandler}
        className={`custom_select input_div ${
          className !== 'undefined' ? className : ''
        } ${disabled ? 'disabled' : ''}`}
      >
        {children}

        {/*если без опций сверху*/}
        {isOpen && !headOptions && (
          <div className="select_items">
            {itemsList.map((item: Item, ind: number) => (
              <div
                key={ind}
                className={`custom_select_item${
                  selectedItem?.value === item.value ? ' selected_item' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  processing(e);
                  // если выбрал не тот же самый пункт, который уже выбран
                  if (item.value !== selectedItem?.value)
                    selectHandler(item.value, e.target);
                  setTimeout(setOpen, 0, false);
                }}
              >
                {item.text}
              </div>
            ))}
          </div>
        )}

        {/*если есть опции сверху*/}
        {isOpen && headOptions && (
          <div className="select_items">
            {itemsList
              .filter(
                (item) =>
                  parseInt(headOptions['current_context_id']) !== item.value
              )
              .map((item: Item, ind: number) => (
                <Link
                  key={item.value}
                  className={`custom_select_item${
                    parseInt(headOptions['current_context_id']) === item.value
                      ? ' selected_item'
                      : ''
                  }`}
                  to={`/${headOptions['type']}/${item.value}`}
                  // onClick={itemClickHandler}
                >
                  {item.text}
                </Link>
              ))}
          </div>
        )}
      </div>
    </SelectContext.Provider>
  );
};

export default CustomSelect;
