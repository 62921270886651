import React from "react";
import styled from "styled-components";

interface Props {
  isLoading: boolean;
  sizePX?: string;
}

const CommonCirclePreloader: React.FC<Props> = ({
  isLoading,
  sizePX = "17",
}) => {
  const Preloader = styled.div`
    ${({ isLoading }) =>
      isLoading &&
      `
      &:after {
        content: '';
        border-radius: 50%;
        border: 3px solid #1BAAF0;
        z-index: 3;
        border-bottom-color: #fff;
        border-left-color: #fff;
        animation: spin .5s linear infinite;
        /* transform: translateX(-50%); */
        position:absolute;
        top:10px;
        height:${sizePX}px;
        width:${sizePX}px;
  
  `};
  `;

  return isLoading ? <Preloader isLoading={isLoading} /> : null;
};

export default CommonCirclePreloader;
