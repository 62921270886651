// Статусы в Easy Task меню "Задачи"
// 0: {id: 2, name: 'Отправлено автору', slug: 'for_approval_chief'}
// 1: {id: 3, name: 'Отправлено исполнителю', slug: 'for_approval_performer'}
// 2: {id: 10, name: 'В работе', slug: 'in_work'}
// 3: {id: 11, name: 'Отменена', slug: 'canceled'}
// 4: {id: 12, name: 'Выполнено', slug: 'done'}
// 5: {id: 13, name: 'Завершено', slug: 'completed'}
// 6: {id: 14, name: 'Просрочена', slug: 'overdue'}
// 7: {id: 15, name: 'В архиве', slug: 'archived'}
// 8: {id: 18, name: 'Подзадача', slug: 'podzadacha'}
// 9: {id: 19, name: 'Идея', slug: 'idea'}

// Статусы в ТП:
// 0 - черновик
// 2 - в работе
// 4 - завершена
// 5 - отменена

// Статусы в задачах:
//  0 - Черновик
//  1 - Ожидание
//  2 - В работе
//  3 - Выполнена
//  4 - Завершена
//  5 - Отменена
//  6 - Пропущена
//

import "./App.css";
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { StatusContext } from "./context/status";
//import Header from "./components/header/Header";
import BusinessMain from "./components/business-main/BusinessMain";
//import LeftNav from "./components/left-nav/LeftNav.jsx";
import "./components/left-nav/LeftNav.jsx";
import { cookieMaster } from "../../../utils/CookieMaster";
import axios from "axios";
import SearchField from "./components/search-field/SearchField";

function App() {
  let company_id = localStorage.getItem("company_id");

  // useRef для использования в файлах CreateBp и BusinessMainи BpItemTaskMenu, для закрытия всплывающего окна меню и меня для "запуск с"
  const Mount = useRef(false);

  const [bearer, setBearer] = useState(
    document.cookie.replace(
      /(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    )
  );

  // https://easytaskbp.tk/

  const [apiBp, setApiBb] = useState(`${process.env.REACT_APP_BACKEND}/api/v1`);

  const [thisTabs, setThisTabs] = useState(2); // текущая вкладка задачи
  const [idSample1, setIdSample1] = useState(1); // id первого шаблона ("Договоры") в базе
  const [idSample2, setIdSample2] = useState(2); // id второго шаблона ("Прием на работу") в базе
  const [idSample3, setIdSample3] = useState(3); // id третьего шаблона ("Увольнение") в базе
  const [comment, setComment] = useState(""); // для передачи комментов из нетипового бп, в ЕТ
  const [timeСosts, setTimeСosts] = useState(""); // для передачи трудозатрат из нетипового бп, в ЕТ
  const [createBpFromSample, setCreateBpFromSample] = useState(false); // Создается ли типовая задача из шаблона (Шаблоны/Запустить)
  const [bp, setBp] = useState({}); // Текущая типовая задача (объект с полями)
  const [bpList, setBpList] = useState([]);
  const [createBpStatus, setCreateBpStatus] = useState(false);
  const [createBpSampleStatus, setCreateBpSampleStatus] = useState(false);
  const [createTaskStatus, setCreateTaskStatus] = useState(false);
  const [bpListChange, setBpListChange] = useState(false);
  const [change, setChange] = useState(0);
  const [closeWindowTask, setCloseWindowTask] = useState(0); // Закрытие окна задачи

  const [openSaveBp, setOpenSaveBp] = useState(false); // открытие и закрытие окна для сохранения бп
  const [loadTemplateBp, setLoadTemplateBp] = useState(true);

  const [bpListSamples, setBpListSamples] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [bpDarfts, setBpDrafts] = useState(false);
  const [bpWorks, setBpWorks] = useState(false);
  const [isTreatyFilterApply, setIsTreatyFilterApply] = useState(false);
  const [isWorkFilterApply, setIsWorkFilterApply] = useState(false);
  const [isDismissalFilterApply, setIsDismissalFilterApply] = useState(false);
  const controller = new AbortController();
  const [bpFilter, setBpFilter] = useState(false); // отправка запроса на расширенную фильтрацию БП
  const [textBpFilter, setTextBpFilter] = useState(""); // содержимое фильтра БП

  const [executorIdofTask, setExecutorIdofTask] = useState();

  const [initiator_id, setInitiator_id] = useState(
    +localStorage.getItem("user_id")
  );
  const [isAdmin, setIsAdmin] = useState(false);

  const [createBpForm, setCreateBpForm] = useState({
    name: null,
    initiator_id: +localStorage.getItem("user_id"),
    project_id: 1,
    project_section_id: 1,
    deadlineDate: null,
    deadlineTime: "00:00:00",
    tasks: null,
    file_id: null,
    deadline: null,
  });

  const [createBpSampleFormDate, setCreateBpSampleFormDate] = useState({
    deadlineDate: null,
    deadlineTime: "00:00",
  });

  const [createBpSampleForm, setCreateBpSampleForm] = useState({
    type: null,
    businessProcess: {
      name: null,
      deadline: null,
      project_id: 1,
      project_section_id: 1,
      tasks: 1,
      initiator_id: +cookieMaster.getCookie("user_id"),
    },
    options: [],
  });

  const [createBpSampleFormOptions, setCreateBpSampleFormOptions] = useState(
    {}
  );

  const [createTaskFormDate, setCreateTaskFormDate] = useState({
    beginDate: null,
    beginTime: null,
    endDate: null,
    endTime: null,
  });

  const [selectColumns, setSelectColumns] = useState([
    "id",
    "initiator_id",
    "name",
    "status",
    "deadline",
    "project_id",
  ]); // Выбранные колонки в списке БП

  const [businessProcessCurrentGlobal, setBusinessProcessCurrentGlobal] =
    useState({});
  const [changeTask, setchangeTask] = useState(0); // изменение данных в задаче
  const [taskCurrentGlobal, setTaskCurrentGlobal] = useState({}); // данные о задаче
  const [etTaskId, setEtTaskId] = useState(); // данные о задаче

  const [usersFromSample, setUsersFromSample] = useState([]); // массив пользователей для автоподстановки в шаблоны

  const [statusTask, setStatusTask] = useState(0);
  const [statusBp, setStatusBp] = useState(0);
  const [statusBpId, setStatusBpId] = useState(0);
  const [statusTaskId, setStatusTaskId] = useState(0);
  const [statusOfTask, setStatusOfTask] = useState();

  const [start, setStart] = useState(false);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState("id");
  const [filterMethod, setFilterMethod] = useState("desc");
  const [sortActive, setSortActive] = useState(false); //для сортировки при нажатии на название
  const [openTasks, setOpenTasks] = useState("");
  const [idBp, setIdBp] = useState("");
  const [tasksList, setTasksList] = useState([]);
  const [nowBp, setNowBp] = useState({});
  const [depsTask, setDepsTask] = useState();
  const [tasks, setTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectSections, setProjectSections] = useState([]);
  const [nextLinkProjects, setNextLinkProjects] = useState(
    `${process.env.REACT_APP_PUBLIC_API}/projects`
  );
  const [descript, setDescript] = useState(
    "Что нужно сделать:\n\nКак нужно сделать:\n\nКакой должен быть результат:"
  );

  // console.log(openTasks);
  // console.log(taskCurrentGlobal);
  const createTaskFormTemp = {
    name: "",
    description:
      "Что нужно сделать:\n\nКак нужно сделать:\n\nКакой должен быть результат:",
    begin: null,
    end: null,
    business_process_id: 4,
    et_task_id: null,
    executor_id: users[0] ? users[0].id : 1,
    template_id: null,
    extra: null,
    created_at: null,
    updated_at: null,
    status: 0,

    data: {
      author_id: businessProcessCurrentGlobal.initiator_id,
      checklist: [],
      task_load: 0.1,
      work_load: 0.1,
      project_id: 0,
      priority_id: 2,
      project_section_id: 0,
    },
    files: {
      by_tag: [],
      list: [],
    },
    childEtTasks: [],
    et_task: [],
  };

  const [createTaskForm, setCreateTaskForm] = useState({
    ...createTaskFormTemp,
  });

  const userId = parseInt(+localStorage.getItem("user_id"));

  // console.log(users[0] ? users[0].id : 1);

  const [createTaskSampleForm, setCreateTaskSampleForm] = useState({});
  const [createTaskSampleFormStatus, setCreateTaskSampleFormStatus] =
    useState(false);

  const [idSample, setIdSample] = useState("1");
  const [sampleArr, setSampleArr] = useState([]);
  const [statusCreateTask, setStatusCreateTask] = useState(false);
  const [arrTasksSample, setArrTasksSample] = useState([]);
  const [depsTaskSample, setDepsTaskSample] = useState([]);
  const [valueTaskSample, setValueTaskSample] = useState([]);
  const [nowTask, setNowTask] = useState("");
  const [endTasksSample, setEndTasksSample] = useState([]);
  const [tasksArr, setTasksArr] = useState([]);
  const [taskSample, setTaskSample] = useState({
    name: "",
    executor_id: "",
  });
  const [lengthArrTasks, setLengthArrTasks] = useState("");
  const [addTaskSample, setAddTaskSample] = useState(false);
  const [addTasksMenu, setAddTasksMenu] = useState(false);
  const [thisBp, setThisBp] = useState({});

  //menu

  const [openMenuTasks, setOpenMenuTasks] = useState(false);
  const [openMenuBp, setOpenMenuBp] = useState(false);
  const [idCall, setIdCall] = useState("");
  //для всплывающего меню "Запуск с"
  const [openMenuTask, setOpenMenuTask] = useState(false);

  const [selectedTaskOfBP, setSelectedTaskOfBP] = useState(""); //ИМЯ выбранной задачи из бизнес-процесса. Применяется для привязки формы к задаче по имени
  const [taskId, setTaskId] = useState(); //ид выбранной задачи из бизнес-процесса. Применяется для работы с формами по бизнес-процесса
  const [selectedBp, setSelectedBp] = useState(); //ид выбранного бизнес-процесса. Применяется для получения карточек по бизнес-процесса

  // const [showGeneralResult, setShowGeneralResult] = useState(); //показ общего результата. П
  const [showChatWindow, setShowChatWindow] = useState(); //показ общего чата.

  const [isTemplateTask, setIsTemplateTask] = useState(false);

  const [teamStructure, setTeamStructure] = useState(); //структура коллектива

  const [deps, setDeps] = useState(); // отдел сотрудника

  const [relatedBp, setRelatedBp] = useState();
  const [relatedTaskId, setRelatedTaskId] = useState();

  const [redirectedByClick, setRedirectedByClick] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [etTask, setEtTask] = useState();

  // Если пользователь не залогинен - переводим его на страницу авторизации
  useLayoutEffect(() => {
    let token = cookieMaster.getCookie("access_token");
    if (token === undefined || token === null) {
      window.location.replace(`/login`);
    }
    if (token.length == 0) {
      window.location.replace(`/login`);
    }
  }, []);

  let clearFilter = localStorage.getItem("clearFilter");

  useEffect(() => {
    setCreateTaskForm({
      ...createTaskFormTemp,
    });
  }, [createTaskStatus]);

  useEffect(() => {
    if (clearFilter) {
      setOpenTasks(false);
      setSelectedBp("");
      if (!textBpFilter && !bpFilter) {
        localStorage.removeItem("clearFilter");
      } else {
        setTextBpFilter("");
        setBpFilter(false);
        localStorage.removeItem("clearFilter");
      }
    }
  }, [clearFilter, textBpFilter, bpFilter]);

  const [settingsOfFields, setSettingsOfFields] = useState(); //состояние настроек форм. Подтягиваются после следующего запроса
  const [loadDataNow, setloadDataNow] = useState(true);

  useEffect(() => {
    if (!!nextLinkProjects) {
      axios
        .get(nextLinkProjects, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
          },
        })
        .then((res) => {
          setProjects([...projects, ...res.data.data]);
          setNextLinkProjects(res.data.links.next);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [nextLinkProjects]);

  // console.log(bpList);

  // Определение - админ текущий пользователь или нет

  // useEffect(() => {
  //   if (users && initiator_id) {
  //     let user = users.find((user) => +user.id == +initiator_id);
  //     // console.log("user", user);
  //     if (user) {
  //       setIsAdmin(user.isAdmin);
  //     }
  //   }
  // }, [users, initiator_id, isAdmin]);

  useEffect(() => {
    let configForOneUser = {
      method: "get",
      url: `${process.env.REACT_APP_PUBLIC_API}/users/${userId}`,
      headers: {
        Authorization: bearer,
        company_id: company_id,
      },
    };

    axios(configForOneUser)
      .then((response) => {
        let user = response.data.data;
        setIsAdmin(user.isAdmin);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [userId]);
  // console.log(isAdmin);

  // запрос для получения данных с бэка и последующего заполнения форм в задачах, созданных автоматически при выборе шаблона: Договора
  useEffect(() => {
    if (selectedBp) {
      //  console.log(selectedBp, "***************app useeff work");

      axios
        .get(`${apiBp}/bp/${selectedBp}/task`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            // Информация берется из Cookies, вместо localStorage
            "secret-token": document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log("***", res.data.data);

          // Для БП "Договор"
          //***************************************************************************************** */
          if (res.data.data[0].template_id === "init") {
            setSettingsOfFields({
              ...settingsOfFields,

              init: {
                extra: { ...res.data.data[0].extra },
              },
              checkOfHeadOfDepartment: {
                extra: { ...res.data.data[1].extra },
              },
              checkOfJurist: {
                extra: {
                  ...res.data.data[2].extra,
                },
              },
              checkOfChiefAccountant: {
                extra: {
                  ...res.data.data[3].extra,
                },
              },
              checkOfExecutiveDirector: {
                extra: {
                  ...res.data.data[4].extra,
                },
              },
              checkOfFinanceDirector: {
                extra: {
                  ...res.data.data[5].extra,
                },
              },
              checkFinalOfJurist: {
                extra: {
                  ...res.data.data[6].extra,
                },
              },
              signingOfTreaty: {
                extra: {
                  ...res.data.data[7].extra,
                },
              },
              enteringDataInto1CUT: {
                extra: {
                  ...res.data.data[8].extra,
                },
              },
              finalRegistrationByJurist: {
                extra: {
                  ...res.data.data[9].extra,
                },
              },
            });
          }
          //********************************************************************** */

          // Для БП "Прием на работу"
          //*********************************************************************** */
          if (res.data.data[0].template_id == "initial") {
            setSettingsOfFields({
              ...settingsOfFields,

              initial: {
                extra: {
                  ...res.data.data[0].extra,
                },
              },
              checkSecurity: {
                extra: {
                  ...res.data.data[1].extra,
                },
              },
              acceptByHeadOfDep: {
                extra: {
                  ...res.data.data[2].extra,
                },
              },
              agreementByDeputyGenManager: {
                extra: {
                  ...res.data.data[3].extra,
                },
              },
              forkedStage_SMZ_IP: {
                extra: {
                  ...res.data.data[4].extra,
                },
              },
              forkedStage_GPH_TD: {
                extra: {
                  ...res.data.data[5].extra,
                },
              },
              signing: {
                extra: {
                  ...res.data.data[6].extra,
                },
              },
              completion_3monthTimer: {
                extra: {
                  ...res.data.data[7].extra,
                },
              },
              acceptByDeputyGenManager: {
                extra: {
                  ...res.data.data[8].extra,
                },
              },
              employeeRegistration: {
                extra: {
                  ...res.data.data[9].extra,
                },
              },
              processTerminating: {
                extra: {
                  ...res.data.data[10].extra,
                },
              },
            });
          }
          //********************************************************************** */

          // Для БП Увольнение
          //************************************************************************* */
          if (res.data.data[0].template_id == "initDismissal") {
            setSettingsOfFields({
              ...settingsOfFields,

              /********************************************************************** */
              // Для БП Увольнение

              initDismissal: {
                extra: {
                  ...res.data.data[0].extra,
                },
              },
              initialProcessingOfDocuments: {
                extra: {
                  ...res.data.data[1].extra,
                },
              },
              signingOfTreatyDismissal: {
                extra: {
                  ...res.data.data[2].extra,
                },
              },
              confirmationOfPaymentOfAccountingCalculation: {
                extra: {
                  ...res.data.data[3].extra,
                },
              },
              employeeAccountActions: {
                extra: {
                  ...res.data.data[4].extra,
                },
              },
              preparationAndApprovalFinalPaymentForUT: {
                extra: {
                  ...res.data.data[5].extra,
                },
              },
              finalSettlementWithEmployee: {
                extra: {
                  ...res.data.data[6].extra,
                },
              },
              completionOfBP: {
                extra: {
                  ...res.data.data[7].extra,
                },
              },
            });
          }
          //********************************************************************** */
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setSettingsOfFields();
    }
  }, [selectedBp, loadDataNow, taskId, thisTabs]);
  // console.log("taskId  ", taskId);
  // console.log("taskId  ", settingsOfFields);

  //************************************************ */

  useEffect(() => {
    if (company_id) {
      // console.log("company_id", company_id);

      axios
        .get(`${process.env.REACT_APP_PUBLIC_API}/assoc-departments`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            // Информация берется из Cookies, вместо localStorage
            "secret-token": document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res && res.data && res.data.data) {
            // console.log("usersFromSample", res.data.data);

            setTeamStructure(res.data.data);

            // console.log("teamStucture", res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [company_id]);

  // console.log(teamStructure);

  useEffect(() => {
    axios
      .get(`${apiBp}/bp-template`, {
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": document.cookie.replace(
            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
          ),
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log("массивы шаблонов: ", res);
        if (res.data.data.length === 0) {
          console.log("Пока нет шаблонов");
          /****************************************** */
          let data = JSON.stringify({
            company_id: 1,
            name: "Первый БП без шаблона",
            initiator_id: userId,
            project_id: 1,
            project_section_id: 1,
            deadline: "2022-10-15T20:26:08",
          });

          let config = {
            method: "post",
            url: `${apiBp}/bp`,
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": document.cookie.replace(
                /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
              ),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              setBpListChange((prev) => !prev);
              console.log(response.data.data.id);
              let idBisnessProcess = response.data.data.id;

              //***************************************************** */

              let data1 = JSON.stringify({
                company_id: company_id,
                name: "Шаблон «Договоры»",
              });

              let config1 = {
                method: "post",
                url: `${apiBp}/bp-template/from-bp/${idBisnessProcess}`,
                headers: {
                  Authorization: bearer,
                  "company-id": company_id,
                  "secret-token": document.cookie.replace(
                    /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                    "$1"
                  ),
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                data: data1,
              };

              axios(config1)
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });

              let data2 = JSON.stringify({
                company_id: company_id,
                name: "Шаблон «Прием на работу»",
              });

              let config2 = {
                method: "post",
                url: `${apiBp}/bp-template/from-bp/${idBisnessProcess}`,
                headers: {
                  Authorization: bearer,
                  "company-id": company_id,
                  "secret-token": document.cookie.replace(
                    /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                    "$1"
                  ),
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                data: data2,
              };

              axios(config2)
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });

              let data3 = JSON.stringify({
                company_id: company_id,
                name: "Шаблон «Увольнение»",
              });

              let config3 = {
                method: "post",
                url: `${apiBp}/bp-template/from-bp/${idBisnessProcess}`,
                headers: {
                  Authorization: bearer,
                  "company-id": company_id,
                  "secret-token": document.cookie.replace(
                    /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                    "$1"
                  ),
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                data: data3,
              };

              axios(config3)
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });

              //***************************************************** */
            })
            .catch(function (error) {
              console.log(error);
            });

          //****************************************** */
        } else {
          setIdSample1(+res.data.data[0].id);
          setIdSample2(+res.data.data[1].id);
          setIdSample3(+res.data.data[2].id);
        }
      })
      .catch((e) => {
        console.log("ошибка получения списка шаблонов ", e);
      });
  }, []);

  // console.log(selectedBp);

  //*********************************************** */
  // console.log(createTaskForm.data.priority_id);
  // console.log(taskCurrentGlobal);

  return (
    <StatusContext.Provider
      value={{
        users,
        start,
        setStart,
        thisBp,
        setThisBp,
        setUsers,
        createBpStatus,
        setCreateBpStatus,
        createTaskStatus,
        setCreateTaskStatus,
        createBpSampleStatus,
        setCreateBpSampleStatus,
        createBpForm,
        setCreateBpForm,
        createBpSampleForm,
        setCreateBpSampleForm,
        createBpSampleFormDate,
        setCreateBpSampleFormDate,
        createTaskForm,
        setCreateTaskForm,
        createBpSampleFormOptions,
        setCreateBpSampleFormOptions,
        createTaskSampleForm,
        setCreateTaskSampleForm,
        createTaskSampleFormStatus,
        setCreateTaskSampleFormStatus,
        filter,
        setFilter,
        filterMethod,
        setFilterMethod,
        openTasks,
        setOpenTasks,
        idBp,
        setIdBp,
        bearer,
        tasksList,
        setTasksList,
        createTaskFormDate,
        setCreateTaskFormDate,
        nowBp,
        setNowBp,
        depsTask,
        setDepsTask,
        apiBp,
        tasks,
        setTasks,
        idSample,
        setIdSample,
        sampleArr,
        setSampleArr,
        arrTasksSample,
        setArrTasksSample,
        depsTaskSample,
        setDepsTaskSample,
        valueTaskSample,
        setValueTaskSample,
        nowTask,
        setNowTask,
        endTasksSample,
        setEndTasksSample,
        statusCreateTask,
        setStatusCreateTask,
        tasksArr,
        setTasksArr,
        taskSample,
        setTaskSample,
        lengthArrTasks,
        setLengthArrTasks,
        addTaskSample,
        setAddTaskSample,
        bpList,
        setBpList,
        addTasksMenu,
        setAddTasksMenu,
        openMenuTasks,
        setOpenMenuTasks,
        openMenuBp,
        setOpenMenuBp,
        idCall,
        setIdCall,
        selectedTaskOfBP,
        setSelectedTaskOfBP,
        bpListChange,
        setBpListChange,
        statusTask,
        setStatusTask,
        statusBp,
        setStatusBp,
        statusBpId,
        setStatusBpId,
        statusTaskId,
        setStatusTaskId,
        change,
        setChange,
        selectedBp,
        setSelectedBp,
        setTaskId,
        taskId,
        settingsOfFields,
        setSettingsOfFields,
        bpListSamples,
        setBpListSamples,
        loadDataNow,
        setloadDataNow,
        bp,
        setBp,
        createBpFromSample,
        setCreateBpFromSample,
        initiator_id,
        setInitiator_id,
        usersFromSample,
        setUsersFromSample,
        userId,
        deps,
        isTemplateTask,
        setIsTemplateTask,
        idSample1,
        idSample2,
        idSample3,
        teamStructure,
        setTeamStructure,
        thisTabs,
        setThisTabs,
        closeWindowTask,
        setCloseWindowTask,
        Mount,
        isAdmin,
        setIsAdmin,
        taskCurrentGlobal,
        setTaskCurrentGlobal,
        changeTask,
        setchangeTask,
        businessProcessCurrentGlobal,
        setBusinessProcessCurrentGlobal,
        openMenuTask,
        setOpenMenuTask,
        statusOfTask,
        setStatusOfTask,
        executorIdofTask,
        setExecutorIdofTask,
        comment,
        setComment,
        timeСosts,
        setTimeСosts,
        company_id,
        projects,
        setProjects,
        projectSections,
        setProjectSections,
        relatedBp,
        setRelatedBp,
        relatedTaskId,
        setRelatedTaskId,
        showChatWindow,
        setShowChatWindow,
        openFilter,
        setOpenFilter,
        bpDarfts,
        setBpDrafts,
        bpWorks,
        setBpWorks,
        bpFilter,
        setBpFilter,
        textBpFilter,
        setTextBpFilter,
        redirectedByClick,
        setRedirectedByClick,
        selectColumns,
        setSelectColumns,
        descript,
        setDescript,
        etTask,
        setEtTask,
        isTreatyFilterApply,
        setIsTreatyFilterApply,
        isWorkFilterApply,
        setIsWorkFilterApply,
        isDismissalFilterApply,
        setIsDismissalFilterApply,
        etTaskId,
        setEtTaskId,
        sortActive,
        setSortActive,
        controller,
        openSaveBp,
        setOpenSaveBp,
        loadTemplateBp,
        setLoadTemplateBp,
      }}
    >
      <div style={{ position: "absolute", top: "17px", right: "160px" }}>
        <SearchField
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          clearFilter={clearFilter}
        />
      </div>
      <div className="bussines-page">
        <div className="right-content">
          <BusinessMain />
        </div>
      </div>
    </StatusContext.Provider>
  );
}

export default App;
