import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTask } from '../../../../common/actions';
import { State } from '../../../../rootReducer';
import { fetchTaskDependence } from '../../../Tasks/actions';
import {
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setPrevId,
} from '../../actions';
import Dependence from '../Dependence';

const DependencePrev = ({ prevTask }) => {
  const dispatch = useDispatch();
  const { selectedTask, flagForActionCyclycTask } = useSelector(
    (state: State) => state.taskInfoPlate
  );
  return (
    <Dependence
      task={prevTask}
      // id={prevTask.id}
      // name={prevTask.name}
      // status_id={prevTask.status_id}
      // dedline={{
      // begin: prevTask.begin,
      // end: prevTask.end,
      // }}
      icon="prev-dep"
      // priority_id={prevTask.priority_id}
      dependence="prev"
      // load={prevTask.task_load}
      onDelete={() => {
        if (selectedTask) {
          if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
            // сравнение одного с несколькими
            // эта задача вообще циклическая или сама по себе ?
            // значит циклическая
            if (flagForActionCyclycTask === '') {
              dispatch(setCyclicTaskToChange({ prev_id: null })); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
            } else if (flagForActionCyclycTask === 'task') {
              dispatch(updateTask(selectedTask.id, { prev_id: null }));
            } else {
              dispatch(updateTask(selectedTask.id, { prev_id: null }));
              dispatch(sendReqToChangeTemplRepTask({ prev_id: null }));
            }
          } else {
            dispatch(updateTask(selectedTask.id, { prev_id: null }));
          }
          setTimeout(() => {
            dispatch(fetchTaskDependence(selectedTask!.id));
          }, 250);
        } else dispatch(setPrevId(null));
      }}
    />
  );
};

export default DependencePrev;
