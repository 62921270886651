// Выбор сотрудника, по которому фильтруются задачи или проекта

import React, { useEffect, useState, useCallback, useRef } from "react";
import NameWithSubtitle from "./NameWithSubtitle";
import { useParams } from "react-router-dom";
import { State } from "../../../rootReducer";
import { connect } from "react-redux";
import { User, Project, Role, Item } from "../../types";
import {
  ActualToggleAction,
  setLoadingTasks,
} from "../../../pages/Tasks/actions";
import {
  selectProject,
  setIsFetchingTasks,
} from "../../../pages/Projects/actions";
import { CreateNotif } from "../../../utils/createNotification";
import SelectWithSecondaryBtn from "./SelectWithSecondaryBtn";
import MultySelect from "./MultySelect";
import MultySelectUserFromTasks from "./MultySelectUserFromTasks";
import Toggler from "../Togglers/Toggler";
import { useHistory } from "react-router-dom";
import { LocalGasStation } from "@material-ui/icons";
import styled from "styled-components";
import { setFilters } from "../../actions";

type Props = {
  changeProject?:  boolean;
  setChangeProject?: (boolean) => void;
  type: string;
  users: User[];
  projects: Project[];
  roles: Role[];
  itemsList: Item[];
  setLoadingTasks: (is: boolean) => void;
  setIsFetchingTasks: (is: boolean) => void;
  attached_items?: Item[];
  ActualToggleAction: (val: object) => void;
  exec_auth: string;
  withImage?: boolean;
  default_templates: any;
  setFilters: (
    data_from?: string,
    date_to?: string,
    executors?: number[],
    authors?: number[],
    statuses?: number[],
    actual?: boolean
  ) => void;
  current_set: any;
  selectProject: (project: Project) => void;
};
const WrapperSelect = styled.div`
  display: flex;
  height: 100%;
`;

const UserProjectSelect: React.FC<Props> = ({
  changeProject,   //*************** */
  setChangeProject,  //************* */
  type,
  users,
  projects,
  roles,
  itemsList,
  setLoadingTasks,
  setIsFetchingTasks,
  attached_items,
  ActualToggleAction,
  exec_auth,
  withImage,
  setFilters,
  default_templates,
  current_set,
  selectProject,
}) => {
  const [name, setName] = useState("");
  const [selectedItemImage, setSelectedItemImage] = useState("");

  const [subtitle, setSubtitle] = useState("");
  const [open, setOpen] = useState(false);
  const [severalUsersSelected, setSeveralUsersSelected] = useState(false);

  const idFromUrl = useParams();
  const id = +idFromUrl["id"];

  const select = useRef<HTMLDivElement>(null);
  let history = useHistory();
  const [TogglerPos, setTogglerPos] = useState<string>("");

  const outsideClick = useCallback((e: Event) => {
    if (!select.current?.contains(e.target as HTMLElement)) setOpen(false);
  }, []);

  // const itemClickHandler = useCallback((e: React.MouseEvent) => {
  //     e.stopPropagation()
  //     if (type === 'tasks') setLoadingTasks(true)
  //     else setIsFetchingTasks(true)
  //
  //     setOpen(false)
  // }, [setIsFetchingTasks, setLoadingTasks, type])

  useEffect(() => {
    document.addEventListener("click", outsideClick);
    return () => document.removeEventListener("click", outsideClick);
  }, [outsideClick]);

  useEffect(() => {
    if (type === "tasks" && users.length > 0) {
      let current = users.find((user) => user.id === +id); // текущий пользователь, взятый из get params

      if (current) {
        // если авторов или исполнителей больше 1го
        if (
          current_set.authors.length > 1 ||
          current_set.executors.length > 1 ||
          current_set.executors.length + current_set.authors.length > 1
        ) {
          setSeveralUsersSelected(true);
          let full_phrase = "";
          if (current_set.executors.length) {
            full_phrase += "Исполнитель (" + current_set.executors.length + ")";
          }
          if (
            (full_phrase !== "" && current_set.authors.length) ||
            current_set.authors.length > 1
          ) {
            if (full_phrase !== "") full_phrase += ", ";
            full_phrase += "Автор (" + current_set.authors.length + ")";
          }
          setName(full_phrase);
        } else {
          let only_one_usr =
            current_set.executors.length + current_set.authors.length;
          if (only_one_usr === 1) {
            setSelectedItemImage(current.image?.url as string);
            setName(`${current.surname} ${current.name}`);
            setSeveralUsersSelected(false);
          } else {
            setName(`Все сотрудники`);
            setSeveralUsersSelected(true);
          }
        }
        // let role = roles.find((role) => role.id === current?.roles[0]);
        // if (role) setSubtitle(role.name);
      } else {
        CreateNotif("Пользователь удален, либо еще не создан");
        setName("Выбрать другого пользователя");
      }
    }
  }, [id, projects, roles, type, users, current_set]);

  useEffect(() => {
    if (type === "projects") {
      let current = projects.find((project) => project.id === +id);

      if (current) {
        setName(current.name);
        setSubtitle(current.description);
      } else {
        // CreateNotif('Проект удален, либо еще не создан');
        setName("Выбрать другой проект");
      }
    }
  }, [id, projects, type, changeProject]); // *******************************************

  useEffect(() => {
    if (type === "calendar" && users.length > 0) {
      const current = users.find((user) => user.id === +id); // текущий пользователь, взятый из get params
      if (current) {
        setSelectedItemImage(current.image?.url as string);
        setName(`${current.surname} ${current.name}`);
        setSeveralUsersSelected(false);
      }
    }
  }, [id, type, users, changeProject]);  // *******************************************

  useEffect(() => {
    setTogglerPos(exec_auth);
  }, [exec_auth]);

  return (
    <WrapperSelect
      ref={select}
      // className="custom_select user_project_select"
      // style={{ display: 'flex' }}
    >



{type === "tasks" && (
      <MultySelectUserFromTasks
        changeProject = {changeProject}   //************************************** */
        radio={true}
        withImage={withImage}
        selectedItemImage={selectedItemImage}
        showAvatar={
          type === "tasks" || (type === "calendar" && !severalUsersSelected)
        }
        IconName={severalUsersSelected ? "peoples" : undefined}
        selectedItems={[]}
        length_block={260 as number}
        current_value={name}
        attached_items={attached_items}
        itemsList={itemsList}
        addHandler={(arg) => {
          if (type === "tasks") {
            if (
              current_set.authors.length + current_set.executors.length ===
              1
            ) {
              if (current_set.authors.length) {
                setFilters(undefined, undefined, undefined, [arg]);
              } else {
                setFilters(undefined, undefined, [arg]);
              }
            } else {
              setFilters("", "", [arg], [], [2, 3, 10, 12, 14]);
            }
          } // Нового исполнителя в фильтры мы назначаем только если выбор произошел в разделе таскс

          history.replace(`${arg}`);
        }}
        removeHandler={() => {}}
      /> )}
     
     {type !== "tasks" && (
      <MultySelect
        changeProject = {changeProject}   //************************************** */
        radio={true}
        withImage={withImage}
        selectedItemImage={selectedItemImage}
        showAvatar={
          type === "tasks" || (type === "calendar" && !severalUsersSelected)
        }
        IconName={severalUsersSelected ? "peoples" : undefined}
        selectedItems={[]}
        length_block={260 as number}
        current_value={name}
        attached_items={attached_items}
        itemsList={itemsList}
        addHandler={(arg) => {
          if (type === "tasks") {
            if (
              current_set.authors.length + current_set.executors.length ===
              1
            ) {
              if (current_set.authors.length) {
                setFilters(undefined, undefined, undefined, [arg]);
              } else {
                setFilters(undefined, undefined, [arg]);
              }
            } else {
              setFilters("", "", [arg], [], [2, 3, 10, 12, 14]);
            }
          } // Нового исполнителя в фильтры мы назначаем только если выбор произошел в разделе таскс

          if (type === "projects") {
            let found_project = projects.find((project) => project.id === arg);
            if (found_project) {selectProject(found_project);setChangeProject &&  setChangeProject(prev => !prev)}  //*************** */
          }

          history.replace(`${arg}`);
        }}
        removeHandler={() => {}}
      /> )}

      {type === "tasks" && (
        <></>
        // <Toggler
        //   elemsList={[
        //     { name: 'Исполнитель', value: 'executor' },
        //     { name: 'Автор', value: 'author' },
        //   ]}
        //   selectHandler={(active) => {
        //     setTogglerPos(active as string);
        //     ActualToggleAction({
        //       erase_applied_filters: false,
        //       exec_auth_value: active,
        //     });
        //   }}
        //   styles={{ height: '32px', marginTop: '5px', marginLeft: '8px' }}
        //   activeValue={TogglerPos}
        // />
      )}
    </WrapperSelect>
  );
};

const mapStateToProps = (state: State) => {
  return {
    default_templates: state.commonInfo.filters.default_templates,
    exec_auth: state.tasksPage.filters.exec_auth,
    users: state.commonInfo.users,
    projects: state.commonInfo.projects,
    roles: state.commonInfo.roles,
    current_set: state.commonInfo.filters.current_set,
  };
};

const mapDispatchToProsp = {
  setLoadingTasks,
  setIsFetchingTasks,
  ActualToggleAction,
  setFilters,
  selectProject,
};

export default connect(mapStateToProps, mapDispatchToProsp)(UserProjectSelect);
