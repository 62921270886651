import React, { useContext, useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StatusContext } from "../../context/status";
import "./CreateTaskForm.scss";

import TextareaAutosize from "react-textarea-autosize";
import TaskPriority from "./components/taskPriority/TaskPriority";
import TaskCheckList from "./components/taskCheckList/TaskCheckList";
import TaskCreateAddFileButton from "./components/TaskCreateAddFileButton";
import TaskCreateLoad from "./components/TaskCreateLoad";

const CreateTaskForm = () => {
  const {
    bearer,
    company_id,
    createTaskForm,
    setCreateTaskForm,
    createTaskFormDate,
    setCreateTaskFormDate,
    depsTask,
    setDepsTask,
    tasks,
    createTaskSampleFormStatus,
    sampleArr,
    idSample,
    arrTasksSample,
    setArrTasksSample,
    valueTaskSample,
    setValueTaskSample,
    nowTask,
    setTasksArr,
    tasksArr,
    taskSample,
    setTaskSample,
    setStatusCreateTask,
    setCreateTaskStatus,
    setCreateBpSampleStatus,
    setLengthArrTasks,
    setCreateBpStatus,
    users,
    businessProcessCurrentGlobal,
    descript,
    setDescript,
    projects,
    projectSections,
    setProjectSections,
  } = useContext(StatusContext);

  const author = users.find(
    (user) => user.id === businessProcessCurrentGlobal.initiator_id
  );
  const etApi = process.env.REACT_APP_PUBLIC_API;

  // console.log(projectSections);

  // console.log(createTaskForm.data.project_id);

  // console.log(createTaskForm.data.project_section_id);

  // const [checkListArr, setCheckListArr] = useState(
  //   createTaskForm.data.checklist
  // );
  const [priorityId, setPriorityId] = useState(createTaskForm.data.priority_id);
  // const [allFiles, setAllFiles] = useState(createTaskForm.files);
  const [taskLoadValue, setTaskLoadValue] = useState(0.1);
  const [workLoadValue, setWorkLoadValue] = useState(0.1);
  const [typeOfValue, setTypeOfValue] = useState("task_load");

  // useEffect(() => {
  //   setCreateTaskForm({ ...createTaskForm, files: allFiles });

  // }, [allFiles]);

  useEffect(() => {
    if (createTaskForm.data.project_id) {
      axios
        .get(
          `${process.env.REACT_APP_PUBLIC_API}/projects/${createTaskForm.data.project_id}`,
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
            },
          }
        )
        .then((res) => {
          // console.log(res.data.data);
          setProjectSections(res.data.data.sections);
        });
    }
  }, [createTaskForm, createTaskForm.data.project_id]);

  useEffect(() => {
    if (
      typeOfValue &&
      createTaskForm.executor_id > 0 &&
      createTaskForm.begin &&
      createTaskForm.end
    ) {
      switch (typeOfValue) {
        case "task_load": {
          let config = {
            method: "get",
            url: `${etApi}/task-load?userId=${+createTaskForm.executor_id}&begin=${
              createTaskForm.begin
            }&end=${createTaskForm.end}&task_load=${+taskLoadValue}`,
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          };
          axios(config).then((res) => {
            setWorkLoadValue((prev) => +res.data.work_load);
          });

          break;
        }
        case "work_load": {
          let config = {
            method: "get",
            url: `${etApi}/task-load?userId=${+createTaskForm.executor_id}&begin=${
              createTaskForm.begin
            }&end=${createTaskForm.end}&work_load=${+workLoadValue}`,
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          };
          axios(config).then((res) => {
            setTaskLoadValue((prev) => +res.data.task_load);
          });

          break;
        }
        default:
          console.log("IBRA");
          break;
      }
    }
  }, [typeOfValue, taskLoadValue, workLoadValue]);

  // useEffect(() => {
  //   setCreateTaskForm({
  //     ...createTaskForm,
  //     data: {
  //       ...createTaskForm.data,
  //       checklist: checkListArr,
  //     },
  //   });
  // }, [checkListArr]);
  useEffect(() => {
    setCreateTaskForm({
      ...createTaskForm,
      data: {
        ...createTaskForm.data,
        priority_id: priorityId,
      },
    });
  }, [priorityId]);

  useEffect(() => {
    typeOfValue === "task_load"
      ? setCreateTaskForm({
          ...createTaskForm,
          data: {
            ...createTaskForm.data,
            task_load: +taskLoadValue,
          },
        })
      : setCreateTaskForm({
          ...createTaskForm,
          data: {
            ...createTaskForm.data,
            work_load: +workLoadValue,
          },
        });
  }, [typeOfValue, taskLoadValue, workLoadValue]);

  useEffect(() => {
    if (
      !!createTaskFormDate.endDate &&
      !!createTaskFormDate.endTime &&
      !!createTaskFormDate.beginDate &&
      !!createTaskFormDate.beginTime
    ) {
      setCreateTaskForm({
        ...createTaskForm,
        end:
          createTaskFormDate.endDate + " " + createTaskFormDate.endTime + ":00",
        begin:
          createTaskFormDate.beginDate +
          " " +
          createTaskFormDate.beginTime +
          ":00",
      });
    }
  }, [createTaskFormDate]);

  useEffect(() => {
    if (createTaskSampleFormStatus) {
      let bp = sampleArr.filter((el) => el.id === parseInt(idSample));

      if (bp[0].tasksId) {
        setArrTasksSample([...bp[0].tasksId.split("|")]);
      }

      if (bp[0].businessProcessId) {
        if (bp[0].businessProcessId.tasks) {
          let arrMas = [];
          for (let i in bp[0].businessProcessId.tasks) {
            arrMas.push(bp[0].businessProcessId.tasks[i].id);
          }
          setArrTasksSample([...arrMas]);
        }
      }
    }
  }, [createTaskSampleFormStatus]);

  useEffect(() => {
    if (arrTasksSample.length >= 1) {
      Promise.all(
        arrTasksSample.map(async (el) => {
          return axios
            .get(`${process.env.REACT_APP_PUBLIC_API}/tasks/${el}`, {
              headers: {
                Authorization: bearer,
                "company-id": company_id,
              },
            })
            .then((result) => {
              return result.data.data;
            })
            .catch((e) => {
              console.log(e);
            });
        })
      )
        .then((r) => setValueTaskSample(r))
        .catch((e) => {
          console.log(e);
        });
    }
  }, [arrTasksSample]);

  useEffect(() => {
    if (tasksArr.length > 0) {
      if (tasksArr.length === valueTaskSample.length) {
        for (let i in tasksArr) {
          if (!!valueTaskSample[i].next_id) {
            let a;
            valueTaskSample.filter((el, id) => {
              if (el.id === valueTaskSample[i].next_id) {
                return (a = id);
              }
            });

            axios
              .patch(
                `${process.env.REACT_APP_PUBLIC_API}/tasks/${tasksArr[i]}`,
                { next_id: tasksArr[a] },
                {
                  headers: {
                    Authorization: bearer,
                    "company-id": company_id,
                  },
                }
              )
              .then((res) => {
                //    console.log(res);
              })
              .catch((e) => {
                console.log(e);
              });
          }
          if (!!valueTaskSample[i].parent_id) {
            let a;
            valueTaskSample.filter((el, id) => {
              if (el.id === valueTaskSample[i].parent_id) {
                return (a = id);
              }
            });

            axios
              .patch(
                `${process.env.REACT_APP_PUBLIC_API}/tasks/${tasksArr[i]}`,
                { parent_id: tasksArr[a] },
                {
                  headers: {
                    Authorization: bearer,
                    "company-id": company_id,
                  },
                }
              )
              .then((res) => {
                //   console.log(res);
              })
              .catch((e) => {
                console.log(e);
              });
          }
          if (!!valueTaskSample[i].prev_id) {
            let a;
            valueTaskSample.filter((el, id) => {
              if (el.id === valueTaskSample[i].prev_id) {
                return (a = id);
              }
            });

            axios
              .patch(
                `${process.env.REACT_APP_PUBLIC_API}/tasks/${tasksArr[i]}`,
                { prev_id: tasksArr[a] },
                {
                  headers: {
                    Authorization: bearer,
                    "company-id": company_id,
                  },
                }
              )
              .then((res) => {
                //  console.log(res);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        setStatusCreateTask(false);
        setCreateTaskStatus(false);
        setCreateBpStatus(true);
        setCreateBpSampleStatus(true);
      }
    }
  }, [tasksArr]);

  useEffect(() => {
    setTaskSample(createTaskForm);
    if (!!nowTask) {
      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_API}/tasks`,
          {
            author_id: nowTask.author_id,
            begin: nowTask.begin,
            cyclic_task_id: nowTask.cyclic_task_id,
            description: nowTask.description,
            end: nowTask.end,
            executor_id: taskSample.executor_id,
            name: taskSample.name,
            next_id: null,
            parent_id: null,
            prev_id: null,
            priority_id: nowTask.priority_id,
            project_id: createTaskForm.data.project_id,
            project_section_id: createTaskForm.data.project_section_id,
            provide_to: 1,
            status_id: nowTask.status_id,
            status_related_user_id: null,
            task_load: nowTask.task_load,
            task_load_sum: nowTask.task_load_sum,
            timesheets: nowTask.timesheets,
            work_load: nowTask.work_load,
            workflow_id: nowTask.workflow_id,
            id_bp: 130,
          },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
            },
          }
        )
        .then((res) => {
          setTaskSample({
            ...taskSample,
            name: "",
          });
          setTasksArr([...tasksArr, res.data.data.id]);
          setCreateTaskForm({ ...createTaskForm, name: "" });
        })
        .catch((err) => {
          setLengthArrTasks(tasksArr.length);
          setTaskSample({
            ...taskSample,
          });
          //    console.log("errrrr " + err);
        });
    }
  }, [nowTask]);

  useEffect(() => {
    setDescript(
      "Что нужно сделать:\n\nКак нужно сделать:\n\nКакой должен быть результат:"
    );
  }, []);

//   let ast;
//   useEffect(() => {
//     console.log(businessProcessCurrentGlobal?.tasks);
//     let a = businessProcessCurrentGlobal?.tasks;
//     if (a) {
//       ast = a[a.length - 1].end.slice(0, 10)
      
//     }
  
//   }, [businessProcessCurrentGlobal, createTaskFormDate]);

//   useEffect(() => {
//     // if (ast) {
//         console.log(ast < createTaskFormDate.beginDate ? "aaaa" : "sssss");
//         console.log(new Date(ast), "---",new Date(createTaskFormDate.beginDate) );
//     // }
//   }, [businessProcessCurrentGlobal, createTaskFormDate])
// console.log(createTaskFormDate);
  const executors = useMemo(() => users, [users]);

  return (
    <form id="new-bp__form">
      <div>
        <label className="p__drop-content" htmlFor="input-name-Task">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
          />
          Наименование этапа*
        </label>
        <input
          className="input-form input-name-task__list"
          type="text"
          id="input-name-task"
          value={createTaskForm.name}
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              setCreateTaskForm({ ...createTaskForm, name: "" });
              setTaskSample({ ...taskSample, name: "" });
            } else {
              setCreateTaskForm({ ...createTaskForm, name: e.target.value });
              setTaskSample({ ...taskSample, name: e.target.value });
            }
          }}
        />
      </div>
      {!createTaskSampleFormStatus && (
        <div>
          <label className="p__drop-content">
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/NewspaperClipping.svg`}
            />
            Описание
          </label>
          <TextareaAutosize
            minRows={5}
            className="input-form input-form-create-bp"
            type="text"
            value={descript}
            onChange={(e) => {
              setDescript((prev) => e.target.value);
              setCreateTaskForm({
                ...createTaskForm,
                description: e.target.value,
              });
            }}
          ></TextareaAutosize>
        </div>
      )}
      {/* <div>
        <TaskCheckList
          checkListArr={checkListArr}
          setCheckListArr={setCheckListArr}
        />
      </div> */}

      <div>
        <label className="p__drop-content" htmlFor="input-project">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
            alt="Article"
          />
          Проект*
        </label>
        <select
          id="input-project"
          className="input-form"
          value={
            createTaskForm.data.project_id ? createTaskForm.data.project_id : ""
          }
          onChange={(e) => {
            setCreateTaskForm({
              ...createTaskForm,
              data: {
                ...createTaskForm.data,
                project_id: parseInt(+e.target.value),
              },
            });
            setTaskSample({
              ...taskSample,
              project_id: parseInt(+e.target.value),
            });
          }}
        >
          <option value={null}>Выбрать</option>
          {projects.map((el) => {
            return (
              <option key={el.id} value={el.id}>
                {el.name}
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <label className="p__drop-content" htmlFor="input-project-section">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
            alt="Article"
          />
          Раздел проекта*
        </label>
        <select
          id="input-project-section"
          className="input-form"
          disabled={!createTaskForm.data.project_id ? true : false}
          value={
            createTaskForm.data.project_section_id
              ? createTaskForm.data.project_section_id
              : ""
          }
          onChange={(e) => {
            setCreateTaskForm({
              ...createTaskForm,
              data: {
                ...createTaskForm.data,
                project_section_id: parseInt(+e.target.value),
              },
            });
            setTaskSample({
              ...taskSample,
              project_section_id: parseInt(+e.target.value),
            });
          }}
        >
          <option value={null}>Выбрать</option>
          {projectSections.map((el) => {
            return (
              <option key={el.id} value={el.id}>
                {el.name}
              </option>
            );
          })}
        </select>
      </div>

      <div>
        <div className="oneRowTwoElem">
          <div>
            <label
              className="p__drop-content"
              // htmlFor="businessTask__executor"
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/User.svg`}
              />
              Автор
            </label>
            <input
              type="text"
              className="input-form blockOfFormAutor"
              id="businessTask__executor"
              value={author.name + " " + author.surname}
            ></input>
          </div>

          <div>
            <label
              className="p__drop-content"
              // htmlFor="businessTask__executor"
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                alt="Article"
              />
              Приоритет
            </label>
            <div>
              <TaskPriority
                priorityId={priorityId}
                setPriorityId={setPriorityId}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <label className="p__drop-content" htmlFor="businessTask__executor">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/User.svg`}
          />
          Исполнитель
        </label>
        <select
          className="input-form"
          id="businessTask__executor"
          value={taskSample.executor_id}
          onChange={(e) => {
            setCreateTaskForm({
              ...createTaskForm,
              executor_id: parseInt(e.target.value),
            });
            setTaskSample({
              ...taskSample,
              executor_id: parseInt(e.target.value),
            });
          }}
        >
          {executors.map((executor) => (
            <option key={executor.id} value={executor.id}>
              {executor.name} {executor.surname}
            </option>
          ))}
        </select>
      </div>
      {!createTaskSampleFormStatus ? (
        <>
          <div className="input__date">
            <label className="p__drop-content">
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/CalendarBlank.svg`}
              />
              Дата и время начала*
            </label>
            <div>
              <input
                className="input-form"
                type="date"
                id="businessTask__date-start"
                htmlFor="businessTask__date-start"
                onChange={(e) => {
                  if (!!e.target.value) {
                    setCreateTaskFormDate({
                      ...createTaskFormDate,
                      beginDate: new Date(e.target.value)
                        .toLocaleDateString()
                        .replace(/\./g, "-"),
                    });
                  }
                }}
              />
              <input
                className="input-form"
                type="time"
                onChange={(e) => {
                  if (!!e.target.value) {
                    setCreateTaskFormDate({
                      ...createTaskFormDate,
                      beginTime: e.target.value,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="input__date">
            <label className="p__drop-content" htmlFor="businessTask__date-end">
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/CalendarBlank.svg`}
              />
              Дата и время окончания*
            </label>
            <div>
              <input
                className="input-form"
                type="date"
                id="businessTask__date-end"
                onChange={(e) => {
                  if (!!e.target.value) {
                    setCreateTaskFormDate({
                      ...createTaskFormDate,
                      endDate: new Date(e.target.value)
                        .toLocaleDateString()
                        .replace(/\./g, "-"),
                    });
                  }
                }}
              />
              <input
                className="input-form"
                type="time"
                onChange={(e) => {
                  if (!!e.target.value) {
                    setCreateTaskFormDate({
                      ...createTaskFormDate,
                      endTime: e.target.value,
                    });
                  }
                }}
              />
            </div>
          </div>
          {/* <div>
            <label className="p__drop-content">
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Vector.svg`}
              />
              Прикрепить файл
            </label>
            <TaskCreateAddFileButton
              allFiles={allFiles}
              setAllFiles={setAllFiles}
            />
          </div> */}

          <div>
            <TaskCreateLoad
              taskLoadValue={taskLoadValue}
              setTaskLoadValue={setTaskLoadValue}
              workLoadValue={workLoadValue}
              setWorkLoadValue={setWorkLoadValue}
              typeOfValue={typeOfValue}
              setTypeOfValue={setTypeOfValue}
            />
          </div>
          {/* {tasks.length >= 1 ? (
            <div className="form-task__dependencies">
              <div className="p__drop-content">
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/ArrowUDownRight.svg`}
                />
                Зависимости
              </div>
              <div className="form-task__dependencies__btns">
                <button
                  className={
                    depsTask === "Родительская"
                      ? "form-task__dependencies__btn-active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setDepsTask("Родительская");
                    setCreateTaskForm({
                      ...createTaskForm,
                      prev_id: null,
                      parent_id: null,
                      next_id: null,
                    });
                  }}
                >
                  Родительская
                </button>
                <button
                  className={
                    depsTask === "Дочерняя"
                      ? "form-task__dependencies__btn-active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setDepsTask("Дочерняя");
                    setCreateTaskForm({
                      ...createTaskForm,
                      next_id: null,
                      prev_id: null,
                      parent_id: tasks[tasks.length - 1],
                    });
                  }}
                >
                  Дочерняя
                </button>
                <button
                  className={
                    depsTask === "Предыдущая"
                      ? "form-task__dependencies__btn-active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setDepsTask("Предыдущая");
                    setCreateTaskForm({
                      ...createTaskForm,
                      next_id: null,
                      parent_id: null,
                    });
                  }}
                >
                  Предыдущая
                </button>
                <button
                  className={
                    depsTask === "Следующая"
                      ? "form-task__dependencies__btn-active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setDepsTask("Следующая");
                    setCreateTaskForm({
                      ...createTaskForm,
                      prev_id: null,
                      parent_id: null,
                    });
                  }}
                >
                  Следующая
                </button>
              </div>
            </div>
          ) : (
            <></>
          )} */}
        </>
      ) : (
        <div className="task-sample__deps">
          <div className="p__drop-content">
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/ArrowUDownRight.svg`}
            />
            Зависимости
          </div>
          <div className="input-form">Зависимости</div>
        </div>
      )}
    </form>
  );
};

export default CreateTaskForm;
