// Верхнее меню в экране "Задачи"
import React, { useCallback, useEffect, useMemo, useState } from "react";
// import DisplayTypeSelect from '../../../common/components/Selects/DisplayTypeSelect'
import UserProjectSelect from "../../../common/components/Selects/UserProjectSelect";
import { User, Item, Task } from "../../../common/types";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  setTaskAddMode,
  setShowVisulaization,
  changeTaskList,
  fetchTasks,
  setTableFilter,
  setShowColumnsCustom,
  closingDay,
  setShowDaysOff,
} from "../actions";
import { State } from "../../../rootReducer";
import TableSearch from "../../../common/components/TableSearch/TableSearch";
import ColumnsWindow from "../../../common/ColumnsCustomWindow";
import {
  setShownTaskInfoPlate,
  setTaskPriority,
  // uploadFile,
} from "../../TaskInfoPlate/actions";
import Button from "../../../common/components/newComponent/Buttons/Button";
import styled from "styled-components";
import Icon from "../../../common/components/Icon";
import FiltersWindow from "../../../common/FiltersWindow";
import {
  DefaultFilterTemplates,
  FilterTemplates,
  setFilters,
  ShowFiltersWindow,
} from "../../../common/actions";
import { fetchData } from "../../../utils/fetchData";
import { urlApp } from "../../../App";
import { cookieMaster } from "../../../utils/CookieMaster";
import { useContext } from "react";
import { useStateContext } from "../../../useStateContext";

type Props = {
  users: User[];
  selectedUserId: number;
  showVisual: boolean;
  priorities: any;
  setTaskAddMode: (mode: boolean) => void;
  setShowVisulaization: (show: boolean) => void;
  setShowColumnsCustom: (show: boolean) => void;
  // appliedFiltersExist: boolean;
  showFiltersWindow: boolean;
  showColumnsWindow: boolean;
  tableFilter: string;
  setTableFilter: (filter: string) => void;
  changeTaskList: (tasks: Task[]) => void;
  fetchTasks: (pageNum: number) => void;
  setShownTaskInfoPlate: (val: boolean) => void;
  positions: any;
  current_user: any;
  filters: any;
  closingDay: (value: boolean) => void;
  closingDayWindowShow: boolean;
  ShowFiltersWindow: () => void;
  DefaultFilterTemplates: (data) => void;
  FilterTemplates: (data: {}) => void;
  setShowDaysOff: (show: boolean) => void;
  isShownDayClose: boolean;
  setFilters: (
    data_from?: string,
    date_to?: string,
    executors?: number[],
    authors?: number[],
    statuses?: number[],
    actual?: boolean
  ) => void;
  handleChangeFactPage: () => void;
  isFactPage: boolean;
  isSettingsPage: boolean;
  handleChangeSettingsPage: () => void;
};
const WrapperTasksActions = styled.div`
  // display: flex;
  justify-content: space-between;
  margin: 8px 40px 16px;
`;
const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`;
const RightBlock = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperIconColumn = styled.div`
  margin-right: 8px;
  border: 1px solid
    ${({ showColumnsWindow }) =>
      showColumnsWindow ? "#02405e" : "transparent"};
  border-radius: 25%;
`;
const WrapperIconVisual = styled.div`
  margin-right: 8px;
  border: 1px solid
    ${({ showVisual }) => (showVisual ? "#02405e" : "transparent")};
  border-radius: 25%;
`;

const LineOfActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderIcon = styled.div`
  width: 41px;
  height: 41px;
  border: ${(props) =>
    props.active ? "1px solid black" : "1px solid rgba(0, 0, 0, 0.12)"};
  border-radius: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-right: 10px;
  cursor: pointer;
`;

const TasksActions: React.FC<Props> = ({
  tableFilter,
  setShownTaskInfoPlate,
  setTableFilter,
  fetchTasks,
  changeTaskList,
  users,
  selectedUserId,
  showVisual,
  setShowVisulaization,
  setShowColumnsCustom,
  showColumnsWindow,
  // appliedFiltersExist,
  showFiltersWindow,
  positions,
  priorities,
  current_user,
  closingDay,
  ShowFiltersWindow,
  filters,
  handleChangeFactPage,
  isFactPage,
  isSettingsPage,
  handleChangeSettingsPage,
}) => {
  const location = useLocation();

  const {
    openPageTasks,
    setOpenPageTasks, // индикатор - если меняется - (не важно - true или false) - заходим на страницу tasks
  } = useContext(useStateContext);

  useEffect(() => {
    //if (!location.pathname.includes("tasks")) {
    setFilters("", "", [], [], [], false);
    if (!location.hash) history.replace(`${current_user}`);
    setOpenPageTasks((prev) => prev + 1);
    setTimeout(() => {
      ShowFiltersWindow();
    }, 10);
    setTimeout(() => {
      ShowFiltersWindow();
    }, 20);
    setTimeout(() => {
      ShowFiltersWindow();
    }, 30);
    setTimeout(() => {
      ShowFiltersWindow();
    }, 40);
    // }
  }, []);

  const [timer, setTimer] =
    useState<ReturnType<typeof setTimeout> | null>(null);
  const dispatch = useDispatch();
  const tasksOrder = useSelector((state: State) => state.tasksPage.tasksOrder);

  const dataForUserSelect = useMemo(() => {
    let selected = null as Item | null;

    let found_my_user = users.find((user) => {
      return user.id === current_user;
    });

    let attached_items: Item[] = [];
    if (found_my_user) {
      attached_items.push({
        text: `${found_my_user.surname} ${found_my_user.name}`,
        name_cap_letters:
          found_my_user.surname.substr(0, 1).toUpperCase() +
          found_my_user.name.substr(0, 1).toUpperCase(),
        value: found_my_user.id,
        subtitle: found_my_user.email,
        position: positions.find(
          (pos) => pos.id === found_my_user?.positions[0]
        )?.name,
        urlImage: found_my_user?.image?.url,
      });
    }

    let list = users
      .map((user) => {
        let item = {
          text: `${user.surname} ${user.name}`,
          name_cap_letters:
            user.surname.substr(0, 1).toUpperCase() +
            user.name.substr(0, 1).toUpperCase(),
          value: user.id,
          subtitle: user.email,
          position: positions.find((pos) => pos.id === user.positions[0])?.name,
          urlImage: user?.image?.url,
        };
        if (user.id === selectedUserId) selected = item;
        return item;
      })
      .filter((list_item) => list_item.value !== current_user);

    return { selected, list, attached_items };
  }, [selectedUserId, users]);

  const searchChangeHandler = useCallback(
    (filter: string) => {
      setTableFilter(filter);
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          changeTaskList([]);
          fetchTasks(1);
        }, 500)
      );
    },
    [changeTaskList, fetchTasks, setTableFilter, timer]
  );

  let history = useHistory();

  let priority = priorities.find((el) => el.slug === "medium") || priorities[0];
  let company_id = localStorage.getItem("company_id");
  let default_filter_active = false;
  let filter_btn_inscription;
  Object.keys(filters["default_templates"]).forEach((item) => {
    if (filters["default_templates"][item].active) {
      filter_btn_inscription = item;
      default_filter_active = true;
    }
  });
  if (filter_btn_inscription === undefined) {
    Object.keys(filters["templates"]).forEach((item) => {
      if (filters["templates"][item].active) {
        filter_btn_inscription = item;
      }
    });
  }
  const uploadTasksExcel = () => {
    let link = `/uploads/tasks?order=${tasksOrder.col_name}&`;
    const { executors, authors, statuses, date_from, date_to } =
      filters.current_set;
    if (!tasksOrder.order_direct) link = link + `orderType=desc&`;
    if (executors.length > 0) link = link + `executor_id=${executors}&`;
    if (authors.length > 0) link = link + `author_id=${authors}&`;
    if (statuses.length > 0) link = link + `status_id=${statuses}&`;
    if (date_from.length > 0) link = link + `begin_from=${date_from}&`;
    if (date_to.length > 0) link = link + `end_to=${date_to}&`;

    fetch(`${urlApp + link}`, {
      headers: {
        "company-id": `${company_id}`,
        Authorization: `${cookieMaster.getCookie("access_token")}`,
      },
    })
      .then((data) => data.blob())
      .then((blob) => {
        let tmp = document.createElement("a");
        let csvData = new Blob([blob], { type: "text/xlsx" });
        let csvUrl = URL.createObjectURL(csvData);
        tmp.href = csvUrl;
        tmp.setAttribute("download", "tasks_from_view.xlsx");
        tmp.click();
      });
  };

  useEffect(() => {
    let dataFetch2 = false;
    let data2: [] = [];
    let priorities2: [] = [];
    let priority2;

    fetchData
      .get(`/api/v1/priorities`)

      .then(function (data) {
        dataFetch2 = true;
        data2 = data;
        priorities2 = data2;
        // console.log("data2",data2)
        // console.log("priorities2",priorities2)

        if (priorities2) {
          if (Array.isArray(priorities2)) {
            if (priorities2.length !== 0) {
              priorities = priorities2;
              priority2 = priorities2.find(
                (el: { id; name; company_id; slug }) => el.slug === "medium"
              );
              // console.log("priority2",priority2)
            }
          }
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });

    // console.log("priorities2",priorities2)

    let dataFetch = false;
    let data1: [] = [];

    fetchData
      .get(`/api/v1/tasks?`)

      .then(function (data) {
        dataFetch = true;
        data1 = data;
        // console.log("data",data)
      })
      .catch(function (error) {
        console.log("error", error);
      });

    // console.log(tasks,"tasks")

    setTimeout(() => {
      if (dataFetch) {
        if (data1) {
          if (Array.isArray(data1)) {
            if (
              data1.length === 0 &&
              history.location.pathname.includes("tasks")
            ) {
              setShownTaskInfoPlate(true);
            }
          }
        }
      }

      if (priority2) {
        if ("id" in priority2) {
          // console.log("priority2.id",priority2.id)
          dispatch(setTaskPriority(priority2.id));
        }
      }
    }, 3000);
  }, []);

  return (
    <WrapperTasksActions
    // className="tasks_actions main_actions grid"
    >
      <LineOfActions>
        <LeftBlock>
          {/* Выбор сотрудника, по которому фильтруются задачи */}
          <UserProjectSelect
            type="tasks"
            itemsList={dataForUserSelect.list}
            attached_items={dataForUserSelect.attached_items}
          />
        </LeftBlock>
        <RightBlock>
          {/* <div
          className={`columns_select ${showColumnsWindow ? 'active' : ''}`}
          onClick={() => {
            setShowColumnsCustom(!showColumnsWindow);
          }}
        /> */}
          <Icon
            name="load"
            width="32px"
            height="32px"
            onClick={() => uploadTasksExcel()}
          />
          <WrapperIconVisual showVisual={showVisual}>
            <Icon
              name="visual"
              width="32px"
              height="32px"
              onClick={() => setShowVisulaization(!showVisual)}
            />
          </WrapperIconVisual>
          <WrapperIconColumn showColumnsWindow={showColumnsWindow}>
            <Icon
              name="eye"
              onClick={() => {
                setShowColumnsCustom(!showColumnsWindow);
              }}
            />
          </WrapperIconColumn>
          {/*<HeaderIcon onClick={handleChangeFactPage} active={isFactPage}>*/}
          {/*  <span>*/}
          {/*    Ф*/}
          {/*  </span>*/}
          {/*</HeaderIcon>*/}
          {/*<HeaderIcon onClick={handleChangeSettingsPage} active={isSettingsPage}>*/}
          {/*  <span>*/}
          {/*    Н*/}
          {/*  </span>*/}
          {/*</HeaderIcon>*/}
          {/*<ViewWeekRoundedIcon onClick={() => {*/}
          {/*    setShowColumnsCustom(!showColumnsWindow)*/}
          {/*}} className={`columns_select${showColumnsWindow ? ' active' : ''}`}*/}
          {/*/>*/}

          {/* Скроем кнопку "Закрытие дня" */}
          {/* current_user === selectedUserId && (
            <Button
              icon={'deskList'}
              title={'Закрытие дня'}
              style={{ height: '32px', padding: '0 8px', lineHeight: '32px' }}
              iconStyle={{
                marginRight: '5px',
                marginTop: '7px',
                width: '16px',
                height: '16px',
              }}
              design={'outline'}
              onClick={() => {
                // setShowDaysOff(true)
                closingDay(true);
              }}
            />
            ) */}

          {/*<DisplayTypeSelect selectedElem={{text: 'Список', value: 'list'}} selectHandler={() => {            }}/>*/}
          <Button
            title="Добавить"
            icon="plus_circle"
            style={{ padding: ".5rem", width: "100px", marginLeft: "8px" }}
            onClick={() => {
              dispatch(setTaskPriority(priority.id));
              setShownTaskInfoPlate(true);
            }}
          />
        </RightBlock>
      </LineOfActions>
      <LineOfActions>
        <LeftBlock>
          <div style={{ position: "absolute", top: "17px", right: "160px" }}>
            <TableSearch
              searchValue={tableFilter}
              setSearchValue={searchChangeHandler}
              isOpen={!!tableFilter}
            />
          </div>
          {/*Фильтры*/}
          {/*<Button design={'outline'} onClick={() => ShowFiltersWindow()}*/}
          <Button
            design={default_filter_active ? "outline" : "blue_lightest"}
            onClick={() => ShowFiltersWindow()}
            style={{
              margin: "0 8px",
              padding: "8px",
              minWidth: "150px",
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Icon name={"filter"} style={{ marginRight: "4px" }} />
            {filter_btn_inscription
              ? filter_btn_inscription
              : "Пользовательские"}
          </Button>
        </LeftBlock>
        <RightBlock></RightBlock>
      </LineOfActions>

      {/*{showFiltersWindow && <TasksFilters/>}*/}
      {showColumnsWindow && <ColumnsWindow context={"tasks"} />}
      {showFiltersWindow && <FiltersWindow context={"tasks"} />}
    </WrapperTasksActions>
  );
};

const mapStateToProps = (state: State) => {
  // console.log(state.commonInfo.filters.current_set.statuses)
  return {
    positions: state.commonInfo.positions,
    priorities: state.commonInfo.priorities,
    current_user: state.commonInfo.current_user,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    // appliedFiltersExist: Boolean(
    //     Object.keys(state.tasksPage.filters.appliedFilters).length
    // ),
    users: state.commonInfo.users,
    selectedUserId: state.tasksPage.selectedUserId,
    showVisual: state.tasksPage.showVisualization,
    // showFiltersWindow: state.tasksPage.filtersWindowShow,
    tableFilter: state.tasksPage.tableFilter,
    showColumnsWindow: state.tasksPage.showColumnsWindow,
    closingDayWindowShow: state.tasksPage.closingDayWindowShow,
    showFiltersWindow: state.commonInfo.filters.showFiltersWindow,
    filters: state.commonInfo.filters,
    isShownDayClose: state.tasksPage.isShownDayClose,
  };
};

const mapDispatchToProps = {
  setTaskAddMode,
  setShowVisulaization,
  setShowColumnsCustom,
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setShownTaskInfoPlate,
  closingDay,
  setTaskPriority,
  ShowFiltersWindow,
  DefaultFilterTemplates,
  FilterTemplates,
  setFilters,
  setShowDaysOff,
  // setColsWindowShow
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksActions);
