import React from 'react';
import styled from 'styled-components';
import { columnsOfTables } from '../../shared_data';

type Props = {
  data_project?: string;
  data_section?: string;
};
const WrapperProjectCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding-left: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  min-width: 192px;
  // max-width: ${columnsOfTables[7].style?.minWidth};
`;
const ProjectStyle = styled.div`
  width: 100%;
  font-size: 14px;
  color: #212121;
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  padding-right: 4px;
  text-overflow: ellipsis; /* Добавляем многоточие */
`;
const SectionStyle = styled.div`
  width: 100%;
  margin-top: 4px;
  font-size: 13px;
  color: #a8a8a8;
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  padding-right: 4px;
  text-overflow: ellipsis; /* Добавляем многоточие */
`;
const ProjectCell: React.FC<Props> = ({ data_project, data_section }) => {
  return (
    <WrapperProjectCell title={data_project}>
      <ProjectStyle>{data_project}</ProjectStyle>
      <SectionStyle>{data_section}</SectionStyle>
    </WrapperProjectCell>
  );
};

export default ProjectCell;
