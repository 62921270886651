import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { Task, Section, User } from "../../../common/types";
import DateMaster from "../../../utils/DateMaster";
import ProjectCell from "../../../common/components/TableCells/ProjectSection";
import IdCell from "../../../common/components/TableCells/IdCell";
import TaskNameCell from "../../../common/components/TableCells/TaskNameCell";
import DeadLineCell from "../../../common/components/TableCells/DeadLineCell";
import ExecutorCell from "../../../common/components/TableCells/ExecutorCell";
import TaskWorkLoadingCell from "../../../common/components/TableCells/TaskWorkLoadingCell";
import PriorityCell from "../../../common/components/TableCells/PriorityCell";
import { selectTask } from "../../TaskInfoPlate/actions";
import { connect, useDispatch } from "react-redux";
import TaskStatus from "../../../common/components/TaskStatus/TaskStatus";
import { setShownTaskInfoPlate } from "../../TaskInfoPlate/actions";
import { State } from "../../../rootReducer";
import { getSelectedTask } from "../../TaskInfoPlate/selectors";
import { updateStatusWindowTrue } from "../../../pages/Tasks/actions"; // 571 задача redmine *********************************
import { updateStatusWindowFalse } from "../../../pages/Tasks/actions"; // 571 задача redmine *********************************
import { openExistingTask, setProjectSections } from "../../NewTask/actions";
import {
  fetchTaskDependence,
  readNotificationByTask,
  setTaskAddMode,
} from "../actions";
import { store, urlApp } from "../../../App";
import styled from "styled-components";
import { columnsOfTables } from "../../../common/shared_data";
import BPModal from "../../../common/components/BPModalWindow/BPModalWindow";
import { useContext } from "react";
import { useStateContext } from "../../../useStateContext";
import {
  showAnimateCompanyWindow,
  showCompanyWindow,
} from "../../../common/actions";
import { setInitial, setPlateEditMode } from "../../../pages/Company/actions";

import { useLocation } from "react-router-dom";
const imgUrl = require("../../../img/task_bp_icon.svg");
const imgUrlDisable = require("../../../img/task_bp_icon_disable.svg");

type Props = {
  statusWindow: boolean; // 571 задача redmine *********************************
  updateStatusWindowTrue: (statusWindow: boolean) => void; // 571 задача redmine *********************************
  updateStatusWindowFalse: (statusWindow: boolean) => void; // 571 задача redmine ********************************* /
  task: Task;
  selectedTask: Task | null;
  taskAddMode: boolean;
  users: User[];
  selectTask: (task: Task) => void;
  setShownTaskInfoPlate: (isShownTaskInfoPlate: boolean) => void;
  openExistingTask: (task: Task) => void;
  setTaskAddMode: (mode: boolean) => void;
  setProjectSections: (sections: Section[]) => void;
  activeColumns: string[];
  projects: object;
  partitions: object;
  markTask?: boolean;
  readNotificationByTask: (id_task: number) => void;
  idOfTaskToShow: number;
  setIdOfTaskToShow: (value: number) => void;
  showSidePlate: boolean;
  setShowSidePlate: (value: boolean) => void;
  idOfTask: number;
};

const TaskStyle = styled.div`
  display: flex;
  margin: 0 12px;
  margin-bottom: 12px;
  /* margin-right: 40px; */
  justify-content: space-between;
  min-height: 56px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  /* border-bottom: 1px solid; */
  border-image: linear-gradient(to right, #ffffff, #e0e0e0, #e0e0e0, #ffffff)
    45% 0%;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  transition: background-color 200ms ease;

  &:hover {
    background: linear-gradient(
      to right,
      white 0%,
      #f2f2f2 5%,
      #f2f2f2 90%,
      white 100%
    );
  }
  ${({ active }) =>
    active &&
    `
    background: linear-gradient(to right, white 0%,#F3FAFE 5%, #F3FAFE 90%, white 100%)!important;
    border-image: linear-gradient(to right,#ffffff,#99D7F5,#99D7F5,#ffffff ) 0% 5% 75% 7%;

    `}
`;
const BlockLeftFlex = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const BlockRightFlex = styled.div`
  position: relative;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

// const DragHandle = SortableHandle(() => <div className="task_drag_handle" />)
const TaskTr: React.FC<Props> = ({
  statusWindow, // 571 задача redmine *********************************
  updateStatusWindowTrue, // 571 задача redmine *********************************
  updateStatusWindowFalse, // 571 задача redmine *********************************
  task,
  users,
  projects,
  partitions,
  selectedTask,
  setProjectSections,
  selectTask,
  setShownTaskInfoPlate,
  openExistingTask,
  setTaskAddMode,
  activeColumns,
  markTask,
  readNotificationByTask,
  idOfTaskToShow,
  setIdOfTaskToShow,
  showSidePlate,
  setShowSidePlate,
  idOfTask,
}) => {
  const {
    show1,
    setshow1, // Показывать или нет Профиль пользователя / компании
  } = useContext(useStateContext);

  const dispatch = useDispatch();

  const refForScroll = useRef<HTMLDivElement>(null);

  //состояние для показа одного модального окна для связки с бизнес-процесса

  const dateMaster = useMemo(() => {
    return new DateMaster(task.end);
  }, [task]);

  // use]);

  useEffect(() => {
    if (task.id === idOfTask) {
      dispatch(selectTask(task));

      if (refForScroll && refForScroll.current) {
        refForScroll.current.scrollIntoView();
      }
    }
  }, [task.id, idOfTask]);

  // console.log(task);

  const clickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    const target = e.target as HTMLElement;
    if (idOfTaskToShow !== +target.id) {
      setShowSidePlate(false);
      setIdOfTaskToShow(+target.id);
    } else if (idOfTaskToShow === +target.id) {
      setShowSidePlate(true);
      setIdOfTaskToShow(0);
    }
  };

  // Тут вызывается окно редактирования задачи при клике на задачу
  const trClickHandler = useCallback(
    (e: React.MouseEvent) => {
      setshow1(false); // При открытии задачи закрываем окно профиля  компании / пользователя, если оно вдруг открыто

      // При открытии задачи закрываем окно "О компании", если оно вдруг открыто
      showAnimateCompanyWindow(false);
      setTimeout(() => {
        dispatch(showCompanyWindow(false));
        setPlateEditMode(false);
        setInitial();
      }, 800);

      setShowSidePlate(true);
      setIdOfTaskToShow(0);

      if (statusWindow) {
        // 571 задача redmine *********************************
        e.stopPropagation(); // 571 задача redmine *********************************
        if (task.id !== selectedTask?.id) {
          let target = e.target;

          // let html_target = target as HTMLElement;

          readNotificationByTask(task.id);

          if (store.getState().tasksPage.taskAddMode) return;
          if (selectedTask?.id !== task.id) {
            selectTask(task);
          }
          if (!(target as HTMLElement).closest(".task_status_wrapper"))
            setShownTaskInfoPlate(true);
        }
      }

      // 571 задача redmine *********************************
    },
    [
      setShownTaskInfoPlate,
      selectedTask,
      task,
      selectTask,
      statusWindow,
      showSidePlate,
    ] // 571 задача из redmine.
  );

  const executor = users.find((user) => user.id === task.executor_id);
  const author = users.find((user) => user.id === task.author_id);
  // console.log(author);

  let project_of_task;
  let section_of_task;
  Object.keys(projects).forEach((id) => {
    if (projects[id].id === task.project_id) {
      project_of_task = projects[id].name;
      projects[id].sections.forEach((section) => {
        if (section.id === task.project_section_id) {
          section_of_task = section.name;
        }
      });
    }
  });

  return (
    <TaskStyle
      active={task.id === selectedTask?.id}
      onClick={trClickHandler}
      ref={idOfTask === task.id ? refForScroll : null}
    >
      <BlockLeftFlex>
        {activeColumns.includes("id") && <IdCell id={task.id} />}
        {activeColumns.includes("author_id") && (
          <ExecutorCell
            minWidth={columnsOfTables[1].style?.minWidth}
            url={
              author?.image
                ? urlApp + `/files/${author.image.url}/download`
                : null
            }
            executorName={
              author ? `${author.surname} ${author.name}` : "Неизвестен"
            }
          />
        )}
        {activeColumns.includes("name") && (
          <TaskNameCell
            name={task.name}
            desc={task.description}
            notificationBall={false}
            mark={markTask}
            id={task.id}
          />
        )}
      </BlockLeftFlex>
      <BlockRightFlex>
        {activeColumns.includes("status_id") && (
          <TaskStatus // 571 задача из redmine. Из этого дочернего компонента- при начала работы с дочерним компонентом вернуть в родительский statusWindow= false.
            id={
              // 571 задача из redmine. При окончании- statusWindow = true
              // если статус "в работе" и дедлайн уже прошел, то надо ставить статус "просрочена" - id 14
              task.status_id === 10 &&
              new Date(
                dateMaster.year,
                dateMaster.month,
                dateMaster.day,
                dateMaster.hours,
                dateMaster.minutes
              ).getTime() <= new Date(Date.now()).getTime()
                ? 14
                : task.status_id
            }
            taskId={task.id}
            disabled={task && task.id_bp !== null ? true : false}
          />
        )}
        {activeColumns.includes("end") && (
          <DeadLineCell deadline={dateMaster.deadlineMini} />
        )}

        {activeColumns.includes("executor_id") && (
          <ExecutorCell
            minWidth={columnsOfTables[5].style?.minWidth}
            url={
              executor?.image
                ? urlApp + `/files/${executor.image.url}/download`
                : null
            }
            executorName={
              executor ? `${executor.surname} ${executor.name}` : "Неизвестен"
            }
          />
        )}
        {activeColumns.includes("task_load") && (
          <TaskWorkLoadingCell
            taskLoad={task.task_load}
            workLoad={task.work_load}
          />
        )}

        {activeColumns.includes("project_name") && (
          <ProjectCell
            data_project={project_of_task}
            data_section={section_of_task}
          />
        )}
        {/* {activeColumns.includes('section_name') && (
        <PartitionCell data_section={section_of_task} />
      )} */}
        {activeColumns.includes("priority_id") && (
          <PriorityCell
            style={{ backgroundColor: "transparent" }}
            priorityId={task.priority_id}
          />
        )}
        <div
          onClick={(e) => clickHandler(e)}
          id={task.id.toString()}
          style={{
            marginRight: "10px",
            marginTop: "15px",
            position: "relative",
            zIndex: 4,
          }}
        >
          {task.id_bp ? (
            <img id={task.id.toString()} src={imgUrl} alt="" />
          ) : (
            <img id={task.id.toString()} src={imgUrlDisable} alt="" />
          )}
          {task.id === idOfTaskToShow && task.id_bp && (
            <BPModal id_bp={task.id_bp} />
          )}
        </div>
      </BlockRightFlex>
    </TaskStyle>
  );
};

const mapStateToProps = (state: State) => {
  return {
    statusWindow: state.tasksPage.statusWindow, /// 571 задача из redmine *********************************************** */
    projects: state.commonInfo.projects,
    partitions: state.commonInfo.sections,
    selectedTask: getSelectedTask(state),
    taskAddMode: state.tasksPage.taskAddMode,
    activeColumns: state.tasksPage.activeColumns,
    users: state.commonInfo.users,
  };
};

const mapDispatchToProps = {
  updateStatusWindowTrue, // 571 задача из redmine ************************************************ */
  updateStatusWindowFalse, // 571 задача из redmine ************************************************ */
  selectTask,
  setShownTaskInfoPlate,
  openExistingTask,
  setTaskAddMode,
  setProjectSections,
  readNotificationByTask,
  fetchTaskDependence,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTr);
