import React from 'react';
import styled from 'styled-components';
import { columnsOfTables } from '../../shared_data';

type Props = {
  deadline: string;
};
const WrapperDeadLineCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 4px;
  line-height: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  min-width: ${columnsOfTables[4].style?.minWidth};
`;
const DateStyle = styled.span`
  font-size: 14px;
  color: #212121;
`;
const DayStyle = styled.span`
  font-size: 13px;
  color: #a8a8a8;
`;

const DeadLineCell: React.FC<Props> = ({ deadline }) => {
  return (
    <WrapperDeadLineCell>
      <DateStyle>{deadline}</DateStyle>
      <DayStyle>{}</DayStyle>
    </WrapperDeadLineCell>
  );
};

export default DeadLineCell;
