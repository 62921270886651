import React, { useCallback, useRef } from 'react'; //**************************************************************** */
import { uploadFile, deleteFile } from '../actions';
import { connect } from 'react-redux';
// import withTitle from '../../../utils/hoc/withTitle';
import FileElem from '../../../common/components/FileElem/FileElem';
import { File as FileType } from '../../../common/types';
import { State } from '../../../rootReducer';
import { getFiles } from '../selectors';
import { droppedItemsAnalize } from '../../../utils/droppedItemsAnalize';
import styled from 'styled-components';
import {CreateNotif} from '../../../utils/createNotification'; ///****************** */

type Props = {
  files: FileType[];
  uploadFile: (file: File) => void;
  deleteFile: (id: number) => void;
};
const WrapperListFile = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
`;
const Files: React.FC<Props> = ({ files, uploadFile, deleteFile }) => {

   //**************************************** */
   const inputFile = useRef<HTMLInputElement>(null);

   const onButtonClick = () => {
     // `current` points to the mounted file input element
    if (inputFile?.current){
     inputFile.current.click();
    
  // inputFile.current.value- ссылка для загружаемого файла
    }
    };
  //*************************************** */


  const onChange = (e) => {
  
    let item = e.target.files[0];
     if  (e.target.files[0].size < 10485760){
          uploadFile(item) }
     else  CreateNotif('Загружаемый файл должен быть меньше 10 Мб!', 'warning');
    }
    
  //*************************************** */


  const dropHandler = useCallback(
    (e) => {
      e.preventDefault();
      let items = e.dataTransfer.items;
      droppedItemsAnalize(uploadFile, ...items);
    },
    [uploadFile]
  );

  return (
    <>

      {/* ************************************************************ */}
      <div
        onClick={onButtonClick}  
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => dropHandler(e)}
        className="project_files is_drag_enter"
      />
       {/* ************************************************************ */}
     <input type='file' id='file' ref={inputFile} style={{display: 'none'}}     onChange={onChange}/>
       {/* ************************************************************ */}
      {files.length > 0 && (
        <WrapperListFile className="file_list">
          {files.map((file) => (
            <FileElem
              id={file.id}
              url={file.url}
              name={file.name}
              deleteHandler={deleteFile}
              key={file.name}
            />
          ))}
        </WrapperListFile>
      )}
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    files: getFiles(state),
  };
};

const mapDispatchToProps = {
  uploadFile,
  deleteFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Files);
