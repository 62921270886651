// Картинка для пользователя
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { CreateNotif } from '../../../utils/createNotification';
import { State } from '../../../rootReducer';
import { connect } from 'react-redux';
import { setUserImage } from '../actions';
import { urlApp } from '../../../App';
import styled from 'styled-components';
import Icon from '../../../common/components/Icon';

const WrapperAvatar = styled.div`
  position: relative;
  justify-self: center;
  align-self: center;
  background-image: url('../img/avatar_placeholder.png');
  width: 96px;
  height: 96px;
  img {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
  label {
    /* position: absolute; */
    /* bottom: -15px; */
    /* left: calc(50% - 15px); */
    border-radius: 50%;
    box-shadow: 0px 0px 4px rgba(32, 48, 90, 0.1);
    /* width: 25px; */
    /* height: 25px; */
    cursor: pointer;
    background-color: white;
    img {
      width: 96px;
      height: 96px;
    }
  }
  input[type='file'] {
    display: none;
  }
`;
//Аватар можно менять для новых пользователей и для себя

const Avatar = ({
  image,
  setUserImage,
  userId,
  currentUserId,
  setChangeDataUser,
  rolesUser,
  currentUserRoles,
}) => {
  const [url, setUrl] = useState<string | null>(null);

  const img = useRef<HTMLImageElement>(null);

  const changeHandler = useCallback(
    (e) => {
      let file = e.target.files[0];
      if (!file) return;
      if (!file.type.match('image.*')) {
        CreateNotif('Файл не является изображением');
        return;
      }
      setUserImage(file);
      userId !== 0 &&
        setChangeDataUser((prev) => ({
          ...prev,
          data: { ...prev.data, file },
          schedule: { ...prev.schedule },
        }));
      },
    [setUserImage]
  );

  useEffect(() => {
    if (!image) return;
    if (image instanceof File) {
      let reader = new FileReader();
      reader.onload = ((theFile) => {
        return function (e) {
          setUrl(e.target.result);

          img.current?.setAttribute('srс', e.target.result);
        };
      })(image);

      reader.readAsDataURL(image);
    } else if (image.url) {
      setUrl(urlApp + `/files/${image.url}/download`);
    }
  }, [image]);

  return (
    <WrapperAvatar>
      {/* <Icon width="100%" name="avatar" src={url ? url : ''} ref={img} /> */}

      <label>
        <Icon width="100%" name="avatar" src={url ? url : ''} />
        {(userId === 0 ||
          currentUserId === userId ||
          currentUserRoles.includes(1) ||
          currentUserRoles.sort()[0] < rolesUser.sort()[0]) && (
          <input onChange={changeHandler} type="file" />
        )}
      </label>
    </WrapperAvatar>
  );
};

const mapStateToProps = (state: State) => ({
  image: state.newUser.image,
  userId: state.newUser.id,
  currentUserId: state.commonInfo.current_user,
  rolesUser: state.newUser.roles,
  currentUserRoles: state.commonInfo.roles,
});

const mapDispatchToProps = {
  setUserImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
