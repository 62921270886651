// Сам элемент загрузки

import React from 'react';
import './styles.sass';
import styled from 'styled-components';

export type ToggleElem = {
  name: string;
  value?: string | number;
  id?: number;
};

type Props = {
  elemsList: ToggleElem[];
  activeValue?: string | number | null;
  selectHandler: (value: string | number) => void;
  styles?: object;
};

const TogglerBox = styled.div`
  position: relative;
  display: flex;
  color: ${({ active }) => (active ? '##02405e' : '#707070')};

  box-shadow: 0 0 10px rgb(0 0 0 / 4%);
  background: #f2f2f2;
  transition: right 0.5s ease;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 6px;
  height: 36px;
  ${({ styles }) => styles && styles}
`;
const TogglerItem = styled.div`
  background: ${({ active }) => active && '#FFFFFF'};
  color: ${({ active }) => active && '##02405e'};
  border: 2px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
`;

const Toggler: React.FC<Props> = ({
  elemsList,
  styles,
  activeValue = null,
  selectHandler,
}) => {
  return (
    <TogglerBox styles={{ ...styles }}>
      {elemsList.map((elem) => (
        <TogglerItem
          key={elem.value || elem.id}
          active={elem.value === activeValue || elem.id === activeValue}
          onClick={() =>
            selectHandler(
              elem.value ? elem.value : (elem.id as number | string)
            )
          }
        >
          {elem.name}
        </TogglerItem>
      ))}
    </TogglerBox>
  );
};

export default Toggler;
