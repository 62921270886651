import React, { useContext, useEffect } from "react";
import { CreateNotif } from "../../../../../../utils/createNotification";
import { StatusContext } from "../../../context/status";
import "../BpResultForm.scss";

const SelectTemplate = ({ listArr, name, data, setData, changeBpName }) => {
  const { isAdmin, setloadDataNow, statusOfTask, executorIdofTask, userId } =
    useContext(StatusContext);

  useEffect(() => {
    if (!data[name]) {
      setData((prevState) => {
        return { ...prevState, [name]: listArr[0] };
      });
    }
  }, [name]);

  return (
    <select
      className={
        statusOfTask === 0 || statusOfTask === 2
          ? "input-form"
          : "input-form unavailableForm" //не дает редактировать форму в зависимости от статуса
      }
      name={name}
      onChange={(e) => {
        if (executorIdofTask === userId || isAdmin) {
          setloadDataNow((loadDataNow) => !loadDataNow);
          setData((prevState) => {
            return { ...prevState, [e.target.name]: e.target.value };
          });
          if (changeBpName) changeBpName(e.target.value);
        } else {
          CreateNotif("У вас нет полномочий для данного действия");
        }
      }}
    >
      {listArr.map((item) => {
        if (item.id) {
          if (
            data &&
            data[name] &&
            data[name] == item.surname + " " + item.name
          ) {
            return (
              <option
                id={name}
                value={item.surname + " " + item.name}
                key={item.id}
                selected
              >
                {item.surname + " " + item.name}
              </option>
            );
          } else {
            return (
              <option
                id={name}
                value={item.surname + " " + item.name}
                key={item.id}
              >
                {item.surname + " " + item.name}
              </option>
            );
          }
        }
        return data && data[name] && data[name] == item ? (
          <option key={item} value={item} selected>
            {item}
          </option>
        ) : (
          <option key={item} value={item}>
            {item}
          </option>
        );
      })}
    </select>
  );
};

export default SelectTemplate;
