import React, {useEffect, useState} from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import {connect} from 'react-redux';
import {State} from '../../../rootReducer';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Input from "../../../common/components/Inputs/Input";
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn";
import ScheduleDay from "../../NewUser/components/ScheduleDay";
import Button from "../../../common/components/newComponent/Buttons/Button";
import {Item, Task} from "../../../common/types";
import {
    clearCyclicTaskToChange,
    sendReqToChangeTemplRepTask,
    setCyclicTaskToChange,
    setFlagRepeatTaskToChange,
    setStartCyclick
} from "../actions";
import {updateTask} from "../../../common/actions";


type Props = {
    repeat: any,
    setStartCyclick: (period?: string, params?: Array<number>, interval?: number, show_modal_window?: boolean, stringForMainPart?: string) => void,
    setFlagRepeatTaskToChange: (val: string) => void,
    selectedTask: Task | null;
    rememberObjectCyclicTaskToChange: {};
    updateTask: (id: number, params: any) => void;
    setCyclicTaskToChange: (val: {}) => void;
    clearCyclicTaskToChange: () => void
}


const ConfirmEditRepeatTask: React.FC<Props> = ({
                                                    setFlagRepeatTaskToChange,
                                                    selectedTask,
                                                    rememberObjectCyclicTaskToChange,
                                                    updateTask,
                                                    setCyclicTaskToChange,
                                                    clearCyclicTaskToChange
                                                }) => {

    const standardAction = () => {
        updateTask(selectedTask?.id as number, rememberObjectCyclicTaskToChange)
        clearCyclicTaskToChange()
    }

    return (
        <div className="add_pannel_wrapper">
            <div className={`confirm_edit_repeat_task_window`}>
                <div className={'firstRow'}>
                    <div>Изменить повторяющуюся задачу ?</div>
                </div>
                <div className={'secondRow'}>
                    Вы изменяете повторяющуюся задачу. Изменить только эту задачу, или все ее повторения в будущем ?
                </div>
                <div className={'bottomRow'}>
                    <Button title={'Все будущие'} design={'secondary'} onClick={() => {
                        sendReqToChangeTemplRepTask(rememberObjectCyclicTaskToChange);  // послать на сервер сигнал о изменении для всех последующих
                        standardAction()
                    }}/>
                    <Button title={'Только эту задачу'} onClick={() => {
                        standardAction()
                    }}/>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        selectedTask: state.taskInfoPlate.selectedTask,
        repeat: state.taskInfoPlate.repeat,
        rememberObjectCyclicTaskToChange: state.taskInfoPlate.rememberObjectCyclicTaskToChange,
    }
}

const mapDispatchToProps = {
    setStartCyclick,
    setFlagRepeatTaskToChange,
    updateTask,
    setCyclicTaskToChange,
    clearCyclicTaskToChange,
    sendReqToChangeTemplRepTask,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEditRepeatTask)