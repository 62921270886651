import React, { FC } from 'react';
import styled from 'styled-components';

import attach from '../../../../img/attach.svg';
import deleteIcon from '../../../../img/x-black.svg';

const Chips = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
  background: #e6ecef;
  cursor: pointer;
  border-radius: 20px;
  height: 32px;
	box-sizing: border-box;
	padding: 0 5px;
	gap: 5px;
`;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
  background: #fff;
  border-radius: 16px;
  width: 24px;
  height: 24px;
`;

const Content = styled.div`
	width: 85px;
	overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DeleteIcon = styled.div`
	width: 20px;
	height: 20px;
	object-fit: contain;
	
	& img {
		width: 100%;
		height: 100%;
	}
`;

type Props = {
	file: File,
	handleDelete: () => void;
};

const ViewFile: FC<Props> = ({ file, handleDelete }) => {
	return (
		<Chips onClick={handleDelete}>
			<IconContainer>
				<img src={attach} alt='закладка' />
			</IconContainer>
			<Content>{file.name}</Content>
			<DeleteIcon>
				<img src={deleteIcon} alt='удалить' />
			</DeleteIcon>
		</Chips>
	);
};

export default ViewFile;
