import axios from "axios";
import { nanoid } from "nanoid";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import AddFileButton from "../templates/AddFileButton";
import NameOfStep from "../templates/NameOfStep";
import "../BpResultForm.scss";
// пропс в последующем можно удалить
//

let fileTranslate;

const Completion_3monthTimer = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [allCompletion_3monthTimerSend, setAllCompletion_3monthTimerSend] =
    useState([]);

  const [allCompletion_3monthTimer, setAllCompletion_3monthTimer] = useState(
    []
  );

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  //Добавляет файлы
  const onSendFile = (e, numberCompletion_3monthTimer) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberCompletion_3monthTimer === "Completion_3monthTimer") {
        if (file && file.name) {
          setAllCompletion_3monthTimer([
            ...allCompletion_3monthTimer,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "Completion_3monthTimer",
            },
          ]);

          console.log("allCompletion_3monthTimer", allCompletion_3monthTimer);
        }
      }
    }, [500]);
  };
  //Удаляет файл
  const onDeletedFile = (keyId, numberCompletion_3monthTimer) => {
    if (numberCompletion_3monthTimer === "Completion_3monthTimer") {
      setAllCompletion_3monthTimer(
        allCompletion_3monthTimer.filter((file) => file.id !== keyId)
      );
    }
  };

  //Смотрит на какую кнопку какого раздела с файлами было нажато
  const onClickFetchCompletion_3monthTimer = (
    e,
    numberCompletion_3monthTimer
  ) => {
    e.preventDefault();
    console.log(numberCompletion_3monthTimer);

    let data;
    if (numberCompletion_3monthTimer === "Completion_3monthTimer") {
      data = JSON.stringify({
        files: allCompletion_3monthTimer,
      });
      console.log("Нажал на кнопку для отправки файлов Completion_3monthTimer");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    setAllCompletion_3monthTimer([]);
  };

  const [data, setData] = useState(
    settingsOfFields?.completion_3monthTimer?.extra
      ? settingsOfFields?.completion_3monthTimer.extra
      : {}
  );

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  useEffect(() => {
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields?.completion_3monthTimer, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="completion_3monthTimer" className="form">
      <div>
        <NameOfStep text={"Запрос на согласование"} />
        <div className="file-uploaded__block">
          <ul>
            {taskCurrentGlobal &&
              taskCurrentGlobal["files"] &&
              taskCurrentGlobal["files"]["list"]
                .filter((item) => item?.tag == "Completion_3monthTimer")
                .map((item2) => (
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                      target="_blank"
                    >
                      {item2.name}
                    </a>
                  </li>
                ))}
          </ul>
        </div>
        <AddFileButton
          filesList={taskCurrentGlobal}
          data={data}
          setData={setData}
          id={"h8"}
          handlerOnSendFile={onSendFile}
          handlerOnDeletedFile={onDeletedFile}
          handlerOnClickFetchFiles={onClickFetchCompletion_3monthTimer}
          allFiles={allCompletion_3monthTimer}
          numberFiles={"Completion_3monthTimer"}
        />
      </div>
      <div></div>
    </form>
  );
};

export default Completion_3monthTimer;
