/*************************************************************** */
import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./LeftNav.scss";
//import HeaderAvatar from './HeaderAvatar';
import {
  selectTask,
  setDefaultValues,
  setShownTaskInfoPlate,
} from "../../../pages/TaskInfoPlate/actions";
import { connect, useDispatch } from "react-redux";
import { setFiltersTasks, setLoadingTasks } from "../../../pages/Tasks/actions";
import { State } from "../../../rootReducer";
import { setIsFetchingUsers } from "../../../pages/Users/actions";
import {
  fetchTasks,
  setIsFetchingTasks,
} from "../../../pages/Projects/actions";
import "./styles.sass";
import NotificationWindow from "../../NotificationWindow";
import styled from "styled-components";
import Icon from "../Icon";
import {
  DefaultFilterTemplates,
  FilterTemplates,
  setFilters,
  ShowFiltersWindow,
  updateTimestamp,
} from "../../actions";

const WrapperHead = styled.div`
  border-bottom: 1px solid #e1e1e7;
`;

/*  ***************************
Цвета:
Синий: #99e6ff
Светло-синий: #ccf2ff
Цвет из макета: #292A34;
Если isLeftBlockBig = true - ширина левого меню 286 px
Если isLeftBlockBig = false - ширина левого меню 146 px
*/
/* min-height: ${({ isProjectsPage }) =>
    isProjectsPage ? "100%" : "99.8vh"};  */

const HeaderBlock = styled.header`
  /* margin: 0 auto; */
  /* max-width: 1280px; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; /**************/
  flex-direction: column; /***/
  width: ${({ isLeftBlockBig }) => (isLeftBlockBig ? "286px" : "146px")};
  min-height: 100%;
  padding-left: 37px;
  padding-top: 61px;
  background: #292a34; /****/
`;
const NavigationBlock = styled.nav`
  display: flex;
  flex-direction: column; /***/
  margin-top: 70px; /***********/
  a {
    border-radius: 50%;
    display: block;
    margin: 0 0 25px 0; /***************/
    margin-right: 0; /***************/
    background-position: flex-start;
    background-repeat: no-repeat;
    /* transition: background .2s ease; */
    &:last-of-type {
      margin-right: 0;
    }
  }
  span {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 18px;
    color: #aaa;
  }
`;
const FlexBlock = styled.div`
  display: flex;
  flex-direction: column; /***/
  margin: 0; /***************/
  margin-left: 5px;
`;

/*
color: ${({hoverPage }) => {hoverPage }};
 
   */
// const span = styled.span`
//   font-family: "Roboto";
//   font-weight: 500;
//   font-size: 18px;
//   color: #aaa;
// `;

const LinksBlock = styled.div`
  position: absolute;
  bottom: 75px;
  div {
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  span {
    margin-left: 10px;
    color: #c4c4c4;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    &:hover {
      color: #fff;
    }
  }
  a {
    cursor: pointer;
  }
`;

const Img = styled.img`

src= ${({ src }) => src};
title= ${({ title }) => title};
alt=${({ alt }) => alt};
margin-right:5px;
width:54px;
height:54px;
`;

let logo1; //******************************************************* */

let shape_open; //******************************************************* */

let shape_close; //******************************************************* */

let tasks;

let projects;

let users;

let calendar;

let bp;

let tasks_active;

let tasks_hover;

let projects_active;

let project_hover;

let users_active;

let users_hover;

let calendar_active;

let calendar_hover;

let bp_active;

let bp_hover;

let pdf_icon_active;
let chat_icon_active;
let youtube_icon_active;

const Header = ({
  isLeftBlockBig,
  setIsLeftBlockBig,
  selectedTask,
  notifications,
  selectTask,
  isShownTaskInfoPlate,
  setShownTaskInfoPlate,
  setLoadingTasks,
  setIsFetchingUsers,
  setIsFetchingTasks,
  showFiltersWindow,
  ShowFiltersWindow,
  filters,
  current_filters,
  DefaultFilterTemplates,
  FilterTemplates,
  selectedProjectId,
  setFiltersTasks,
  fetchTasks,
  selectedUserId,
  setFilters,
  setDefaultValues,
  current_user,
  isProjectsPage,
}) => {
  // console.log(isProjectsPage);
  let [hoverPage, sethoverPage] = useState<number>(0);
  const hoverHandlerTasks = () => {
    if (!location.pathname.match("/tasks")) {
      sethoverPage(1);
    }
  };

  const hoverHandlerProjects = () => {
    if (!location.pathname.match("/projects")) {
      sethoverPage(2);
    }
  };

  const hoverHandlerUsers = () => {
    if (!location.pathname.match("/users")) {
      sethoverPage(3);
    }
  };

  const hoverHandlerCalendar = () => {
    if (!location.pathname.match("/calendar")) {
      sethoverPage(4);
    }
  };

  const hoverHandlerBp = () => {
    if (!location.pathname.match("/bp")) {
      sethoverPage(5);
    }
  };

  useEffect(() => {
    logo1 = require("../Header/img/logo1.svg"); //******************************************************* */

    shape_open = require("../Header/img/shape_open.svg"); //******************************************************* */

    shape_close = require("../Header/img/shape_close.svg"); //******************************************************* */

    tasks = require("../Header/img/tasks.svg");

    projects = require("../Header/img/projects.svg");

    users = require("../Header/img/users.svg");

    calendar = require("../Header/img/calendar.svg");

    bp = require("../Header/img/bp.svg");

    tasks_active = require("../Header/img/tasks_active.svg");

    tasks_hover = require("../Header/img/tasks_hover.svg");

    projects_active = require("../Header/img/projects_active.svg");

    project_hover = require("../Header/img/projects_hover.svg");

    users_active = require("../Header/img/users_active.svg");

    users_hover = require("../Header/img/users_hover.svg");

    calendar_active = require("../Header/img/calendar_active.svg");

    calendar_hover = require("../Header/img/calendar_hover.svg");

    bp_active = require("../Header/img/bp_active.svg");

    bp_hover = require("../Header/img/bp_hover.svg");

    pdf_icon_active = require("../Header/img/pdf_icon_active.svg");

    chat_icon_active = require("../Header/img/chat_icon_active.svg");

    youtube_icon_active = require("../Header/img/youtube_icon_active.svg");
  }, []);

  const outHandler = () => {
    sethoverPage(0);
  };

  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const [showNotificationWindow, setShowNotificationWindow] =
    useState<boolean>(false);
  const [FirstRender, setFirstRender] = useState<boolean>(true);
  const navClickHandler = useCallback(
    (e) => {
      if (!e.target.closest("a")) return;
      if (isShownTaskInfoPlate) setDefaultValues();
    },
    [
      isShownTaskInfoPlate,
      selectTask,
      selectedTask,
      setShownTaskInfoPlate,
      setDefaultValues,
    ]
  );

  if (FirstRender) {
    let LS_filters = localStorage.getItem("filters");
    // localStorage.removeItem('filters')

    if (LS_filters) {
      let parsedLS = JSON.parse(LS_filters);
      let users_filters = parsedLS ? parsedLS["users_filters"] : {};
      let last_active = parsedLS ? parsedLS["last_active"] : {};

      Object.keys(users_filters).forEach(
        (item) => (users_filters[item]["active"] = false)
      );

      FilterTemplates(users_filters); // в редюсер

      setFilters(
        last_active.date_from,
        last_active.date_to,
        last_active.executors,
        last_active.authors,
        last_active.statuses,
        last_active.actual
      );
    } else {
      let structure = {
        last_active: {},
        users_filters: {},
      };
      localStorage.setItem("filters", JSON.stringify(structure));
    }

    setFirstRender(false);
  }

  // если был загружен какой то файл, но не был использован, то его нужно удалять. Факт его загрузки и неиспользования - наличие его айдишника в локалстороже.

  const sameActionForAllParts = () => {
    dispatch(updateTimestamp(""));
    if (showFiltersWindow) {
      // скрыть окно с фильтрами, если оно показывается. Это нужно потому, что при клике н другие разделы проекта тут же срабатывает роутер, и обычный алгоритмы клика аутсайд не отрабатывают.
      ShowFiltersWindow();
    }
  };

  useEffect(() => {
    let default_list = {
      Все: {
        date_from: "",
        date_to: "",
        executors: [],
        authors: [],
        statuses: [],
        actual: false,
      },
      // 'Актуальные': {
      //     'date_from': '',
      //     'date_to': '',
      //     'executors': [],
      //     'authors': [],
      //     'statuses': [],
      //     'actual': true
      // },
      Исполнитель: {
        date_from: "",
        date_to: "",
        executors: [
          !current_filters.executors.length && !current_filters.authors.length
            ? current_user
            : selectedUserId,
        ],
        authors: [],
        statuses: [2, 3, 10, 12, 14],
        actual: false,
      },
      Автор: {
        date_from: "",
        date_to: "",
        executors: [],
        authors: [
          !current_filters.executors.length && !current_filters.authors.length
            ? current_user
            : selectedUserId,
        ],
        statuses: [2, 3, 10, 12, 14],
        actual: false,
      },
      Просроченные: {
        date_from: "",
        date_to: "",
        executors: [selectedUserId],
        authors: [],
        statuses: [14],
        actual: false,
      },
      Архивные: {
        date_from: "",
        date_to: "",
        executors: [selectedUserId],
        authors: [],
        statuses: [15, 11, 13],
        actual: false,
      },
    };
    Object.keys(default_list).forEach(
      (item) => (default_list[item]["active"] = false)
    );
    DefaultFilterTemplates(default_list); // в редюсер
  }, [selectedUserId, current_filters]);

  // это сравнение лежит здесь, потому что нужно знать результат ее исполнения до открытия окна с фильтрами - на кнопке отображается текущий примененный фильтр.
  useEffect(() => {
    comparison();
    saveChangesLocalStorage();

    let context = location.pathname.substr(
      1,
      location.pathname.indexOf("/", 1) - 1
    );
    if (!FirstRender) {
      // делать запрос НА СЕРВЕР для обновления табличной части при изменении фильтра
      switch (context) {
        case "tasks":
          setFiltersTasks(current_filters); // послать запрос в контексте раздела Таскс
          break;
        case "projects":
          fetchTasks(selectedProjectId as number, 1);
          break;
      }
    } else setFirstRender(!FirstRender);
  }, [current_filters]);

  useEffect(() => {
    comparison();
  }, [filters.default_templates]);

  function comparison() {
    // сравнение выбранного набора параметров фильтра в правой части, с дефолтными и пользовательскими фильтрами в редюсере
    let filters_copy = { ...filters };
    let keys_default_filters = Object.keys(filters_copy.default_templates);
    keys_default_filters.forEach((item) => {
      // мы берем каждый дефолтный фильтр, и сравниваем каждый его параметр с тем, что сейчас выставлено в правой части. Если полный мэтч - значит делаем брэйк и выставляем у него флаг Эктив. Если нет - идет сравниать даль
      // let actual = false
      // if (current_filters.actual === keys_default_filters.default_templates[item].actual) actual = true

      let date_from = false;
      if (
        current_filters.date_from ===
        filters_copy.default_templates[item].date_from
      )
        date_from = true;

      let date_to = false;
      if (
        current_filters.date_to === filters_copy.default_templates[item].date_to
      )
        date_to = true;

      let executors = false;
      // нету ни того ни другого
      if (
        !Boolean(current_filters.executors.length) &&
        !Boolean(filters_copy.default_templates[item].executors.length)
      ) {
        executors = true;
      } else {
        // есть оба
        if (
          Boolean(current_filters.executors.length) &&
          Boolean(filters_copy.default_templates[item].executors.length)
        ) {
          let reducer = [...current_filters.executors];
          filters_copy.default_templates[item].executors.forEach(
            (defTempItem) => {
              current_filters.executors.forEach((execId) => {
                if (defTempItem === execId)
                  reducer.splice(reducer.indexOf(execId), 1);
              });
            }
          );
          if (!reducer.length) executors = true;
        }
      }

      let authors = false;
      // нету ни того ни другого
      if (
        !Boolean(current_filters.authors.length) &&
        !Boolean(filters_copy.default_templates[item].authors.length)
      ) {
        authors = true;
      } else {
        // есть оба
        if (
          Boolean(current_filters.authors.length) &&
          Boolean(filters_copy.default_templates[item].authors.length)
        ) {
          let reducer = [...current_filters.authors];
          filters_copy.default_templates[item].authors.forEach(
            (defTempItem) => {
              current_filters.authors.forEach((authorId) => {
                if (defTempItem === authorId)
                  reducer.splice(reducer.indexOf(authorId), 1);
              });
            }
          );
          if (!reducer.length) authors = true;
        }
      }

      let statuses = false;
      // нету ни того ни другого
      if (
        !Boolean(current_filters.statuses.length) &&
        !Boolean(filters_copy.default_templates[item].statuses.length)
      ) {
        statuses = true;
      } else {
        // есть оба
        if (
          Boolean(current_filters.statuses.length) &&
          Boolean(filters_copy.default_templates[item].statuses.length)
        ) {
          if (
            current_filters.statuses.length ===
            filters_copy.default_templates[item].statuses.length
          ) {
            // их количество одинаково
            let default_statuses_by_item = [
              ...filters_copy.default_templates[item].statuses,
            ];
            filters_copy.default_templates[item].statuses.forEach(
              (defTempItem) => {
                current_filters.statuses.forEach((execId) => {
                  if (defTempItem === execId)
                    default_statuses_by_item.splice(
                      default_statuses_by_item.indexOf(execId),
                      1
                    );
                });
              }
            );
            if (!default_statuses_by_item.length) statuses = true;
          }
        }
      }

      // if (statuses && authors && executors && date_from && date_to && actual) {   // выделяю пункт голубым
      if (statuses && authors && executors && date_from && date_to) {
        // выделяю пункт голубым
        filters_copy.default_templates[item].active = true;
      } else {
        if (filters_copy.default_templates[item].active) {
          //   проверяю, выделен ли
          filters_copy.default_templates[item].active = false; // снимаю выделение
        }
      }
    });
    if (keys_default_filters.length)
      DefaultFilterTemplates(filters_copy.default_templates);

    let keys_filters = Object.keys(filters_copy.templates);
    keys_filters.forEach((item) => {
      // мы берем каждый дефолтный фильтр, и сравниваем каждый его параметр с тем, что сейчас выставлено в правой части. Если полный мэтч - значит делаем брэйк и выставляем у него флаг Эктив. Если нет - идет сравниать даль

      let date_from = false;
      if (current_filters.date_from === filters_copy.templates[item].date_from)
        date_from = true;

      let date_to = false;
      if (current_filters.date_to === filters_copy.templates[item].date_to)
        date_to = true;

      let executors = false;
      // нету ни того ни другого
      if (
        !Boolean(current_filters.executors.length) &&
        !Boolean(filters_copy.templates[item].executors.length)
      ) {
        executors = true;
      } else {
        // есть оба
        if (
          Boolean(current_filters.executors.length) &&
          Boolean(filters_copy.templates[item].executors.length)
        ) {
          let reducer = [...current_filters.executors];
          filters_copy.templates[item].executors.forEach((defTempItem) => {
            current_filters.executors.forEach((execId) => {
              if (defTempItem === execId)
                reducer.splice(reducer.indexOf(execId), 1);
            });
          });
          if (!reducer.length) executors = true;
        }
      }

      let authors = false;
      // нету ни того ни другого
      if (
        !Boolean(current_filters.authors.length) &&
        !Boolean(filters_copy.templates[item].authors.length)
      ) {
        authors = true;
      } else {
        // есть оба
        if (
          Boolean(current_filters.authors.length) &&
          Boolean(filters_copy.templates[item].authors.length)
        ) {
          let reducer = [...current_filters.authors];
          filters_copy.templates[item].authors.forEach((defTempItem) => {
            current_filters.authors.forEach((authorId) => {
              if (defTempItem === authorId)
                reducer.splice(reducer.indexOf(authorId), 1);
            });
          });
          if (!reducer.length) authors = true;
        }
      }

      let statuses = false;
      // нету ни того ни другого
      if (
        !Boolean(current_filters.statuses.length) &&
        !Boolean(filters_copy.templates[item].statuses.length)
      ) {
        statuses = true;
      } else {
        // есть оба
        if (
          Boolean(current_filters.statuses.length) &&
          Boolean(filters_copy.templates[item].statuses.length)
        ) {
          if (
            current_filters.statuses.length ===
            filters_copy.templates[item].statuses.length
          ) {
            // их количество одинаково
            let reducer = [...current_filters.statuses];
            filters_copy.templates[item].statuses.forEach((defTempItem) => {
              current_filters.statuses.forEach((execId) => {
                if (defTempItem === execId)
                  reducer.splice(reducer.indexOf(execId), 1);
              });
            });
            if (!reducer.length) statuses = true;
          }
        }
      }

      if (statuses && authors && executors && date_from && date_to) {
        // выделяю пункт голубым
        filters_copy.templates[item].active = true;
        FilterTemplates(filters_copy.templates);
      } else {
        if (filters_copy.templates[item].active) {
          //   проверяю, выделен ли
          filters_copy.templates[item].active = false; // снимаю выделение
          FilterTemplates(filters_copy.templates);
        }
      }
    });
  }

  function saveChangesLocalStorage() {
    // сохраняет последние выбранные параметры фильтра в правой части в локалсторож
    let LS_object = {};
    let LSfiltersString = localStorage.getItem("filters");
    if (LSfiltersString) {
      LS_object = JSON.parse(LSfiltersString);
      LS_object["last_active"] = {
        actual: current_filters.actual,
        date_from: current_filters.date_from,
        date_to: current_filters.date_to,
        authors: current_filters.authors,
        executors: current_filters.executors,
        statuses: current_filters.statuses,
      };
      localStorage.setItem("filters", JSON.stringify(LS_object));
    } else {
      let newLSresult = {
        users_filters: LS_object,
        last_active: {},
      };
      localStorage.setItem("filters", JSON.stringify(newLSresult));
    }
  }

  let pathname = location.pathname;

  return (
    <WrapperHead>
      {/* ****************************************************************************** */}
      {/* Передаем размер левого меню в styled component */}
      <HeaderBlock
        isLeftBlockBig={isLeftBlockBig}
        isProjectsPage={isProjectsPage}
      >
        {/* ****************************************************************************** */}

        {isLeftBlockBig ? (
          <div
            onClick={() => {
              setIsLeftBlockBig(false);
            }}
          >
            <img
              style={{
                position: "absolute",
                width: "50px",
                height: "21px",
                left: "256px",
                top: "21px",
              }}
              src={shape_close}
            />
          </div>
        ) : (
          <div
            onClick={() => {
              setIsLeftBlockBig(true);
            }}
          >
            <img
              style={{
                position: "absolute",
                width: "50px",
                height: "21px",
                left: "126px",
                top: "21px",
              }}
              src={shape_open}
            />
          </div>
        )}

        {/* ****************************************************************************** */}

        <span onClick={() => history.replace("/")}>
          {isLeftBlockBig ? (
            <div>
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  fontSize: "40px",
                  color: "#01B0E9",
                }}
              >
                EASY
              </span>
            </div>
          ) : (
            <div>
              {" "}
              <img style={{ marginLeft: "15px" }} src={logo1} />{" "}
            </div>
          )}
        </span>

        <FlexBlock>
          <NavigationBlock onClick={navClickHandler}>
            {/* Для показа профиля пользователя */}
            {/* <HeaderAvatar/> */}
            <div style={{ height: "10px" }}></div>
            {isLeftBlockBig && <span style={{ height: "15px" }}></span>}

            <Link
              onMouseEnter={hoverHandlerTasks}
              onMouseLeave={outHandler}
              style={{ textDecoration: "none" }}
              tabIndex={-1}
              to="/tasks"
              onClick={() => {
                sameActionForAllParts();
                if (pathname !== "/tasks") setLoadingTasks(true);
              }}
            >
              <div className={pathname.match("/tasks") ? "active" : ""}>
                {hoverPage !== 1 && (
                  <>
                    <Img
                      src={pathname.match("/tasks") ? tasks_active : tasks}
                      title="Задачи"
                      alt={"Задачи"}
                    />
                    <span
                      style={
                        pathname.match("/tasks")
                          ? { color: "#fff" }
                          : { color: "#aaa" }
                      }
                    >
                      {" "}
                      {isLeftBlockBig ? "Задачи" : ""}{" "}
                    </span>
                  </>
                )}

                {hoverPage === 1 && (
                  <>
                    <Img
                      src={
                        pathname.match("/tasks") ? tasks_active : tasks_hover
                      }
                      title="Задачи"
                      alt={"Задачи"}
                    />
                    <span style={{ color: "#fff" }}>
                      {" "}
                      {isLeftBlockBig ? "Задачи" : ""}{" "}
                    </span>
                  </>
                )}
              </div>
            </Link>
            <Link
              onMouseEnter={hoverHandlerProjects}
              onMouseLeave={outHandler}
              style={{ textDecoration: "none" }}
              tabIndex={-1}
              to="/projects"
              onClick={() => {
                sameActionForAllParts();
                if (pathname.match("/projects")) setIsFetchingTasks(true);
              }}
            >
              <div className={pathname.match("/projects") ? "active" : ""}>
                {hoverPage !== 2 && (
                  <>
                    <Img
                      src={
                        pathname.match("/projects") ? projects_active : projects
                      }
                      title="Проекты"
                      alt={"Проекты"}
                    />
                    <span
                      style={
                        pathname.match("/projects")
                          ? { color: "#fff" }
                          : { color: "#aaa" }
                      }
                    >
                      {" "}
                      {isLeftBlockBig ? "Проекты" : ""}{" "}
                    </span>
                  </>
                )}

                {hoverPage === 2 && (
                  <>
                    <Img
                      src={
                        pathname.match("/projects")
                          ? projects_active
                          : project_hover
                      }
                      title="Проекты"
                      alt={"Проекты"}
                    />
                    <span style={{ color: "#fff" }}>
                      {" "}
                      {isLeftBlockBig ? "Проекты" : ""}{" "}
                    </span>
                  </>
                )}
              </div>
            </Link>

            {/* Easy Meeting 
                        <Link
                            style={{textDecoration:"none"}}
                            tabIndex={-1}
                            to="/meetings"
                            onClick={() => {
                                sameActionForAllParts()
                                if (!location.pathname.match('/meetings'))
                                    setIsFetchingTasks(true);
                            }}
                        >
                            <div
                                className={
                              (location.pathname.match('/meetings') ? 'active' : '')
                                }
                            >
                                <img width={27} height={27}
                                     src={require('../../../img/clock_black.svg')}
                                     style={{paddingTop: '3px'}}
                                     title="EasyMeeting"
                                     alt={'EasyMeeting'}
                                />
                            </div>
                        </Link>
                    */}

            <Link
              onMouseEnter={hoverHandlerUsers}
              onMouseLeave={outHandler}
              style={{ textDecoration: "none" }}
              tabIndex={-1}
              to="/users"
              onClick={() => {
                if (!pathname.match("/users")) {
                  sameActionForAllParts();
                  setIsFetchingUsers(true);
                }
              }}
            >
              <div className={pathname.match("/users") ? "active" : ""}>
                {hoverPage !== 3 && (
                  <>
                    <Img
                      src={pathname.match("/users") ? users_active : users}
                      title="Сотрудники"
                      alt={"Сотрудники"}
                    />
                    <span
                      style={
                        pathname.match("/users")
                          ? { color: "#fff" }
                          : { color: "#aaa" }
                      }
                    >
                      {" "}
                      {isLeftBlockBig ? "Сотрудники" : ""}{" "}
                    </span>
                  </>
                )}
                {hoverPage === 3 && (
                  <>
                    <Img
                      src={
                        pathname.match("/users") ? users_active : users_hover
                      }
                      title="Сотрудники"
                      alt={"Сотрудники"}
                    />
                    <span style={{ color: "#fff" }}>
                      {" "}
                      {isLeftBlockBig ? "Сотрудники" : ""}{" "}
                    </span>
                  </>
                )}
              </div>
            </Link>

            {/*             
            <Link
              onMouseEnter={hoverHandlerCalendar}
              onMouseLeave={outHandler}
              style={{ textDecoration: "none" }}
              tabIndex={-1}
              to="/calendar"
              onClick={() => {
                sameActionForAllParts();
              //  if (pathname !== "/calendar")  setIsFetchingUsers(true);
              }}
            >
              <div className={pathname.match("/calendar") ? "active" : ""}>
                {hoverPage !== 4 && (
                  <>
                    <Img
                      src={pathname.match("/calendar") ? calendar_active : calendar}
                      title="Календарь"
                      alt={"Календарь"}
                    />
                    <span
                      style={
                        pathname.match("/calendar")
                          ? { color: "#fff" }
                          : { color: "#aaa" }
                      }
                    >
                      {" "}
                      {isLeftBlockBig ? "Календарь" : ""}{" "}
                    </span>
                  </>
                )}
                {hoverPage === 4 && (
                  <>
                    <Img
                      src={
                        pathname.match("/calendar") ? calendar_active : calendar_hover
                      }
                      title="Календарь"
                      alt={"Календарь"}
                    />
                    <span style={{ color: "#fff" }}>
                      {" "}
                      {isLeftBlockBig ? "Календарь" : ""}{" "}
                    </span>
                  </>
                )}
              </div>
            </Link>
                    */}

            <Link
              onMouseEnter={hoverHandlerBp}
              onMouseLeave={outHandler}
              style={{ textDecoration: "none" }}
              tabIndex={-1}
              to="/bp"
              onClick={() => {
                sameActionForAllParts();
                //  if (pathname !== "/bp")  setIsFetchingUsers(true);
                localStorage.setItem("clearFilter", "true");
              }}
            >
              <div className={pathname.match("/bp") ? "active" : ""}>
                {hoverPage !== 5 && (
                  <>
                    <Img
                      src={pathname.match("/bp") ? bp_active : bp}
                      title="Типовые бизнес-процессы"
                      alt={"Типовые бизнес-процессы"}
                    />
                    <span
                      style={
                        pathname.match("/bp")
                          ? { color: "#fff" }
                          : { color: "#aaa" }
                      }
                    >
                      {" "}
                      {isLeftBlockBig ? "Бизнес-процессы" : ""}{" "}
                    </span>
                  </>
                )}
                {hoverPage === 5 && (
                  <>
                    <Img
                      src={pathname.match("/bp") ? bp_active : bp_hover}
                      title="Типовые бизнес-процессы"
                      alt={"Типовые бизнес-процессы"}
                    />
                    <span style={{ color: "#fff" }}>
                      {" "}
                      {isLeftBlockBig ? "Бизнес-процессы" : ""}{" "}
                    </span>
                  </>
                )}
              </div>
            </Link>
          </NavigationBlock>
          <LinksBlock>
            <div>
              <a href={require("../Faq/usersManualDoc.pdf")} target="_blanc">
                <img
                  src={pdf_icon_active}
                  title="Руководство пользователя"
                  alt={"Руководство пользователя"}
                />
                <span>{isLeftBlockBig ? "Руководство пользователя" : ""}</span>
              </a>
            </div>
            {/* <div>
              <a
                onClick={() => {
                  // @ts-ignore
                  jivo_api.open();
                  return false;
                }}
              >
                <img
                  src={chat_icon_active}
                  title="Чат техподдержки EASY"
                  alt={"Чат техподдержки EASY"}
                />
                <span>{isLeftBlockBig ? "Чат техподдержки EASY" : ""}</span>
              </a>
            </div>
            <div>
              <a
                href="https://www.youtube.com/watch?v=B34zXHnXU0A"
                target="_blank"
              >
                <img
                  src={youtube_icon_active}
                  title="YouTube"
                  alt={"YouTube"}
                />
                <span>{isLeftBlockBig ? "YouTube" : ""}</span>
              </a>
            </div> */}
          </LinksBlock>

          {/*   
                    <NotificationWindow
                        showNotificationWindow={showNotificationWindow}
                        setShowNotificationWindow={setShowNotificationWindow}
                        notifications={notifications}
                    />*/}
        </FlexBlock>
      </HeaderBlock>
    </WrapperHead>
  );
};

const mapStateToProps = (state: State) => ({
  notifications: state.commonInfo.remote_notifications.unread,
  // show_notification_window: state.commonInfo.show_notification_window,
  selectedTask: state.taskInfoPlate.selectedTask,
  isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
  showFiltersWindow: state.commonInfo.filters.showFiltersWindow,
  filters: state.commonInfo.filters,
  current_user: state.commonInfo.current_user,
  current_filters: state.commonInfo.filters.current_set,
  selectedProjectId: state.projectsPage.selectedProject?.id,
  selectedUserId: state.tasksPage.selectedUserId,
});

const mapDispatchToProps = {
  selectTask,
  setShownTaskInfoPlate,
  setLoadingTasks,
  setIsFetchingUsers,
  setIsFetchingTasks,
  ShowFiltersWindow,
  DefaultFilterTemplates,
  FilterTemplates,
  setFiltersTasks,
  fetchTasks,
  setFilters,
  setDefaultValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
