// Главное окно для добавления новой задачи или редактирования имеющейся
// (смотреть не в NewTask а тут код описан)
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setShownTaskInfoPlate,
  setTaskInfoActiveTab,
  setProjectUsers,
  setComentList,
  setExecutorSchedule,
  fetchProjectSections,
  rewriteWholeTaskList,
  selectTask,
  setTaskName,
  setDefaultValues,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setFlagRepeatTaskToChange,
  updateChecklist,
  updateCommentsList,
  setTaskPriority,
  clearCyclicTaskToChange,
  setCommentText,
  setLabelsData,
} from "./actions";
import { Task, User, Comment, Schedule } from "../../common/types";
import Toggler, { ToggleElem } from "../../common/components/Togglers/Toggler";
import InfoTab from "./components/InfoTab";
import CommentTab from "./components/CommentTab";
import { State } from "../../rootReducer";
import { fetchData } from "../../utils/fetchData";
import "./styles.sass";
import { updateTask } from "../../common/actions";
import TaskStatus from "../../common/components/TaskStatus/TaskStatus";
import DateMaster from "../../utils/DateMaster";
import { TextareaAutosize } from "@material-ui/core";
import styled from "styled-components";
import Icon from "../../common/components/Icon";
import { useHistory } from "react-router-dom";
import { timeUpdate, urlApp } from "../../App";
import { fetchUpdateData } from "../../utils/fetchUpdateData";
import Button from "../../common/components/newComponent/Buttons/Button";
import ContextDropdownMenu from "./components/ContextDropdownMenu";
import { fetchTaskDependence, setFilterWindowShow } from "../Tasks/actions";
import Notifications from "../TaskInfoPlate/components/Notifications";

type Props = {
  isShownTaskInfoPlate: boolean;
  selectedTask: Task | null;
  checkList: any[];
  taskInfoActiveTab: string;
  selectedUserId: number;
  taskName: string;
  users: User[];
  commentList: Comment[];
  setShownTaskInfoPlate: (isShownTaskInfoPlate: boolean) => void;
  setTaskInfoActiveTab: (activeTab: string) => void;
  setProjectUsers: (users: User[]) => void;
  setComentList: (commentList: Comment[]) => void;
  updateTask: (id: number, params: any) => void;
  setExecutorSchedule: (schedule: Schedule) => void;
  fetchProjectSections: (projectId: number) => void;
  // changeCheckboxItem: (orderNumber: string | null, text: string | null, checkboxState: boolean) => void,
  rewriteWholeTaskList: (response: any) => void;
  currentUserId: number | undefined;
  currentUserTopRole: number | undefined;
  selectTask: (val: Task | null) => void;
  setTaskName: (name: string) => void;
  setDefaultValues: () => void;
  flagForActionCyclycTask: string;
  rememberObjectCyclicTaskToChange: {};
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  setCyclicTaskToChange: (val: {}) => void;
  setFlagRepeatTaskToChange: (val: string) => void;
  clearCyclicTaskToChange: () => void;
  labelsData: {
    comments_count: number;
    files_count: number;
  };
  setLabelsData: (comments_count?: number, files_count?: number) => void;
};

const togglerElems: ToggleElem[] = [
  {
    name: "Информация",
    value: "info",
  },
  {
    name: "Комментарии",
    value: "comments",
  },
  // ,{
  //     name: 'История',
  //     value: 'history'
  // }
];

const WrapperTaskInfoPlate = styled.div`
  position: fixed;
  top: 76px;
  right: ${({ isShownTaskInfoPlate }) =>
    isShownTaskInfoPlate ? "0" : "-600px"};
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  background: #ffffff;
  transition: right 0.8s ease;
  z-index: 100;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  color: #212121 !important;
  display: flex;
  width: 584px;
  height: calc(100vh - 76px);
`;

const WrapperNotification = styled.div`
  position: absolute;
  z-index: 1;
  width: 352px;
  /* height: 472px; */
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  top: 40px;
`;

const TaskInfoPlate: React.FC<Props> = (props) => {
  const {
    setShownTaskInfoPlate,
    setTaskInfoActiveTab,
    setProjectUsers,
    setComentList,
    commentList,
    updateTask,
    checkList,
    setExecutorSchedule,
    fetchProjectSections,
    selectedTask,
    selectedUserId,
    users,
    taskInfoActiveTab,
    rewriteWholeTaskList,
    currentUserId,
    currentUserTopRole,
    selectTask,
    setTaskName,
    setDefaultValues,
    isShownTaskInfoPlate,
    taskName,
    flagForActionCyclycTask,
    rememberObjectCyclicTaskToChange,
    sendReqToChangeTemplRepTask,
    setCyclicTaskToChange,
    setFlagRepeatTaskToChange,
    clearCyclicTaskToChange,
    labelsData,
    setLabelsData,
  } = props;
  const { timestamp } = useSelector((state: State) => state.commonInfo);
  const { dependencesTask } = useSelector(
    (state: State) => state.taskInfoPlate
  );
  const [notification, setNotification] = useState(false);
  const templateChecklist = [...checkList];
  const templateСommentList = [...commentList];
  const [titleStyle, setTitleStyle] = useState({});

  const [showContextDropDown, setShowContextDropDown] = useState(false);

  // self-close for window
  let plate = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (selectedTask?.id && isShownTaskInfoPlate)
      dispatch(fetchTaskDependence(selectedTask.id));
  }, [selectedTask?.id, isShownTaskInfoPlate]);

  const outsideClickHandlerDropdownMenu = useCallback(
    function (e: Event): any {
      let Element = e.target as Element;
      if (Element.closest(".ThreeDotsDropdownMenu"))
        //*** if (!Element.closest('.ThreeDotsDropdownMenu'))
        setShowContextDropDown(false);
    },
    [setFilterWindowShow]
  );
  useEffect(() => {
    if (showContextDropDown) {
      document.addEventListener("click", outsideClickHandlerDropdownMenu);
      return () =>
        document.removeEventListener("click", outsideClickHandlerDropdownMenu);
    }
  }, [showContextDropDown]);
  // self-close for window

  const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    if (selectedTask) {
      setTitleStyle({
        background: "white",
        borderColor: "white",
        borderRadius: "6px",
        fontSize: "20px",
        color: "#212121",
        padding: "0",
        height: "50px",
      });
    } else {
      setTitleStyle({
        background: "FFF",
        borderRadius: "6px",
        padding: "12px",
        height: "50px",
        color: "#212121",
      });
    }
  }, [selectedTask]);

  // при открытии должен ходить запрос на получение чек-листа. Изначально задача чек-лист в себе не хранит.
  useEffect(() => {
   
    (async () => {
      if (flagForActionCyclycTask !== "") setFlagRepeatTaskToChange(""); // обнуляю определенное значение для подтверджения изменения повторящейся задачи.

      // setTaskName('');

      if (selectedTask) setShownTaskInfoPlate(true);
      const taskId = selectedTask?.id;
      if (!taskId) return;
      let checklistForTask = await fetchData.get(
        `/api/v1/tasks/${taskId}/checklists`
      );
      if (checklistForTask) {
        // когда слишком много кликаешь с задачи на задачу, то выпадает ошибка в снекбаре too many attempts - и сервер вместо нормального ответа присылает undefined. Чтобы все не крашнулось, есть это условие.
        checklistForTask = Object.values(checklistForTask);
        rewriteWholeTaskList(checklistForTask);
      }

      // информация по лейблам о комментах и атачментах
      const executor_id = selectedTask?.executor_id;
      if (!executor_id) return;
      const data = await fetchData.get(
        `/api/v1/tasks/${selectedTask?.id}/info`
      );
   //   console.log("data*****************",data)
      setLabelsData(0, 0);
     if (data) {
     let comments = data?.comments_count;
     let files_count = data?.files_count;
     setLabelsData(comments, files_count);

    //  console.log("labelsData",labelsData);
    }
     
    })();
    // Do something
    // setTaskName(selectedTask?.name || '');
  }, [selectedTask?.id]);

  useEffect(() => {
    // запрашивать список юзеров по проекту
    (async () => {
      const projectId = selectedTask?.project_id;
      if (!projectId) return;
      let usersForProject = await fetchData.get(
        `/api/v1/projects/${projectId}/users`
      );
      if (usersForProject) {
        // когда слишком много кликаешь с задачи на задачу, то выпадает ошибка в снекбаре too many attempts - и сервер вместо нормального ответа присылает undefined. Чтобы все не крашнулось, есть это условие.
        usersForProject = Object.values(usersForProject);
        let usersList = users.filter((user) =>
          usersForProject.includes(user.id)
        );
        setProjectUsers(usersList);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedTask?.id, setProjectUsers, users])
  }, [setProjectUsers, users]);

  useEffect(() => {
    (async () => {
      const taskId = selectedTask?.id;
      if (!taskId) return;
      const commentList = await fetchData.get(
        `/api/v1/tasks/${taskId}/comments?order=created_at&page=1&orderType=desc`
      );
      if (commentList) {
        setComentList(commentList);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedTask!.id, setComentList])
  }, [setComentList]);

  useEffect(() => {
    (async () => {
      const executor_id = selectedTask?.executor_id;
      if (!executor_id) return;
      const data = await fetchData.get(`/api/v1/users/${executor_id}/schedule`);
      if (data?.schedule) {
        setExecutorSchedule(data.schedule);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedTask) fetchProjectSections(selectedTask.project_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTask?.project_id]);

  const dateMaster = useMemo(() => {
    if (selectedTask) return new DateMaster(selectedTask?.end);
  }, [selectedTask]);

  // self-close for window
  // const outsideClickHandler = useCallback(
  //   function (e: Event): any {
  //     let targetAsElement = e.target as Element;
  //     // thisIsTaskTr
  //     // for (let i = 0; i < targetAsElement.classList.length; i++) {
  //     //     if (targetAsElement.classList[i] === 'task_tr') thisIsTaskTr = true
  //     // }

  //     // если у него нету родителя ".task_info_plate" и это не другой таск
  //     // if (
  //     //   !targetAsElement.closest('.task_info_plate') &&
  //     //   !targetAsElement.closest('.tasks_table_content') &&
  //     //   !targetAsElement.closest('.MuiDialog-root')
  //     // ) {
  //     //    setTimeout(() => setShownTaskInfoPlate(false), 100)
  //     // }
  //   },
  //   [setShownTaskInfoPlate]
  // );

  // useEffect(() => {
  //   document.addEventListener('click', outsideClickHandler);
  //   return () => document.removeEventListener('click', outsideClickHandler);
  // }, [outsideClickHandler]);

  //  отслеживает подтвердждения изменений повторяющихся задач
  useEffect(() => {
    if (selectedTask && Object.keys(rememberObjectCyclicTaskToChange).length) {
      updateTask(selectedTask.id, rememberObjectCyclicTaskToChange);
      if (flagForActionCyclycTask !== "task")
        sendReqToChangeTemplRepTask(rememberObjectCyclicTaskToChange);
      clearCyclicTaskToChange();
    }
  }, [flagForActionCyclycTask]);

  useEffect(() => {
    let intervalFetch;
    if (timestamp) {
      if (selectedTask) {
        intervalFetch = setInterval(() => {
          fetchUpdateData(timestamp, "checklistAndComments", [selectedTask?.id])
            ?.then((data) => {
              if (data.tasks_checklists?.length > 0) {
                data.tasks_checklists.forEach((updateCheck) => {
                  if (templateChecklist.length === 0) {
                    templateChecklist.push(updateCheck);
                  } else {
                    for (let i = 0; i < templateChecklist.length; i++) {
                      if (updateCheck.hasOwnProperty("create")) {
                        templateChecklist.push(updateCheck);
                        break;
                      } else if (
                        templateChecklist[i].id === updateCheck.id &&
                        updateCheck.hasOwnProperty("delete")
                      ) {
                        templateChecklist.splice(i, 1);
                        break;
                      } else if (
                        templateChecklist[i].id === updateCheck.id &&
                        !updateCheck.hasOwnProperty("delete") &&
                        !updateCheck.hasOwnProperty("create")
                      ) {
                        templateChecklist.splice(i, 1, updateCheck);
                        break;
                      }
                    }
                  }
                });
                dispatch(updateChecklist(templateChecklist));
              }
              return data;
            })
            .then((data) => {
              if (data.tasks_comments?.length > 0) {
                data.tasks_comments.forEach((updateComment) => {
                  if (templateСommentList.length === 0) {
                    templateСommentList.unshift(updateComment);
                  } else {
                    for (let i = 0; i < templateСommentList.length; i++) {
                      if (
                        updateComment.hasOwnProperty("create") ||
                        (templateСommentList.length === i + 1 &&
                          templateСommentList[i].id !== updateComment.id)
                      ) {
                        templateСommentList.unshift(updateComment);
                        break;
                      } else if (
                        templateСommentList[i].id === updateComment.id &&
                        updateComment.hasOwnProperty("delete")
                      ) {
                        templateСommentList.splice(i, 1);
                        break;
                      } else if (
                        templateСommentList[i].id === updateComment.id &&
                        !updateComment.hasOwnProperty("delete") &&
                        !updateComment.hasOwnProperty("create")
                      ) {
                        templateСommentList.splice(i, 1, updateComment);
                        break;
                      }
                    }
                  }
                });

                dispatch(updateCommentsList(templateСommentList));
              }
            })
            .catch((error) => console.warn(error));
        }, timeUpdate);
      }
      // else if (taskInfoActiveTab === 'comments') {
      //   intervalFetch = setInterval(() => {
      //     fetchUpdateData(timestamp, 'comments', [selectedTask?.id])
      //       ?.then(({ tasks_comments }) => {
      //         if (tasks_comments?.length > 0) {
      //           tasks_comments.forEach((updateComment) => {
      //             if (templateСommentList.length === 0) {
      //               templateСommentList.unshift(updateComment);
      //             } else {
      //               for (let i = 0; i < templateСommentList.length; i++) {
      //                 if (
      //                   updateComment.hasOwnProperty('create') ||
      //                   (templateСommentList.length === i + 1 &&
      //                     templateСommentList[i].id !== updateComment.id)
      //                 ) {
      //                   templateСommentList.unshift(updateComment);
      //                   break;
      //                 } else if (
      //                   templateСommentList[i].id === updateComment.id &&
      //                   updateComment.hasOwnProperty('delete')
      //                 ) {
      //                   templateСommentList.splice(i, 1);
      //                   break;
      //                 } else if (
      //                   templateСommentList[i].id === updateComment.id &&
      //                   !updateComment.hasOwnProperty('delete') &&
      //                   !updateComment.hasOwnProperty('create')
      //                 ) {
      //                   templateСommentList.splice(i, 1, updateComment);
      //                   break;
      //                 }
      //               }
      //             }
      //           });

      //           dispatch(updateCommentsList(templateСommentList));
      //         }
      //       })
      //       .catch((error) => console.warn(error));
      //   }, timeUpdate);
      // }
    }
    return () => {
      clearInterval(intervalFetch);
    };
  }, [timestamp, taskInfoActiveTab, templateChecklist, templateСommentList]);

  //**************************************************** */
  const inputEl = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    // console.log("***")
    if (inputEl && inputEl.current) {
      inputEl.current.focus();
    }
  }, [isShownTaskInfoPlate]);
  //**************************************************** */

  // console.log("selectedTask",selectedTask);

  return (
    //<WrapperTaskInfoPlate>
    <WrapperTaskInfoPlate
      isShownTaskInfoPlate={isShownTaskInfoPlate}
      className={`task_info_plate`}
    >
      <div className="task_name">
        <Icon
          style={{ marginBottom: "8px" }}
          name="cross"
          onClick={() => {
            setDefaultValues();
            setShowContextDropDown(false);
            setShownTaskInfoPlate(false);
            history.location.pathname.includes("tasks") &&
              history.replace(`${selectedUserId}`);
          }}
        />
        {/* Выводим предупреждение о важных полях, помеченных звездочкой- их надо заполнить, иначе не получится сохранить задачу */}
        <br />
        <span> * - обязательные для заполнения поля </span>
        <br /> <br />
        {/*disabled={currentUserId === selectedTask.author_id ? '' : (currentUserTopRole === 1 ? '' : 'disabled')}*/}
        {/*
        
        Чтобы при открытии задачи курсор позиционировался на наименование задачи:

          Добавлено:
          ref={inputEl}

          и, выше:
          
          const inputEl = useRef<HTMLTextAreaElement>(null); 

         useEffect(()=>{
         //  console.log("***")
           if(inputEl && inputEl.current) {
           inputEl.current.focus();
          } 
          },[isShownTaskInfoPlate])
        
        */}
        <TextareaAutosize
          disabled={selectedTask && selectedTask.id_bp !== null ? true : false}
          ref={inputEl}
          id={"rightPlate_name_textarea"}
          aria-label=""
          placeholder="Введите название*"
          onChange={(e) => {
            setTaskName(e.currentTarget.value);
          }}
          className={
            currentUserId === selectedTask?.author_id
              ? ""
              : currentUserTopRole === 1
              ? ""
              : "disabled"
          }
          style={titleStyle}
          // disabled={currentUserId === selectedTask.author_id ? false : (currentUserTopRole !== 1)}
          onBlur={(e) => {
            if (selectedTask) {
              // setEditDescMode(false)
              if (e.target.value !== selectedTask?.name) {
                // если новое значение отличается от начального
                // мгновенно обновляю значение для заколлапсированного состояния
                // setRenewValueDesc(e.target.value)
                // send value to Saga

                if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                  // сравнение одного с несколькими
                  // эта задача вообще циклическая или сама по себе ?
                  // значит циклическая
                  // if (flagForActionCyclycTask === '') {
                  setCyclicTaskToChange({ name: e.target.value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                  // } else if (flagForActionCyclycTask === 'task') {
                  //   updateTask(selectedTask!.id, { name: e.target.value });
                  // } else {
                  //   updateTask(selectedTask!.id, { name: e.target.value });
                  //   sendReqToChangeTemplRepTask({ name: e.target.value });
                  // }
                } else updateTask(selectedTask!.id, { name: e.target.value });
              }
            } else {
              // console.log('II');
              setTaskName(e.target.value);
            }
          }}
          value={taskName}
        />
      </div>

      {selectedTask && (
        <div className="tabs_desc">
          {notification && (
            <WrapperNotification>
              <Notifications />
            </WrapperNotification>
          )}

          <div className={"first_row"} style={{ position: "relative" }}>
            <Toggler
              elemsList={togglerElems}
              selectHandler={(active) => {
                if (active === "info") {
                  dispatch(setCommentText(""));
                }
                setTaskInfoActiveTab(active as string);
              }}
              activeValue={taskInfoActiveTab}
            />

            <div className={"iconsBar"}>
              <div className={"attachments"}>
                <div className={"count"}>{labelsData.files_count}</div>
                <div className={"icon"} />
              </div>
              <div className={"comments"}>
                <div className={"count"}>{labelsData.comments_count}</div>
                <div className={"icon"} />
              </div>
              <div
                className={"bell"}
                onClick={() => {
                  setNotification(!notification);
                }}
              />
            </div>

            {/*если статус в работе и дедлайн прошел - он подменяется на просрочено*/}
            {selectedTask && (
              <TaskStatus
                id={
                  dateMaster &&
                  selectedTask.status_id === 10 &&
                  new Date(
                    dateMaster.year,
                    dateMaster.month,
                    dateMaster.day,
                    dateMaster.hours,
                    dateMaster.minutes
                  ).getTime() <= new Date(Date.now()).getTime()
                    ? 14
                    : selectedTask.status_id
                  // 14
                }
                taskId={selectedTask.id}
                /*  disabled={
                  
                  currentUserId === selectedTask.author_id ||
                  currentUserId === selectedTask.executor_id
                    ? false
                    : currentUserTopRole !== 1
                }*/
                disabled={
                  selectedTask && selectedTask.id_bp !== null ? true : false
                }
              />
            )}

            {/* Тут описывается кнопка выбора дополнительного меню (например, для создания подзадач) */}
            <Button
              onClick={() => {
                setTaskInfoActiveTab("info" as string); // При клике на кнопку перенаправляем на вкладку "Инфо"
                // setTaskInfoActiveTab - функция, переданная из state в props, которая изменяет переменную taskInfoActiveTab
                // Если activeValue= "info" - открывается главная вкладка задачи
                // Если activeValue= "comments" - открывается вкладка с комментариями
                setShowContextDropDown(!showContextDropDown); // делаем кнопку видимой/скрываем при клике на нее
              }}
              icon={"3dots"}
              design={"outline"}
            />
            {/* тут запускается дополнительное меню (например, для создания подзадач) */}
            {showContextDropDown &&
              selectedTask &&
              selectedTask.id_bp === null && <ContextDropdownMenu />}
          </div>
        </div>
      )}

      {/* Тут выбирается какую вкладку запускать- info или comment */}
      {taskInfoActiveTab === "info" && <InfoTab />}
      {taskInfoActiveTab === "comments" && <CommentTab />}
    </WrapperTaskInfoPlate>
    // </WrapperTaskInfoPlate>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentUserId: state.commonInfo.currentUserInfo?.id,
    checkList: state.taskInfoPlate.checkList,
    selectedUserId: state.tasksPage.selectedUserId,
    currentUserTopRole:
      state.commonInfo.currentUserInfo?.roles &&
      state.commonInfo.currentUserInfo?.roles[0],
    selectedTask: state.taskInfoPlate.selectedTask,
    taskName: state.taskInfoPlate.name,
    users: state.commonInfo.users,
    taskInfoActiveTab: state.taskInfoPlate.taskInfoActiveTab,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
    rememberObjectCyclicTaskToChange:
      state.taskInfoPlate.rememberObjectCyclicTaskToChange,
    commentList: state.taskInfoPlate.commentList,
    labelsData: state.taskInfoPlate.labelsData,
  };
};

const mapDispatchToProps = {
  setShownTaskInfoPlate,
  setTaskInfoActiveTab,
  setProjectUsers,
  setComentList,
  updateTask,
  setExecutorSchedule,
  fetchProjectSections,
  rewriteWholeTaskList,
  selectTask,
  setTaskName,
  setDefaultValues,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setFlagRepeatTaskToChange,
  clearCyclicTaskToChange,
  setLabelsData,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(TaskInfoPlate);
