import React, {useCallback, useState, useRef, useEffect} from 'react';
import AddButton from '../../../common/components/Buttons/AddButton';
// import DisplayTypeSelect from '../../../common/components/Selects/DisplayTypeSelect'
import UserProjectSelect from '../../../common/components/Selects/UserProjectSelect';
import {Project, SelectData, Section, Task} from '../../../common/types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getProjects} from '../../../common/selectors';
import {State} from '../../../rootReducer';

import DotsButton from '../../../common/components/Buttons/DotsButton';
import {openExistingProject} from '../../NewProject/actions';
import {useHistory, useParams} from 'react-router-dom';
import {fetchData} from '../../../utils/fetchData';
import TableSearch from '../../../common/components/TableSearch/TableSearch';

import FilterButton from '../../../common/components/Buttons/filterButton';
import ViewWeekRoundedIcon from '@material-ui/icons/ViewWeekRounded';
import ProjectsFilters from '../../Projects/components/ProjectFilters';
import ColumnsWindow from '../../../common/ColumnsCustomWindow';
import Button from '../../../common/components/newComponent/Buttons/Button';
import Toggler from '../../../common/components/Togglers/Toggler';
import {
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    ShowFiltersWindow,
} from '../../../common/actions';
import FiltersWindow from '../../../common/FiltersWindow';
import Icon from '../../../common/components/Icon';
import {cookieMaster} from '../../../utils/CookieMaster';
import {urlApp} from '../../../App';
import {setNewParametersToReducer} from "../actions";
import styled from "styled-components";
import Rules from "./Rules";
import Tasks from "./Tasks";
import Ideas from "./Ideas";

type Props = {
    projects: Project[];
    sections: Section[];
    selectedProject: Project | null;
    openExistingProject: (project: Project) => void;
    currentUserRoles: number[] | undefined;
    tableFilter: string;
    projectId: number | null;
    appliedFiltersExist: boolean;
    showColumnsWindow: boolean;
    showFiltersWindow: boolean;
    tableOrTreeToggler: string;
    ShowFiltersWindow: () => void;
    current_user: number;
    filters: any;
    FilterTemplates: (data: {}) => void;
    DefaultFilterTemplates: (data) => void;
    setFilters: (
        data_from?: string,
        date_to?: string,
        executors?: number[],
        authors?: number[],
        statuses?: number[],
        actual?: boolean
    ) => void;
    // businessProcessesModalShow: (show: boolean) => void;
    setNewParametersToReducer: (obj: object) => void,
    reducerRules: any[],
    meetingTasks: any[],
    meetingIdeas: any[]
};


const IconsBarItem = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 36px;
  margin-right: 8px;
`;

const ProjectActions: React.FC<Props> = ({
                                             showFiltersWindow,
                                             showColumnsWindow,
                                             projectId,
                                             projects,
                                             selectedProject,
                                             openExistingProject,
                                             currentUserRoles,
                                             tableOrTreeToggler,
                                             ShowFiltersWindow,
                                             filters,
                                             setNewParametersToReducer,
                                             reducerRules,
                                             meetingTasks,
                                             meetingIdeas
                                         }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [timer, setTimer] =
        useState<ReturnType<typeof setTimeout> | null>(null);
    // создал изолированную копию объекта, чтобы не перезаписать пропс
    const newRolesArr = currentUserRoles?.map((item) => {
        return item;
    });
    newRolesArr?.sort();

    // const [showMenu, setShow] = useState<boolean>(false);
    const [resToggler, setResToggler] = useState<number>(0);

    const {id} = useParams();
    const menu = useRef<HTMLDivElement>(null);
    const tasksOrder = useSelector(
        (state: State) => state.projectsPage.tasksOrder
    );
    // const outsideClick = useCallback((e: Event) => {
    // if (!menu.current?.contains(e.target as HTMLElement)) setShow(false);
    // }, []);

    // useEffect(() => {
    //   document.addEventListener('click', outsideClick);
    //   return () => document.removeEventListener('click', outsideClick);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    let company_id = localStorage.getItem('company_id');


    // console.log(showFiltersWindow)
    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Toggler
                    elemsList={[{name: 'Правила', id: 0}, {name: 'Задачи', id: 1}, {name: 'Идеи', id: 2}]}
                    selectHandler={active => setResToggler(active as number)}
                    activeValue={resToggler}
                />
                <div style={{display: 'flex'}}>
                    <IconsBarItem>
                        <div style={{marginRight: '4px'}}>{reducerRules.length}</div>
                        <Icon name={'i_rounded'}/>
                    </IconsBarItem>
                    <IconsBarItem>
                        <div style={{marginRight: '4px'}}>{meetingTasks.length}</div>
                        <Icon name={'list_rounded'}/>
                    </IconsBarItem>
                    <IconsBarItem>
                        <div style={{marginRight: '4px'}}>{meetingIdeas.length}</div>
                        <Icon name={'lamp_on'}/>
                    </IconsBarItem>
                </div>
                {/*{showFiltersWindow && <ProjectsFilters/>}*/}
                {/*{showColumnsWindow && <ColumnsWindow context={'projects'}/>}*/}
                {/*{showFiltersWindow && <FiltersWindow context={'projects'}/>}*/}
            </div>


            {resToggler === 0 && <Rules/>}
            {resToggler === 1 && <Tasks/>}
            {resToggler === 2 && <Ideas/>}
        </div>
    );
};

const mapStateToProps = (state: State) => {
    return {
        appliedFiltersExist: Boolean(
            Object.keys(state.projectsPage.filters.appliedFilters).length
        ),
        projects: getProjects(state),
        // selectData: getProjectSelectData(state),
        selectedProject: state.projectsPage.selectedProject,
        sections: state.commonInfo.sections,
        // showFiltersWindow: state.projectsPage.filtersWindowShow,
        showFiltersWindow: state.commonInfo.filters.showFiltersWindow,
        currentUserRoles: state.commonInfo.currentUserInfo?.roles,
        tableFilter: state.projectsPage.tableFilter,
        projectId: state.projectsPage.selectedProject?.id || null,
        showColumnsWindow: state.projectsPage.showColumnsWindow,
        tableOrTreeToggler: state.projectsPage.tableOrTreeToggler,
        current_user: state.commonInfo.current_user,
        filters: state.commonInfo.filters,
        reducerRules: state.meetingsPage.meetingRules,
        meetingTasks: state.meetingsPage.meetingTasks,
        meetingIdeas: state.meetingsPage.meetingIdeas
    };
};

const mapDispatchToProps = {
    // fetchTasks,
    openExistingProject,
    ShowFiltersWindow,
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    setNewParametersToReducer
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectActions);
