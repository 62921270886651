import {
  types,
  fetchTasksAction,
  changeTaskListAction,
  setTaskAddModeAction,
  setFilterAddModeAction,
  setLoadingTasksAction,
  setSelectedUserIdAction,
  setTableFilterAction,
  setShowVisulaizationAction,
  setShowDaysOffAction,
  setStatusFilterAction,
  setFilterDraftAction,
  setFilterAddNewRow,
  changeVisualisationListAction,
  setAllOrActualAction,
  setTableOrderAction,
  setShowColumnsCustomAction,
  setShowableColumns,
  setExecAuthAction,
  loadSpecificTaskAction,
  fetchTasksVisualizationAction,
  clearVisualizationLish,
  notificationsForTasksAction,
  readNotificationByTaskAction,
  procedureFinishTask,
  timeSheetActionsAction,
  closingDayAction,
  LocalTimeSheetRecordAction,
  uploadFileAction,
  deleteFileAction,
  sendCommentAction,
  timesheetCacheRecordAction,
  closingDayDispatchAllAction,
  fetchTaskDependenceAction,
  closingDayHidingSignalAction,
  updateStatusActionTrue,   // 571 задача redmine *********************************   
  updateStatusActionFalse   // 571 задача redmine *********************************   
} from './action_types';
import { File, Task } from '../../common/types';

// 571 задача redmine ********************************* 
export const updateStatusWindowTrue = (statusWindow: boolean): updateStatusActionTrue => ({
  type: types.UPDATE_STATUS_WINDOW_TRUE,
  statusWindow,
});
//************************************************* */

// 571 задача redmine ********************************* 
export const updateStatusWindowFalse = (statusWindow: boolean): updateStatusActionFalse => ({
  type: types.UPDATE_STATUS_WINDOW_FALSE,
  statusWindow,
});
//************************************************* */




export const fetchTasks = (pageNum: number): fetchTasksAction => ({
  type: types.FETCH_TASKS,
  pageNum,
});

export const setFiltersTasks = (
  filterObject: Object
): setStatusFilterAction => ({
  type: types.SET_FILTERS,
  filterObject,
});

export const changeTaskList = (tasks: Task[]): changeTaskListAction => ({
  type: types.CHANGE_TASK_LIST,
  tasks,
});

export const fetchVisualizationList = (
  selectedUserId: number
): fetchTasksVisualizationAction => ({
  type: types.FETCH_TASKS_VISUALIZATION,
  selectedUserId,
});

export const fetchTaskDependence = (id: number): fetchTaskDependenceAction => ({
  type: types.FETCH_TASK_DEPENDENCE,
  id,
});

export const changeVisualisationList = (
  tasks: Task[]
): changeVisualisationListAction => ({
  type: types.CHANGE_VISUALISATION_LIST,
  tasks,
});

export const clearVisualizationList = (): clearVisualizationLish => ({
  type: types.CLEAR_VISUALIZATION_LIST,
});

export const setTaskAddMode = (mode: boolean): setTaskAddModeAction => ({
  type: types.SET_TASK_ADD_MODE,
  mode,
});

export const setFilterWindowShow = (mode: boolean): setFilterAddModeAction => ({
  type: types.SET_FILTER_WIN_MODE,
  mode,
});

export const setDraftFilter = (draft: number): setFilterDraftAction => ({
  type: types.SET_DRAFT_VALUE,
  draft,
});

export const setAddNewRow = (value: boolean): setFilterAddNewRow => ({
  type: types.SET_FILTERS_ADD_NEW_ROW,
  value,
});

export const setLoadingTasks = (isLoading: boolean): setLoadingTasksAction => ({
  type: types.SET_LOADING_TASK,
  isLoading,
});

export const setTableFilter = (filter: string): setTableFilterAction => ({
  type: types.SET_TABLE_FILTER,
  filter,
});

export const setSelectedUserId = (id: number): setSelectedUserIdAction => ({
  type: types.SET_SELECTED_USER_ID,
  id,
});

export const setShowVisulaization = (
  show: boolean
): setShowVisulaizationAction => ({
  type: types.SET_SHOW_VISUALIZATION,
  show,
});

export const setShowColumnsCustom = (
  show: boolean
): setShowColumnsCustomAction => ({
  type: types.SET_SHOW_COLUMNS_WINDOW,
  show,
});

export const setShowDaysOff = (show: boolean): setShowDaysOffAction => ({
  type: types.SET_SHOW_DAYS_OFF,
  show,
});

export const ActualToggleAction = (value: object): setAllOrActualAction => ({
  type: types.SET_ALL_OR_ACTUAL,
  value,
});

export const SetExecAuth = (val: string): setExecAuthAction => ({
  type: types.SET_EXEC_AUTH,
  val,
});

export const setOrder = (
  col_name: string,
  order_direct: boolean
): setTableOrderAction => ({
  type: types.SET_TABLE_ORDER,
  col_name,
  order_direct,
});

export const setShowCols = (value: string): setShowableColumns => ({
  type: types.SET_SHOWING_COLS,
  value,
});

export const loadSpecificTask = (value: number): loadSpecificTaskAction => ({
  type: types.LOAD_SPECIFIC_TASK,
  value,
});

export const notificationsForTasks = (
  value: any[]
): notificationsForTasksAction => ({
  type: types.NOTIFICATIONS_FOR_TASKS,
  value,
});

export const readNotificationByTask = (
  id_task: number
): readNotificationByTaskAction => ({
  type: types.READ_NOTIFICATION_BY_TASK,
  id_task,
});

export const launchProcedureFinishTask = (
  show: boolean,
  taskId: number,
  short_version: boolean
): procedureFinishTask => ({
  type: types.TASK_DONE_CONFIRMATION,
  show,
  taskId,
  short_version,
});

export const timeSheetActions = (
  actionType: string,
  taskId: number,
  value: number,
  timesheetId?: number
): timeSheetActionsAction => ({
  type: types.TIME_SHEET_ACTIONS,
  actionType,
  value,
  taskId,
  timesheetId,
});

export const LocalTimeSheetRecord = (
  actionType: string,
  taskId: number,
  task_load_sum?: number | null,
  timesheetId?: number | null,
  status_id?: number | null,
  commentText?: string,
  commentFiles?: File[]
): LocalTimeSheetRecordAction => ({
  type: types.LOCAL_TIMESHEET_RECORD,
  actionType, // add, clear_all
  taskId, //  number
  task_load_sum, // number
  status_id, //number

  commentText, // str
  commentFiles, // File

  timesheetId, // number
});

export const closingDay = (value: boolean): closingDayAction => ({
  type: types.CLOSING_DAY,
  value,
});

export const closingDayHidingSignal = (value: boolean): closingDayHidingSignalAction => ({
  type: types.CLOSING_DAY_HIDING_SIGNAL,
  value,
});

export const addFileToTimesheet = (file: File): uploadFileAction => ({
  type: types.UPLOAD_FILE_TASKS,
  file,
});

export const deleteFile = (
  attachmentId: number,
  taskId?: number
): deleteFileAction => ({
  type: types.DELETE_FILE,
  attachmentId,
  taskId,
});

export const sendComment = (
  task_Id?: number,
  commentText?: string
): sendCommentAction => ({
  type: types.SEND_COMMENT,
  task_Id,
  commentText,
});

export const closingDayDispatchAll = (): closingDayDispatchAllAction => ({
  type: types.CLOSING_DAY_DISPATCH_ALL,
});

export const timesheetCacheRecord = (
  actionType: string,
  task_id?: number,
  task_load_sum?: number,
  status_id?: number
): timesheetCacheRecordAction => ({
  type: types.TIMESHEET_CACHE_RECORD,
  actionType,
  task_id,
  task_load_sum,
  status_id,
});
