import { takeEvery, all, call, put } from "redux-saga/effects";
import {
  types,
  updateTaskAction,
  fetchExistingUserDataAction,
  setUserPositionsAction,
} from "./action_types";
import { fetchData } from "../utils/fetchData";
import { store } from "../App";
import { changeTaskList, fetchVisualizationList } from "../pages/Tasks/actions";
import { selectTask, setTaskPriority } from "../pages/TaskInfoPlate/actions";
import {
  changeTaskList as changeProjectTaskList,
  fetchTasks,
} from "../pages/Projects/actions";
import { CreateNotif } from "../utils/createNotification";
import {
  openExistingUser,
  setUserSchedule,
  setUserWorkBegin,
  setUserWorkEnd,
  setUserDaysOff,
} from "../pages/NewUser/actions";
import { setUserAddMode } from "../pages/Users/actions";
import {
  setCurrentUserInfo,
  change_remote_notifications,
  setUserPositions,
} from "./actions";
import { User } from "./types";
import { da } from "date-fns/locale";
import { cookieMaster } from "../utils/CookieMaster";

export function* watchFetchCommonInfo() {
  yield takeEvery(types.FETCH_COMMON_INFO, fetchCommonInfo);
}
export function* watchFetchUserPositions() {
  yield takeEvery(types.FETCH_USER_POSITIONS, fetchUserPositions);
}

export function* watchUpdateTask() {
  yield takeEvery(types.UPDATE_TASK, updateTask);
}

export function* watchFetchExistingUser() {
  yield takeEvery(types.FETCH_EXISTING_USER_DATA, fetchExistingUser);
}

export function* watchNotificationsRead() {
  // @ts-ignore
  yield takeEvery(types.SET_NOTIFICATION_READ, setNotifRead);
}

export function* watchLoadNextPage() {
  // @ts-ignore
  yield takeEvery(types.SET_LOAD_NEXTPAGE_NOTIFICATIONS, loadNextpage);
}

export function* watchClearNotifications() {
  yield takeEvery(types.CLEAR_NOTIFICATIONS, clearNotifications);
}

async function getAllProjects() {
  let page = 1,
    projects: any = [];
  while (true) {
    let data: any = await fetchData.get(`/api/v1/projects?page=${page}`);
    if (data) {
      projects = projects.concat(data["data"]);
      if (!data?.links?.next) break;
      page++;
    }
  }
  return projects;
}

async function getAllSections() {
  let page = 1,
    sections: any = [];
  while (true) {
    let data: any = await fetchData.get(`/api/v1/projectsections?page=${page}`);
    if (data) {
      sections = sections.concat(data["data"]);
      if (!data?.links?.next) break;
      page++;
    }
  }
  return sections;
}

async function getCommonUsers() {
  let page = 1,
    users: User[] = [];
  while (true) {
    let data: User[] = await fetchData.get(
      `/api/v1/users?page=${page}&order=surname&ordertype=desc`
    );

    if (data) {
      users = users.concat(data);
      if (data.length < 20) break;
      page++;
    }
  }

  return users;
}

function* fetchCommonInfo() {
  let currentUserId = store.getState().commonInfo.current_user;
  let companyList = yield call(
    fetchData.get,
    `/api/v1/users/${currentUserId}/companies`
  );

  let company_id;
  if (localStorage.getItem("company_id")) {
    company_id = localStorage.getItem("company_id");
    if (!companyList.includes(parseInt(company_id))) {
      localStorage.setItem("company_id", companyList[0]);
      // обновляется айдишник компании в заголовке для всех запросов, осуществляемых через fetchData
      fetchData.addHeaders({
        "company-id": `${localStorage.getItem("company_id")}`,
      });
    }
  }

  const {
    projects,
    // company,
    users,
    statuses,
    roles,
    positions,
    departments,
    priorities,
    workgroups,
    sections,
    read_notifs,
    unread_notifs,
  } = yield all({
    projects: call(getAllProjects),
    // company: call(getAllCompanies),
    users: call(getCommonUsers), //костыль, надо сделать правильно!!!
    statuses: call(fetchData.get, "/api/v1/statuses"),
    roles: call(fetchData.get, "/api/v1/roles"),
    positions: call(fetchData.get, `/api/v1/positions?limit=-1`),
    departments: call(fetchData.get, `/api/v1/departments?limit=-1`),
    priorities: call(fetchData.get, "/api/v1/priorities"),
    workgroups: call(fetchData.get, "/api/v1/workgroups"),
    sections: call(getAllSections),
    unread_notifs: call(
      fetchData.get,
      `/api/v1/users/${currentUserId}/unread-notifications?order=created_at&offset=0&limit=10&type=system&orderType=desc`
    ),
    read_notifs: call(
      fetchData.get,
      `/api/v1/users/${currentUserId}/read-notifications?order=created_at&offtset=0&limit=10&type=system&orderType=desc`
    ),
  });
  yield put(setTaskPriority(priorities[0]?.id));

  let currentUser = users.find((user) => user.id === currentUserId);
  if (!currentUser)
    currentUser = yield call(fetchData.get, `/api/v1/users/${currentUserId}`);

  let company = {};

  for (let i = 0; i < companyList.length; i++) {
    yield fetchData.get(`/api/v1/companies/${companyList[i]}`).then((data) => {
      // if (!company) company = {}
      company[companyList[i]] = data;
    });
  }

  // const commentListResponse = await fetchData.get(
  //     `/api/v1/pricing_plans/${companyInfo.id}`
  // );
  // setPricing_plans(commentListResponse)
  //
  // const commentListRespons1e = await fetchData.get(
  //     `/api/v1/account/info`
  // );
  // setAccountInfo(commentListRespons1e)

  if (currentUser) {
    yield put(setCurrentUserInfo(currentUser));
  }

  // sorting statuses by name
  let sortedStatuses = [];
  Object.keys(statuses)
    .map((status) => statuses[status].name)
    .sort()
    .forEach((status_name) => {
      Object.keys(statuses).forEach((source_status) => {
        if (status_name === statuses[source_status]["name"])
          // @ts-ignore
          sortedStatuses.push(statuses[source_status]);
      });
    });

  yield put({
    type: types.ADD_COMMON_INFO,
    projects,
    users,
    statuses: sortedStatuses,
    roles,
    positions,
    departments,
    priorities,
    workgroups,
    sections,
    company,
    unread_notifications: unread_notifs,
    read_notifications: unread_notifs.length < 10 ? read_notifs : [],
    load_next: unread_notifs.length < 10 ? "read" : [],
    end: unread_notifs < 10 && read_notifs.length < 10,
  });
  // yield put(selectProject(projects[0]))
}

function* updateTask({ taskId, params, withNotif }: updateTaskAction) {
  const task = yield call(
    fetchData.patch,
    `/api/v1/tasks/${taskId}`,
    JSON.stringify(params)
  );

  if (task) {
    const { selectedProject } = store.getState().projectsPage;
    let tasks = store.getState().tasksPage.tasks.slice();
    let projectTasks = store.getState().projectsPage.tasks.slice();
    let selectedTask = store.getState().taskInfoPlate.selectedTask;
    let myId = store.getState().commonInfo.current_user;

    let indexInTasks = tasks.findIndex((task) => task.id === taskId);
    // если в табличном списке найдена эта задача
    if (indexInTasks > -1) tasks.splice(indexInTasks, 1, task); // заменяю прежнюю задачу на вернувшуюся

    let browserLink = window.location.href; // смотрит, какой url в браузере открыт
    if (browserLink.indexOf("projects") > -1) {
      // если проекты
      yield put(changeProjectTaskList([])); // очищаю все данные по списку или дереву
      yield put(fetchTasks(selectedProject?.id as number, 1)); // заново загружаю первую страницу таблицы или дерево
    } else if (browserLink.indexOf("tasks") > -1) {
      // если задачи
      yield put(changeTaskList(tasks));
    }

    if (selectedTask?.id === taskId) {
      // до сих пор ли выделена та самая задача, по которой мы изменяли данные ?
      yield put(selectTask(task));
    }

    let indexInProjectTasks = projectTasks.findIndex(
      (task) => task.id === taskId
    );
    if (indexInProjectTasks !== -1) {
      projectTasks.splice(indexInProjectTasks, 1, task);
      yield put(changeProjectTaskList(projectTasks));
    }
    if (withNotif) CreateNotif("Задача успешно обновлена", "success");

    yield put(fetchVisualizationList(myId));
  }
}

function* fetchExistingUser({ id }: fetchExistingUserDataAction) {
  let data = yield call(fetchData.get, `/api/v1/users/${id}/schedule`);

  const users = store.getState().commonInfo.users;
  let user = users.find((user) => user.id === id);

  if (user) {
    yield put(openExistingUser(user));
    yield put(setUserAddMode(true));
    if (data.schedule.daysOff.length > 0) {
      yield put(setUserDaysOff(data.schedule.daysOff));
    }
  }
  if (data) {
    const schedule = data.schedule.weekDays.map((day) => day.day);

    if (schedule) {
      const { begin, end } = data.schedule.weekDays[0].time[0];

      yield put(setUserSchedule(schedule));
      yield put(setUserWorkBegin(begin.slice(0, 5)));
      yield put(setUserWorkEnd(end.slice(0, 5)));
      if (data.schedule.daysOff.length > 0) {
        yield put(setUserDaysOff(data.schedule.daysOff));
      }
    }
  }
}

function getFoundItemValue(source, id_notification, found_item) {
  source.forEach((item, id) => {
    if (item["id"] === id_notification) found_item = { item, id };
  });
  return found_item;
}

function* clearNotifications({}) {
  const current_user_id = store.getState().commonInfo.currentUserInfo?.id;

  const response = yield all({
    response: call(
      fetchData.patch,
      `/api/v1/users/${current_user_id}/read-system-notification`,
      {}
    ),
  });

  if (response.response === 1) {
    yield put(change_remote_notifications([], [], "read", false, 0));
    yield loadNextpage();
  }
}

function* setNotifRead({ id_notification, id_current_user, readonly }) {
  const remote_notifs = store.getState().commonInfo.remote_notifications;
  const { notification_for_tasks } = store.getState().tasksPage;

  let new_read = remote_notifs.read.map((item) => item);
  let new_unread = remote_notifs.unread.map((item) => item);
  let found_item: any;

  found_item = getFoundItemValue(new_unread, id_notification, found_item);

  if (found_item) {
    // this item was as unread
    let response = yield call(
      fetchData.patch,
      `/api/v1/users/${id_current_user}/read-notification?id=${id_notification}`,
      {}
    );

    if (response) {
      // @ts-ignore
      new_read.push(found_item.item);
      new_unread.splice(found_item.id, 1);
      yield put(change_remote_notifications(new_unread, new_read));
    }
  }
}

function* loadNextpage() {
  const currentUserId = store.getState().commonInfo.current_user;
  let remote_notifications = store.getState().commonInfo.remote_notifications;
  let { load_next, offset } = remote_notifications;
  const limit = 10;

  // request
  const { response } = yield all({
    response: call(
      fetchData.get,
      `/api/v1/users/${currentUserId}/${load_next}-notifications?order=created_at&offset=${offset}&limit=${limit}&type=system&orderType=desc`
    ),
  });

  let new_unread = remote_notifications.unread.map((item) => item);
  let new_read = remote_notifications.read.map((item) => item);

  if (load_next === "unread") new_unread.push.apply(new_unread, response);
  else new_read.push.apply(new_read, response);

  // put new values to reducer
  yield put({
    type: types.SET_REMOTE_NOTIFICATIONS,
    // unread: load_next_part === 'unread' ? response : [],
    unread: new_unread,
    read: new_read,
    load_next: load_next,
    end: load_next === "read" && response.length < 10,
    offset: remote_notifications.offset === 0 ? response.length : undefined,
  });

  // сразу же, когда заканчивается unread, грузить первую пачку read
  if (response.length < limit) {
    if (load_next === "unread") {
      load_next = "read";

      // request
      const { response_read } = yield all({
        response_read: call(
          fetchData.get,
          `/api/v1/users/${currentUserId}/${load_next}-notifications?order=created_at&offset=0&limit=${limit}&type=system&orderType=desc`
        ),
      });

      yield put({
        type: types.SET_REMOTE_NOTIFICATIONS,
        unread: new_unread,
        read: response_read,
        load_next: load_next,
        end: response_read.length < 10,
      });
    }
  }
}

function* fetchUserPositions() {
  let positions = yield call(fetchData.get, `/api/v1/positions?limit=-1`);
  if (positions) yield put(setUserPositions(positions));
}
