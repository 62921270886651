import React, { useEffect, useState, useRef } from "react";
import { setUserLogin } from "../actions";
import { connect } from "react-redux";
import Input from "../../../common/components/Inputs/Input";
import { State } from "../../../rootReducer";
import { User } from "../../../common/types";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import { urlApp } from "../../../App";
import { fetchData } from "../../../utils/fetchData";
import warning from "../../../img/Warning.svg";

type Props = {
  login: string;
  userId: number;
  currentUserInfo: User | null;
  setUserLogin?: (name: string) => void;
  setChangeDataUser?: any;
  isAddUserInCompany?: boolean;
  changeDataUser: string | any;
  autoLog: boolean;
  setAutoLog: (v: boolean) => void;
};

const UserLogin: React.FC<Props> = ({
  login,
  userId,
  currentUserInfo,
  setUserLogin = () => {},
  setChangeDataUser,
  isAddUserInCompany,
  changeDataUser,
  autoLog,
  setAutoLog,
}) => {
  const [log, setLog] = useState(login);
  const [firstLog, setFirstLog] = useState(login);

useEffect(() => {
  setFirstLog(login)
}, [userId])

  useEffect(() => {
    fetchData
      .post(`/api/v1/users/check-login`, JSON.stringify({ login: login }))
      .then((res) => {
        console.log(res.available);
        if (res.available === false) {
          setAutoLog(true);
        } else {
          setAutoLog(false);
        }
        setLog(login)
      })
      .catch((error) => console.log(error, "0000"));
  }, [login]);

  // console.log(log);

  return (
    <NameWithInput title="Login" iconName="people" necessarily>
      <Input
        disabled={isAddUserInCompany || !currentUserInfo?.roles.includes(1)}
        inputType="text"
        placeholder=""
        value={login}
        styleInput={{ position: "relative" }}
        title=""
        changeHandler={(name) => {
          setUserLogin(name);
          userId !== 0 &&
            setChangeDataUser((prev) => {
              return {
                ...prev,
                data: { ...prev.data, login: name },
                schedule: { ...prev.schedule },
              };
            });
        }}
      />
      {autoLog && firstLog !== log && login && (
        <img
          src={warning}
          alt=""
          style={{ position: "absolute", right: "10px", top: "7px" }}
          title="Такой логин уже есть в системе"
        />
      )}
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    userId: state.newUser.id,
    login: state.newUser.login,
    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setUserLogin: setUserLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);
