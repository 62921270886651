import React, {useCallback, useState, useRef, useEffect} from 'react';
import AddButton from '../../../common/components/Buttons/AddButton';
// import DisplayTypeSelect from '../../../common/components/Selects/DisplayTypeSelect'
import UserProjectSelect from '../../../common/components/Selects/UserProjectSelect';
import {Project, SelectData, Section, Task, User} from '../../../common/types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getProjects} from '../../../common/selectors';
import {State} from '../../../rootReducer';

import DotsButton from '../../../common/components/Buttons/DotsButton';
import {openExistingProject} from '../../NewProject/actions';
import {useHistory, useParams} from 'react-router-dom';
import {fetchData} from '../../../utils/fetchData';
import TableSearch from '../../../common/components/TableSearch/TableSearch';

import FilterButton from '../../../common/components/Buttons/filterButton';
import ViewWeekRoundedIcon from '@material-ui/icons/ViewWeekRounded';
import ProjectsFilters from '../../Projects/components/ProjectFilters';
import ColumnsWindow from '../../../common/ColumnsCustomWindow';
import Button from '../../../common/components/newComponent/Buttons/Button';
import Toggler from '../../../common/components/Togglers/Toggler';
import {
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    ShowFiltersWindow,
} from '../../../common/actions';
import FiltersWindow from '../../../common/FiltersWindow';
import Icon from '../../../common/components/Icon';
import {cookieMaster} from '../../../utils/CookieMaster';
import {urlApp} from '../../../App';
import {createDeleteResolutionItem, setNewParametersToReducer} from "../actions";
import styled from "styled-components";
import RuleItem from "./RuleItem";
import {nanoid} from "nanoid";
import TaskItem from "./TaskItem";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import {TextareaAutosize} from "@material-ui/core";
import MultySelect from "../../../common/components/Selects/MultySelect";
import TaskDateTime from "../../../common/components/DatePickers/DateTimePiker";
import {initialState} from "../reducer";
import CheckboxItem from "../../../common/components/CheckboxItem/CheckboxItem";

type Props = {
    // projects: Project[];
    // sections: Section[];
    selectedProject: Project | null;
    openExistingProject: (project: Project) => void;
    currentUserRoles: number[] | undefined;
    tableFilter: string;
    projectId: number | null;
    // appliedFiltersExist: boolean;
    showColumnsWindow: boolean;
    showFiltersWindow: boolean;
    tableOrTreeToggler: string;
    ShowFiltersWindow: () => void;
    current_user: number;
    filters: any;
    FilterTemplates: (data: {}) => void;
    DefaultFilterTemplates: (data) => void;
    setFilters: (
        data_from?: string,
        date_to?: string,
        executors?: number[],
        authors?: number[],
        statuses?: number[],
        actual?: boolean
    ) => void;
    // businessProcessesModalShow: (show: boolean) => void;
    setNewParametersToReducer: (obj: object) => void,
    meetingTasks: any[],
    newTask: {
        agenda_item_id: number | null,
        name: string,
        description: string,
        begin: string,
        end: string,
        responsible_id: number,
        done: number,
        executors: number[]
    },
    commonUsers: User[],
    checkList: any[],
    createDeleteResolutionItem: (context: string, delete_index?: number) => void    // если послать без delete_index, то создаст. Если с ним - удалит
};


const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 36px;
  margin-right: 8px;
`;

const Tasks: React.FC<Props> = ({
                                    // showFiltersWindow,
                                    // showColumnsWindow,
                                    // projectId,
                                    // projects,
                                    // selectedProject,
                                    // openExistingProject,
                                    // currentUserRoles,
                                    // tableOrTreeToggler,
                                    // ShowFiltersWindow,
                                    // filters,
                                    setNewParametersToReducer,
                                    meetingTasks,
                                    newTask,
                                    createDeleteResolutionItem,
                                    commonUsers,
                                    checkList
                                }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [timer, setTimer] =
        useState<ReturnType<typeof setTimeout> | null>(null);
    // создал изолированную копию объекта, чтобы не перезаписать пропс
    // const newRolesArr = currentUserRoles?.map((item) => {
    //     return item;
    // });
    // newRolesArr?.sort();

    const [ShowAddNewRule, setShowAddNewRule] = useState<boolean>(false);
    const [showMenu, setShow] = useState<boolean>(false);
    const {id} = useParams();
    const menu = useRef<HTMLDivElement>(null);
    // const tasksOrder = useSelector(
    //     (state: State) => state.projectsPage.tasksOrder
    // );
    const outsideClick = useCallback((e: Event) => {
        if (!menu.current?.contains(e.target as HTMLElement)) setShow(false);
    }, []);

    // useEffect(() => {
    //   document.addEventListener('click', outsideClick);
    //   return () => document.removeEventListener('click', outsideClick);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    let company_id = localStorage.getItem('company_id');

    // console.log(showFiltersWindow)
    return <div>

        <div style={{marginTop: '8px'}}>
            {/*существующие*/}
            {!!meetingTasks.length && meetingTasks.map(task => (<TaskItem taskItem={task} key={nanoid()}/>))}
        </div>

        {!ShowAddNewRule && <div style={{marginTop: '8px', justifyContent: 'start'}}>
            <Button title={'Добавить задачу'} design={'outline'} onClick={() => setShowAddNewRule(true)}/>
        </div>}

        {ShowAddNewRule && <div className={'addNewMeetingItem'}>

            <div style={{fontSize: '16px'}}>
                Новая задача
            </div>

            <NameWithInput title="Название" iconName="people">
                <TextareaAutosize value={newTask.name}
                                  onChange={arg => setNewParametersToReducer({
                                      newTask: {
                                          ...newTask,
                                          name: arg.target.value
                                      }
                                  })}/>
            </NameWithInput>

            <NameWithInput title="Выполнена" iconName="people" style={{paddingTop: '8px'}}>
                <CheckboxItem text={''} checked={!!newTask.done} value={0} addHandler={(ag) => {
                  //  console.log(ag, newTask.done)
                    setNewParametersToReducer({
                        newTask: {
                            ...newTask,
                            done: !!newTask.done ? 0 : 1
                        }
                    })
                }} removeHandler={() => {
                    setNewParametersToReducer({
                        newTask: {
                            ...newTask,
                            done: !!newTask.done ? 0 : 1
                        }
                    })
                }}/>
            </NameWithInput>

            <NameWithInput title="Описание" iconName="people">
                <TextareaAutosize value={newTask.description} onChange={arg => setNewParametersToReducer({
                    newTask: {
                        ...newTask,
                        description: arg.target.value
                    }
                })}/>
            </NameWithInput>

            <NameWithInput title="Пункт повестки" iconName="people">
                <MultySelect itemsList={checkList.map(item => {
                    return {
                        text: item.name,
                        value: item.id
                    }
                })}
                             radio={true}
                             selectedItems={
                                 newTask.agenda_item_id ? [checkList.find(item => item.id === newTask.agenda_item_id)].map(item => ({
                                             text: item.name,
                                             value: item.id
                                         }
                                     ))
                                     : []
                             }
                             addHandler={(value) => {
                                 setNewParametersToReducer({
                                     newTask: {
                                         ...newTask,
                                         agenda_item_id: value
                                     }
                                 })
                             }}
                             removeHandler={() => {
                                 setNewParametersToReducer({
                                     newTask: {
                                         ...newTask,
                                         agenda_item_id: null
                                     }
                                 })
                             }}
                />
            </NameWithInput>

            <NameWithInput title="Ответственный" iconName="people">
                <MultySelect itemsList={commonUsers.map(user => {
                    return {
                        text: user.name + ' ' + user.surname,
                        value: user.id
                    }
                })}
                             radio={true}
                             selectedItems={
                                 commonUsers.filter(user => newTask.responsible_id === user.id).map(user => {
                                     return {
                                         text: user.name + ' ' + user.surname,
                                         value: user.id
                                     }
                                 })
                             }
                             addHandler={(value) => {
                                 setNewParametersToReducer({
                                     newTask: {
                                         ...newTask,
                                         responsible_id: value
                                     }
                                 })
                             }}
                             removeHandler={(arg) => {
                                 setNewParametersToReducer({
                                     newTask: {
                                         ...newTask,
                                         responsible_id: null
                                     }
                                 })
                             }}
                />
            </NameWithInput>

            <NameWithInput title="На кого распространяется" iconName="people">
                <MultySelect itemsList={commonUsers.map(user => {
                    return {
                        text: user.name + ' ' + user.surname,
                        value: user.id
                    }
                })}
                             selectedItems={
                                 commonUsers.filter(user => newTask.executors.includes(user.id)).map(user => {
                                     return {
                                         text: user.name + ' ' + user.surname,
                                         value: user.id
                                     }
                                 })
                             }
                             addHandler={(value) => {
                                 setNewParametersToReducer({
                                     newTask: {
                                         ...newTask,
                                         executors: [...newTask.executors, value]
                                     }
                                 })
                             }}
                             removeHandler={(arg) => {
                                 setNewParametersToReducer({
                                     newTask: {
                                         ...newTask,
                                         executors: [...newTask.executors.filter(item => item !== arg)]
                                     }
                                 })
                             }}
                />
            </NameWithInput>

            <NameWithInput title="Начало" iconName="calendar-dark">
                <div style={{width: '232px'}}>
                    <TaskDateTime
                        acceptHandler={(val) => setNewParametersToReducer({
                            newTask: {
                                ...newTask,
                                begin: val
                            }
                        })}
                        date={
                            newTask.begin ? newTask.begin : ''
                        }
                    />
                </div>
            </NameWithInput>

            <NameWithInput title="Конец" iconName="calendar-dark">
                <div style={{width: '232px'}}>
                    <TaskDateTime
                        acceptHandler={(val) => setNewParametersToReducer({
                            newTask: {
                                ...newTask,
                                end: val
                            }
                        })}
                        date={
                            newTask.end ? newTask.end : ''
                        }
                    />
                </div>
            </NameWithInput>


            <FlexDiv style={{justifyContent: 'flex-end', margin: '0px'}}>
                <Button title={"Отмена"} design={'secondary'} style={{marginRight: '8px'}} onClick={() => {
                    setNewParametersToReducer({
                        newTask: {
                            ...initialState.newTask
                        }
                    })
                    setShowAddNewRule(false)
                }}/>
                <Button title={"Сохранить"}
                        disabled={newTask.name === '' || newTask.agenda_item_id === null || newTask.begin === ''}
                        onClick={() => {
                            createDeleteResolutionItem('task')
                            setShowAddNewRule(false)
                        }}/>
            </FlexDiv>

        </div>}

    </div>
};

const mapStateToProps = (state: State) => {
    return {
        appliedFiltersExist: Boolean(
            Object.keys(state.projectsPage.filters.appliedFilters).length
        ),
        meetingTasks: state.meetingsPage.meetingTasks,
        projects: getProjects(state),
        // selectData: getProjectSelectData(state),
        selectedProject: state.projectsPage.selectedProject,
        sections: state.commonInfo.sections,
        // showFiltersWindow: state.projectsPage.filtersWindowShow,
        showFiltersWindow: state.commonInfo.filters.showFiltersWindow,
        currentUserRoles: state.commonInfo.currentUserInfo?.roles,
        tableFilter: state.projectsPage.tableFilter,
        projectId: state.projectsPage.selectedProject?.id || null,
        showColumnsWindow: state.projectsPage.showColumnsWindow,
        tableOrTreeToggler: state.projectsPage.tableOrTreeToggler,
        current_user: state.commonInfo.current_user,
        filters: state.commonInfo.filters,
        newTask: state.meetingsPage.newTask,
        checkList: state.meetingsPage.checkList,
        commonUsers: state.commonInfo.users,
    };
};

const mapDispatchToProps = {
    // fetchTasks,
    openExistingProject,
    ShowFiltersWindow,
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    setNewParametersToReducer,
    createDeleteResolutionItem
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
