import React, {useMemo, useCallback} from 'react';
import {Meeting, Task, User, Project} from '../../../common/types';
import DateMater from '../../../utils/DateMaster';
import TaskNameCell from '../../../common/components/TableCells/TaskNameCell';
import DeadLineCell from '../../../common/components/TableCells/DeadLineCell';
import TaskWorkLoadingCell from '../../../common/components/TableCells/TaskWorkLoadingCell';
import PriorityCell from '../../../common/components/TableCells/PriorityCell';
import {selectTask, setShownTaskInfoPlate} from '../../TaskInfoPlate/actions';
import {connect} from 'react-redux';
import TaskStatus from '../../../common/components/TaskStatus/TaskStatus';
import {State} from '../../../rootReducer';
import {getSelectedTask} from '../../TaskInfoPlate/selectors';
import OpenBtn from '../../../common/components/TableCells/OpenBtn';
import {urlApp} from '../../../App';
import IdCell from '../../../common/components/TableCells/IdCell';
// import DateCell from '../../../common/components/TableCells/MeetingDateCell';

import ProjectCell from '../../../common/components/TableCells/ProjectSection';
import PartitionCell from '../../../common/components/TableCells/PartitionCell';
import styled from 'styled-components';
import {columnsOfTables} from '../../../common/shared_data';
import {meetingsColumnsOfTables} from "./MeetingsTableHead";
import DateCell from "../../../common/components/TableCells/MeetingDateCell";
import MeetingIdCell from "../../../common/components/TableCells/MeetingIdCell";
import MeetingSecretaryCell from "../../../common/components/TableCells/MeetingSecretaryCell";
import MeetingParticipantsCell from "../../../common/components/TableCells/MeetingParticipantsCell";
import MeetingProjectSection from "../../../common/components/TableCells/MeetingProjectSection";
import MeetingStatusCell from "../../../common/components/TableCells/MeetingStatusCell";
import {editMeeting, meetingPlateHidingSignal, setNewParametersToReducer} from "../actions";
import {status_list} from "./RightPlate";

type Props = {
    // meeting: Meeting;
    meeting: any;
    // selectedTask: Task | null;
    // selectTask: (task: Task) => void;
    // setShownTaskInfoPlate: (show: boolean) => void;
    isParent?: boolean;
    counter?: number;
    isOpen?: boolean;
    setOpen?: (open: boolean) => void;
    users: User[];
    projects: object;
    meetingPlateHidingSignal: (val: boolean) => void,
    setNewParametersToReducer: (val: object) => void,
    editMeeting: () => void,
    // partitions: object;
    // activeColumns: string[];
};

const TaskStyle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  border-bottom: 1px solid #e0e0e0;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  &:hover {
    background: linear-gradient(
      to right,
      white 0%,
      #f2f2f2 5%,
      #f2f2f2 90%,
      white 100%
    );
  }

  ${({active}) =>
    active &&
    `
    background: linear-gradient(to right, white 0%,#F3FAFE 5%, #F3FAFE 90%, white 100%)!important;
    border-image: linear-gradient(to right,#ffffff,#99D7F5,#99D7F5,#ffffff ) 0% 5% 75% 7%;
    `}
`;

const BlockLeftFlex = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const BlockRightFlex = styled.div`
  position: relative;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
    >div{
        padding-left: 8px;
    } 
`;

const MeetingsTableTr: React.FC<Props> = ({
                                              projects,
                                              meeting,
                                              users,
                                              meetingPlateHidingSignal,
                                              setNewParametersToReducer,
                                              editMeeting,
                                              isParent = false,
                                              counter = 0,
                                              isOpen = false,
                                              setOpen = () => {
                                              },
                                          }) => {
    // const dateMaster = useMemo(() => {
    //     return new DateMater(task.end);
    // }, [task]);

    const trClickHandler = useCallback(
        (e: React.MouseEvent) => {
            // if (!(e.target as HTMLElement).closest('.task_status_wrapper'))
            //     setShownTaskInfoPlate(true);
            // if (selectedTask?.id !== task.id) selectTask(task);

            // if(e.target)
            // console.log(e.currentTarget.className)
            let yy = e.target as Element
            // console.log(yy.closest('.taskStatusWrapper'), e.currentTarget.parentElement)

            if (!yy.closest('.taskStatusWrapper')) {
                setNewParametersToReducer({showRightPlate: true, selectedMeetingId: meeting.id})
                setTimeout(() => meetingPlateHidingSignal(true), 800)
            }
        },
        []
    );

    // const executor = users.find((user) => user.id === task.executor_id);
    const author = users.find((user) => user.id === meeting.author_id);
    const secretary = users.find((user) => user.id === meeting.secretary_id);

    // let project_of_task: string = '';
    // Object.keys(projects).forEach((id) => {
    //     if (projects[id].id === meeting.projects[0]) {
    //         project_of_task = projects[id].name;
    //     }
    // });

    // let section_of_task: string = '';
    // Object.keys(partitions).forEach((id) => {
    //     if (partitions[id].id === task.project_section_id) {
    //         section_of_task = partitions[id].name;
    //     }
    // });


    // console.log(meeting, projects, Object.values(projects))

    let participants = []
    if (users.length) {
        participants = meeting.participants.map(item => {
            let usr = users.find((user) => user.id === item)
            if (usr) {
                return {
                    url: usr.image,
                    name: usr?.name + ' ' + usr?.surname
                }
            }
        })

    }
    let project: Project = {
        id: 0,
        name: '',
        description: '',
        priority_id: 0,
        author_id: 0
    }
    if (meeting.projects[0] && Object.keys(projects).length) {
        project = Object.values(projects).find(item => item.id === meeting.projects[0])
    }

    // console.log(meeting)

    return (
        <TaskStyle active={false} onClick={trClickHandler}>
            <BlockLeftFlex>
                <MeetingIdCell id={meeting.id}/>

                <DateCell date={meeting.begin}/>

                <TaskNameCell id={meeting.id} name={meeting.name} desc={meeting.description}/>

            </BlockLeftFlex>
            <BlockRightFlex>

                <MeetingSecretaryCell
                    minWidth={'72px'}
                    url={
                        secretary?.image
                            ? urlApp + `/files/${secretary?.image?.url}/download`
                            : null
                    }
                    executorName={
                        secretary ? `${secretary.surname} ${secretary.name}` : 'НЕИЗВЕСТЕН'
                    }
                />

                <MeetingParticipantsCell
                    minWidth={'136px'}
                    data={
                        participants
                    }
                />

                <MeetingProjectSection
                    data_project={project ? project.name : ''}
                    data_section={project ? project.description : ''}
                />

                {/*<MeetingStatusCell statusName={meeting.status}/>*/}
                <TaskStatus
                    id={status_list.find(item => item.name === meeting.status)?.id as number}
                    taskId={meeting.id}
                    passHigher={(arg) => {
                        setNewParametersToReducer({status: status_list.find(item => item.id === arg)?.name, selectedMeetingId: meeting.id})
                        editMeeting()
                    }}
                    customStatusList={status_list}
                />


            </BlockRightFlex>
        </TaskStyle>
    );
};

const mapStateToProps = (state: State) => {
    return {
        users: state.commonInfo.users,
        projects: state.commonInfo.projects,
        partitions: state.commonInfo.sections,
        activeColumns: state.projectsPage.activeColumns,
        selectedTask: getSelectedTask(state),
    };
};

const mapDispatchToProps = {
    selectTask,
    setShownTaskInfoPlate,
    meetingPlateHidingSignal,
    setNewParametersToReducer,
    editMeeting
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingsTableTr);
