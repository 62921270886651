import React, { useEffect, useLayoutEffect, useState } from "react"; //************************************ */
import UsersActions from "./components/UsersActions";
import UsersTable from "./components/UsersTable";
import DepGroupPlate from "./components/DepGroupPlate";
import { fetchUsers, openDeps, setUserAddMode, setUsers } from "./actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { State } from "../../rootReducer";
import { User } from "../../common/types";
import { openExistingUser } from "../NewUser/actions";
// import {getShowingDepGroupPlate} from "./selectors";
// import PriorityToggler from "../../common/components/Togglers/PriorityToggler";
// import NameDescriptionTextField from "../../common/components/Inputs/NameDescriptionTextField";
// import ProjectSelect from "../NewTask/components/ProjectSelect";
// import SectionSelect from "../NewTask/components/SectionSelect";
// import ExecutorSelect from "../NewTask/components/ExecutorSelectWithDelegate";
// import Author from "../NewTask/components/Author";
// import TaskBegin from "../NewTask/components/TaskBegin";
// import TaskEnd from "../NewTask/components/TaskEnd";
// import TaskWorkLoad from "../NewTask/components/TaskWorkLoad";
// import QualitySpeedToggler from "../NewTask/components/QualitySpeedToggler";
// import TaskAddictions from "../NewTask/components/TaskAddictions";
import styled from "styled-components";
import { fetchData } from "../../utils/fetchData";
import { fetchUpdateData } from "../../utils/fetchUpdateData";
import { updateNewNotification, updateTimestamp } from "../../common/actions";
import { timeUpdate } from "../../App";
import { cookieMaster } from "../../utils/CookieMaster"; //*********************************** */

type Props = {
  users: User[];
  fetchUsers: (pageNum: number) => void;
  openExistingUser: (user: User) => void;
  setUserAddMode: (mode: boolean) => void;
  // showDepGroupPlate: boolean,
  openDeps: () => void;
};

const WrapperTableUser = styled.div`
  display: flex;
  margin: 0 auto;
  /* max-width: 1280px; */
  /* padding: 0 4px; */
`;

const BlockUsersStyle = styled.div`
  min-width: calc(
    100vw - ${({ isLeftBlock }) => (isLeftBlock ? "600px" : "304px")}
  );
  transition-duration: 0.4s;
  /* width: 100%;*/ /***************/
`;

const Users: React.FC<Props> = ({
  openDeps,
  users,
  fetchUsers,
  openExistingUser,
  setUserAddMode
}) => {
  // Если пользователь не залогинен - переводим его на страницу авторизации
  useLayoutEffect(() => {
    let token = cookieMaster.getCookie("access_token");
    if (token === undefined || token === null) {
      window.location.replace(`/login`);
    }
    if (token.length == 0) {
      window.location.replace(`/login`);
    }
  }, []);
  // *********************************************************************** */

  const { id } = useParams();
  const dispatch = useDispatch();
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const usersPage = useSelector(
    (state: State) => state.usersPage.depGroupUsers
  );
  const timestamp = useSelector((state: State) => state.commonInfo.timestamp);
  const templateUsers = [...usersPage];
  if (firstRender) {
    setFirstRender(false);
    openDeps();
  }

  useEffect(() => {
    fetchUsers(1);
  }, [fetchUsers]);

  useEffect(() => {
    if (id && users.length > 0) {
      let user = users.find((u) => u.id === +id);
      if (user) openExistingUser(user);
      setUserAddMode(true);
    }
  }, [id, openExistingUser, setUserAddMode, users]);

  useEffect(() => {
    if (timestamp === "") {
      let day =
        new Date().getDate() > 9
          ? new Date().getDate()
          : "0" + new Date().getDate();
      let month =
        new Date().getMonth() > 9
          ? Number(new Date().getMonth()) + 1
          : "0" + (Number(new Date().getMonth()) + 1);
      let year = new Date().getFullYear();

      let dataString = `${year}-${month}-${day} ${new Date().toLocaleTimeString()}`;

      fetchData
        .get(`/api/v1/update-data?timestamp=${dataString}`)
        .then(({ timestamp }) => dispatch(updateTimestamp(timestamp)))
        .catch((error) => console.warn(error));
    } else {
      const intervalFetch = setInterval(() => {
        fetchUpdateData(timestamp, "users", [1])
          ?.then(({ timestamp, notifications, users = null }) => {
            dispatch(updateTimestamp(timestamp));
            if (notifications?.length > 0) {
              notifications.map((el) => {
                if (el.hasOwnProperty("create")) {
                  dispatch(updateNewNotification([el]));
                }
              });
            }
            if (users?.length > 0) {
              users.forEach((updateUser) => {
                templateUsers.map((el, index) => {
                  if (el.id === updateUser.id) {
                    templateUsers.splice(index, 1, updateUser);
                  }
                });
              });
              dispatch(setUsers(templateUsers));
            }
          })
          .catch((error) => console.warn(error));

        // if (usersTEST.length > 0) {
        //   console.log(usersTEST);
        //   console.log(templateUsers);
        //   usersTEST.forEach((updateUser: any) => {
        //     templateUsers.map((el, index) => {
        //       if (el.id === updateUser.id) {
        //         templateUsers.splice(index, 1, updateUser);
        //       }
        //     });
        //   });

        //   dispatch(setUsers(templateUsers));
        // }
      }, timeUpdate);

      return () => {
        clearInterval(intervalFetch);
      };
    }
  }, [timestamp, usersPage]);

  const [isLeftBlock, setIsLeftBlock] = useState<boolean>(false);

  return (
    <WrapperTableUser>
      <DepGroupPlate
        isLeftBlock={isLeftBlock}
        setIsLeftBlock={setIsLeftBlock}
      />
      <BlockUsersStyle
        isLeftBlock={isLeftBlock}
        setIsLeftBlock={setIsLeftBlock}
      >
        <UsersActions />
        <UsersTable />
      </BlockUsersStyle>
    </WrapperTableUser>
  );
};

const mapStateToProps = (state: State) => {
  return {
    // showDepGroupPlate: getShowingDepGroupPlate(state),
    users: state.commonInfo.users,
    changeIndicator: state.newUser.changeIndicator
  };
};

const mapDispatchToProps = {
  fetchUsers,
  openExistingUser,
  setUserAddMode,
  openDeps
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
