import { cookieMaster } from './CookieMaster';
import { CreateNotif } from './createNotification';
import { urlApp, store } from '../App';
import { setVerified } from '../common/actions';

export const loginBodyParts = {
  grant_type: 'password',
  client_id: '2',
  client_secret: '67KYLMbv2Zfj9wy8CS0Iyb0glqayQt6Mk5itWnmE',
  // client_secret: 'HIGePrqaTcm4BomgJZAFEL7PJWYQDA4ohYIpjyvy',
};

type Headers = {
  [key: string]: string;
};
let company_id = localStorage.getItem('company_id');
export class FetchData {
  //private baseUrl: string = urlApp // вынес в App
  // private baseUrl: string = 'http://way.easy-task.ru'
  private headers: Headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Authorization: `${cookieMaster.getCookie('access_token')}`,
    'company-id': `${company_id}`,
  };
  public responseHandlerMethod: string = 'json';

  private checkHeaders(headers: Headers | {}) {
    if (Object.keys(headers).length > 0) {
      return headers;
    }
    return this.headers;
  }

  private responseHandlerMethodCheck(responseHandlerMethod: string) {
    if (responseHandlerMethod) {
      return responseHandlerMethod;
    }

    return this.responseHandlerMethod;
  }

  private async makeRequest(
    url: string,
    method: string,
    body: any,
    headers: Headers = this.headers,
    responseHandlerMethod: string = this.responseHandlerMethod,
    abortController = new AbortController()
  ): Promise<any | undefined> {
    const { signal } = abortController || {};

    try {
      const res = await fetch(`${urlApp}${url}`, {
        signal: signal,
        method,
        headers: this.checkHeaders(headers),
        body,
      });
      const res_1 = await this.statusCheck(
        res,
        this.responseHandlerMethodCheck(responseHandlerMethod)
      );
      return this.responseHandler(res_1, url);
    } catch (error) {
      console.error(error);

      // if (err.name == 'AbortError') {
      //   console.error(err)
      // } else {
      // throw new Error(error);
      // }
    }
  }

  private statusCheck(
    response: Response,
    responseHandlerMethod: string
  ): Promise<any> | undefined {
    if (response.status === 401) {
      // cookieMaster.deleteCookie('access_token')
      // cookieMaster.deleteCookie('login')
      // window.location.pathname = '/login'
      // CreateNotif('')
      // window.history.replaceState(null, '', '/login')
      return response[responseHandlerMethod]();
    } else if (response.status === 403) {
      store.dispatch(setVerified(false));
      return response[responseHandlerMethod]();
    } else {
      return response[responseHandlerMethod]();
    }
  }

  private responseHandler(response: any, url: string) {
    if (response.error) {
      if (response.details) {
        Object.values(response.details).forEach((detail) => {
          if (typeof detail === 'string') CreateNotif(detail);
          else (detail as string[]).forEach((text) => CreateNotif(text));
        });
      } else {
        // если битая авторизация, то перенаправлять на логин
        if (
          response.error === 'Unauthenticated.' &&
          window.location.pathname !== '/login'
        ) {
          cookieMaster.deleteCookie('access_token');
          window.location.assign(`${urlApp}/login`);
        }
        if (response.error === 'Your email address is not verified.') {
          cookieMaster.deleteCookie('access_token');
          window.location.replace('/login');
        }
        if (
          response.error === 'Unauthenticated.' &&
          window.location.pathname === '/login'
        ) {
          CreateNotif('Неверно указан email или пароль!');
        }
        CreateNotif(response.error);
      }
    } else {
      if (url.includes('/api/v1/projects?')) {
        return response;
      }
      if (url.includes('/api/v1/projectsections?')) {
        return response;
      }

      return response.data || response;
    }
  }

  public get = (
    url: string,
    headers?: Headers,
    responseHandlerMethod?: string,
    abortController?: any
  ) => {
    return this.makeRequest(
      url,
      'GET',
      null,
      headers,
      responseHandlerMethod,
      abortController
    );
  };

  public post = (
    url: string,
    body: any,
    headers?: Headers,
    responseHandlerMethod?: string
  ) => {
    return this.makeRequest.call(
      this,
      url,
      'POST',
      body,
      headers,
      responseHandlerMethod
    );
  };

  public patch = (
    url: string,
    body: any,
    headers?: Headers,
    responseHandlerMethod?: string
  ) => {
    return this.makeRequest(url, 'PATCH', body, headers, responseHandlerMethod);
  };

  public delete = (
    url: string,
    body?: any,
    headers?: Headers,
    responseHandlerMethod?: string
  ) => {
    return this.makeRequest(
      url,
      'DELETE',
      body,
      headers,
      responseHandlerMethod
    );
  };

  public addHeaders(headers: Headers): void {
    this.headers = {
      ...this.headers,
      ...headers,
    };
  }
}

export const fetchData = new FetchData();
