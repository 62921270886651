import React from 'react';





const DotsButton = ({ children, clickHandler, active }) => {
    return (
        <div onClick={clickHandler} className={`dots_button${active ? ' active' : ''}`}>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            {children}
        </div>
    )
}


export default DotsButton