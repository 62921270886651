import {
  types,
  setProjectNameAction,
  setProjectDescriptionAction,
  setProjectSectionsAction,
  setProjectFilesAction,
  clearProjectAction,
  setProjectCuratorsAction,
  setProjectCpeListAction,
  setProjectChiefAction,
  setProjectLeaderAction,
  setProjectPriorityAction,
  createProjectAction,
  uploadFileAction,
  deleteFileAction,
  setIsSendingAction,
  openExistingProjectAction,
  removeProjectSectionAction,
  changeSectionNameAction,
  updateProjectAction,
  addSectionAction,
} from './action_types';
import {
  File as FileType,
  Project,
  SectionInComponent,
} from '../../common/types';

export const setProjectName = (name: string): setProjectNameAction => ({
  type: types.SET_PROJECT_NAME,
  name,
});

export const setProjectDescription = (
  description: string
): setProjectDescriptionAction => ({
  type: types.SET_PROJECT_DESCRIPTION,
  description,
});

export const setProjectSections = (
  sections: SectionInComponent[]
): setProjectSectionsAction => ({
  type: types.SET_PROJECT_SECTIONS,
  sections,
});

export const setProjectFiles = (files: FileType[]): setProjectFilesAction => ({
  type: types.SET_PROJECT_FILES,
  files,
});

export const clearProject = (): clearProjectAction => ({
  type: types.CLEAR_PROJECT,
});

export const setProjectCurators = (
  curators: number[]
): setProjectCuratorsAction => ({
  type: types.SET_PROJECT_CURATORS,
  curators,
});

export const setProjectCpeList = (
  cpeList: number[]
): setProjectCpeListAction => ({
  type: types.SET_PROJECT_CPE_LIST,
  cpeList,
});

export const setProjectChief = (
  head_of_departments: number[]
): setProjectChiefAction => ({
  type: types.SET_PROJECT_CHIEF,
  head_of_departments,
});
export const setProjectLeader = (
  head_of_work_groups: number[]
): setProjectLeaderAction => ({
  type: types.SET_PROJECT_LEADER,
  head_of_work_groups,
});

export const setProjectPriority = (
  priority: number
): setProjectPriorityAction => ({
  type: types.SET_PROJECT_PRIORITY,
  priority,
});

export const createProject = (): createProjectAction => ({
  type: types.CREATE_PROJECT,
});

export const updateProject = (): updateProjectAction => ({
  type: types.UPDATE_PROJECT,
});

export const uploadFile = (file: File): uploadFileAction => ({
  type: types.UPLOAD_FILE,
  file,
});

export const deleteFile = (id: number): deleteFileAction => ({
  type: types.DELETE_FILE,
  id,
});

export const setIsSending = (isSending: boolean): setIsSendingAction => ({
  type: types.SET_IS_SENDING,
  isSending,
});

export const openExistingProject = (
  project: Project
): openExistingProjectAction => ({
  type: types.OPEN_EXISTING_PROJECT,
  project,
});

export const removeProjectSection = (
  sectionId: number
): removeProjectSectionAction => ({
  type: types.REMOVE_PROJECT_SECTION,
  sectionId,
});

export const changeSectionName = (
  sectionId: number,
  name: string
): changeSectionNameAction => ({
  type: types.CHANGE_SECTION_NAME,
  sectionId,
  name,
});

export const addSection = (
  projectId: number,
  name: string
): addSectionAction => ({
  type: types.ADD_SECTION,
  projectId,
  name,
});
