import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import SelectWithSecondaryBtn from '../../../../common/components/Selects/SelectWithSecondaryBtn';

import { updateTask } from '../../../../common/actions';
import {
	setCyclicTaskToChange,
	setTaskSection,
} from '../../../TaskInfoPlate/actions';
import {
	getSectionSelectList,
	getSelectedTask,
} from '../../../TaskInfoPlate/selectors';

import { State } from '../../../../rootReducer';

const Wrapper = styled.div`
	width: 100%;
`;

const SectionAutocomplete: FC = () => {
	const dispatch = useDispatch();
	const state = useSelector((state: State) => state);
	const { currentUserInfo } = useSelector((state: State) => state.commonInfo);
	const selectedTask = getSelectedTask(state);
	const	sectionSelect = getSectionSelectList(state);

	const disabledComponent =
		currentUserInfo?.id === selectedTask?.author_id ||
		currentUserInfo?.id === selectedTask?.executor_id ||
		!selectedTask
			? false
			: currentUserInfo?.roles[0] !== 1;

	return (
		<Wrapper>
			<SelectWithSecondaryBtn
				list={sectionSelect.list}
				selected={sectionSelect.selected}
				disabled={disabledComponent || !sectionSelect.list.length}
				selectHandler={(value) => {
					if (selectedTask) {
						// проверить шаблон и спросить про изменение
						if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
							// сравнение одного с несколькими
							// эта задача вообще циклическая или сама по себе ?
							// значит циклическая

							// больше не нужно запоминать выбор в текущей сессии работы с задачей

							// if (flagForActionCyclycTask === '') {
							dispatch(setCyclicTaskToChange({ project_section_id: value })); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
							// } else if (flagForActionCyclycTask === 'task') {
							//     updateTask(selectedTask.id, {project_section_id: value});
							// } else {
							//     updateTask(selectedTask.id, {project_section_id: value});
							//     sendReqToChangeTemplRepTask({project_section_id: value});
							// }
						} else {
							dispatch(updateTask(selectedTask.id, { project_section_id: value }));
						}
					} else dispatch(setTaskSection(value as number));
				}}
			/>
		</Wrapper>
	);
};

export default SectionAutocomplete;
