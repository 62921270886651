import { getPositions } from './../../common/selectors';
import { State } from '../../rootReducer';
import { createSelector } from 'reselect';
import { getUsers } from '../../common/selectors';
import { Item, User, SectionInComponent } from '../../common/types';
import { File as FileType } from '../../common/types';

export const getProjectCpe = (state: State): number[] =>
  state.newProject.cpe_list;
export const getProjectCurators = (state: State): number[] =>
  state.newProject.curators;
export const getProjectChief = (state: State): number[] =>
  state.newProject.head_of_departments;
export const getProjectLeader = (state: State): number[] =>
  state.newProject.head_of_work_groups;
export const getFiles = (state: State): FileType[] => state.newProject.files;
export const getSections = (state: State): SectionInComponent[] =>
  state.newProject.sections;
export const getNewProjectData = (state: State) => state.newProject;

export const getCpeSelectData = createSelector(
  [getUsers, getProjectCpe, getPositions],
  (users, cpe, positions) => {
    let selected: Item[] = [];
    let list = users
      .filter((user) => user.roles.includes(4))
      .map((user) => {
        let item = {
          urlImage: user.image?.url || false,
          text: `${user.surname} ${user.name}`,
          position: positions?.find(
            (position) => position?.id === user?.positions[0]
          )?.name,
          value: user.id,
        };

        if (cpe.includes(user.id)) selected?.push(item);
        return item;
      });
    return { list, selected };
  }
);

export const getCuratorSelecData = createSelector(
  [getUsers, getProjectCurators, getPositions],
  (users, curators, positions = []) => {
    let selected: Item[] = [];
    let list = users
      .filter((user) => user.roles.includes(3))
      .map((user: User) => {
        // куратор - это 3я роль

        let item = {
          urlImage: user.image?.url || false,
          text: `${user.surname} ${user.name}`,
          position: positions?.find(
            (position) => position?.id === user?.positions[0]
          )?.name,
          value: user.id,
        };

        if (curators.includes(user.id)) selected?.push(item);

        return item;
      });
    return { list, selected };
  }
);

export const getChiefSelecData = createSelector(
  [getUsers, getProjectChief, getPositions],
  (users, head_of_departments, positions) => {
    let selected: Item[] = [];
    let list = users
      .filter((user) => user.roles.includes(5))
      .map((user: User) => {
        let item = {
          urlImage: user.image?.url || false,
          text: `${user.surname} ${user.name}`,
          position: positions?.find(
            (position) => position?.id === user?.positions[0]
          )?.name,
          value: user.id,
        };

        if (head_of_departments.includes(user.id)) selected?.push(item);

        return item;
      });
    return { list, selected };
  }
);

export const getLeaderSelecData = createSelector(
  [getUsers, getProjectLeader, getPositions],
  (users, head_of_work_groups, positions) => {
    let selected: Item[] = [];
    let list = users
      .filter((user) => user.roles.includes(6))
      .map((user: User) => {
        let item = {
          urlImage: user.image?.url || false,
          text: `${user.surname} ${user.name}`,
          position: positions?.find(
            (position) => position?.id === user?.positions[0]
          )?.name,
          value: user.id,
        };

        if (head_of_work_groups.includes(user.id)) selected?.push(item);

        return item;
      });
    return { list, selected };
  }
);

export const getCanSend = createSelector([getNewProjectData], (data) => {
  let canSend = true;
  Object.keys(data).forEach((key) => {
    if (
      key === 'files' ||
      key === 'id' ||
      key === 'head_of_work_groups' ||
      key === 'head_of_departments'
    )
      return;
    if (
      (typeof data[key] === 'string' || typeof data[key] === 'number') &&
      !data[key] &&
      !data[key].length
    ) {
      canSend = false;
    } else if (data[key] instanceof Array && data[key].length === 0) {
      canSend = false;
    }
  });
  return canSend;
});
