import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import BpItem from "../bp-item/BpItem.jsx";
import SortBtn from "../ui/sort-btn/SortBtn.jsx";
import { StatusContext } from "../../context/status.js";
import { useHistory, useLocation } from "react-router-dom";

const BusinessMainList = () => {
  const {
    filter,
    setFilter,
    createBpStatus,
    createBpSampleStatus,
    apiBp,
    bpList,
    setBpList,
    openMenuTasks,
    openMenuBp,
    createTaskStatus,
    createTaskSampleFormStatus,
    setCreateTaskStatus,
    setCreateTaskSampleFormStatus,
    setCreateBpSampleStatus,
    tasks,
    bpListChange,
    setBpListChange,
    statusBp,
    setStatusBp,
    statusBpId,
    setStatusBpId,
    statusTaskId,
    setStatusTaskId,
    change,
    setChange,
    setOpenMenuTasks,
    setOpenMenuBp,
    bearer,
    company_id,
    setTaskId,
    relatedBp,
    setRelatedBp,
    setRelatedTaskId,
    setSelectedBp,
    openFilter,
    setOpenFilter,
    bpDarfts,
    bpWorks,
    bpFilter,
    textBpFilter,
    selectColumns,
    isTreatyFilterApply,
    setIsTreatyFilterApply,
    isWorkFilterApply,
    setIsWorkFilterApply,
    isDismissalFilterApply,
    setIsDismissalFilterApply,
    setFilterMethod,
    sortActive,
    setSortActive,
    controller,
  } = useContext(StatusContext);

  const location = useLocation();
  const history = useHistory();
  const token = document.cookie.replace(
    /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
  

  // Запрос списка БП
  // Фильтры по БП : по выбору, "В работе" или "Черновики". Если нет- запрос БП без вильтра
  useEffect(() => {
    // Если выбран фильтр по БП- "в работе"
    if (bpWorks === true) {
      axios
        .get(`${apiBp}/bp?filter[status][]=2`, {
          signal: controller.signal,
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setBpList(response.data.data);
          } else {
            setBpList(null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
   
    }
    
    // Если выбран фильтр по БП- "Черновики"
    else if (bpDarfts === true) {
      axios
        .get(`${apiBp}/bp?filter[status][]=0`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setBpList(response.data.data);
          } else {
            setBpList(null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // Если выбран фильтр по БП - "Договор"
    else if (isTreatyFilterApply === true) {
      axios
        .get(`${apiBp}/bp?filter[template_id]=treaty&filter[status][]=2`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setBpList(response.data.data);
          } else {
            setBpList(null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // Если выбран фильтр по БП - "Прием на работу"
    else if (isWorkFilterApply === true) {
      axios
        .get(`${apiBp}/bp?filter[template_id]=work&filter[status][]=2`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setBpList(response.data.data);
          } else {
            setBpList(null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // Если выбран фильтр по БП - "Увольнение"
    else if (isDismissalFilterApply === true) {
      axios
        .get(`${apiBp}/bp?filter[template_id]=dismissal&filter[status][]=2`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setBpList(response.data.data);
          } else {
            setBpList(null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // Если выбран фильтр по БП- "Произвольный"
    else if (bpFilter === true) {
      axios
        .get(`${apiBp}/bp?${textBpFilter}`, {
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setBpList(response.data.data);
          } else {
            setBpList(null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // Если не было выбрано никакого фильтра
    else if (
      !bpWorks &&
      !bpDarfts &&
      !bpFilter &&
      !isTreatyFilterApply &&
      !isWorkFilterApply &&
      !isDismissalFilterApply
    ) {
      axios
        .get(`${apiBp}/bp`, {
          signal: controller.signal,
          headers: {
            Authorization: bearer,
            "company-id": company_id,
            "secret-token": token,
          },
        })
        .then((response) => {
          if (response.data && response.data.data) {
            setBpList(response.data.data); //.sort((a, b) => b.id - a.id)
          } else {
            setBpList(null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [
    setBpList,
    filter,
    createTaskStatus,
    createTaskSampleFormStatus,
    setCreateTaskStatus,
    setCreateTaskSampleFormStatus,
    createBpSampleStatus,
    setCreateBpSampleStatus,
    tasks,
    bpListChange,
    setBpListChange,
    statusBp,
    setStatusBp,
    statusBpId,
    setStatusBpId,
    statusTaskId,
    setStatusTaskId,
    change,
    setChange,
    openMenuTasks,
    setOpenMenuTasks,
    openMenuBp,
    setOpenMenuBp,
    openFilter,
    setOpenFilter,
    bpDarfts,
    bpWorks,
    bpFilter,
    textBpFilter,
    isWorkFilterApply,
    isTreatyFilterApply,
    isDismissalFilterApply,
  ]);

  const sortFunc = (e) => {
    setFilter(e.dataset.sort);
  };
  // переход из вкладки задач в бп
  const taskIdInLocation = +location.search.slice(1);
  useEffect(() => {
    if (bpList) {
      bpList.forEach((bp) => {
        let tasks = bp.tasks;
        for (let i = 0; i < tasks.length; i++) {
          if (tasks[i].id === taskIdInLocation) {
            setRelatedBp((prev) => bp);
            setRelatedTaskId((prev) => taskIdInLocation);
          }
        }
      });
    }
  }, [bpList]);

  // console.log("bpList", bpList);
  // console.log(sortActive);

  return (
    <div className="business__main-content__list-block">
      <div
        className={
          createBpStatus || createBpSampleStatus || openMenuTasks || openMenuBp
            ? "business__main-content__list-block__title business__main-content__list-block__title-active"
            : "business__main-content__list-block__title"
        }
      >
        <div>
          {selectColumns.includes("id") && (
            <button
              className="p-grey sort"
              data-sort="id1"
              onClick={(e) => {
                sortFunc(e.target);
                setSortActive(!sortActive);
                sortActive && setFilterMethod("asc");
                !sortActive && setFilterMethod("desc");
                sortActive &&
                  axios
                    .get(`${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=id`, {
                      headers: {
                        "secret-token": document.cookie.replace(
                          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                          "$1"
                        ),
                        Accept: "application/json",
                        "company-id": company_id,
                        Authorization: bearer,
                      },
                    })
                    .then((res) => {
                      setBpList(res.data.data);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                !sortActive &&
                  axios
                    .get(
                      `${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=-id`,
                      {
                        headers: {
                          "secret-token": document.cookie.replace(
                            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                            "$1"
                          ),
                          Accept: "application/json",
                          "company-id": company_id,
                          Authorization: bearer,
                        },
                      }
                    )
                    .then((res) => {
                      setBpList(res.data.data);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
              }}
              style={{ marginLeft: "60px" }}
            >
              ID
              {filter === "id1" ? <SortBtn name={"id1"} /> : <></>}
            </button>
          )}

          {selectColumns.includes("initiator_id") && (
            <div
              style={{ display: "flex", gap: 23 + "px", alignItems: "center" }}
            >
              {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 4 + "px",
              }}
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/arrow.svg`}
                alt="arrow"
              />
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/arrow.svg`}
                alt="arrow"
                style={{ transform: "rotate(180deg)" }}
              />
            </div> */}
              <button
                className="p-grey sort"
                data-sort="initiator_id"
                style={{ cursor: "default" }}
                // onClick={(e) => sortFunc(e.target)}
              >
                Инициатор
                {filter === "initiator_id" ? <SortBtn /> : <></>}
              </button>
            </div>
          )}

          {selectColumns.includes("name") && (
            <button
              className="p-grey sort"
              data-sort="name"
              onClick={(e) => {
                sortFunc(e.target);
                setSortActive(!sortActive);
                sortActive && setFilterMethod("asc");
                !sortActive && setFilterMethod("desc");
                sortActive &&
                  axios
                    .get(
                      `${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=name`,
                      {
                        headers: {
                          "secret-token": document.cookie.replace(
                            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                            "$1"
                          ),
                          Accept: "application/json",
                          "company-id": company_id,
                          Authorization: bearer,
                        },
                      }
                    )
                    .then((res) => {
                      setBpList(res.data.data);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                !sortActive &&
                  axios
                    .get(
                      `${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=-name`,
                      {
                        headers: {
                          "secret-token": document.cookie.replace(
                            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                            "$1"
                          ),
                          Accept: "application/json",
                          "company-id": company_id,
                          Authorization: bearer,
                        },
                      }
                    )
                    .then((res) => {
                      setBpList(res.data.data);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
              }}
              style={{ marginLeft: "35px" }}
            >
              Наименование
              {filter === "name" ? <SortBtn name={"Наименование"} /> : <></>}
            </button>
          )}
        </div>

        <div className="status_header">
          {selectColumns.includes("deadline") ? null : (
            <span style={{ marginRight: "30px", width: "65px" }}></span>
          )}

          {selectColumns.includes("project_id") ? null : (
            <span style={{ marginRight: "325px", width: "65px" }}></span>
          )}

          {selectColumns.includes("status") ? (
            <button
              className="p-grey sort"
              data-sort="status"
              onClick={(e) => {
                sortFunc(e.target);
                setSortActive(!sortActive);
                sortActive && setFilterMethod("asc");
                !sortActive && setFilterMethod("desc");
                sortActive &&
                  axios
                    .get(
                      `${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=status`,
                      {
                        headers: {
                          "secret-token": document.cookie.replace(
                            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                            "$1"
                          ),
                          Accept: "application/json",
                          "company-id": company_id,
                          Authorization: bearer,
                        },
                      }
                    )
                    .then((res) => {
                      setBpList(res.data.data);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                !sortActive &&
                  axios
                    .get(
                      `${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=-status`,
                      {
                        headers: {
                          "secret-token": document.cookie.replace(
                            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                            "$1"
                          ),
                          Accept: "application/json",
                          "company-id": company_id,
                          Authorization: bearer,
                        },
                      }
                    )
                    .then((res) => {
                      setBpList(res.data.data);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
              }}
              style={{ marginLeft: "100px", width: "65px" }}
            >
              Статус
              {filter === "status" ? <SortBtn name={"Статус"} /> : <></>}
            </button>
          ) : (
            <span style={{ marginLeft: "100px", width: "65px" }}></span>
          )}

          {selectColumns.includes("project_id") ? null : selectColumns.includes(
              "status"
            ) ? (
            <span style={{ marginRight: "90px", width: "65px" }}></span>
          ) : (
            <>
              <span style={{ marginRight: "90px", width: "65px" }}></span>
              <span style={{ marginRight: "44px", width: "65px" }}></span>
            </>
          )}

          {selectColumns.includes("deadline") ? (
            <button
              className="p-grey sort"
              data-sort="deadline"
              onClick={(e) => {
                sortFunc(e.target);
                setSortActive(!sortActive);
                sortActive && setFilterMethod("asc");
                !sortActive && setFilterMethod("desc");
                sortActive &&
                  axios
                    .get(
                      `${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=deadline`,
                      {
                        headers: {
                          "secret-token": document.cookie.replace(
                            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                            "$1"
                          ),
                          Accept: "application/json",
                          "company-id": company_id,
                          Authorization: bearer,
                        },
                      }
                    )
                    .then((res) => {
                      setBpList(res.data.data);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                !sortActive &&
                  axios
                    .get(
                      `${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=-deadline`,
                      {
                        headers: {
                          "secret-token": document.cookie.replace(
                            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                            "$1"
                          ),
                          Accept: "application/json",
                          "company-id": company_id,
                          Authorization: bearer,
                        },
                      }
                    )
                    .then((res) => {
                      setBpList(res.data.data);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
              }}
              style={{ marginRight: "170px", width: "65px" }}
            >
              Дедлайн
              {filter === "deadline" ? <SortBtn name={"Дедлайн"} /> : <></>}
            </button>
          ) : (
            <span style={{ marginRight: "170px", width: "65px" }}></span>
          )}

          {/* <button
            className="p-grey sort"
            data-sort="id5"
            onClick={(e) => sortFunc(e.target)}
          >
            Исполнитель
            {filter === "id5" ? <SortBtn /> : <></>}
          </button> */}

          {selectColumns.includes("project_id") && (
            <button
              className="p-grey sort"
              data-sort="project_id"
              style={{ cursor: "default" }}
              // style={{marginLeft: "100px"}}
              // onClick={(e) => sortFunc(e.target)}
            >
              Проект
              {filter === "project_id" ? <SortBtn /> : <></>}
            </button>
          )}
          {/* {openMenuTasks ||
          openMenuBp ||
          createTaskStatus ||
          createBpSampleStatus ? (
            <></>
          ) : ( 
            <button
              className="p-grey sort"
              data-sort="id7"
              onClick={(e) => sortFunc(e.target)}
            >
              Приоритет
              {filter === "id7" ? <SortBtn /> : <></>}
            </button>
          )} */}
        </div>
      </div>
      {/* Переход к общему списку БП и обнуление данных */}
      {relatedBp && (
        <div className="redirect-to-general-list">
          <span
            onClick={() => {
              setRelatedBp();
              setSelectedBp();
              setTaskId();
              setOpenMenuTasks();
              setOpenMenuBp();
              history.push("/bp");
            }}
          >
            Вернуться к общему списку БП
          </span>
        </div>
      )}
      <div className="business__main-content__list-block__container">
        <div>
          {bpList && relatedBp ? (
            <BpItem
              el={relatedBp}
              key={relatedBp.id}
              redirect={!!taskIdInLocation}
            />
          ) : (
            bpList.map((bpItem) => <BpItem el={bpItem} key={bpItem.id} />)
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessMainList;
