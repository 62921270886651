/*** Регистрация ****/
import React, { useState } from 'react';
import { validateName } from "../../../utils/validPatterns";
import { validateSurname } from "../../../utils/validPatterns";
import CustomBlockStandart from './CustomBlock';
import CustomBlock from './CustomBlockRegistration';
import styled from 'styled-components';
import CustomInput from '../../../common/components/newComponent/Inputs/CustomInput';
import Checkbox from '../../../common/components/CheckboxItem/Checkbox';
import Button from '../../../common/components/newComponent/Buttons/Button';
import { validatePassword } from '../../../utils/validPatterns';
import { fetchData } from '../../../utils/fetchData';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { cookieMaster } from '../../../utils/CookieMaster';
import { useSelector,useDispatch } from 'react-redux';
import { State } from '../../../rootReducer';
import { urlApp } from '../../../App';
import palette from '../../../common/components/palette'; //******************************************** */
import { CreateNotif } from '../../../utils/createNotification';
import { fetchToken } from '../actions';
import Input from '../../../common/components/Inputs/Input';






const CounterStyle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  color: #707070;
  margin-bottom: 18px;
  text-align:center;
`;
const CheckboxBlock = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #707070;
  display: flex;
  margin: 16px 0;
  span {
    margin-left: 8px;
  }
`;
const WrapperBlockInputs = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 6px;
  position: relative;
  margin-bottom: 24px;

`;

const TextStyle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #707070;
  margin-bottom: 12px;
`;

//************************************** */
const TextStyle2 = styled(TextStyle)`
  color: #00c;
`;
//************************************* */

const TextStyle3 = styled(TextStyle)`
font-weight: bold;
text-align:justify;
`
/************************************* */

const Divider = styled.div`
width:5px;
height:5px;
`


const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    align-items: center;
    padding: 8px 0;
    width: 132px;
  }
`;

// Документы - политика конфеденциальности и оферта
const Document = styled.a`
href= ${({ href }) => (href)};
title= ${({ href }) => (href)};
`;

const P = styled.p`
margin-top:24px;
margin-left:5px;
text-align:center;
`


const Register = () => {

  let reg = false;

  const dispatch = useDispatch();
  const [counter, setCounter] = useState(1);
  const [confirmation, setСonfirmation] = useState(true);
  const [dataUser, setDataUser] = useState({
    email: '',
    name: '',
    surname: '',
    password: '',
  });
  let history = useHistory();
  // let { current_user } = useSelector((state: State) => state.commonInfo);
  let validationEmail =  /^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,5}$/i                // /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;
  let validationName =  /^[a-zA-Zа-яА-ЯЁё-\s]*$/;
  let validationSurname =   /^[a-zA-Zа-яА-ЯЁё-\s]*$/;

    /*
  useEffect(() => {
    let token = cookieMaster.getCookie('access_token');
    let company_id = localStorage.getItem('company_id') || '';
    if (token && company_id === '') {
      fetchData
        .get(`/api/v1/users/${current_user}/companies`)
        .then((data) => {
          if (data) {
            company_id = data.sort((a, b) => a - b)[0] || 0;
          } else {
            company_id = '0';
          }
          localStorage.setItem('company_id', company_id);
        })
        .then(() => {
          if (token && +company_id === 0) {
            setCounter(3);
          } else if (token && +company_id) {
            history.push('/');
          }
        });
    } else if (token && +company_id === 0) {
      setCounter(3);
    } else if (token && +company_id) {
      history.push('/');
    } else if (!token) {
      setCounter(1);
    }
  }, []);


  */

  const [visible,setVisible] = useState<boolean>(false)


  return (
   
    <CustomBlock
        style={{textAlign:'center'}}
        title={
        counter === 0
          ? 'Спасибо!'
          : counter === 1
          ? 'Быстрая регистрация'
          : counter === 2
          ? 'Добро пожаловать'
          : ''
      }
    >
  


 {/*counter === 2 && ( 
 
        <TextStyle3>
        <p>Мы отправили автоматически сформированный пароль на ваш e-mail. Введите его в поле «Пароль»</p> 
         <Divider/>
          <p>Если в течение десяти минут электронное письмо не было получено, проверьте правильность указанного адреса 
          или обратитесь в чат технической поддержки. Он находится с левой нижней стороны экрана.
          Наши специалисты помогут оперативно решить ваш вопрос.</p>
          <Divider/>    
        </TextStyle3>

 )*/}

      
      {counter <= 2 && counter !== 0 && (
        <CounterStyle>Шаг {counter}/2</CounterStyle>
      )}

      {counter === 1 && (
        
        <>
          <CustomInput
            type={"email"}
            placeholder="Email (новый)"
            value={dataUser.email}
            inputChangeHandler={(value) => {
              setDataUser((prev) => {
                return {
                  ...prev,
                  email: value,
                };
              });
            }}
          />

  
   <CheckboxBlock>
    {/*          <Checkbox
              checked={confirmation}
              onChange={() => {
                setСonfirmation(!confirmation);
              }}
            />
    
  */}

<span style={{fontSize:"12px"}}>Продолжая регистрацию, я согласен с <br/>
<Document target="_blank" href={'https://easy-task.ru/policy'} title={' условия, политика конфиденциальности '} >  условиями, политикой конфиденциальности  </Document>   
<br/>
 и
 <Document target="_blank" href={'https://easy-task.ru/oferta'} title={'договор оферты'} > договором оферты </Document>
</span>
</CheckboxBlock>        


        </>
        
      )}
      {counter === 2 && (
        <WrapperBlockInputs>
      
      {/* ********************************************************** */}

      <form autoComplete="off">     
  
   {/*   <CustomInput
       placeholder="Имя"
      value={dataUser.name}
      inputChangeHandler={(value) => {
       setDataUser((prev) => {
          return {
            ...prev,
           name: value,
          };
          });
         }}
        />   */}
        
   
      <Input
          inputType={"text"}
          placeholder="Имя"
          withIcon={false}
          value={dataUser.name}
          title="Имя"
          changeHandler={(value) => {
            setDataUser((prev) => {
              return {
                ...prev,
                name: value,
              };
            });
          }}
          isValid={dataUser.name ? validateName(dataUser.name) : true}
          validText="Имя должно состоять из русских или латинских букв"
            />    
        
     </form>   

     
{/* **********************************************************  */}  
 <div style={{height:"10px",width:"100%"}}></div>


    {/* Добавлено*/}

    <form autoComplete="off">
      
  {/*   <CustomInput
            placeholder="Фамилия"
            value={dataUser.surname}
            inputChangeHandler={(value) => {
              setDataUser((prev) => {
                return {
                  ...prev,
                  surname: value,
                };
              });
            }}
          />     
          */}     
 
          <Input
          inputType={"text"}
          placeholder="Фамилия"
          withIcon={false}
          value={dataUser.surname}
          title="Фамилия"
          changeHandler={(value) => {
            setDataUser((prev) => {
              return {
                ...prev,
                surname: value,
              };
            });
          }}
          isValid={dataUser.surname ? validateSurname(dataUser.surname) : true}
          validText="Фамилия должна состоять из русских или латинских букв"
            />    
          

      </form>
    
     {/* ********************************************************** 
     <div style={{height:"1px",width:"100%",borderTop:`1px  ${palette.lightGray} solid`}}></div>
      **********************************************************  
     <div style={{ height: "0px", overflow: "hidden", background: "transparent" }}>
        <input type="password"></input>     
    </div>
     {/* ********************************************************** */}  

   
      <form autoComplete="off">
   {/*
        <CustomInput
            placeholder="Email"
            value={dataUser.email}
            inputChangeHandler={(value) => {
              setDataUser((prev) => {
                return {
                  ...prev,
                  email: value,
                };
              });
            }}
            />
 */} 
      </form>
      

     {/* **********************************************************  
     <div style={{height:"1px",width:"100%",borderTop:`1px  ${palette.lightGray} solid`}}></div>
      ********************************************************** */}

    {/* <span style={{margin:"10px 0 5px 5px",color:"#02405e"}}> Введите пароль, отправленный вам на почту </span> */}

     {/* **********************************************************  */}
     <div style={{height:"10px",width:"100%"}}></div>
  

        <form autoComplete="off">   
     
        <CustomInput
            style={{minHeight:"15px"}}
            type={visible?"text":"password"}
            placeholder="Введите пароль, отправленный вам на почту"
            value={dataUser.password}
            inputChangeHandler={(value) => {
              setDataUser((prev) => {
                return {
                  ...prev,
                  password: value,
                };
              });
            }}
          />    
   </form>  
        <img onClick={()=>{setVisible(!visible)}}
          style={{position:"relative",top:"-25px",left:"320px", width:"16px", height:"10px"}} src={require('../../../img/password_eye.png')} />
      
   

         {/*
        <Input
          inputType={visible?"text":"password"}
          placeholder="Пароль"
          withIcon={true}
          value={dataUser.password}
          title="Пароль из письма"
          changeHandler={(value) => {
            setDataUser((prev) => {
              return {
                ...prev,
                password: value,
              };
            });
          }}
            />
        */}
        
        

            
   
           
        
      

           {/* ********************************************************** 
           <div style={{height:"1px",width:"100%",borderTop:`1px  ${palette.lightGray} solid`}}></div>*/}

    


           {/* ********************************************************** */}
        </WrapperBlockInputs>
      )}
      {counter === 0 && (
        <>
        <TextStyle>
          Если вы вводили email, то мы отправили письмо для подтверждения регистрации на ваш адрес электронной почты. 
          Если в течение десяти минут электронное письмо не получено, проверьте, пожалуйста, правильность указанного адреса.
        </TextStyle>
         <TextStyle2>
         Если на ваш адрес электронной почты не поступило письмо с логином и паролем, пожалуйста, обратитесь в чат 
         технической поддержки. Он находится с левой нижней стороны экрана. Наши специалисты помогут оперативно решить ваш вопрос.
         </TextStyle2>
         </>
      )}
      {counter < 3 ? (
        <>
          <Button
            big
            disabled={
              (counter === 1 &&
                confirmation &&
                validationEmail.test(dataUser.email)) ||
              (counter === 2 &&
                dataUser.name.length >= 1 &&
                dataUser.surname.length >= 1 &&
                dataUser.password.length >= 1 &&
                validationName.test(dataUser.name) &&
                validationSurname.test(dataUser.surname) 
              )
                ||
              counter === 0
                ? false
                : true
            }
            title={counter < 3 ? 'Продолжить' : 'Готово'}
            onClick={() => {
              if (counter === 0) {
            //   alert("counter0")
            //    history.push('/login');
              } 
              else if (counter === 1) {

                let dataUserSend1={email:""}
                dataUserSend1.email = dataUser.email; // Введенный email

         //       let isEmail = localStorage.getItem(dataUserSend1.email);
         //       if (isEmail && isEmail=="true") {  
         //         setCounter(counter + 1);                 
         //       }   else {



                fetch(urlApp + '/api/v1/registration/step1', {          
                  credentials: 'include',      
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                  },
                  body: JSON.stringify(dataUserSend1),
                })
                .then(function (response) {
                  return response.json()
                })
                .then(function (data) {
                  if (data.hasOwnProperty('email')){
                  // console.log('data', data)
                  setCounter(counter + 1); 
              //    localStorage.setItem(dataUserSend1.email, "true");
                    localStorage.setItem("regEmail", dataUserSend1.email);
                  }
                  else if ('error' in data){
                 CreateNotif('Такой email уже зарегистрирован. Введите, пожалуйста, другой email!', 'warning');
                 setCounter(1);
                  }
                })
                .catch(function (error) {
                  console.log('error', error);
                  CreateNotif('Ошибка при регистрации. Попробуйте заново!', 'error');
                  setCounter(1);
                })   

          //    }

              }
              else if (counter === 2) {

                let dataUserSend2={email:"",name:"",surname:"",password:""}
                dataUserSend2.email = dataUser.email; // Введенный email
                dataUserSend2.name = dataUser.name; // Введенное имя
                dataUserSend2.surname = dataUser.surname; // Введенная фамилия
                dataUserSend2.password = dataUser.password; // Введенный пароль
                
                // console.log("dataUserSend2",dataUserSend2)

                fetch(urlApp + '/users/registration', {
                  credentials: 'include',
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json;charset=utf-8'             
                  },
                  body: JSON.stringify(dataUserSend2),
                })
                .then(function (response) {
                  // console.log("response",response)
                  return response.json()
                })
                .then(function (data) {

                  if (data.hasOwnProperty('data') ){


                      localStorage.removeItem("regEmail");

   //                 localStorage.setItem(dataUser.email, "false");
   //                 localStorage.removeItem(dataUser.email);
                
                      reg = true;

                      // console.log("reg",reg)





 //************************************************************************************* */
 //if (reg==true) {


  let dataUserSend3={email:"",password:""}
  dataUserSend3.email = dataUser.email; // Введенный email
  dataUserSend3.password = dataUser.password; // Введенный пароль
  
  // console.log("dataUserSend3",dataUserSend3)
 
 
 
  fetch(urlApp + '/api/v1/login', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json;charset=utf-8'
   },
   body: JSON.stringify(dataUserSend3),
 })
 .then(function (response) {
   return response.json()
 })
 .then(function (data) {
 
   if ('user_id' in data){
 
    // console.log('data', data)
     
     localStorage.setItem('access_token', data.access_token);
     localStorage.setItem('company_id', data.company_id);
     localStorage.setItem('user_id', data.user_id);
     localStorage.setItem('firstOpen', 'true');
     
 
     let token = data.access_token;
 
     dispatch(fetchToken(dataUserSend3.email, dataUserSend3.password));
     
     token = cookieMaster.getCookie('access_token');
     
 
 
     setTimeout(() => {if (token) {
       history.push('/') }      }, 500)
     
 
     }
     else if ('error' in data){
     CreateNotif('Введен неверный пароль!  ', 'warning');
     setCounter(2);
     }
 })
 .catch(function (error) {
   console.log('error', error);         
   CreateNotif('Ошибка при регистрации. Попробуйте заново!', 'error');
   setCounter(2);
 })   
//}

//***************************************************************************************************** */

















                    }
                    else if ('error' in data){
                    CreateNotif('Введен неверный пароль!  ', 'warning');
                    setCounter(2);
                    }
                })
                .catch(function (error) {
                  console.log('error', error);         
                  CreateNotif('Ошибка при регистрации. Попробуйте заново!', 'error');
                  setCounter(2);
                })   


 


              } else {
                setCounter(0);
              }
            }}
          />
        {/*counter === 1 && (
            <Button
              big
              design="secondary"
              title="Вперед"
              onClick={() => setCounter(2)}
              style={{ marginTop: '8px', marginBottom: '8px' }}
        />    ) */}

          {counter === 2 && (
            <Button
              big
              design="secondary"
              title="Назад"
              onClick={() => setCounter(1)}
              style={{ marginTop: '8px' }}
            />
          )}
        </>
      ) : (
        <WrapperButtons>
        </WrapperButtons>
      )}
    </CustomBlock>
  );
};

export default Register;