import React, { useState, useEffect, useCallback } from "react";
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn";
import {
  delegateConfirm,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setTaskSpectators,
  setSpectatorId,
  setExecutorSchedule,     //****************************** */
  // setDelegateTo,
  // openExistingTask,
  setProvideTo,
} from "../actions";
import { connect,useDispatch } from "react-redux";
import { User, Item, Schedule, Task } from "../../../common/types";
import { Collapse } from "@material-ui/core";
import { State } from "../../../rootReducer";
import { fetchData } from "../../../utils/fetchData";
import { store } from "../../../App";
import CheckboxItem from "../../../common/components/CheckboxItem/CheckboxItem";
import Checkbox from "../../../common/components/CheckboxItem/Checkbox";
import MultySelectTaskEx from "../../../common/components/Selects/MultySelectTaskExSpectator"; //создаем отдельную форму выбора для ответственного по задаче
import { CreateNotif } from "../../../utils/createNotification";
import styled from "styled-components";
import palette from "../../../common/components/palette";
import Button from "../../../common/components/newComponent/Buttons/Button";
import { updateTask } from "../../../common/actions";

type Props = {
  checkedPersonalTask?:boolean;
  disabled?:boolean;
  projectId: number | null;
  users: User[];
  spectators: number[];    //****************************** */
  // delegated: boolean;
  selectedUserId: number;
  provide_to: number;
  // setDelegateTo: (delegateTo: number) => void;
  setExecutorSchedule: (schedule: Schedule) => void;     //****************************** */
  // openExistingTask: (task: Task) => void;
  setProvideTo: (val: number) => void;
  selectedItems: Item[];
  setSelectedItems: (arg: Item[]) => void;
  // successfulCreatedTask: number[];
  currentUserRoles: number[] | undefined;
  selectedTask: Task | null;
  isShownTaskInfoPlate: boolean;
  delegateConfirm: (users_ids: number[]) => void;
  updateTask: (taskId: number, params: object) => void;
  flagForActionCyclycTask: string;
  setCyclicTaskToChange: (obj: {}) => void;
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  isScheduler?: boolean;
};

const WrapperExecuterWithDelegate = styled.div`
  width: 100%;
`;

const WrapperConfirmButton = styled.div``;

const BlockCheckbox = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 40px;
  color: ${palette.darkBlue};
`;

const SpectatorSelect: React.FC<Props> = ({
  checkedPersonalTask, 
  disabled,
  selectedUserId,
  projectId,
  users,
  spectators,    //****************************** */
  setExecutorSchedule,    //****************************** */
  setProvideTo,
  provide_to,
  selectedItems,
  setSelectedItems,
  currentUserRoles,
  selectedTask,
  delegateConfirm,
  updateTask,
  isShownTaskInfoPlate,
  flagForActionCyclycTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
  isScheduler,
}) => {

  const dispatch = useDispatch(); 

  // const [show, setShow] = useState<boolean>(false)
  const [usersIdList, setUsersIdList] = useState([]); // айдишники пользователей, доступных в этом проекте
  const [thisFirstRender, setFirstRender] = useState(true);
  const [spectatorData, setSpectatorData] = useState(
    // выпадающий список
    {
      selected: null as Item | null,
      list: [] as Item[],
      delegatedTo: null as Item | null,
    }
  );

 /* function setLocalSpectator() {
    let userItem = users.find((user) => user.id === selectedTask?.spectators); 
    if (userItem) {
      setSpectatorData({
        selected: {
          text: `${userItem?.surname} ${userItem?.name}`,
          value: userItem?.id,
          urlImage: userItem?.image?.url,
        },
        list: spectatorData.list,
        delegatedTo: spectatorData.delegatedTo,
      });
    }
  }*/

 /* if (thisFirstRender) {
    if (selectedTask) setLocalSpectator();
    setFirstRender(false);
  }*/
/*
  useEffect(() => {
    if (!usersIdList.length) {
      // если список пользователей по проекту еще не получили,
      if (!spectatorData.selected) {
        // и наблюдатель не выбран
        users.forEach((userObj) => {
          if (userObj.id === selectedUserId) {
            // то ставим исполнителем того, задачи которого сейчас открыты.
            setSelectedItems([
              // ...selectedItems,
              {
                text: `${userObj.surname} ${userObj.name}`,
                value: userObj.id,
                urlImage: userObj?.image?.url,
              },
            ]);
          }
        });
      }
    }
  }, [selectedUserId, isShownTaskInfoPlate]);
*/

  useEffect(() => {
    // при скрытии плашки все очищать
    if (!isShownTaskInfoPlate) {
        setSpectatorData({
        selected: null,
        list: [],
        delegatedTo: null,
      });
      setUsersIdList([]);
    }
  }, [isShownTaskInfoPlate]);

  /*useEffect(() => {
    setLocalSpectator();
  }, [selectedTask]);*/
  

  useEffect(() => {
    (async () => {
      if (projectId || selectedTask) {
        let usersIdForProject = await fetchData.get(
          `/api/v1/projects/${
            selectedTask ? selectedTask.project_id : projectId
          }/users`
        );

        // получили юзеров по этому проекту. Если в этом массиве айдишников есть тот юзер, который сейчас стоит исполнителем - по которому открыты задачи, то его и оставляем. Если нет - значит просто стереть.
        let found;
        if (usersIdForProject) {
          usersIdForProject = Object.values(usersIdForProject); // в старых проектах данные хранились в виде объекта, а не массива. Для гарантии конвертирую в массив.
          if (usersIdForProject.length) {
            usersIdForProject.forEach((id) => {
              if (id === selectedUserId) found = true;
            });
            setUsersIdList(usersIdForProject);
          }

          if (!found) {
            setSpectatorData({
              selected: null,
              list: spectatorData.list,
              delegatedTo: spectatorData.delegatedTo,
            });
          }
        }
      }
    })();
  }, [projectId, selectedTask]);

 /* useEffect(() => {
    (async () => {
      if (!executor_id) return;           
      const data = await fetchData.get(`/api/v1/users/${executor_id}/schedule`);
      setExecutorSchedule(data?.schedule);    
    })();
  }, [executor_id]);   
*/
  useEffect(() => {
    if (isShownTaskInfoPlate || isScheduler) {
      let selected = spectatorData.selected,
        delegatedTo = spectatorData.delegatedTo,
        list: Item[] = [];

      // здесь формируется выпадающий список
      usersIdList.forEach((userId) => {
        // users - это commonUsers
        // достаю полную информацию о пользователе из commonInfo, имея только айдишники пользователей, доступных в этом проекте
        let user = users.find((user) => user.id === userId);
        if (user) {
          let item = {
            text: `${user.surname} ${user.name}`,
            value: user.id,
            urlImage: user?.image?.url,
          };
          // if (user.id === executor_id) selected = item
          list.push(item);
        }
      });
      setSpectatorData({ selected, list, delegatedTo });
    }
  }, [spectators, users, usersIdList]);     //****************************** */

  useEffect(() => {
    if (!isShownTaskInfoPlate) {
      // document.getElementsByClassName('task_info_plate')[0].getElementsByClassName('executor_select')[0].innerHTML=''
      // yy.nodeValue = ''
      // yy.innerText = ''
    }
  }, [isShownTaskInfoPlate]);

  return (
    <WrapperExecuterWithDelegate>

      {/* currentUserRoles?.[0] !== 7 && !selectedTask && !checkedPersonalTask && (
        <BlockCheckbox
          onClick={() => {
            // provide_to === 1 ? setProvideTo(0) : setProvideTo(1)
          }}
        >
          <Checkbox
            disabled={checkedPersonalTask?true:false}
            checked={!!provide_to}
            onChange={(evt) => {
              provide_to === 1&& checkedPersonalTask===false ? setProvideTo(0) : setProvideTo(1);
            }}
          />

        <div onClick={()=>{  provide_to === 1 && checkedPersonalTask===false? setProvideTo(0) : setProvideTo(1)} }
           style={{ marginLeft: '.5rem' }}>Разрешить делегирование</div>
                     <img
                  style={{marginLeft:"5px", width:"20px", height:"20px"}}  
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/question.svg`}
                  title="Исполнители смогут единожды добавить новых исполнителей по задаче. При этом создаются дочерние задачи, где автором будет указан исполнитель, делегировавший задачу."
                  />
        </BlockCheckbox>
      )*/}

      {/* Для исполнителя задачи создаем собственную форму выбора */}
      
      <MultySelectTaskEx     //****************************** */
        classname={"executor_select"}     //****************************** */
        radio={false}
        disabled={disabled || usersIdList.length === 0 }
        showSearchField={
          true
        }
        itemsList={spectatorData.list}
        selectedItems={
       selectedItems
        }
        addHandler={(arg) => {
          // добавляется элемент в массив уже показываемых в чипсах пользователей
          // if (selectedTask) setTaskExecutor([arg])
          // else

          let selectedItemsId = selectedItems.map(item=>+item.value)

          if (selectedTask) {
           /* if (selectedTask.cyclic_task_id > 0) {
              // эта задача вообще циклическая или сама по себе ?
              // значит циклическая
              if (flagForActionCyclycTask === "") {
                setCyclicTaskToChange({ executor_id: arg });   
              } else if (flagForActionCyclycTask === "task") {
                updateTask(selectedTask.id, { executor_id: arg });   
              } else {
                updateTask(selectedTask.id, { executor_id: arg });    
                sendReqToChangeTemplRepTask({ executor_id: arg });  
              }
            } else {*/
              updateTask(selectedTask.id, { spectators: [...selectedItemsId,arg] });  
              dispatch(setSpectatorId( [...selectedItemsId,arg] ));
              setSelectedItems([
                ...selectedItems,
                spectatorData.list.find((item) => item.value === arg) as Item,
              ]);
        //    }
          } else {
            console.log("arg",arg);
            dispatch(setSpectatorId( [...selectedItemsId,arg] ));
            setSelectedItems([
              ...selectedItems,
              spectatorData.list.find((item) => item.value === arg) as Item,
            ]);
          }
        }}
        removeHandler={(arg) => {
        /*  if (selectedTask) {
            setSpectatorData({
              selected: null,
              list: spectatorData.list,
              delegatedTo: spectatorData.delegatedTo,
            });
          } else {*/
            let coped_array = selectedItems.map((each) => ({
              value: each.value,
              text: each.text,
              urlImage: each.urlImage,
            }));
            coped_array.splice(
              coped_array.findIndex((item) => item.value === arg),
              1
            );
            setSelectedItems(coped_array); // соответственно удаляется

            if (selectedTask) {

              let selectedItemsId = coped_array.map(item=>+item.value)

            updateTask(selectedTask.id, { spectators: selectedItemsId });  
            }
        //  }
        }}
        textFieldValue={!isShownTaskInfoPlate ? "" : undefined}
      />
    </WrapperExecuterWithDelegate>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    currentUserRoles: state.commonInfo.currentUserInfo?.roles,
    provide_to: state.taskInfoPlate.provide_to,
    selectedUserId: state.tasksPage.selectedUserId,
    spectators: state.taskInfoPlate.spectators,     //****************************** */
    users: state.commonInfo.users,
    projectId: state.taskInfoPlate.project_id,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
  };
};

const mapDispatchToProps = {
  updateTask,
  setExecutorSchedule,     //****************************** */
  delegateConfirm,
  setProvideTo,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(SpectatorSelect);
