import React, {
	ChangeEvent,
	useEffect,
	useRef,
	useState,
	FC,
} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { toSchedulerHeaderColor } from '../../config';
import { State } from '../../../../rootReducer';

import { readNotificationByTask } from "../../../Tasks/actions";
import {
	setShownTaskInfoPlate,
	isSendingNewTask,
	successfulCreatedNewTasks,
	createTask,
	setTaskName,
	setTaskDescription,
	setTaskBegin,
	setTaskEnd,
	setTaskSection,
	selectTask,
} from "../../../TaskInfoPlate/actions";

import Meeting from '../Meeting';
import Conference from '../Conference';
import ColorPicker from '../ColorPicker';
import LightboxActions from '../LightboxActions';
import ButtonActions from '../ButtonActions';
import Task from '../Task';

import {Item, Task as TaskType} from "../../../../common/types";
import './style.sass';
import {getSectionSelectList} from "../../../TaskInfoPlate/selectors";

const Header = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 40px;
	gap: 10px;
	background: ${props => toSchedulerHeaderColor(props.color)};
	color: white;
	padding-left: 15px;
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 15px;
  overflow-y: scroll;
  height: 100%;
  max-height: 632px;
`;
export interface IRestData {
	description: string;
	name: string;
}

export type Colors = 'orange' | 'green' | 'blue' | 'violet' | 'red';
export type Event = 'meeting' | 'task' | 'conference';

const scheduler = window.scheduler;

const Lightbox: FC = () => {
	const dispatch = useDispatch();
	const state = useSelector((state: State) => state);
	const { checkList } = useSelector((state: State) => state.taskInfoPlate);
	const { currentUserInfo } = useSelector((state: State) => state.commonInfo);
	const [restData, setRestData] = useState<IRestData>({
		name: 'Новое событие',
		description: 'Описание нового события',
	});
	const [participants, setParticipants] = useState<Array<number | string>>([]);
	const [selectedItems, setSelectedItems] = useState<Item[]>([]);
	const [files, setFiles] = useState<Array<File>>([]);
	const [color, setColor] = useState<Colors>('blue');
	const [isCreatedTask, setIsCreatedTask] = useState<boolean>(false);

	const [startDate, setStartDate] = useState<string>('');
	const [endDate, setEndDate] = useState<string>('');
	
	const [startTime, setStartTime] = useState<string>('');
	const [endTime, setEndTime] = useState<string>('');

	const [eventType, setEventType] = useState<Event>('conference');

	const formRef = useRef<HTMLDivElement>(null);

	const saveForm = () => {
		const	sectionSelect = getSectionSelectList(state);
		const parseDate = scheduler.date.str_to_date("%Y-%m-%d");
		const event = scheduler.getEvent(scheduler.getState().lightbox_id);
		const startDateTime = moment(startDate + ' ' + startTime).format();
		const endDateTime = moment(endDate + ' ' + endTime).format();

		event.text = restData.name;
		event.description = restData.description;
		event.color = color;
		event.files = files;
		event.participants = participants;
		event.holder = currentUserInfo?.id;
		event.eventType = eventType;
		event.start_date = parseDate(startDateTime);
		event.end_date = parseDate(endDateTime);

		if (formRef.current) {
			scheduler.endLightbox(true, formRef.current);
		}

		if (eventType === 'task') {
			const start = moment(startDateTime).format('DD-MM-YYYY HH:mm:ss');
			const end = moment(endDateTime).format('DD-MM-YYYY HH:mm:ss');
			dispatch(setTaskSection(sectionSelect.selected?.value as number));
			dispatch(setTaskName(restData.name));
			dispatch(setTaskDescription(restData.description));
			dispatch(setTaskBegin(start))
			dispatch(setTaskEnd(end))
			dispatch(isSendingNewTask(true));
			dispatch(successfulCreatedNewTasks({
				createdTasks: [],
				executorsIds: selectedItems.map((item) => item.value) as [],
				checkItems: [...checkList] as [],
			}));
			if (selectedItems.length) {
				selectedItems.forEach((item) =>
					dispatch(createTask(item.value as number))
				);
			} else {
				dispatch(createTask(currentUserInfo?.id as number, true));
			}
		}
	};

	const closeForm = () => {
		if (formRef.current) {
			dispatch(selectTask(null));
			scheduler.endLightbox(false, formRef.current);
		}
	};

	const deleteForm = () => {
		if (formRef.current) {
			const event_id = scheduler.getState().lightbox_id;
			dispatch(selectTask(null));
			scheduler.endLightbox(false, formRef.current);
			scheduler.deleteEvent(event_id);
		}
	};

	const changeDate = (event: ChangeEvent<HTMLInputElement>, isEndDate: boolean) => {
		const date = moment(event.target.value).format('YYYY-MM-DD');

		if (isEndDate) {
			setEndDate(date);
		} else {
			setStartDate(date);
		}
	};

	const changeTime = (event: ChangeEvent<HTMLInputElement>, isEndTime: boolean) => {
		if (isEndTime) {
			setEndTime(event.target.value);
		} else {
			setStartTime(event.target.value);
		}
	};

	const handleChangeData = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) => {
		setRestData((prevData) => {
			return {
				...prevData,
				[type]: event.target.value,
			};
		});
	};

	useEffect(() => {
		scheduler.showLightbox = (id) => {
			if (formRef.current) {
				const ev = scheduler.getEvent(id);

				const eventStartDate = moment(ev.start_date).format('YYYY-MM-DD');
				const eventEndDate = moment(ev.end_date).format('YYYY-MM-DD');

				const eventStartTime = moment(ev.start_date).format('HH:mm');
				const eventEndTime = moment(ev.end_date).format('HH:mm');
				const data = ev.description ? { name: ev.text, description: ev.description } : { name: 'Новое событие', description: 'Описание нового события' };

				scheduler.startLightbox(id, formRef.current);

				setStartDate(eventStartDate);
				setEndDate(eventEndDate);

				setStartTime(eventStartTime);
				setEndTime(eventEndTime);

				setColor(ev.color || 'blue');
				setParticipants(ev.participants || []);
				setFiles(ev.files || []);
				setRestData(data);
				setEventType(ev.eventType || 'task');

				if (ev.eventType === 'task') {
					dispatch(readNotificationByTask(ev.id));
					dispatch(selectTask(ev as TaskType))
					dispatch(setShownTaskInfoPlate(false));
				}

				if (ev.eventType === 'task') {
					setIsCreatedTask(true);
				}
			}
		};
	}, [currentUserInfo]);

	return (
		<div ref={formRef} id="lightbox-form">
			<Header>
				<span>{startTime} - {endTime}</span>
				<span>{restData.name}</span>
			</Header>
			<FormContainer>
				<LightboxActions
					isCreatedTask={isCreatedTask}
					event={eventType}
					setEvent={setEventType}
				/>
				{eventType === 'meeting' && <Meeting
          color={color}
          setColor={setColor}
					endDate={endDate}
					endTime={endTime}
					startTime={startTime}
					startDate={startDate}
					changeDate={changeDate}
					changeTime={changeTime}
					files={files}
					participants={participants}
					setParticipants={setParticipants}
					setFiles={setFiles}
					restData={restData}
					handleChangeData={handleChangeData}
				/>}
				{eventType === 'conference' && <Conference
          color={color}
          setColor={setColor}
          endDate={endDate}
          endTime={endTime}
          startTime={startTime}
          startDate={startDate}
          changeDate={changeDate}
          changeTime={changeTime}
					files={files}
					participants={participants}
					setParticipants={setParticipants}
					setFiles={setFiles}
					restData={restData}
					handleChangeData={handleChangeData}
				/>}
				{eventType === 'task' && <Task
					color={color}
					setColor={setColor}
          endDate={endDate}
          endTime={endTime}
          startTime={startTime}
          startDate={startDate}
          changeDate={changeDate}
          changeTime={changeTime}
					selectedItems={selectedItems}
					setSelectedItems={setSelectedItems}
					restData={restData}
					handleChangeData={handleChangeData}
				/>}
			</FormContainer>
			<ButtonActions
				eventType={eventType}
				isCreatedTask={isCreatedTask}
				deleteForm={deleteForm}
				closeForm={closeForm}
				saveForm={saveForm}
			/>
		</div>
	);
};

export default Lightbox;
