import {
    types,
    createTaskAction,
    setTaskNameAction,
    setTaskDescriptionAction,
    clearTaskAction,
    setTaskPriorityAction,
    setTaskProjectAction,
    setTaskExecutorAction,
    setTaskSpectatorAction,
    setTaskBeginAction,
    setTaskEndAction,
    setTaskSectionAction,
    setIsSendingTaskAction,
    setTaskWorkLoadAction,
    setTaskLoadAction,
    setExecutorScheduleAction,
    setSpectatorScheduleAction,
    openExistingTaskAction,
    setProjectSectionsAction,
    fetchProjectSectionsAction,
    setPrevTaskAction,
    setNextTaskAction,
    setParentTaskAction,
    setChildTasksAction,
    // delegateTaskAction,
    setDelegateToAction,
    setRecalculateLoadAction, setLastChangedParameter, setSetProvideTo, successful_create_new_task_action
} from './action_types'
import {Schedule, Task, Section} from '../../common/types'


export const setTaskName = (name: string): setTaskNameAction => ({
    type: types.SET_TASK_NAME,
    name
})

export const setTaskDescription = (description: string): setTaskDescriptionAction => ({
    type: types.SET_TASK_DESCRIPTION,
    description
})

export const clearTask = (): clearTaskAction => ({
    type: types.CLEAR_TASK
})

export const setTaskPriority = (priority: number): setTaskPriorityAction => ({
    type: types.SET_TASK_PRIORITY,
    priority
})

export const setTaskProject = (projectId: number): setTaskProjectAction => ({
    type: types.SET_TASK_PROJECT,
    projectId
})

export const setTaskExecutor = (executor_id: number | null): setTaskExecutorAction => ({
    type: types.SET_TASK_EXECUTOR,
    executor_id
})

export const setTaskSpectator = (spectators: number[]): setTaskSpectatorAction => ({
    type: types.SET_TASK_SPECTATOR,
    spectators
})

export const setTaskBegin = (begin: string): setTaskBeginAction => ({
    type: types.SET_TASK_BEGIN,
    begin
})

export const setTaskEnd = (end: string): setTaskEndAction => ({
    type: types.SET_TASK_END,
    end
})

export const createTask = (executor_id?: number): createTaskAction => ({
    type: types.CREATE_TASK,
    executor_id
})

export const setTaskSection = (id: number): setTaskSectionAction => ({
    type: types.SET_TASK_SECTION,
    id
})

export const setIsSendingTask = (isSending: boolean): setIsSendingTaskAction => ({
    type: types.SET_IS_FETCHING_TASK,
    isSending
})

export const setTaskWorkLoad = (workLoad: number): setTaskWorkLoadAction => ({
    type: types.SET_TASK_WORKLOAD,
    workLoad
})

export const setTaskLoad = (taskLoad: number): setTaskLoadAction => ({
    type: types.SET_TASKLOAD,
    taskLoad
})

export const setExecutorSchedule = (schedule: Schedule): setExecutorScheduleAction => ({
    type: types.SET_EXECUTOR_SCHEDULE,
    schedule
})

export const setSpectatorSchedule = (schedule: Schedule): setSpectatorScheduleAction => ({
    type: types.SET_SPECTATOR_SCHEDULE,
    schedule
})

export const openExistingTask = (task: Task): openExistingTaskAction => ({
    type: types.OPEN_EXISTING_TASK,
    task
})

export const setProjectSections = (sections: Section[]): setProjectSectionsAction => ({
    type: types.SET_PROJECT_SECTIONS,
    sections
})

export const fetchProjectSections = (projectId: number): fetchProjectSectionsAction => ({
    type: types.FETCH_PROJECT_SECTIONS,
    projectId
})

export const setPrevTask = (id: number): setPrevTaskAction => ({
    type: types.SET_PREV_TASK,
    id
})
export const setNextTask = (id: number): setNextTaskAction => ({
    type: types.SET_NEXT_TASK,
    id
})
export const setParentTask = (id: number): setParentTaskAction => ({
    type: types.SET_PARENT_TASK,
    id
})
export const setChildTasks = (ids: number[]): setChildTasksAction => ({
    type: types.SET_CHILD_TASKS,
    ids
})

// export const delegateTask = (executor_id: number[], taskId: number): delegateTaskAction => ({
//     type: types.DELEGATE_TASK,
//     executor_id: executor_id,
//     taskId
// })

export const setDelegateTo = (delegateTo: number): setDelegateToAction => ({
    type: types.SET_DELEGATE_TO,
    delegateTo
})

export const recalculateTaskLoad = (arg?: object): setRecalculateLoadAction => ({
    type: types.SET_RECALCULATE,
    arg
})

export const lastChanged = (arg: string): setLastChangedParameter => ({
    type: types.SET_LAST_CHANGED,
    arg
})

export const setProvideTo = (val: number): setSetProvideTo => ({
    type: types.SET_PROVIDE_TO,
    val
})

export const successful_create_new_task = (val: number[]): successful_create_new_task_action => ({
    type: types.SUCCESSFUL_DELEGATED_NEW_TASK,
    val
})



// SET_PROVIDE_TO