import React, { forwardRef, useState, useEffect } from 'react';
import styled from 'styled-components';

const HideCheckbox = styled.input`
	display: none;
`;

const WrapperCheckbox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	padding: 0 8px;
	border-radius: 10px;
	cursor: pointer;
	min-width: 80px;
	max-width: 150px;
	width: 100%;
  border: 1px solid ${props => props.checked ? '#1baaf0' : 'rgb(229, 232, 235)'};
	background: ${props => props.checked ? '#1baaf0' : 'white'};
	color: ${props => props.checked ? 'white' : 'black'};
	
	&:hover {
    box-shadow: 0 5px 10px lightgrey;
	} 
`;

const CustomCheckbox = forwardRef<HTMLInputElement, any>(
	({ label, name, value, onChange, children, ...rest }, forwardedRef) => {
		const [checked, setChecked] = useState<boolean>(false);

		useEffect(() => {
			if (onChange) {
				onChange(checked);
			}
		}, [checked]);

		return (
			<WrapperCheckbox checked={checked} onClick={() => setChecked(!checked)}>
				<HideCheckbox
					ref={forwardedRef}
					type="checkbox"
					name={name}
					checked={checked}
					onChange={() => {}}
				/>
				{label}
			</WrapperCheckbox>
		);
	}
);

export default CustomCheckbox;