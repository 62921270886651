import React, {
	Dispatch,
	FC,
	SetStateAction,
	DragEvent,
	ChangeEvent,
} from 'react';
import styled from 'styled-components';

import ViewFile from './ViewFile';

import cloud from '../../../../img/upload_cloud.svg';

type Props = {
	files: Array<File>,
	setFiles: Dispatch<SetStateAction<Array<File>>>,
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	width: 100%;
`;

const WrapperFile = styled.div`
  height: 150px;
  position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
  border: 1px dashed #1BAAF0;
  border-radius: 4px;
	width: 100%;
  background-color: rgba(243,250,254,.9);
	flex-direction: column;
`;

const Description = styled.p`
  color: #1baaf0;
  font-size: 16px;
`;

const FakeInput = styled.input`
	opacity: 0;
	width: 100%;
	height: 100%;
  position: absolute;
`;

const InputsContainer = styled.div`
  width: 100%;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 7px;
`;

const LightboxFiles: FC<Props> = ({ setFiles, files }) => {

	const dragHandler = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const dropHandler = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();

		const data: Array<File> = Object.values(e.dataTransfer.files);
		const validated = data.filter((e) => e.size < 5 * 1024 * 1024);

		setFiles((prevFiles) => ([...prevFiles, ...validated]));
	};

	const handleAddFile = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const data = Object.values(e.target.files);
			const validated = data.filter((e) => e.size < 5 * 1024 * 1024);

			setFiles((prevFile) => ([...prevFile, ...validated]));
		}
	};

	const handleDeleteChips = (index) => {
		const updatedFiles = files.filter((e, i) => i !== index);
		setFiles(updatedFiles);
	};

	return (
		<Wrapper>
			<WrapperFile
				onDragOver={dragHandler}
				onDrop={dropHandler}
			>
				<img src={cloud} alt='загрузите файл' />
				<Description>прикрипите или загрузите файл</Description>
				<FakeInput type='file' onChange={handleAddFile} />
			</WrapperFile>
			<InputsContainer>
				{
					files.map((e, index) => <ViewFile file={e} handleDelete={() => handleDeleteChips(index)} />)
				}
			</InputsContainer>
		</Wrapper>
	);
};

export default LightboxFiles;
