import React from 'react';
import styled from 'styled-components';

const WrapperBlock = styled.label`
  display: flex;
  width: 100%;
  cursor: pointer;
  margin-right: 24px;
  span {
    padding-right: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SelectionRadioInput = styled.input`
  position: absolute;
  display: none;
  right: 0;
  bottom: 0;
`;
const WrapperCheck = styled.div`
  display: flex;
  position: relative;
  min-width: 16px;
  min-height: 16px;
  margin-right: 8px;
  border: 1px solid #02405e;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
`;
const CheckStyle = styled.div`
  ${({ check }) =>
    check &&
    `position: absolute;
    width: 8px;
    height: 8px;
    background: #1baaf0;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0px);
    cursor: pointer;`}
`;

const RadioButton = ({ name, value, title, check, onChange }) => {
  return (
    <WrapperBlock>
      <WrapperCheck>
        <CheckStyle check={check} />
      </WrapperCheck>
      <SelectionRadioInput
        type="radio"
        value={value}
        name={name}
        checked={check}
        onChange={onChange}
      />
      <span> {title}</span>
    </WrapperBlock>
  );
};

export default RadioButton;
