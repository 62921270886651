import { SchedulerStatic } from 'dhtmlx-scheduler';
import moment from 'moment';

type Colors = 'orange' | 'green' | 'blue' | 'violet' | 'red';

export const initConfig = (scheduler: SchedulerStatic) => {
	scheduler.clearAll();
	scheduler.skin = 'material';
	scheduler.xy.nav_height = 50;
	scheduler.config.details_on_create = true;
	scheduler.config.details_on_dblclick = true;
	scheduler.config.collision_limit = 1;
	scheduler.config.header = {
		rows: [
			{
				cols: [
					{
						html: "Календарь",
						click: () => console.log('==========>1', 1),
					},
					{
						html:"Повестка",
						click: () => console.log('==========>1', 1),
					},
					"spacer",
					"prev",
					"date",
					"next",
					"spacer",
					"day",
					"week",
					"month",
				]
			},
		]
	};

};
export const toSchedulerHeaderColor = (color: Colors) => {
	switch (color) {
		case 'orange':
			return '#d14b02';
		case 'blue':
			return '#0288d1';
		case 'green':
			return '#02d14b';
		case 'violet':
			return '#d10288';
		case 'red':
			return '#d10220'
		default:
			return '#0288d1';
	}
};


const toSchedulerColor = (color: Colors, scheduler: SchedulerStatic, id: number) => {
	switch (color) {
		case 'orange':
			scheduler.getEvent(id).color = '#d14b02';
			break;
		case 'blue':
			scheduler.getEvent(id).color = '#0288d1';
			break;
		case 'green':
			scheduler.getEvent(id).color = '#02d14b';
			break;
		case 'violet':
			scheduler.getEvent(id).color = '#d10288';
			break;
		case 'red':
			scheduler.getEvent(id).color = '#d10220'
			break;
	}
};

export const initSchedulerEvents = (scheduler: SchedulerStatic) => {
	const logSchedulerEvent = (action, event, id) => {
		// console.log('==========>action', action);
		// console.log('==========>event', event);
		// console.log('==========>id', id);
	};

	scheduler.attachEvent('onEventAdded', (id, ev) => {
		const date = moment(ev.start_date).format('dddd');
		if (date === 'Saturday' || date === 'Sunday') {
			scheduler.deleteEvent(id);
		} else {
			toSchedulerColor(ev.color, scheduler, id);
			logSchedulerEvent('create', ev, id);
		}
	});

	scheduler.attachEvent('onEventChanged', (id, ev) => {
		toSchedulerColor(ev.color, scheduler, id);
		logSchedulerEvent('update', ev, id);
	});

	scheduler.attachEvent('onEventDeleted', (id, ev) => {
		logSchedulerEvent('delete', ev, id);
	});
};

