import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../common/components/newComponent/Buttons/Button';
import { fetchData } from '../../../utils/fetchData';
import { useHistory, useLocation } from 'react-router-dom';
import Input from '../../../common/components/Inputs/Input';
import CustomBlock from './CustomBlock';
import Icon from '../../../common/components/Icon';
const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  /* align-items: center; */
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  div {
    width: 100%;
  }
  button {
    margin-top: 24px;
    width: 100%;
  }
  p {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #02405e;
    margin: 24px 0;
  }
  span {
    font-size: 12px;
    line-height: 16px;
    color: #707070;
    margin-bottom: 8px;
  }
  img {
    margin: 0 auto;
  }
`;

const EmailConfirmed: React.FC = () => {
  const location = useLocation();
  const [status, setStatus] = useState('');
  const [Email, setEmail] = useState('');
  let history = useHistory();

  useEffect(() => {
    (async () => {
      let status;
      await fetch(`/email${location.pathname}${location.search}`, {}).then(
        (data) => (status = data.status)
      );

      if (status) {
        setStatus(status);
      } else {
        setStatus('error');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomBlock>
      <ContentRow>
        <Icon name={status === 'error' ? 'armor-cross' : 'armor-ok'} />
        <p>{status === 'error' ? 'Ошибка верификации' : 'Проверка успешна'}</p>

        {status === 'error' ? (
          <>
            <span>Введите Email еще раз</span>
            <Input
              inputType={'text'}
              placeholder={'Email'}
              title={'введите Email'}
              value={Email}
              changeHandler={(e) => {
                setEmail(e);
              }}
            />

            <Button
              big
              title="Отправить повторно"
              onClick={() => {
                fetchData
                  .get(`/email/resend/${Email}`)
                  .then(() => history.push('/login'));
              }}
            />
          </>
        ) : (
          <Button
            style={{ marginTop: '0' }}
            title="Продолжить"
            onClick={() => {
              history.push('/login');
            }}
          />
        )}
      </ContentRow>
    </CustomBlock>
  );
};

export default EmailConfirmed;
