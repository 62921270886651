import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import NameOfStep from "../templates/NameOfStep";
import RadioButtons from "../templates/RadioButtons";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import "../BpResultForm.scss";
import { nanoid } from "nanoid";
import AddFileButton from "../templates/AddFileButton";

let fileTranslate;
const CheckOfExecutiveDirector = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,

    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.checkOfExecutiveDirector?.extra
      ? settingsOfFields?.checkOfExecutiveDirector.extra
      : {}
  );

  const [allFilesExecDir1, setAllFilesExecDir1] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  //Добавляет файлы
  const onSendFileExecDir = (e, numberFilesExecDir) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesExecDir === "filesExecDir1") {
        if (file && file.name) {
          setAllFilesExecDir1([
            ...allFilesExecDir1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesExecDir1",
            },
          ]);

          console.log("allFilesExecDir1", allFilesExecDir1);
        }
      }
    }, [500]);
  };
  //Удаляет файл
  const onDeletedFileExecDir = (keyId, numberFilesExecDir) => {
    if (numberFilesExecDir === "filesExecDir1") {
      setAllFilesExecDir1(allFilesExecDir1.filter((file) => file.id !== keyId));
    }
  };

  //Смотрит на какую кнопку какого раздела с файлами было нажато
  const onClickFetchFilesExecDir = (e, numberFilesExecDir) => {
    e.preventDefault();
    console.log(numberFilesExecDir);

    let data;
    if (numberFilesExecDir === "filesExecDir1") {
      data = JSON.stringify({
        files: allFilesExecDir1,
      });
      console.log("Нажал на кнопку для отправки файлов filesExecDir1");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesExecDir === "filesExecDir1") {
      setAllFilesExecDir1([]);
    }
  };

  const url = `${apiBp}/task/${taskId}`;
  // console.log(taskId);
  useEffect(() => {
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields?.checkOfExecutiveDirector, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);
  // console.log("     ", );

  return (
    <form id="checkOfExecutiveDirector">
      <div>
        <NameOfStep text={"Определение группы доступа контрагента в 1С"} />
        <div>
          <TextAreaOrInputElement
            name={"e5"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
          />
        </div>
      </div>
      <div>
        <NameOfStep
          text={
            "Материальность расхода по договору и необходимость альтернативных коммерческих предложений"
          }
        />
        <div>
          <TextAreaOrInputElement
            name={"e5_1"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
          />
        </div>
      </div>
      <div>
        <NameOfStep text={"Комментарии по заключению юристов"} />
        <div>
          <TextAreaOrInputElement
            name={"e5_2"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
            placeholder={
              "* Обязательно прочтение договора и всех комментариев юристов, протокола разногласий и комментарий по вопросам и дополнениям"
            }
          />
        </div>
      </div>
      <div>
        <NameOfStep text={"Комментарии по заключению главного бухгалтера"} />
        <div>
          <TextAreaOrInputElement
            name={"e5_3"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
            placeholder={
              "* Обязательно прочтение договора и всех комментариев бухгалтеров, протокола разногласий и комментарий по вопросам и дополнениям"
            }
          />
        </div>
      </div>
      <div>
        <NameOfStep text={"Комментарии и уведомление смежных подразделений"} />
        <div>
          <TextAreaOrInputElement
            name={"e5_4"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
          />
        </div>
      </div>
      <div>
        <NameOfStep
          text={"Анализ альтернативных коммерческих предложений проведен"}
        />
        <RadioButtons
          data={data}
          setData={setData}
          name={"e5_5"}
          positiveText={"Да"}
          negativeText={"Нет"}
          otherText={"Не применимо"}
        />
        {data.e5_5 === "Да" && (
          <div>
            <TextAreaOrInputElement
              name={"e5_5_1"}
              isInput={true}
              type={"text"}
              data={data}
              setData={setData}
            />
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal &&
                  taskCurrentGlobal["files"] &&
                  taskCurrentGlobal["files"]["list"]
                    .filter((item) => item.tag == "filesExecDir1")
                    .map((item2) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                          target="_blank"
                        >
                          {item2.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
            <AddFileButton
              filesList={taskCurrentGlobal}
              data={data}
              setData={setData}
              id={"c3"}
              handlerOnSendFile={onSendFileExecDir}
              handlerOnDeletedFile={onDeletedFileExecDir}
              handlerOnClickFetchFiles={onClickFetchFilesExecDir}
              allFiles={allFilesExecDir1}
              numberFiles={"filesExecDir1"}
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default CheckOfExecutiveDirector;
