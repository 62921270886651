import React, { FC, useRef, useEffect } from 'react';

import { IEvent } from '../index';
import { initConfig, initSchedulerEvents } from '../config';

import { Task } from '../../../common/types';

import 'dhtmlx-scheduler';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css';
import 'dhtmlx-scheduler/codebase/locale/locale_ru';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_editors';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_minical';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_multiselect';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_collision';

import './style.sass';

interface ICalendar {
	events: Array<IEvent | Task>,
}

const scheduler = window.scheduler;

const Scheduler: FC<ICalendar> = ({ events }) => {
	const calendarRefContainer = useRef<HTMLDivElement>(null);

	useEffect(() => {
		initConfig(scheduler);
		initSchedulerEvents(scheduler);

		if (calendarRefContainer.current) {
			scheduler.init(calendarRefContainer.current, new Date());
			scheduler.parse(events);
		}

	}, [events]);

	return (
		<div id="scheduler_here" ref={calendarRefContainer} className="dhx_cal_container" style={{
			width: '100%',
			height: '100%',
		}}>
			<div className="dhx_cal_navline">
				<div className="dhx_cal_prev_button">&nbsp;</div>
				<div className="dhx_cal_next_button">&nbsp;</div>
				<div className="dhx_cal_today_button" />
				<div className="dhx_cal_date" />
				<div className="dhx_cal_tab" />
				<div className="dhx_cal_tab" />
				<div className="dhx_cal_tab" />
			</div>
			<div className="dhx_cal_header" />
			<div className="dhx_cal_data" />
		</div>
	);
};

export default Scheduler;
