import React from "react";
import { connect, useSelector } from "react-redux";
import { State } from "../../../rootReducer";
import { Role } from "../../types";
import styled, { keyframes } from "styled-components";
import { columnsOfTables } from "../../shared_data";

type Props = {
  name: string;
  desc: string | undefined; // undefined потому что по задаче может быть еще не назначено исполнителя. Из ProjectTaskTr, TaskTr может быть undefined
  roles: Role[];
  className?: string;
  notificationBall?: boolean;
  mark?: boolean;
  id?: number;
};
const WrapperNameCell = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  line-height: 16px;
  /* max-width: ${columnsOfTables[1].style.minWidth}; */
  min-width: ${columnsOfTables[2].style.maxWidth};
`;
const TitleStyle = styled.div`
  padding-top: 12px;
  letter-spacing: 0.15px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ active }) => (active ? "#02405E" : "#212121")};
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  padding-right: 4px;
  text-overflow: ellipsis; /* Добавляем многоточие */
`;

const blinking = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const MarkerNewTask = styled.div`
  animation: ${blinking} 2s linear infinite;
  opacity: 1;
  border-radius: 100%;
  max-width: 8px;
  width: 100%;
  height: 8px;
  background-color: #1baaf0;
  margin-top: 15px;
  margin-right: 8px;
`;

const DescriptionStyle = styled.div`
  font-weight: normal;
  font-size: 13px;
  color: ${({ active }) => (active ? "#02405E" : "#a8a8a8")};
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  padding-right: 4px;
  text-overflow: ellipsis; /* Добавляем многоточие */
`;

const NameCell: React.FC<Props> = ({ name, mark = false, desc, id }) => {
  const selectTaskId = useSelector(
    (state: State) => state.taskInfoPlate.selectedTask
  );
  return (
    <WrapperNameCell>
      <div style={{ display: "flex" }}>
        {mark && <MarkerNewTask />}
        <TitleStyle active={id === selectTaskId?.id}>{name}</TitleStyle>
      </div>
      <DescriptionStyle active={id === selectTaskId?.id}>
        {desc}
      </DescriptionStyle>
    </WrapperNameCell>
  );
};

const mapStateToProps = (state: State) => {
  return {
    roles: state.commonInfo.roles,
  };
};

export default connect(mapStateToProps)(NameCell);
