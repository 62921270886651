import React, { useCallback, useState } from "react";
import Input from "../../../common/components/Inputs/Input";
import { fetchToken } from "../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CustomBlock from "./CustomBlock";
import Button from "../../../common/components/newComponent/Buttons/Button";

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;

  div {
    width: 100%;
    margin-bottom: 8px;
  }
  a {
    text-decoration: none;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
    transition: color 0.2s ease;
    &:hover {
      color: #707070;
    }
  }
`;

const Login = ({ fetchToken }) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const formSubmitHandler = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      fetchToken(login, password);
    },
    [login, fetchToken, password]
  );

  return (
    <CustomBlock title="Войти">
      <FormStyle onSubmit={formSubmitHandler}>
        <Input
          inputType="text"
          placeholder="Логин"
          title=""
          withIcon={false}
          value={login}
          changeHandler={(value) => setLogin(value.trim())}
        />
        <Input
          inputType="password"
          placeholder="Пароль"
          withIcon={true}
          value={password}
          title=""
          changeHandler={(value) => setPassword(value)}
          // isValid={password ? validatePassword(password) : true}
          validText="Пароль должен состоять из 6-30 символов и включать в себя хотя бы одно число и букву в верхнем регистре"
        />

        <Link to="/forgot-password">Забыли пароль?</Link>
        {console.log(login === "", password === "")}
        <Button
          disabled={login === "" || password === ""}
          title="Войти"
          big
          style={{ width: "100%", marginTop: "32px" }}
        />
      </FormStyle>
    </CustomBlock>
  );
};

const mapDispatchToProps = {
  fetchToken,
};

export default connect(null, mapDispatchToProps)(Login);
