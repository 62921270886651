// *** Вкладки редактирования (просмотра) данных о задаче:  информация о задаче / результат / чат
import axios from "axios";
import React, { useContext, useMemo } from "react";
import { useEffect } from "react";
import { StatusContext } from "../../context/status";
import "./ChatMenu.scss";
import { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";

// Шаблон "Договор"
import BpResultFormTreaty from "../bp-result-forms/bp-result-form-treaty/Treaty";
import CheckOfHeadOfDepartment from "../bp-result-forms/bp-result-form-treaty/CheckOfHeadOfDepartment";
import CheckOfJurist from "../bp-result-forms/bp-result-form-treaty/CheckOfJurist";
import СheckOfChiefAccountant from "../bp-result-forms/bp-result-form-treaty/СheckOfChiefAccountant";
import CheckOfFinanceDirector from "../bp-result-forms/bp-result-form-treaty/CheckOfFinanceDirector";
import CheckOfExecutiveDirector from "../bp-result-forms/bp-result-form-treaty/CheckOfExecutiveDirector";
import CheckFinalOfJurist from "../bp-result-forms/bp-result-form-treaty/CheckFinalOfJurist";
import SigningOfTreaty from "../bp-result-forms/bp-result-form-treaty/SigningOfTreaty";
import EnteringDataInto1CUT from "../bp-result-forms/bp-result-form-treaty/EnteringDataInto1CUT";
import FinalRegistrationByJurist from "../bp-result-forms/bp-result-form-treaty/FinalRegistrationByJurist";

// Шаблон "Прием на работу "
import RecruitInitial from "../bp-result-forms/bp-result-form-work/RecruitInitial";
import CheckSecurity from "../bp-result-forms/bp-result-form-work/CheckSecurity";
import AcceptByHeadOfDep from "../bp-result-forms/bp-result-form-work/AcceptByHeadOfDep";
import AgreementByDeputyGenManager from "../bp-result-forms/bp-result-form-work/AgreementByDeputyGenManager";
import ForkedStage_SMZ_IP from "../bp-result-forms/bp-result-form-work/ForkedStage_SMZ_IP";
import ForkedStage_GPH_TD from "../bp-result-forms/bp-result-form-work/ForkedStage_GPH_TD";
import Signing from "../bp-result-forms/bp-result-form-work/Signing";
import Completion_3monthTimer from "../bp-result-forms/bp-result-form-work/Completion_3monthTimer";
import AcceptByDeputyGenManager from "../bp-result-forms/bp-result-form-work/AcceptByDeputyGenManager";
import EmployeeRegistration from "../bp-result-forms/bp-result-form-work/EmployeeRegistration";
import ProcessTerminating from "../bp-result-forms/bp-result-form-work/ProcessTerminating";

// Шаблон "Увольнение"
import BpResultFormDismissal from "../bp-result-forms/bp-result-form-dismissal/BpResultFormDismissal";
import InitialProcessingOfDocumentsDismissal from "../bp-result-forms/bp-result-form-dismissal/InitialProcessingOfDocuments";
import SigningOfTreatyDismissal from "../bp-result-forms/bp-result-form-dismissal/SigningOfTreatyDismissal";
import ConfirmationOfPaymentOfAccountingCalculationDismissal from "../bp-result-forms/bp-result-form-dismissal/ConfirmationOfPaymentOfAccountingCalculation";
import EmployeeAccountActionsDismissal from "../bp-result-forms/bp-result-form-dismissal/EmployeeAccountActions";
import PreparationAndApprovalFinalPaymentForUTDismissal from "../bp-result-forms/bp-result-form-dismissal/PreparationAndApprovalFinalPaymentForUT";
import FinalSettlementWithEmployeeDismissal from "../bp-result-forms/bp-result-form-dismissal/FinalSettlementWithEmployee";
import CompletionOfBPDismissal from "../bp-result-forms/bp-result-form-dismissal/CompletionOfBP";
import procent from "../../../../../img/percentage.svg";
//Нетиповой шаблон
import NoSampleForm from "../bp-result-forms/bp-result-form-noSample/NoSampleForm";

import { CreateNotif } from "../../../../../utils/createNotification";
import Chat from "./Chat";
import ChatBp from "./ChatBp";
import AgreeButton from "./components/AgreeButton";
import TaskInfoMenu from "./components/TaskInfoMenu";
import { useHistory } from "react-router-dom";
import ChildTaskButton from "./components/ChildTaskButton";

const ChatMenu = () => {
  const {
    isAdmin,
    bearer,
    openMenuTasks,
    openMenuBp,
    setOpenMenuTasks,
    setOpenMenuBp,
    apiBp,
    users,
    selectedTaskOfBP,
    setStatusTask,
    setStatusBp,
    setStatusBpId,
    setStatusTaskId,
    setChange,
    change,
    taskId,
    showGeneralResult,
    selectedBp,
    createBpFromSample,
    setCreateBpFromSample,
    setBpListChange,
    isTemplateTask,
    setIsTemplateTask,
    thisTabs,
    setThisTabs,
    closeWindowTask,
    setCloseWindowTask,
    setTaskCurrentGlobal,
    changeTask,
    setBusinessProcessCurrentGlobal,
    statusOfTask,
    executorIdofTask,
    userId,
    businessProcessCurrentGlobal,
    taskCurrentGlobal,
    company_id,
    setComment,
    setShowChatWindow,
    showChatWindow,
    settingsOfFields,
    redirectedByClick,
    setRedirectedByClick,
    setDescript,
    bp,
    setEtTaskId,
    lengthArrTasks,
    setChangeTask,
    setProjectSections,
  } = useContext(StatusContext);

  const autor = users.find(
    (user) => user.id === businessProcessCurrentGlobal.initiator_id
  );

  const [taskCurrent, setTaskCurrent] = useState({});
  const [businessProcess, setBusinessProcess] = useState({});
  const [currentTaskIndex, setCurrentTaskIndex] = useState();
  const [nameTask, setNameTask] = useState(""); // выбранное Наименование этапа для БП без шаблона
  const [selectedExecutor, setSelectedExecutor] = useState({});
  const [beginDayTask, setBeginDayTask] = useState(""); // выбранная дата начала задачи для БП без шаблона
  const [endDayTask, setEndDayTask] = useState(""); // выбранная дата окончания задачи для БП без шаблона
  const [beginTask, setBeginTask] = useState(""); // выбранная дата начала задачи для БП без шаблона
  const [endTask, setEndTask] = useState(""); // выбранная дата окончания задачи для БП без шаблона
  const [beginTimeTask, setBeginTimeTask] = useState("00:00:00"); // выбранное время начала задачи для БП без шаблона
  const [endTimeTask, setEndTimeTask] = useState("00:00:00"); // выбранное время окончания задачи для БП без шаблона
  const [tasksOfBp, setTasksOfBp] = useState();
  const [prevTaskIndex, setPrevTaskIndex] = useState();
  const [nextTaskIndex, setNextTaskIndex] = useState();
  const [dateError, setDateError] = useState(false);
  const [checklistTask, setChecklistTask] = useState([]);
  const [projectTask, setProjectTask] = useState();
  const [projectSectionTask, setProjectSectionTask] = useState();
  const [priorityTask, setPriorityTask] = useState();
  const [authorTask, setAuthorTask] = useState();
  const [filesTask, setFilesTask] = useState();
  const [taskLoad, setTaskLoad] = useState();
  const [workLoad, setWorkLoad] = useState();
  const [currentEtTask, setCurrentEtTask] = useState();
  const [sectionsOfTask, setSectionsOfTask] = useState();
  const [taskChange, setTaskChange] = useState(false);

  //для того чтобы комментарий из нетипового бп обновлялся при смене задачи
  useEffect(() => {
    if ((taskId, businessProcessCurrentGlobal.tasks)) {
      businessProcessCurrentGlobal.tasks.find((task, index) => {
        if (task.id === taskId) setCurrentTaskIndex(index);
      });
      return setComment("");
    }
  }, [taskId, businessProcessCurrentGlobal.tasks]);

  useEffect(() => {
    if (businessProcessCurrentGlobal.template_id && !showChatWindow) {
      setThisTabs(2);
    } else if (!businessProcessCurrentGlobal.template_id && !showChatWindow){
      setThisTabs(1);
    }
  }, [businessProcessCurrentGlobal.template_id, showChatWindow]);

  useEffect(() => {
    if (redirectedByClick) {
      setThisTabs(1);
    }
  }, [taskId, redirectedByClick, showChatWindow]);

  let headers = {
    Authorization: bearer,
    "company-id": company_id,
    "secret-token": document.cookie.replace(
      /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    ),
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  useEffect(() => {
    if (taskId) {
      let config = {
        method: "get",
        url: `${apiBp}/task/${taskId}`,
        headers: headers,
      };

      axios(config)
        .then(function (response) {
          let res = response.data;
          if (res.template_id) {
            setIsTemplateTask(true);
          } else {
            setIsTemplateTask(false);
          }
          setTaskCurrent(res);
          setTaskCurrentGlobal(res);
          setEtTaskId(+res.et_task_id);
          // if (etTaskId) {
          //   setChecklistTask(res.et_task.checklist);
          //   setCurrentEtTask(res.et_task);
          // } else {
          //   setChecklistTask(res.data.checklist);
          // }
          setSelectedExecutor(+res.executor_id);
          setBeginTask(res.begin);
          setEndTask(res.end);
          setBeginDayTask(res.begin.slice(0, 10));
          setEndDayTask(res.end.slice(0, 10));
          setBeginTimeTask(res.begin.slice(11, 16));
          setEndTimeTask(res.end.slice(11, 16));
          setNameTask(res.name);
          setDescript(res.description);
          setStatusTask(res.status);
          setEtTaskId(+res.et_task_id);
          setCurrentEtTask(+res.et_task);
          setAuthorTask(+res.data.author_id);
          setPriorityTask(+res.data.priority_id);
          setProjectTask(+res.data.project_id);
          setProjectSectionTask(+res.data.project_section_id);
          setTaskLoad(+res.data.task_load);
          setWorkLoad(+res.data.work_load);
        }) 
        .catch((e) => {
          console.log(e);
        });
    }
    setStatusTask(0);
  }, [
    taskId,
    selectedBp,
    change,
    openMenuTasks,
    createBpFromSample,
    selectedBp,
    thisTabs,
    closeWindowTask,
    changeTask,
    businessProcessCurrentGlobal,
    taskChange,
  ]);

  // console.log("task",taskCurrentGlobal);
  // console.log(taskCurrentGlobal.executor_id);
  //console.log("business process",businessProcessCurrentGlobal);

  const history = useHistory();

  useEffect(() => {
    if (projectTask || businessProcess.project_id) {
      axios
        .get(
          `${process.env.REACT_APP_PUBLIC_API}/projects/${
            projectTask ? projectTask : businessProcess.project_id
          }`,
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
            },
          }
        )
        .then((res) => {
          // console.log(res.data.data);
          setSectionsOfTask(res.data.data.sections);
        });
    } else {
      setSectionsOfTask();
    }
  }, [taskCurrent, taskId, projectTask, projectSectionTask, statusOfTask]);

  useEffect(() => {
    setCreateBpFromSample(false);
  }, [taskId, selectedBp, thisTabs, closeWindowTask]);

  // Получение данных о текущем БП  по его id: selectedBp
  useEffect(() => {
    if (selectedBp) {
      axios
        .get(`${apiBp}/bp/${selectedBp}`, {
          headers: headers,
        })
        .then((res) => {
          if (res && res.data && res.data.data) {
            // console.log("businessProcess", res.data.data);
            setBusinessProcess(res.data.data);
            setBusinessProcessCurrentGlobal(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedBp]);

  const changeTabs = (i) => {
    setThisTabs(i);
    setRedirectedByClick(false);
    setShowChatWindow(false);
  };

  const changeStatus = (status) => {
    if (status === 0) return status + 2;
    else if (status === 2 || status === 3) {
      setChange((prev) => prev + 1);
      return status + 1;
    } else if (status === 7) {
      setChange((prev) => 3);
      return status - 4;
    } else return status + 0;
  };

  //******************************************************* */

  const checkError = document.getElementsByClassName(
    "error-message-in-forms-check"
  );
  const addError = document.getElementsByClassName(
    "error-message-in-forms-add-file"
  );
  const inputError = document.getElementsByClassName(
    "error-message-in-forms-input"
  );

  // console.log(!addError.length, !checkError.length, !inputError.length);

  const showErrors = () => {
    if (checkError.length)
      CreateNotif("Выберите необходимые значения", "error");
    if (inputError.length)
      CreateNotif("Заполните все необходимы поля", "error");
    if (addError.length)
      CreateNotif("Прикрепите все необходимые файлы", "error");
  };

  // console.log("p-chm", projectTask);
  // console.log("--ps- chm", projectSectionTask);

  if (openMenuTasks || openMenuBp) {
    return (
      <div className="chatMenu">
        <div className="chatMenu-header">
          <div className="chatMenu-header__nav">
            <div
              className={
                thisTabs === 1
                  ? "chatMenu-header__nav-item chatMenu-header__nav-item-active"
                  : "chatMenu-header__nav-item"
              }
              onClick={() => {
                changeTabs(1);
                setThisTabs(1);
                setShowChatWindow(true);
                setRedirectedByClick(false);
              }}
            >
              Информация
            </div>

            {businessProcess.template_id && (
              <div
                className={
                  thisTabs === 2
                    ? "chatMenu-header__nav-item chatMenu-header__nav-item-active"
                    : "chatMenu-header__nav-item"
                }
                // style={
                //   openMenuTasks || !options.length > 0 ? { color: "#CBCBCB" } : {}
                // }

                onClick={() => {
                  changeTabs(2);
                  setThisTabs(2);
                  setShowChatWindow(false);
                }}
              >
                Результат
              </div>
            )}

            <div
              className={
                thisTabs === 3
                  ? "chatMenu-header__nav-item chatMenu-header__nav-item-active"
                  : "chatMenu-header__nav-item"
              }
              // style={openMenuTasks ? { color: "#CBCBCB" } : {}
              onClick={() => {
                changeTabs(3);
                setThisTabs(3);
                setShowChatWindow(true);
                setRedirectedByClick(false);
              }}
            >
              Чат
            </div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="chatMenu-header__close"
            onClick={() => {
              if (thisTabs == 2) {
                setThisTabs(1);
              } else if (thisTabs == 1) {
                businessProcessCurrentGlobal.template_id
                  ? setThisTabs(2)
                  : setThisTabs(1);
              }
              setCloseWindowTask((prev) => prev + 1);
              setOpenMenuTasks(false);
              setOpenMenuBp(false);
              setRedirectedByClick(false);
            }}
          >
            Закрыть
          </div>
        </div>
        <div className="chatMenu-container">
          {thisTabs === 1 && (
            <TaskInfoMenu
              businessProcess={businessProcess}
              headers={headers}
              changeStatus={changeStatus}
              checklistTask={checklistTask}
              setChecklistTask={setChecklistTask}
              projectTask={projectTask}
              setProjectTask={setProjectTask}
              projectSectionTask={projectSectionTask}
              setProjectSectionTask={setProjectSectionTask}
              priorityTask={priorityTask}
              setPriorityTask={setPriorityTask}
              authorTask={authorTask}
              setAuthorTask={setAuthorTask}
              filesTask={filesTask}
              setFilesTask={setFilesTask}
              taskLoad={taskLoad}
              setTaskLoad={setTaskLoad}
              workLoad={workLoad}
              setWorkLoad={setWorkLoad}
              nameTask={nameTask}
              setNameTask={setNameTask}
              selectedExecutor={selectedExecutor}
              setSelectedExecutor={setSelectedExecutor}
              beginDayTask={beginDayTask}
              setBeginDayTask={setBeginDayTask}
              endDayTask={endDayTask}
              setEndDayTask={setEndDayTask}
              beginTask={beginTask}
              setBeginTask={setBeginTask}
              endTask={endTask}
              setEndTask={setEndTask}
              beginTimeTask={beginTimeTask}
              setBeginTimeTask={setBeginTimeTask}
              endTimeTask={endTimeTask}
              setEndTimeTask={setEndTimeTask}
              sectionsOfTask={sectionsOfTask}
              taskChange={taskChange}
              setTaskChange={setTaskChange}
            />
          )}

          {/* Для просмотра бизнес-процесса "Договор" */}
          {openMenuBp &&
            thisTabs === 2 &&
            businessProcess &&
            businessProcess.template_id == "treaty" && (
              <div
                // className={
                //   statusOfTask === 0 || statusOfTask === 2 || statusOfTask === 3
                //     ? ""
                //     : "unavailableForm"
                // }
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 35 + "px",
                }}
              >
                {taskId && <div>* Поле обязательно для заполнения</div>}
                {selectedTaskOfBP === "Инициация договора" && taskId && (
                  <BpResultFormTreaty />
                )}
                {selectedTaskOfBP === "Проверка руководителем подразделения" &&
                  taskId && <CheckOfHeadOfDepartment />}
                {selectedTaskOfBP === "Проверка юристом" && taskId && (
                  <CheckOfJurist />
                )}
                {selectedTaskOfBP === "Проверка главным бухгалтером" &&
                  taskId && <СheckOfChiefAccountant />}
                {selectedTaskOfBP === "Проверка исполнительным директором" &&
                  taskId && <CheckOfExecutiveDirector />}
                {selectedTaskOfBP === "Проверка финансовым директором" &&
                  taskId && <CheckOfFinanceDirector />}
                {selectedTaskOfBP === "Финальная проверка юристом" &&
                  taskId && <CheckFinalOfJurist />}
                {selectedTaskOfBP === "Подписание договора" && taskId && (
                  <SigningOfTreaty />
                )}
                {selectedTaskOfBP === "Внесение данных в 1С УТ" && taskId && (
                  <EnteringDataInto1CUT />
                )}
                {selectedTaskOfBP === "Финальная регистрация юристом" &&
                  taskId && <FinalRegistrationByJurist />}

                {/* ******************************************************* */}
                {taskCurrent &&
                thisTabs === 2 &&
                taskId &&
                ((taskCurrent.status === 2 &&
                  (executorIdofTask === userId || isAdmin)) ||
                  (taskCurrent.status === 3 &&
                    (businessProcess.initiator_id === userId || isAdmin))) ? (
                  <div
                    className="chatMenu-footer"
                    // style={{
                    //   display:
                    //     taskCurrent.status === 2 &&
                    //     taskCurrent.status === 3 &&
                    //     (businessProcess.initiator_id === userId||isAdmin)
                    //       ? "none"
                    //       : "block",
                    // }}
                  >
                    <div className="chatMenu-footer__nav">
                      <div
                        className={
                          "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
                        }
                        onClick={() => {
                          if (
                            !addError.length &&
                            !checkError.length &&
                            !inputError.length
                          ) {
                            if (taskCurrent.status == 2) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/complete`,
                                headers: headers,
                              };

                              axios(config)
                                .then(function (response) {
                                  setChange((prev) => prev + 1);
                                  // console.log(JSON.stringify(response.data));
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }
                            // console.log(taskCurrent, businessProcess);
                            if (taskCurrent.status == 3 && true) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/finish`,
                                headers: headers,
                              };

                              axios(config)
                                .then(function (response) {
                                  setChange((prev) => prev + 1);
                                  // console.log(JSON.stringify(response.data));
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }
                            // console.log("bp", bp);
                            setStatusTaskId(taskId);
                            setStatusBpId(bp.id);
                            setStatusBp(2);
                            setStatusTask((prev) => changeStatus(prev));
                            setOpenMenuTasks(false);
                            setOpenMenuBp(false);
                          } else {
                            showErrors();
                          }
                        }}
                      >
                        {taskCurrent.status === 2 ? "Выполнено" : "Согласовать"}
                      </div>
                      {taskCurrent.status === 2 &&
                        businessProcess.template_id && (
                          <AgreeButton
                            headers={headers}
                            changeStatus={changeStatus}
                            checkError={checkError.length}
                            addError={addError.length}
                            inputError={inputError.length}
                            showErrors={showErrors}
                          />
                        )}

                      {taskCurrent &&
                      taskCurrent.status === 3 &&
                      (businessProcess.initiator_id === userId || isAdmin) ? (
                        <div
                          className={
                            "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
                          }
                          style={{ marginLeft: "10px", minWidth: "210px" }}
                          onClick={() => {
                            if (taskCurrent.status == 3) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/rework`,
                                headers: headers,
                              };

                              axios(config)
                                .then(function (response) {
                                  setChange((prev) => prev + 1);
                                  // console.log(JSON.stringify(response.data));
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }

                            // setTaskCurrent(...,status:3)

                            // console.log("bp", bp);
                            setStatusTaskId(taskId);
                            setStatusBpId(bp.id);
                            setStatusBp(2);
                            setStatusTask((prev) => changeStatus(prev));
                            setOpenMenuTasks(false);
                            setOpenMenuBp(false);
                          }}
                        >
                          Вернуть исполнителю
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* ******************************************************* */}
                {/* Если создаем бизнес-процесса из быстро из шаблона  */}

                {businessProcess.template_id &&
                businessProcess.template_id === "treaty" &&
                taskCurrent &&
                taskId &&
                taskCurrent.status === 0 &&
                businessProcess.tasks[10].id !== taskId &&
                currentTaskIndex === 0 &&
                (businessProcess.initiator_id === userId || isAdmin) ? (
                  <div className="chatMenu-footer">
                    <div className="chatMenu-footer__nav">
                      <div
                        className={
                          "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
                        }
                        onClick={() => {
                          if (selectedBp && taskId) {
                            if (
                              !addError.length &&
                              !checkError.length &&
                              !inputError.length
                            ) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/bp/${selectedBp}/start`,
                                headers: headers,
                              };

                              axios(config)
                                .then((res) => {
                                  /************************************************************************************ */

                                  // Запрос чтобы поставить задаче статус "Выполнено"
                                  let config2 = {
                                    method: "post",
                                    url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/complete`,
                                    headers: headers,
                                  };

                                  axios(config2)
                                    .then(function (response) {
                                      setChange((prev) => prev + 1);
                                      // console.log(
                                      //   JSON.stringify(response.data)
                                      // );

                                      // Запрос на завершение задачи
                                      let config3 = {
                                        method: "post",
                                        url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/finish`,
                                        headers: headers,
                                      };

                                      axios(config3)
                                        .then(function (response) {
                                          setCreateBpFromSample(false); // Завершаем быстрое создание бизнес-процесса по шаблону
                                          setChange((prev) => prev + 1);
                                          // console.log(
                                          //   JSON.stringify(response.data)
                                          // );
                                        })
                                        .catch((e) => {
                                          console.log(e);
                                        });
                                    })
                                    .catch((e) => {
                                      console.log(e);
                                    });

                                  /**************************************************************************************** */
                                  setBpListChange((prev) => !prev);
                                  setChange((prev) => prev + 1);
                                })

                                .catch((e) => {
                                  console.log(e);
                                });

                              // console.log("bp", bp);
                              setStatusTaskId(taskId);
                              setStatusBpId(bp.id);
                              setStatusBp(2);
                              setStatusTask((prev) => changeStatus(prev));
                              setOpenMenuTasks(false);
                              setOpenMenuBp(false);
                            } else {
                              showErrors();
                            }
                          }

                          // setTaskCurrent(...,status:3)
                        }}
                      >
                        Стартовать Типовой бизнес-процесс{/* договор */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* ******************************************************* */}
              </div>
            )}

          {/* Для просмотра бизнес-процесса "Прием на работу" */}
          {openMenuBp &&
            thisTabs === 2 &&
            businessProcess &&
            businessProcess.template_id == "work" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 35 + "px",
                }}
                // className={
                //   statusOfTask !== 1 || statusOfTask !== 4
                //     ? ""
                //     : "unavailableForm"
                // }
              >
                {taskId && <div>* Поле обязательно для заполнения</div>}
                {selectedTaskOfBP === "Инициирование" && taskId && (
                  <RecruitInitial />
                )}
                {selectedTaskOfBP === "Проверка кандидата СБ" && taskId && (
                  <CheckSecurity />
                )}
                {selectedTaskOfBP ===
                  "Подтверждение/не подтверждение о приеме сотрудника Руководителем" &&
                  taskId && <AcceptByHeadOfDep />}
                {selectedTaskOfBP === "Согласование ЗГМ" && taskId && (
                  <AgreementByDeputyGenManager />
                )}
                {selectedTaskOfBP ===
                  "Этап согласования при приёме по СМЗ/ИП/вне штата" &&
                  taskId && <ForkedStage_SMZ_IP />}
                {selectedTaskOfBP ===
                  "Этап согласования при приёме по ГПХ-ТД" &&
                  taskId && <ForkedStage_GPH_TD />}
                {selectedTaskOfBP === "Подписание и прикрепление договора" &&
                  taskId && <Signing />}
                {selectedTaskOfBP === "Завершение «таймера на 3 месяца»" &&
                  taskId && <Completion_3monthTimer />}
                {selectedTaskOfBP ===
                  "Подтверждение Зам. Генерального Менеджера" &&
                  taskId && <AcceptByDeputyGenManager />}
                {selectedTaskOfBP === "Оформление сотрудника" && taskId && (
                  <EmployeeRegistration />
                )}
                {selectedTaskOfBP === "Завершение процесса" && taskId && (
                  <ProcessTerminating />
                )}

                {/* ***************************************************** */}
                {taskCurrent &&
                thisTabs === 2 &&
                taskId &&
                ((taskCurrent.status === 2 &&
                  (executorIdofTask === userId || isAdmin)) ||
                  (taskCurrent.status === 3 &&
                    (businessProcess.initiator_id === userId || isAdmin))) ? (
                  <div className="chatMenu-footer">
                    <div className="chatMenu-footer__nav">
                      <div
                        className={
                          "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
                        }
                        onClick={() => {
                          if (
                            !addError.length &&
                            !checkError.length &&
                            !inputError.length
                          ) {
                            if (
                              settingsOfFields?.initial.extra.a1_4 === "ГПХ-ТД"
                            ) {
                              let skipConfig = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${businessProcess.tasks[4].id}/skip`,
                                headers: headers,
                              };
                              axios(skipConfig);
                            }
                            if (
                              settingsOfFields?.initial.extra.a1_4 ===
                                "Вне штата" ||
                              settingsOfFields?.initial.extra.a1_4 === "СМЗ" ||
                              settingsOfFields?.initial.extra.a1_4 === "ИП"
                            ) {
                              let skipConfig = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${businessProcess.tasks[5].id}/skip`,
                                headers: headers,
                              };
                              axios(skipConfig);
                            }

                            if (taskCurrent.status == 2) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/complete`,
                                headers: headers,
                              };

                              axios(config)
                                .then(function (response) {
                                  setChange((prev) => prev + 1);
                                  // console.log(JSON.stringify(response.data));
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }

                            if (taskCurrent.status == 3) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/finish`,
                                headers: headers,
                              };

                              axios(config)
                                .then(function (response) {
                                  setChange((prev) => prev + 1);
                                  // console.log(JSON.stringify(response.data));
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }
                            setStatusTaskId(taskId);
                            setStatusBpId(bp.id);
                            setStatusBp(2);
                            setStatusTask((prev) => changeStatus(prev));
                            setOpenMenuTasks(false);
                            setOpenMenuBp(false);
                          } else {
                            showErrors();
                          }
                        }}
                      >
                        {taskCurrent.status === 2 ? "Выполнено" : "Согласовать"}
                      </div>
                      {taskCurrent.status === 2 &&
                        businessProcess.template_id && (
                          <AgreeButton
                            headers={headers}
                            changeStatus={changeStatus}
                            checkError={checkError.length}
                            addError={addError.length}
                            inputError={inputError.length}
                            showErrors={showErrors}
                          />
                        )}

                      {taskCurrent &&
                      taskCurrent.status === 3 &&
                      (businessProcess.initiator_id === userId || isAdmin) ? (
                        <div
                          className={
                            "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
                          }
                          style={{ marginLeft: "10px", minWidth: "210px" }}
                          onClick={() => {
                            if (taskCurrent.status == 3) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/rework`,
                                headers: headers,
                              };

                              axios(config)
                                .then(function (response) {
                                  setChange((prev) => prev + 1);
                                  // console.log(JSON.stringify(response.data));
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }

                            // setTaskCurrent(...,status:3)

                            // console.log("bp", bp);
                            setStatusTaskId(taskId);
                            setStatusBpId(bp.id);
                            setStatusBp(2);
                            setStatusTask((prev) => changeStatus(prev));
                            setOpenMenuTasks(false);
                            setOpenMenuBp(false);
                          }}
                        >
                          Вернуть исполнителю
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* ******************************************************* */}
                {/* Если создаем бизнес-процесса из быстро из шаблона  */}
                {businessProcess.template_id &&
                businessProcess.template_id === "work" &&
                taskCurrent &&
                taskId &&
                currentTaskIndex === 0 &&
                taskCurrent.status === 0 &&
                (businessProcess.initiator_id === userId || isAdmin) ? (
                  <div className="chatMenu-footer">
                    <div className="chatMenu-footer__nav">
                      <div
                        className={
                          "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
                        }
                        onClick={() => {
                          // console.log("selectedBp", selectedBp);
                          if (selectedBp && taskId) {
                            if (
                              !addError.length &&
                              !checkError.length &&
                              !inputError.length
                            ) {
                              if (
                                settingsOfFields?.initial.extra.a1_4 ===
                                "ГПХ-ТД"
                              ) {
                                let skipConfig = {
                                  method: "post",
                                  url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${businessProcess.tasks[4].id}/skip`,
                                  headers: headers,
                                };
                                axios(skipConfig);
                              }
                              if (
                                settingsOfFields?.initial.extra.a1_4 ===
                                  "Вне штата" ||
                                settingsOfFields?.initial.extra.a1_4 ===
                                  "СМЗ" ||
                                settingsOfFields?.initial.extra.a1_4 === "ИП"
                              ) {
                                let skipConfig = {
                                  method: "post",
                                  url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${businessProcess.tasks[5].id}/skip`,
                                  headers: headers,
                                };
                                axios(skipConfig);
                              }
                              // console.log("tasks", tasks);
                              // console.log("taskCurrent", taskCurrent);
                              // if (taskCurrent && taskCurrent.id ) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/bp/${selectedBp}/start`,
                                headers: headers,
                              };

                              axios(config)
                                .then((res) => {
                                  /************************************************************************************ */

                                  // Запрос чтобы поставить задаче статус "Выполнено"
                                  let config2 = {
                                    method: "post",
                                    url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/complete`,
                                    headers: headers,
                                  };

                                  axios(config2)
                                    .then(function (response) {
                                      setChange((prev) => prev + 1);
                                      // console.log(JSON.stringify(response.data));

                                      // Запрос на завершение задачи
                                      let config3 = {
                                        method: "post",
                                        url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/finish`,
                                        headers: headers,
                                      };

                                      axios(config3)
                                        .then(function (response) {
                                          setCreateBpFromSample(false); // Завершаем быстрое создание бизнес-процесса по шаблону
                                          setChange((prev) => prev + 1);
                                          // console.log(
                                          //   JSON.stringify(response.data)
                                          // );
                                        })
                                        .catch((e) => {
                                          console.log(e);
                                        });
                                    })
                                    .catch((e) => {
                                      console.log(e);
                                    });

                                  /**************************************************************************************** */
                                  setBpListChange((prev) => !prev);
                                  setChange((prev) => prev + 1);
                                })

                                .catch((e) => {
                                  console.log(e);
                                });
                              //  } else {

                              //  }

                              setStatusTaskId(taskId);
                              setStatusBpId(bp.id);
                              setStatusBp(2);
                              setStatusTask((prev) => changeStatus(prev));
                              setOpenMenuTasks(false);
                              setOpenMenuBp(false);
                            } else {
                              showErrors();
                            }
                          }
                        }}
                      >
                        Стартовать Типовой бизнес-процесс
                        {/* прием на работу */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* ******************************************************* */}
              </div>
            )}

          {/* Для просмотра бизнес-процесса "Увольнение" */}
          {openMenuBp &&
            thisTabs === 2 &&
            businessProcess &&
            businessProcess.template_id == "dismissal" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 35 + "px",
                }}
                // className={
                //   statusOfTask !== 1 || statusOfTask !== 4
                //     ? ""
                //     : "unavailableForm"
                // }
              >
                {taskId && <div>* Поле обязательно для заполнения</div>}
                {selectedTaskOfBP === "Инициирование увольнения" && taskId && (
                  <BpResultFormDismissal />
                )}
                {selectedTaskOfBP === "Первичное оформление документов" &&
                  taskId && <InitialProcessingOfDocumentsDismissal />}
                {selectedTaskOfBP === "Подписание документов" && taskId && (
                  <SigningOfTreatyDismissal />
                )}
                {selectedTaskOfBP ===
                  "Подтверждение оплаты бухгалтерского расчета" &&
                  taskId && (
                    <ConfirmationOfPaymentOfAccountingCalculationDismissal />
                  )}
                {selectedTaskOfBP === "Действия с учетной записью сотрудника" &&
                  taskId && <EmployeeAccountActionsDismissal />}
                {selectedTaskOfBP ===
                  "Подготовка и согласование итоговой выплаты по УТ" &&
                  taskId && (
                    <PreparationAndApprovalFinalPaymentForUTDismissal />
                  )}
                {selectedTaskOfBP === "Итоговый расчет с сотрудником" &&
                  taskId && <FinalSettlementWithEmployeeDismissal />}
                {selectedTaskOfBP === "Завершение бизнес-процесса" &&
                  taskId && <CompletionOfBPDismissal />}

                {/* ******************************************************* */}
                {taskCurrent &&
                thisTabs === 2 &&
                taskId &&
                ((taskCurrent.status === 2 &&
                  (executorIdofTask === userId || isAdmin)) ||
                  (taskCurrent.status === 3 &&
                    (businessProcess.initiator_id === userId || isAdmin))) ? (
                  <div className="chatMenu-footer">
                    <div className="chatMenu-footer__nav">
                      <div
                        className={
                          "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
                        }
                        onClick={() => {
                          if (
                            !addError.length &&
                            !checkError.length &&
                            !inputError.length
                          ) {
                            if (taskCurrent.status == 2) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/complete`,
                                headers: headers,
                              };

                              axios(config)
                                .then(function (response) {
                                  setChange((prev) => prev + 1);
                                  // console.log(JSON.stringify(response.data));
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }

                            if (taskCurrent.status == 3) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/finish`,
                                headers: headers,
                              };

                              axios(config)
                                .then(function (response) {
                                  setChange((prev) => prev + 1);
                                  // console.log(JSON.stringify(response.data));
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }

                            // console.log("bp", bp);
                            setStatusTaskId(taskId);
                            setStatusBpId(bp.id);
                            setStatusBp(2);
                            setStatusTask((prev) => changeStatus(prev));
                            setOpenMenuTasks(false);
                            setOpenMenuBp(false);
                          } else {
                            showErrors();
                          }
                        }}
                      >
                        {taskCurrent.status === 2 ? "Выполнено" : "Согласовать"}
                      </div>
                      {taskCurrent.status === 2 &&
                        businessProcess.template_id && (
                          <AgreeButton
                            headers={headers}
                            changeStatus={changeStatus}
                            checkError={checkError.length}
                            addError={addError.length}
                            inputError={inputError.length}
                            showErrors={showErrors}
                          />
                        )}
                      {taskCurrent &&
                      taskCurrent.status === 3 &&
                      (businessProcess.initiator_id === userId || isAdmin) ? (
                        <div
                          className={
                            "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
                          }
                          style={{ marginLeft: "10px", minWidth: "210px" }}
                          onClick={() => {
                            if (taskCurrent.status == 3) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/rework`,
                                headers: headers,
                              };

                              axios(config)
                                .then(function (response) {
                                  setChange((prev) => prev + 1);
                                  // console.log(JSON.stringify(response.data));
                                })
                                .catch((e) => {
                                  console.log(e);
                                });
                            }

                            // setTaskCurrent(...,status:3)

                            // console.log("bp", bp);
                            setStatusTaskId(taskId);
                            setStatusBpId(bp.id);
                            setStatusBp(2);
                            setStatusTask((prev) => changeStatus(prev));
                            setOpenMenuTasks(false);
                            setOpenMenuBp(false);
                          }}
                        >
                          Вернуть исполнителю
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* ******************************************************* */}
                {/* Если создаем бизнес-процесса из быстро из шаблона  */}
                {businessProcess.template_id &&
                businessProcess.template_id === "dismissal" &&
                taskCurrent &&
                taskId &&
                currentTaskIndex === 0 &&
                taskCurrent.status === 0 &&
                (businessProcess.initiator_id === userId || isAdmin) ? (
                  <div className="chatMenu-footer">
                    <div className="chatMenu-footer__nav">
                      <div
                        className={
                          "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
                        }
                        onClick={() => {
                          // console.log("selectedBp", selectedBp);
                          if (selectedBp && taskId) {
                            if (
                              !addError.length &&
                              !checkError.length &&
                              !inputError.length
                            ) {
                              // console.log("tasks", tasks);
                              // console.log("taskCurrent", taskCurrent);
                              // if (taskCurrent && taskCurrent.id ) {
                              let config = {
                                method: "post",
                                url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/bp/${selectedBp}/start`,
                                headers: headers,
                              };

                              axios(config)
                                .then((res) => {
                                  /************************************************************************************ */

                                  // Запрос чтобы поставить задаче статус "Выполнено"
                                  let config2 = {
                                    method: "post",
                                    url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/complete`,
                                    headers: headers,
                                  };

                                  axios(config2)
                                    .then(function (response) {
                                      setChange((prev) => prev + 1);
                                      // console.log(JSON.stringify(response.data));

                                      // Запрос на завершение задачи
                                      let config3 = {
                                        method: "post",
                                        url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskCurrent.id}/finish`,
                                        headers: headers,
                                      };

                                      axios(config3)
                                        .then(function (response) {
                                          setCreateBpFromSample(false); // Завершаем быстрое создание бизнес-процесса по шаблону
                                          setChange((prev) => prev + 1);
                                          // console.log(
                                          //   JSON.stringify(response.data)
                                          // );
                                        })
                                        .catch((e) => {
                                          console.log(e);
                                        });
                                    })
                                    .catch((e) => {
                                      console.log(e);
                                    });

                                  /**************************************************************************************** */
                                  setBpListChange((prev) => !prev);
                                  setChange((prev) => prev + 1);
                                })

                                .catch((e) => {
                                  console.log(e);
                                });

                              setStatusTaskId(taskId);
                              setStatusBpId(bp.id);
                              setStatusBp(2);
                              setStatusTask((prev) => changeStatus(prev));
                              setOpenMenuTasks(false);
                              setOpenMenuBp(false);
                            } else {
                              showErrors();
                            }
                          }

                          // setTaskCurrent(...,status:3)

                          // console.log("bp", bp);
                        }}
                      >
                        Стартовать Типовой бизнес-процесс {/* увольнение */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* ******************************************************* */}
              </div>
            )}
          {
            openMenuBp && thisTabs === 3 && !taskId && businessProcess && (
              // (userId === businessProcessCurrentGlobal.initiator_id ||
              //   userId === taskCurrentGlobal.executor_id) && (
              <div>
                <ChatBp />
              </div>
            )
            // )
          }

          {openMenuBp &&
            thisTabs === 3 &&
            taskCurrent &&
            taskId &&
            (userId === businessProcessCurrentGlobal?.initiator_id ||
              userId === taskCurrentGlobal?.executor_id) && (
              <Chat
                userId={userId}
                etTaskId={taskCurrentGlobal?.et_task_id}
                bearer={bearer}
                company_id={company_id}
                taskCurrent={taskCurrent}
              />
            )}

          {/* Для показа общего результата в БП шаблона "Договор" */}
          {openMenuBp &&
            // showGeneralResult &&

            thisTabs === 2 &&
            !taskId &&
            businessProcess &&
            businessProcess.template_id == "treaty" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5 + "px",
                }}
                className="deleteArrow"
              >
                <span>Инициирование договора</span>
                <div className="blockOfForm">
                  <BpResultFormTreaty />
                </div>
                <span>Проверка руководителем подразделения</span>
                <div className="blockOfForm">
                  <CheckOfHeadOfDepartment />
                </div>
                <span>Проверка юристом</span>
                <div className="blockOfForm">
                  <CheckOfJurist />
                </div>
                <span>Проверка главным бухгалтером</span>
                <div className="blockOfForm">
                  <СheckOfChiefAccountant />
                </div>
                <span>Проверка исполнительным директором</span>
                <div className="blockOfForm">
                  <CheckOfExecutiveDirector />
                </div>
                <span>Проверка финансовым директором</span>
                <div className="blockOfForm">
                  <CheckOfFinanceDirector />
                </div>
                <span>Финальная проверка юристом</span>
                <div className="blockOfForm">
                  <CheckFinalOfJurist />
                </div>
                <span>Подписание договора</span>
                <div className="blockOfForm">
                  <SigningOfTreaty />
                </div>
                <span>Внесение данных в 1С УТ</span>
                <div className="blockOfForm">
                  <EnteringDataInto1CUT />
                </div>
                <span>Финальная регистрация юристом</span>
                <div className="blockOfForm">
                  <FinalRegistrationByJurist />
                </div>
              </div>
            )}

          {/* Для показа общего результата в БП шаблона "Прием на работу" */}
          {openMenuBp &&
            // showGeneralResult &&
            thisTabs === 2 &&
            !taskId &&
            businessProcess &&
            businessProcess.template_id == "work" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5 + "px",
                }}
                className="deleteArrow"
              >
                <span>Инициирование договора</span>
                <div className="blockOfForm">
                  <RecruitInitial />
                </div>
                <span>Проверка руководителем подразделения</span>
                <div className="blockOfForm">
                  <CheckSecurity />
                </div>
                <span>Проверка юристом</span>
                <div className="blockOfForm">
                  <AcceptByHeadOfDep />
                </div>
                <span>Проверка главным бухгалтером</span>
                <div className="blockOfForm">
                  <AgreementByDeputyGenManager />
                </div>
                <span>Проверка исполнительным директором</span>
                <div className="blockOfForm">
                  <ForkedStage_SMZ_IP />
                </div>
                <span>Проверка финансовым директором</span>
                <div className="blockOfForm">
                  <Signing />
                </div>
                <span>Финальная проверка юристом</span>
                <div className="blockOfForm">
                  <ForkedStage_GPH_TD />
                </div>
                <span>Подписание договора</span>
                <div className="blockOfForm">
                  <EmployeeRegistration />
                </div>
                <span>Внесение данных в 1С УТ</span>
                <div className="blockOfForm">
                  <Completion_3monthTimer />
                </div>
                <span>Финальная регистрация юристом</span>
                <div className="blockOfForm">
                  <AcceptByDeputyGenManager />
                </div>
              </div>
            )}

          {/* Для показа общего результата в БП шаблона "Увольнение" */}
          {openMenuBp &&
            // showGeneralResult &&
            thisTabs === 2 &&
            !taskId &&
            businessProcess &&
            businessProcess.template_id == "dismissal" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5 + "px",
                }}
                className="deleteArrow"
              >
                <span>Инициирование увольнения</span>
                <div className="blockOfForm">
                  <BpResultFormDismissal />
                </div>
                <span>Первичное оформление документов</span>
                <div className="blockOfForm">
                  <InitialProcessingOfDocumentsDismissal />
                </div>
                <span>Подписание документов</span>
                <div className="blockOfForm">
                  <SigningOfTreatyDismissal />
                </div>
                <span>Подтверждение оплаты бухгалтерского расчета</span>
                <div className="blockOfForm">
                  <ConfirmationOfPaymentOfAccountingCalculationDismissal />
                </div>
                <span>Действия с учетной записью сотрудника</span>
                <div className="blockOfForm">
                  <EmployeeAccountActionsDismissal />
                </div>
                <span>Подготовка и согласование итоговой выплаты по УТ</span>
                <div className="blockOfForm">
                  <PreparationAndApprovalFinalPaymentForUTDismissal />
                </div>
                <span>Итоговый расчет с сотрудником</span>
                <div className="blockOfForm">
                  <FinalSettlementWithEmployeeDismissal />
                </div>
                <span>Завершение бизнес-процесса</span>
                <div className="blockOfForm">
                  <CompletionOfBPDismissal />
                </div>
              </div>
            )}

          {/* Для показа общего результата не типового БП  */}
          {openMenuBp &&
            showGeneralResult &&
            !taskId &&
            thisTabs === 2 &&
            businessProcess &&
            taskCurrent?.status >= 3 &&
            !businessProcess?.template_id && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 35 + "px",
                }}
              >
                {/* <div className="chatMenu-header__nav-item chatMenu-header__nav-item-active"> */}
              </div>
            )}

          {openMenuBp &&
            thisTabs === 2 &&
            taskId &&
            businessProcess &&
            taskCurrent.status >= 2 &&
            !businessProcess.template_id && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 35 + "px",
                }}
              >
                {/* <div className="chatMenu-header__nav-item chatMenu-header__nav-item-active"> */}

                <NoSampleForm />
              </div>
            )}
        </div>
        {taskId &&
          thisTabs === 2 &&
          taskCurrent.et_task_id &&
          (businessProcess.initiator_id === userId ||
            taskCurrent.executor_id === userId ||
            isAdmin) && <ChildTaskButton etTaskId={taskCurrent.et_task_id} />}
      </div>
    );
  } else return null;
};

export default ChatMenu;
