import { fetchTasks } from './../Projects/actions';
import { Action } from 'redux';
import { File, Task } from '../../common/types';
import { Type } from 'typescript';

export enum types {
  UPDATE_STATUS_WINDOW_TRUE = '@@TASKS/UPDATE_STATUS_WINDOW_TRUE',   // 571 задача redmine ********************************* 
  UPDATE_STATUS_WINDOW_FALSE = '@@TASKS/UPDATE_STATUS_WINDOW_FALSE', // 571 задача redmine ********************************* 
  CHANGE_TASK_LIST = '@@TASKS/CHANGE_TASK_LIST',
  FETCH_TASK_DEPENDENCE = '@@TASKS/FETCH_TASK_DEPENDENCE',
  CHANGE_VISUALISATION_LIST = '@@TASKS/CHANGE_VISUALISATION_LIST', //Доработать в сслучае добавление подгрузки при скроле
  FETCH_TASKS = '@@TASKS/FETCH_TASKS',
  FETCH_TASKS_VISUALIZATION = '@@TASKS/FETCH_TASKS_VISUALIZATION',
  CLEAR_VISUALIZATION_LIST = '@@TASKS/CLEAR_VISUALIZATION_LIST',
  SET_SHOWN_TASK_INFO_PLATE = '@@TASKS/SET_SHOWN_TASK_INFO_PLATE',
  SET_TASK_ADD_MODE = '@@TASKS/SET_TASK_ADD_MODE',
  SET_FILTER_WIN_MODE = '@@TASKS/SET_FILTER_WIN_MODE',
  SET_LOADING_TASK = '@@TASKS/SET_LOADING_TASKS',
  SET_TABLE_FILTER = '@@TASKS/SET_TABLE_FILTER',
  SET_FILTERS = '@@TASKS/SET_FILTERS',
  SET_SELECTED_USER_ID = '@@TASK/SET_SELECTED_USER_ID',
  SET_SHOW_VISUALIZATION = '@@TASK/SET_SHOW_VISUALIZATION',
  SET_SHOW_DAYS_OFF = '@@TASK/SET_SHOW_DAYS_OFF',
  SET_STATUS_FILTER = '@@TASK/SET_STATUS_FILTER',
  SET_DRAFT_VALUE = '@@TASKS/SET_DRAFT_STATUS',
  SET_FILTERS_ADD_NEW_ROW = '@@TASKS/SET_FILTERS_ADD_NEW_ROW',
  SET_ALL_OR_ACTUAL = '@@TASKS/SET_ALL_OR_ACTUAL',
  SET_TABLE_ORDER = '@@TASKS/SET_TABLE_ORDER',
  SET_SHOW_COLUMNS_WINDOW = '@@TASKS/SET_SHOW_COLUMNS_WINDOW',
  SET_SHOWING_COLS = '@@TASKS/SET_SHOWING_COLS',
  SET_EXEC_AUTH = '@@TASKS/SET_EXEC_AUTH',
  LOAD_SPECIFIC_TASK = '@@TASKS/LOAD_SPECIFIC_TASK',
  NOTIFICATIONS_FOR_TASKS = '@@TASKS/NOTIFICATIONS_FOR_TASKS',
  READ_NOTIFICATION_BY_TASK = '@@TASKS/READ_NOTIFICATION_BY_TASK',
  TIME_SHEET_ACTIONS = '@@TASKS/TIME_SHEET_ACTIONS',
  CLOSING_DAY = '@@TASKS/CLOSING_DAY',
  CLOSING_DAY_HIDING_SIGNAL = '@@TASKS/CLOSING_DAY_HIDING_SIGNAL',
  LOCAL_TIMESHEET_RECORD = '@@TASKS/LOCAL_TIMESHEET_RECORD',
  UPLOAD_FILE_TASKS = '@@TASKS/UPLOAD_FILE_TASKS',
  TASK_DONE_CONFIRMATION = '@@TASKS/TASK_DONE_CONFIRMATION',
  DELETE_FILE = '@@TASKS/DELETE_FILE',
  SEND_COMMENT = '@@TASKS/SEND_COMMENT',
  TIMESHEET_CACHE_RECORD = '@@TASKS/TIMESHEET_CACHE_RECORD',
  CLOSING_DAY_DISPATCH_ALL = '@@TASKS/CLOSING_DAY_DISPATCH_ALL',
}

// 571 задача redmine ********************************* 
//****************************************************************** */
export interface updateStatusActionTrue extends Action<types.UPDATE_STATUS_WINDOW_TRUE> {
  statusWindow:boolean;
}
//******************************************************************** */

//****************************************************************** */
export interface updateStatusActionFalse extends Action<types.UPDATE_STATUS_WINDOW_FALSE> {
  statusWindow:boolean;
}
//******************************************************************** */

export interface fetchTasksAction extends Action<types.FETCH_TASKS> {
  pageNum: number;
}

export interface changeTaskListAction extends Action<types.CHANGE_TASK_LIST> {
  tasks: Task[];
}

export interface fetchTasksVisualizationAction
  extends Action<types.FETCH_TASKS_VISUALIZATION> {
  selectedUserId: number;
}
export interface fetchTaskDependenceAction
  extends Action<types.FETCH_TASK_DEPENDENCE> {
  id: number;
}

export interface changeVisualisationListAction
  extends Action<types.CHANGE_VISUALISATION_LIST> {
  tasks: Task[];
}

export interface clearVisualizationLish
  extends Action<types.CLEAR_VISUALIZATION_LIST> {}

export interface setTaskAddModeAction extends Action<types.SET_TASK_ADD_MODE> {
  mode: boolean;
}

export interface setFilterAddModeAction
  extends Action<types.SET_FILTER_WIN_MODE> {
  mode: boolean;
}

export interface setFilterDraftAction extends Action<types.SET_DRAFT_VALUE> {
  draft: number;
}

export interface setFilterAddNewRow
  extends Action<types.SET_FILTERS_ADD_NEW_ROW> {
  value: boolean;
}

export interface setLoadingTasksAction extends Action<types.SET_LOADING_TASK> {
  isLoading: boolean;
}

export interface setTableFilterAction extends Action<types.SET_TABLE_FILTER> {
  filter: string;
}

export interface setSelectedUserIdAction
  extends Action<types.SET_SELECTED_USER_ID> {
  id: number;
}

export interface setShowVisulaizationAction
  extends Action<types.SET_SHOW_VISUALIZATION> {
  show: boolean;
}

export interface setShowColumnsCustomAction
  extends Action<types.SET_SHOW_COLUMNS_WINDOW> {
  show: boolean;
}

export interface setShowDaysOffAction extends Action<types.SET_SHOW_DAYS_OFF> {
  show: boolean;
}

export interface setAllOrActualAction extends Action<types.SET_ALL_OR_ACTUAL> {
  value: object;
}

export interface setStatusFilterAction extends Action<types.SET_FILTERS> {
  filterObject: object;
}

export interface setTableOrderAction extends Action<types.SET_TABLE_ORDER> {
  col_name: string;
  order_direct: boolean;
}

export interface setShowableColumns extends Action<types.SET_SHOWING_COLS> {
  value: string;
}

export interface setExecAuthAction extends Action<types.SET_EXEC_AUTH> {
  val: string;
}

export interface loadSpecificTaskAction
  extends Action<types.LOAD_SPECIFIC_TASK> {
  value: number;
}

export interface notificationsForTasksAction
  extends Action<types.NOTIFICATIONS_FOR_TASKS> {
  value: any[];
}

export interface readNotificationByTaskAction
  extends Action<types.READ_NOTIFICATION_BY_TASK> {
  id_task: number;
}

export interface procedureFinishTask
  extends Action<types.TASK_DONE_CONFIRMATION> {
  show: boolean;
  taskId: number;
  short_version: boolean;
}

export interface timeSheetActionsAction
  extends Action<types.TIME_SHEET_ACTIONS> {
  actionType: string;
  taskId: number;
  value: number;
  timesheetId?: number;
}

export interface LocalTimeSheetRecordAction
  extends Action<types.LOCAL_TIMESHEET_RECORD> {
  actionType: string;
  taskId: number;
  task_load_sum?: number | null;
  timesheetId?: number | null;
  status_id?: number | null;
  commentText?: string;
  commentFiles?: File[];
}

export interface closingDayAction extends Action<types.CLOSING_DAY> {
  value: boolean;
}

export interface closingDayHidingSignalAction extends Action<types.CLOSING_DAY_HIDING_SIGNAL> {
  value: boolean;
}

export interface uploadFileAction extends Action<types.UPLOAD_FILE_TASKS> {
  file: File;
}

export interface deleteFileAction extends Action<types.DELETE_FILE> {
  attachmentId: number;
  taskId?: number;
}

export interface sendCommentAction extends Action<types.SEND_COMMENT> {
  task_Id?: number;
  commentText?: string;
}

export interface sendCommentAction extends Action<types.SEND_COMMENT> {
  task_Id?: number;
  commentText?: string;
}

export interface timesheetCacheRecordAction
  extends Action<types.TIMESHEET_CACHE_RECORD> {
  actionType: string;
  task_id?: number;
  task_load_sum?: number;
  status_id?: number;
}

export interface closingDayDispatchAllAction
  extends Action<types.CLOSING_DAY_DISPATCH_ALL> {}

// 571 задача redmine *********************************   
export type TasksAction =
  |updateStatusActionTrue
  |updateStatusActionFalse
  | fetchTasksAction
  | fetchTaskDependenceAction
  | changeTaskListAction
  | setTaskAddModeAction
  | setFilterAddModeAction
  | setLoadingTasksAction
  | setTableFilterAction
  | setSelectedUserIdAction
  | setShowVisulaizationAction
  | setShowDaysOffAction
  | setStatusFilterAction
  | setFilterDraftAction
  | setFilterAddNewRow
  | changeVisualisationListAction
  | setAllOrActualAction
  | setTableOrderAction
  | setShowColumnsCustomAction
  | setShowableColumns
  | setExecAuthAction
  | loadSpecificTaskAction
  | clearVisualizationLish
  | notificationsForTasksAction
  | procedureFinishTask
  | timeSheetActionsAction
  | closingDayAction
  | LocalTimeSheetRecordAction
  | uploadFileAction
  | deleteFileAction
  | sendCommentAction
  | closingDayDispatchAllAction
  | timesheetCacheRecordAction
  | closingDayHidingSignalAction
  | readNotificationByTaskAction;
