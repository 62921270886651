// Создание новой задачи во многом перенесено а каталог TaskInfoPlate
// Файлы из этого каталога возможно не используются- надо проверять

import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import NameDescriptionTextField from "../../common/components/Inputs/NameDescriptionTextField";
import PriorityToggler from "../../common/components/Togglers/PriorityToggler";
import { setTaskAddMode } from "../Tasks/actions";
import {
  clearTask,
  setTaskName,
  setTaskDescription,
  setTaskPriority,
  createTask,
  // delegateTask,
  setProvideTo,
  setTaskExecutor,
  setTaskProject,
  fetchProjectSections,
} from "./actions";
import ProjectSelect from "./components/ProjectSelect";
import SectionSelect from "./components/SectionSelect";
import TaskBegin from "./components/TaskBegin";
import ExecutorSelect from "./components/ExecutorSelectWithDelegate";
import TaskEnd from "./components/TaskEnd";
import Author from "./components/Author";
import TaskWorkLoad from "./components/TaskWorkLoad";
import QualitySpeedToggler from "./components/QualitySpeedToggler";
import TaskAddictions from "./components/TaskAddictions";
import { State } from "../../rootReducer";
import { getCanSend, projectSelectData } from "./selectors";
import { Item } from "../../common/types";
import styled from "styled-components";
import NameWithInput from "../../common/components/newComponent/Inputs/NameWithInput";
import CustomInput from "../../common/components/newComponent/Inputs/CustomInput";
import MultySelect from "../../common/components/Selects/MultySelect";
import Chips from "../../common/components/ChipsItem";
import Button from "../../common/components/newComponent/Buttons/Button";
import Prioritys from "../../common/components/Priority/Prioritys";

type Props = {
  // taskId: number;
  // name: string;
  // description: string;
  // priority_id: number;
  canSend: boolean;
  // isSending: boolean;
  // delegated: boolean;
  // executor_id: number | null;
  setTaskAddMode: (mode: boolean) => void;
  setTaskName: (value: string) => void;
  setTaskDescription: (value: string) => void;
  setTaskPriority: (priority: number) => void;
  // delegateTask: (executor_id: number, taskId: number) => void
  clearTask: () => void;
  createTask: (executor_id: number) => void;
  setTaskExecutor: (val: number | null) => void;
};

const BlockNewTask = styled.div`
  display: flex;
  /* justify-content: center;
  align-items: center; */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  max-width: 584px;
  min-height: 100vh;
  z-index: 1;
  flex-direction: column;
`;
const WrapperNewTask = styled.div`
  padding: 1rem;
  background: #fff;
  margin: auto;
  border-radius: 12px;
  transition: transform 0.3s ease;
  transform: ${({ visible }) => {
    return visible ? "translateY(0vh)" : "translateY(-100vh)";
  }};
  box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);
`;
const BlockInformation = styled.div`
  height: auto;
  width: 100%;
`;
const BlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const WrapperChipsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const BlockButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 1rem;
`;

const TaskAddPannel: React.FC<Props> = ({
  canSend,
  setTaskAddMode,
  setTaskName,
  setTaskDescription,
  setTaskPriority,
  // delegateTask,
  clearTask,
  createTask,
  setTaskExecutor,
}) => {
  const dispatch = useDispatch();

  const state: State = useSelector((state: State) => state);
  const {
    id: taskId,
    author_id,
    name,
    description,
    priority_id,
    isSendingTask,
    delegated,
    executor_id,
  } = state.newTask;
  const { users, current_user } = state.commonInfo;
  const userId = taskId ? author_id : current_user;
  const author = users.find((user) => user.id === userId);

  const selectDataProject = projectSelectData(state);

  const [selectedItems, setSelectedItems] = useState<Item[]>([]); // Сотрудники, которые выбраны в чипсах
  const [visibleForm, setVisibleForm] = useState(false);

  useEffect(() => {
    setVisibleForm(true);
  }, [setVisibleForm]);

  const closeHandler = useCallback(() => {
    setVisibleForm(false);
    setTimeout(() => {
      setTaskAddMode(false);
      clearTask();
    }, 300);
  }, [clearTask, setTaskAddMode]);

  useEffect(() => {
    // исполнитель берется первым по массиву, и устанавливается для рассчета taskworkload и прочих параметров
    if (selectedItems.length) setTaskExecutor(selectedItems[0].value as number);
    else setTaskExecutor(null);
  }, [selectedItems]);

  // только по нажатию кнопки "сохранить"
  const confirmHandler = useCallback(() => {
    selectedItems.forEach((item) => createTask(item.value as number));
  }, [createTask, delegated, selectedItems, executor_id, taskId]);

  // self-close for window
  let plate = useRef<HTMLDivElement>(null);
  const outsideClickHandler = useCallback(
    function (e: Event): any {
      let element = e.target as Node;
      if (element === plate.current) closeHandler();
    },
    [closeHandler]
  );
  useEffect(() => {
    document.addEventListener("click", outsideClickHandler);
    return () => document.removeEventListener("click", outsideClickHandler);
  }, [outsideClickHandler]);
  // self-close for window

  return (
    <BlockNewTask ref={plate}>
      <WrapperNewTask visible={visibleForm}>
        <BlockInformation>
          {/* <BlockHeader className="head">
          <div className="panel_main_title">
            {taskId === 0 ? 'Новая задача' : 'Данные задачи'}
          </div>
          <PriorityToggler
            priority={priority_id}
            selectHandler={setTaskPriority}
          />
        </BlockHeader> */}

          <CustomInput
            type="textarea"
            placeholder="Введите название задачи"
            value={name}
            disabled={delegated}
            inputChangeHandler={setTaskName}
            style={{
              borderColor: "transparent",
              fontSize: "20px",
              lineHeight: "1.5rem",
              paddingLeft: "0",
              backgroundColor: "transparent",
              marginBottom: "1rem",
            }}
            // disabled={delegated}
          />
          <CustomInput
            type="textarea"
            value={description}
            placeholder="Описание"
            disabled={delegated}
            inputChangeHandler={setTaskDescription}
            // disabled={delegated}
          />

          <NameWithInput
            title="Проект"
            iconName="folder"
            // disabled={delegated}
          >
            <ProjectSelect />
          </NameWithInput>

          <NameWithInput title="Раздел" iconName="folder">
            <SectionSelect />
          </NameWithInput>

          <NameWithInput title="Приоритет" iconName="prioritySelect">
            <Prioritys bigSize />
          </NameWithInput>

          <NameWithInput title="Автор" iconName="people">
            <WrapperChipsBlock>
              <Chips
                image={author?.image?.url}
                text={`${author?.surname} ${author?.name} 
            ${author?.patronymic === null ? "" : author?.patronymic}`}
                disabled
              />
            </WrapperChipsBlock>
          </NameWithInput>

          <NameWithInput title="Исполнитель" iconName="people">
            <ExecutorSelect
              selectedItems={selectedItems}
              setSelectedItems={(arg) => setSelectedItems(arg)}
            />
          </NameWithInput>

          <NameWithInput title="Начало" iconName="calendar-dark">
            <TaskBegin />
          </NameWithInput>

          <NameWithInput title="Дедлайн" iconName="calendar-dark">
            <TaskEnd />
          </NameWithInput>

          <NameWithInput title="Загрузка" iconName="percent">
            <TaskWorkLoad selectedItems={selectedItems} />
          </NameWithInput>

          {/* <NameWithInput >
          <QualitySpeedToggler />
        </NameWithInput> */}

          {/* <NameWithInput title="Зависимость" iconName="relation">
          <TaskAddictions />
        </NameWithInput> */}
        </BlockInformation>
        <BlockButtons visible={visibleForm}>
          <Button title="Отмена" onClick={closeHandler} design="secondary" />
          <Button
            isSending={isSendingTask}
            title="Сохранить"
            onClick={confirmHandler}
            disabled={!canSend && !taskId}
            style={{ marginLeft: ".5rem" }}
          />
        </BlockButtons>
      </WrapperNewTask>
    </BlockNewTask>
  );
};

const mapStateToProps = (state: State) => {
  return {
    canSend: getCanSend(state),
  };
};

const mapDispatchToProps = {
  setTaskAddMode,
  setTaskName,
  setTaskDescription,
  clearTask,
  setTaskPriority,
  createTask,
  setTaskExecutor,
  // delegateTask
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskAddPannel);
