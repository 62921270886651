import React from 'react';
import { connect } from 'react-redux';
import { setProjectChief } from '../actions';
import { getChiefSelecData, getProjectChief } from '../selectors';
import { State } from '../../../rootReducer';
import MultySelect from '../../../common/components/Selects/MultySelect';

type Props = {
  placeholder?: string;
  chiefSelectData: any;
  setProjectChief: (head_of_departments: number[]) => void;
  chief_ids: any;
};

const ChiefSelect: React.FC<Props> = ({
  placeholder,
  chiefSelectData,
  chief_ids,
  setProjectChief,
}) => {
  return (
    <MultySelect
      disabled={false}
      placeholder={placeholder}
      itemsList={chiefSelectData.list}
      selectedItems={chiefSelectData.selected}
      isValid={chiefSelectData.selected.length > 0}
      addHandler={(value: number) => {
        setProjectChief([...chief_ids, value as number]);
      }}
      removeHandler={(value: number) => {
        setProjectChief(chief_ids.filter((id) => id !== value));
      }}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    chief_ids: getProjectChief(state),
    chiefSelectData: getChiefSelecData(state),
  };
};

const mapDispatchToProps = {
  setProjectChief,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChiefSelect);
