// Выбор должности для добавления пользователя в компанию

import React, { useEffect } from "react"; //***************************************** */
import withTitle from "../../../utils/hoc/withTitle";
import { connect, useSelector } from "react-redux"; //**************************************** */
import SelectWithSecondaryBtnPosition from "../../../common/components/Selects/SelectWithSecondaryBtnPosition";
import { setUserPosition } from "../actions";
import { State } from "../../../rootReducer";
import { getPosSelectData } from "../selectors";
import { SelectData, User } from "../../../common/types";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";

type Props = {
  posSelectData: SelectData;
  userId: number;
  currentUserInfo: User | null;
  setUserPosition: (position: number[]) => void;
  setChangeDataUser: any;
  rolesUser: number[];
  title?: string;
  isAddUserInCompany?: boolean;
};

//Должность можно менять для новых пользователей, и для других пользователей, если ты админ или директор

const PositionSelect: React.FC<Props> = ({
  posSelectData,
  userId,
  currentUserInfo,
  rolesUser,
  setUserPosition,
  setChangeDataUser,
  title,
  isAddUserInCompany,
}) => {
  // console.log(posSelectData);

  // ***********************  Получение информации из state
  // const { positions} = useSelector(
  //   (state: State) => state.commonInfo
  // );
  //  console.log("positions***",positions)
  //****************************************************** */

  // console.log("isAddUserInCompany",isAddUserInCompany)
  //useEffect(()=>{console.log(setUserPosition);
  //if (isAddUserInCompany===true) { setUserPosition([positions[0].id]); }
  //},[isAddUserInCompany])  //******************************************** */

  const positionId = localStorage.getItem("user_position");

  useEffect(() => {
    if (positionId) {
      setChangeDataUser((prev) => ({
        ...prev,
        data: { ...prev.data, positions: [positionId] },
        schedule: { ...prev.schedule },
      }));
      localStorage.removeItem("user_position");
    }
  }, [positionId]);

  return (
    <NameWithInput title={title} iconName="bag">
      <SelectWithSecondaryBtnPosition
        placeholder="Выберите из списка или введите новую"
        disabled={
          !(
            userId === 0 ||
            (userId === currentUserInfo?.id &&
              currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]) ||
            currentUserInfo?.roles.includes(1) ||
            currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]
          )
        }
        list={posSelectData.list}
        selected={posSelectData.selected}
        selectHandler={(value) => {
          setUserPosition([value as number]);
          (userId !== 0 || isAddUserInCompany) &&
            setChangeDataUser((prev) => ({
              ...prev,
              data: { ...prev.data, positions: [value] },
              schedule: { ...prev.schedule },
            }));
        }}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => ({
  posSelectData: getPosSelectData(state),
  userId: state.newUser.id,
  rolesUser: state.newUser.roles,
  currentUserInfo: state.commonInfo.currentUserInfo,
});

const mapDispatchToProps = {
  setUserPosition: setUserPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionSelect);
