import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { StatusContext } from "../../../context/status";
import { ClickAwayListener } from "@material-ui/core";

const AgreeButton = ({
  headers,
  changeStatus,
  checkError,
  addError,
  inputError,
  showErrors,
}) =>
  //   executors
  {
    const {
      setOpenMenuTasks,
      setOpenMenuBp,
      users,
      setStatusTask,
      setStatusBp,
      setStatusBpId,
      setStatusTaskId,
      setChange,
      taskId,
      taskCurrentGlobal,
      businessProcessCurrentGlobal,
    } = useContext(StatusContext);

    const [openWindow, setOpenWindow] = useState(false);

    const [usersArr, setUsersArr] = useState([
      { value: "", userId: "", inputValue: "" },
    ]);

    const agreeRequest = () => {
      
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/task/${taskId}/review`,
        headers: headers,
        data: JSON.stringify({
          reviewerId: usersArr[0].userId,
        }),
      };

      axios(config)
        .then((res) => {
          setChange((prev) => 7);
        })
        .then((res) => {
          setStatusTaskId(taskId);
          setStatusBpId(businessProcessCurrentGlobal.id);
          setStatusBp(7);
          setStatusTask((prev) => changeStatus(prev));
          setOpenMenuTasks(false);
          setOpenMenuBp(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const inputValueExecutor = (value) => {
      let newValue = value.split(" ").slice(1).join(" ");
      let userId = value.split(" ").shift();

      setUsersArr(
        usersArr.map((item) => {
          return {
            ...item,
            value: newValue,
            userId: userId,
            inputValue: value,
          };
        })
      );
    };

    const executors = useMemo(() => users, [users]);

    return (
      <ClickAwayListener
      onClickAway={() => {
        setOpenWindow(false);
      }}
    >
      <div className="chatMenu-footer">
        <div className="chatMenu-footer__nav">
          <div
            className={
              "chatMenu-footer__nav-item chatMenu-footer__nav-item-active"
            }
            onClick={() => {
              if (checkError || addError || inputError) {
                showErrors();
              } else {
                setOpenWindow(true);
              }
            }}
          >
            Согласовать с
          </div>
          {openWindow && (
            <div className="agree-window">
              <div
                style={{ cursor: "pointer" }}
                className="chatMenu-header__close"
                onClick={() => {
                  setOpenWindow(false);
                }}
              >
                Закрыть
              </div>
              <label
                className="p__drop-content"
                htmlFor="businessTask__executor"
              >
                <img
                  src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/User.svg`}
                />
                Согласовать с
              </label>

              <input
                list={123}
                style={{ marginLeft: "0px", width: 400 }}
                className="input-form "
                id="demo-simple-select-filled"
                value={usersArr.value}
                onChange={(e) => {
                  inputValueExecutor(e.target.value);
                }}
              />

              <datalist id={123}>
                {executors.map((executor) => (
                  <option key={executor.id}>
                    {`${executor.id}  ${executor.name} ${executor.surname}`}
                  </option>
                ))}
              </datalist>

              {/* <select
                className="input-form"
                id="businessTask__executor"
                value={selectedExecutor}
                style={{
                  cursor: "pointer",
                }}
                onChange={(e) => {
                  setSelectedExecutor(e.target.value);
                }}
              >
              
                <option value={""}></option>
                {executors.map((executor) => {
                  if (executor.id !== taskCurrentGlobal.executor_id) {
                    return (
                      <option key={executor.id} value={executor.id}>
                        {executor.name} {executor.surname}{" "}
                      </option>
                    );
                  }
                })}
              </select> */}
              <div
                className="chatMenu-footer__nav-item chatMenu-footer__nav-item-active agree-button"
                style={{ marginTop: "10px", width: "160px" }}
                onClick={(e) => {
                  agreeRequest();
                  setOpenWindow(false);
                }}
              >
                Направить на согласование
              </div>
            </div>
          )}
        </div>
      </div>
    </ClickAwayListener>
    );
  };

export default AgreeButton;
