import React, {useCallback, useEffect} from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import {connect} from 'react-redux';
import ScheduleDay from './ScheduleDay';
import {
    setUserDaysOff,
    setUserSchedule,
    setUserWorkBegin,
    setUserWorkEnd,
} from '../actions';
import TimePiker from '../../../common/components/DatePickers/TimePicker';
import {State} from '../../../rootReducer';
import {DayOff, User} from '../../../common/types';
import {fetchData} from '../../../utils/fetchData';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

const weekDays: { day: number; name: string }[] = [
    {day: 1, name: 'Пн'},
    {day: 2, name: 'Вт'},
    {day: 3, name: 'Ср'},
    {day: 4, name: 'Чт'},
    {day: 5, name: 'Пт'},
    {day: 6, name: 'Сб'},
    {day: 7, name: 'Вс'},
];

type Props = {
    schedule: number[];
    workBegin: string;
    workEnd: string;
    userId: number;
    currentUserInfo: User | null;
    setUserSchedule: (schedule: number[]) => void;
    setUserWorkBegin: (time: string) => void;
    setUserWorkEnd: (time: string) => void;
    daysOff: DayOff[];
    setUserDaysOff: (daysOff: DayOff[]) => void;
    setChangeDataUser: any;
    rolesUser: number[];
};

//расписание можно менять для новых пользователей и если ты админ или директор

const UserSchedule: React.FC<Props> = ({
                                           schedule,
                                           workBegin,
                                           workEnd,
                                           userId,
                                           currentUserInfo,
                                           setUserSchedule,
                                           setUserWorkBegin,
                                           setUserWorkEnd,
                                           daysOff,
                                           setUserDaysOff,
                                           setChangeDataUser,
                                           rolesUser,
                                       }) => {
    useEffect(() => {
        (async () => {
            if (userId === 0) return;
            const data = await fetchData.get(`/api/v1/users/${userId}/schedule`);
            if (data?.schedule) {
                const days = data.schedule.weekDays.map((day) => day.day);
                const {begin, end} = data.schedule.weekDays[0].time[0]; // пока одно начало на все дни, оставляем так, когда будет расширенный график - нужно будет переделать
                setUserSchedule(days);
                setUserWorkBegin(begin);
                setUserWorkEnd(end);
                const dayOff = data.schedule.daysOff;
                setUserDaysOff(dayOff);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, setUserSchedule]);

    const addHandler = useCallback(
        (day: number) => {
            setUserSchedule([...schedule, day]);
        },
        [schedule, setUserSchedule]
    );

    const removeHandler = useCallback(
        (day: number) => {
            let scheduleClone = schedule.slice();
            scheduleClone.splice(scheduleClone.indexOf(day), 1);
            setUserSchedule(scheduleClone);
        },
        [schedule, setUserSchedule]
    );

    return (
        <NameWithInput title="Время работы" iconName="clock">
            <div
                className={`user_schedule${
                    userId === 0 ||
                    currentUserInfo?.roles.includes(1) ||
                    userId === currentUserInfo?.id ||
                    currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]
                        ? ''
                        : ' disabled'
                }`}
            >
                <div className="weekdays_container">
                    {weekDays.map((weekday) => (
                        <ScheduleDay
                            key={weekday.day}
                            name={weekday.name}
                            selected={schedule.includes(weekday.day)}
                            addHandler={() => {
                                setChangeDataUser((prev) => ({
                                    data: {...prev.data},
                                    schedule: {
                                        ...prev.schedule,
                                        weekDays: [
                                            ...Array.from(new Set([...schedule, weekday.day])).map(
                                                (el) => ({
                                                    day: el,
                                                    time: [{begin: workBegin, end: workEnd}],
                                                })
                                            ),
                                        ],
                                    },
                                }));
                                addHandler(weekday.day);
                            }}
                            removeHandler={() => {
                                let clone: any = [...schedule];
                                let weekDaysArray = [
                                    ...clone.filter((el) => el !== weekday.day),
                                ];

                                setChangeDataUser((prev) => ({
                                    data: {...prev.data},
                                    schedule: {
                                        ...prev.schedule,
                                        weekDays: weekDaysArray.map((el) => {
                                            return {
                                                day: el,
                                                time: [{begin: workBegin, end: workEnd}],
                                            };
                                        }),
                                        // weekDays: [
                                        //   ...Array.from(new Set(clone)).map((el, i) => {
                                        //     if (el !== weekday.day) {
                                        //       return {
                                        //         day: el,
                                        //         time: [{ begin: workBegin, end: workEnd }],
                                        //       };
                                        //     } else {
                                        //       clone.splice(i, 1);
                                        //     }
                                        //   }),
                                        // ],
                                    },
                                }));
                                removeHandler(weekday.day);
                            }}
                        />
                    ))}
                </div>
                <div className="work_time_container">
                    <TimePiker
                        time={workBegin}
                        label="С"
                        acceptHandler={(time) => {
                            setChangeDataUser((prev) => ({
                                data: {...prev.data},
                                schedule: {
                                    ...prev.schedule,
                                    weekDays: [
                                        ...schedule.map((el) => ({
                                            day: el,
                                            time: [{begin: time, end: workEnd}],
                                        })),
                                    ],
                                },
                            }));
                            setUserWorkBegin(time);
                        }}
                    />
                    <TimePiker
                        time={workEnd}
                        label="До"
                        acceptHandler={(time) => {
                            setChangeDataUser((prev) => ({
                                data: {...prev.data},
                                schedule: {
                                    ...prev.schedule,
                                    weekDays: [
                                        ...schedule.map((el) => ({
                                            day: el,
                                            time: [{begin: workBegin, end: time}],
                                        })),
                                    ],
                                },
                            }));
                            setUserWorkEnd(time);
                        }}
                    />
                </div>
            </div>
        </NameWithInput>
    );
};

const mapStateToProps = (state: State) => {
    return {
        schedule: state.newUser.schedule,
        workBegin: state.newUser.workBegin,
        workEnd: state.newUser.workEnd,
        userId: state.newUser.id,
        rolesUser: state.newUser.roles,
        currentUserInfo: state.commonInfo.currentUserInfo,
        daysOff: state.newUser.daysOff,
    };
};

const mapDispatchToProps = {
    setUserSchedule: setUserSchedule,
    setUserWorkBegin: setUserWorkBegin,
    setUserWorkEnd: setUserWorkEnd,
    setUserDaysOff: setUserDaysOff,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSchedule);
